//imports
import { default as SelectComponent, StylesConfig } from "react-select";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid, regular } from "@fortawesome/fontawesome-svg-core/import.macro"
import FileUploadComponent from "../../../../../components/fileUpload/fileUpload";
import MiniModalController from "../../../../../components/modals/miniModal/miniModalController";
import ChatComponentController from "../../../../../components/chatComponent/chatComponentController";
import FullSizeModalController from "../../../../../components/modals/fullSizeModal/fullSizeModalController";
import Constants, { TIME_STATUS, COLOR_STATUS, ENROLLMENT_STATUS, ROLE, PERMISSIONS_CODES, ATTENDANCE_STATUS, ATTENDANCE_TYPE } from "../../../../../constants";
import noInfo from "../../../../../assets/images/noInfo.gif"
import { AttendancesModalViewProps, ColourOption } from "./interfaces";
import ExtraStudentModalCrontoller from "../extraStudentModal/extraStudentModalCrontoller";
import { Button, Tab, Tabs, OverlayTrigger, Tooltip } from "react-bootstrap";

//styles
import { styles } from './styles';
import "./styles.css";
import "./styles.scss";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../globals";

const AttendancesModalView = ({
    deleteSessions,
    createSessions,
    selectedSchedule,
    selectedAttendance,
    editableAttendance,
    baseEditableAttendance,
    selectedSession,
    idleLocked,
    checkPoint,
    toggleStudentAttendance,
    showModal,
    hideModal,
    sessionsCount,
    changeSession,
    sessionOptions,
    userProfile,
    fileChange,
    uploadDocuments,
    downloadDocuments,
    allowedFiletypes,
    downloadFile,
    selectedNote,
    showNoteModal,
    isShowNoteModal,
    closeNoteModal,
    changeNote,
    isNewAttendance,
    baseExtraStudentsList,
    extraStudentsList,
    setExtraStudentsList,
    openExtraStudentModal,
    showExtraStudentModal,
    closeExtraStudentModal,
    hasChanges,
    addClasses,
    historicAttendances,
    sessions,
    isLecture,
    showNoteModalLecture,
    activeTab,
    selectTab,
    showExtraStudentModalLecture,
    closeExtraStudentModalLecture,
    openExtraStudentModalLecture,
    extraStudentsListLecture,
    initialStudentsCount,
    currentStudentsCount,
    addDayProps,
    editDayProps,
    detailDayProps,
    cancellationOptionsProps,
    cancellationConfirmProps
}: AttendancesModalViewProps) => {

    const permissions: any[] = useSelector((state: RootState) => state.rootReducer.sessionState.permissions);

    const timeStatusMessages: { [key: string]: string } = {
        "1": `La session aún no ha comenzado vuelve a las ${selectedSession ? selectedSession.sessionHour : ''}`,
        "2": `${selectedSession ? selectedSession.sessionOn.split("/")[0] : ''} de ${selectedSession ? selectedSession.sessionOnMonth : ''}`,
        "3": 'La sesión tiene más de 30 minutos de iniciada, contacte a Servicios Académicos para registrar asistencias.',
        "4": 'La sesión de hoy ya terminó'
    }

    const dot = (color = 'transparent') => ({
        alignItems: 'center',
        display: 'flex',
        ':before': {
            backgroundColor: color,
            borderRadius: 10,
            content: '" "',
            display: 'block',
            marginRight: 8,
            height: 10,
            width: 10,
        },
    });

    const colourStyles: StylesConfig<ColourOption> = {
        control: (baseStyles, state) => ({
            ...baseStyles,
            ...styles.modal.header.select.component.v,
            width: "100%",
            borderColor: selectedSession ? selectedSession.color : "gray",
            borderWidth: "1px"
        }),
        option: (styles, { data, isDisabled, isFocused, isSelected }) => {
            const color = data.color;
            return {
                ...styles,
                backgroundColor: (
                    isSelected
                        ?
                        color
                        :
                        (
                            isFocused
                                ?
                                color + '11'
                                :
                                undefined
                        )
                ),
                color: (
                    isSelected
                        ?
                        'white'
                        :
                        color
                )
            };
        },
        input: (styles) => ({ ...styles, ...dot() }),
        placeholder: (styles) => ({ ...styles, ...dot('#ccc') }),
        singleValue: (styles, { data }) => ({ ...styles, ...dot(data.color) }),
        container: (styles) => ({ ...styles, zIndex: 10 })
    };

    return (
        <>
            <FullSizeModalController
                title={
                    <div>
                        <FontAwesomeIcon style={{ color: "rgb(143, 145, 218)", fontSize: "14px", marginRight: "10px" }} icon={solid("list-check")} />
                        <span style={{ color: "#8F91DA", fontSize: "14px" }}>
                            Asistencias
                        </span>
                    </div>
                }
                show={showModal}
                onClose={() => {
                    checkPoint(() => hideModal())
                }}
                headerContent={
                    <div className="row">
                        <div className="w-100">
                            <div className="d-flex flex-wrap">
                                <div className="w-100">
                                    <span className="d-flex justify-content-center modal_header_title_v"
                                        style={styles.modal.header.title.v}>
                                        {selectedSchedule.course}
                                        &nbsp;
                                        {selectedSchedule.level}
                                    </span>
                                    <div className="d-flex flex-row justify-content-center flex-wrap align-items-center">
                                        <div style={styles.modal.header.info.v}>
                                            <div style={styles.modal.header.info.course.v}>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v" style={styles.modal.header.info.card.span.v}>
                                                        Periodo:
                                                    </span>
                                                    {selectedSchedule.period}
                                                </div>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v" style={styles.modal.header.info.card.span.v}>
                                                        Horario:
                                                    </span>
                                                    {selectedSchedule.name}
                                                </div>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v" style={styles.modal.header.info.card.span.v}>
                                                        Aula:
                                                    </span>
                                                    {`${selectedSchedule.building} ${selectedSchedule.classroom}`}
                                                </div>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v" style={styles.modal.header.info.card.span.v}>
                                                        Código Horario:
                                                    </span>
                                                    {selectedSchedule.code}
                                                </div>
                                            </div>
                                            <div>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v blueDay">
                                                        Alumnos Iniciales:
                                                    </span>
                                                    {initialStudentsCount}
                                                </div>
                                                <div className="modal_header_info_card_span_v" style={styles.modal.header.info.card.v}>
                                                    <span className="modal_header_info_card_span_v greenSuccess">
                                                        Alumnos Actuales:
                                                    </span>
                                                    {currentStudentsCount}
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="d-flex flex-column align-items-center select-component"
                                            style={styles.modal.header.select.v}>
                                            <span className="modal_header_info_card_span_v" style={styles.modal.header.select.title.v}>
                                                Asistencia Disponible
                                            </span>
                                            {
                                                selectedSession
                                                    ?
                                                    (
                                                        userProfile.currentRole === ROLE.MENTOR
                                                            ?
                                                            (
                                                                selectedSession.isOnCurrentDay
                                                                    ?
                                                                    <span className="text-center">
                                                                        {timeStatusMessages[selectedSession.timeStatusId]}
                                                                    </span>
                                                                    :
                                                                    "Hoy no hay sesión"
                                                            )
                                                            :
                                                            <div className="d-flex flex-column">
                                                                <SelectComponent
                                                                    options={sessionOptions}
                                                                    onChange={(value: any) => {
                                                                        checkPoint(() => changeSession(value))
                                                                    }}
                                                                    styles={colourStyles}
                                                                    classNames={{
                                                                        control: (state) => ("styles_modal_select_control_v"),
                                                                        dropdownIndicator: (state) => ("styles_modal_select_dropdownIndicator_v"),
                                                                        option: (state) => ("styles_modal_select_option_v"),
                                                                        input: (state) => ("styles_modal_select_input_v"),
                                                                        singleValue: (state) => ("styles_modal_select_single_v")
                                                                    }}
                                                                    isDisabled={idleLocked}
                                                                    value={selectedSession}
                                                                />
                                                                <span className="modal_header_info_card_span_v text-center w-100" style={{ color: selectedSession.color }}>
                                                                    {
                                                                        selectedSession.color == COLOR_STATUS.FINALIZED ?
                                                                            "Asistencia registrada"
                                                                            :
                                                                            (
                                                                                selectedSession.color == COLOR_STATUS.AVAILABLE ?
                                                                                    "Asistencia por Registrar"
                                                                                    :
                                                                                    "No se registro Asistencia"
                                                                            )
                                                                    }
                                                                </span>
                                                            </div>
                                                    )
                                                    :
                                                    "Ninguno"
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }
                bodyContent={
                    <Tabs
                        activeKey={activeTab}
                        id="attendances-tabs"
                        className="mb-3"
                        onSelect={selectTab}
                    >
                        <Tab className="attendance-tab" eventKey="attendances" title="Asistencias">
                            {
                                (
                                    !selectedSession
                                    || !selectedAttendance
                                    || (userProfile.currentRole === ROLE.MENTOR && !selectedSession.isOnCurrentDay)
                                    || (userProfile.currentRole === ROLE.MENTOR && selectedSession.timeStatusId != TIME_STATUS.ON_TIME)
                                ) ?
                                    <div className="d-flex flex-column align-items-center mt-4">
                                        {
                                            idleLocked
                                                ?
                                                <span>Cargando...</span>
                                                :
                                                <>
                                                    <span>No hay información para mostrar</span>
                                                    <img width={120} src={noInfo} alt="no hay informacion" />
                                                </>
                                        }
                                    </div>
                                    :
                                    <div>
                                        <table className={`${userProfile.currentRole === ROLE.MENTOR ? "menotrStylesMobile" : ""} specialTable attendanceTable`}>
                                            <thead>
                                                <tr>
                                                    <th className="text-center" style={{ color: selectedSession.color }}>
                                                        {selectedSession.sessionOn}
                                                    </th>
                                                    <th>
                                                        Nombre
                                                    </th>
                                                    <th>
                                                        Estatus Matrícula
                                                    </th>
                                                    <th className="menotrStylesMobileItem">
                                                        Correlativo
                                                    </th>
                                                    <th className="menotrStylesMobileItem">
                                                        Última Actualización
                                                    </th>
                                                    <th className="text-center menotrStylesMobileItem">
                                                        Total a Fecha
                                                    </th>
                                                    <th className="text-center menotrStylesMobileItem">
                                                        %
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    selectedAttendance.map((enrollment) =>
                                                        <tr key={"S_" + enrollment.enrollmentId}
                                                            className={addClasses("enrollment", enrollment)}>
                                                            <td className="text-center">
                                                                {
                                                                    (
                                                                        enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE //active
                                                                            ?
                                                                            <div className="d-flex flex-column">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default d-flex flex-column align-items-center"
                                                                                    disabled={idleLocked}
                                                                                    onClick={() => { toggleStudentAttendance(enrollment.enrollmentId) }}
                                                                                >
                                                                                    <FontAwesomeIcon
                                                                                        size="lg"
                                                                                        icon={
                                                                                            editableAttendance[enrollment.enrollmentId].isPresent
                                                                                                ?
                                                                                                regular("square-check")
                                                                                                :
                                                                                                regular("square-minus")
                                                                                        } style={{
                                                                                            color: editableAttendance[enrollment.enrollmentId].isPresent ? "#00e669" : "#ec5353"
                                                                                        }} />
                                                                                    <span
                                                                                        className="modal_header_info_card_span_v"
                                                                                        style={{
                                                                                            fontSize: "12px",
                                                                                            color: editableAttendance[enrollment.enrollmentId].isPresent
                                                                                                ? "#00e669" : "#ec5353"
                                                                                        }}>
                                                                                        {
                                                                                            editableAttendance[enrollment.enrollmentId].isPresent
                                                                                                ? "Presente" : "Ausente"
                                                                                        }
                                                                                    </span>
                                                                                </button>
                                                                                {
                                                                                    userProfile.currentRole === ROLE.MENTOR
                                                                                        ?
                                                                                        ''
                                                                                        :
                                                                                        <button
                                                                                            onClick={() => { showNoteModal(editableAttendance[enrollment.enrollmentId]) }}
                                                                                            disabled={idleLocked}
                                                                                            style={{
                                                                                                ...styles.modal.body.addNote.v,
                                                                                                color: editableAttendance[enrollment.enrollmentId].attendanceNotes ? "#349dff" : ""
                                                                                            }}
                                                                                        >
                                                                                            <span style={styles.modal.body.addNote.span.v}>
                                                                                                {`Nota ${editableAttendance[enrollment.enrollmentId].attendanceNotes ? '' : '+'}`}
                                                                                            </span>
                                                                                        </button>
                                                                                }
                                                                            </div>
                                                                            :
                                                                            <button className="btn btn-default" style={styles.modal.body.noActive.v}>
                                                                                <FontAwesomeIcon
                                                                                    size="xl"
                                                                                    icon={
                                                                                        editableAttendance[enrollment.enrollmentId].isPresent
                                                                                            ?
                                                                                            regular("square-check")
                                                                                            :
                                                                                            solid("square")
                                                                                    } style={{
                                                                                        ...styles.modal.body.noActive.icon.v,
                                                                                        color: editableAttendance[enrollment.enrollmentId].isPresent
                                                                                            ?
                                                                                            "#00e66980"
                                                                                            :
                                                                                            styles.modal.body.noActive.icon.v.color
                                                                                    }} />
                                                                            </button>
                                                                    )
                                                                }
                                                            </td>
                                                            <td>
                                                                {enrollment.firstName} {enrollment.lastName}
                                                            </td>
                                                            <td
                                                                style={{ color: `${enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE ? '#00e669' : ''}` }}
                                                            >
                                                                {enrollment.enrollmentStatus}
                                                            </td>
                                                            <td className="menotrStylesMobileItem">
                                                                {enrollment.correlative}
                                                            </td>
                                                            <td className="menotrStylesMobileItem">
                                                                {enrollment.enrollmentUpdatedFullDate}
                                                            </td>
                                                            <td className="menotrStylesMobileItem">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="textMobile">
                                                                        {editableAttendance[enrollment.enrollmentId].attendanceSummary.attendedSessions}
                                                                    </div>
                                                                    <div className="textMobile px-1">
                                                                        /
                                                                    </div>
                                                                    <div className="textMobile">
                                                                        {editableAttendance[enrollment.enrollmentId].attendanceSummary.totalSessions}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end menotrStylesMobileItem" >
                                                                <div className="d-flex">
                                                                    <div className="textMobile flex-grow-1">
                                                                        { editableAttendance[enrollment.enrollmentId].attendanceSummary.attendancePercentage }
                                                                    </div>
                                                                    <div className="textMobile ps-1">
                                                                        %
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                            </tbody>
                                        </table>
                                        {
                                            !_.isEmpty(extraStudentsList) ?
                                                <div>
                                                    <h6 className="my-4 ms-2" style={{ color: "#8F91DA" }}>Estudiantes Visitantes</h6>
                                                    <table className="specialTable attendanceTable">
                                                        <thead>
                                                            <tr>
                                                                <th className="pb-2 text-align-center">
                                                                    {Constants.Common.identificationCode}
                                                                </th>
                                                                <th className="pb-2 text-align-center">
                                                                    Nombre
                                                                </th>
                                                                <th className="pb-2 text-align-center">
                                                                    Telefono
                                                                </th>
                                                                <th className="pb-2 text-align-center">
                                                                    Correlativo
                                                                </th>
                                                                <th className="pb-2 text-align-center">
                                                                    Notas
                                                                </th>
                                                                <th className="pb-2 text-align-center">
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                Object.values(extraStudentsList).map((extraStudent, index: number) =>
                                                                    <tr key={"eS_" + index}>
                                                                        <td>
                                                                            <p className="cellMobileStyles" style={{ fontSize: "14px", margin: 0 }}>
                                                                                {extraStudent.identificationType}-{extraStudent.identificationCode}
                                                                            </p>
                                                                        </td>
                                                                        <td style={{ fontSize: "14px", margin: 0 }}>
                                                                            {`${extraStudent.firstName ? extraStudent.firstName : ""} ${extraStudent.lastName ? extraStudent.lastName : ""}`}
                                                                        </td>
                                                                        <td style={{ fontSize: "14px", margin: 0 }}>
                                                                            {extraStudent.phone}
                                                                        </td>
                                                                        <td style={{ fontSize: "14px", margin: 0 }}>
                                                                            {extraStudent.correlative}
                                                                        </td>
                                                                        <td style={{ fontSize: "14px", margin: 0 }}>
                                                                            {extraStudent.observations}
                                                                        </td>
                                                                        <td className="border-bottom-0">
                                                                            {
                                                                                !extraStudent.classId ?
                                                                                    <button type="button"
                                                                                        onClick={() => {
                                                                                            if (extraStudentsList[extraStudent.temporalId]) {
                                                                                                let newExtraStudentsList = extraStudentsList;
                                                                                                delete newExtraStudentsList[extraStudent.temporalId];
                                                                                                setExtraStudentsList({ ...newExtraStudentsList });
                                                                                            }
                                                                                        }}
                                                                                        disabled={idleLocked}
                                                                                        className="btn btn-outline-danger"
                                                                                        style={{
                                                                                            borderRadius: "50%",
                                                                                            display: "flex",
                                                                                            alignItems: "center",
                                                                                            justifyContent: "center",
                                                                                            fontSize: "10px",
                                                                                            height: "20px",
                                                                                            width: "20px",
                                                                                            padding: 0
                                                                                        }} >
                                                                                        <FontAwesomeIcon icon={solid("minus")} />
                                                                                    </button>
                                                                                    :
                                                                                    ""
                                                                            }
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            }
                                                        </tbody>
                                                    </table>
                                                </div>
                                                :
                                                ""
                                        }
                                    </div>
                            }
                        </Tab>
                        <Tab
                            className="historic-tab"
                            eventKey="historic"
                            title="Histórico">
                            {
                                historicAttendances.length ?
                                    <div>
                                        <table className="specialTable attendanceTable">
                                            <thead>
                                                <tr>
                                                    <th className="menotrStylesMobileItem d-none d-lg-table-cell">
                                                        Correlativo
                                                    </th>
                                                    <th className="sticky">
                                                        Nombre
                                                    </th>
                                                    <th className="d-none d-lg-table-cell">
                                                        Estatus Matrícula
                                                    </th>
                                                    <th className="menotrStylesMobileItem d-none d-lg-table-cell">
                                                        Última Actualización
                                                    </th>
                                                    {
                                                        sessions.map((session, index) => (
                                                            <th
                                                                key={index}
                                                                className="text-center px-2"
                                                                style={{ color: session.color }}
                                                            >
                                                                <div className="d-flex align-items-center justify-content-center">
                                                                    {
                                                                        session.typeId == ATTENDANCE_TYPE.COMPENSATION &&
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="add-day-tooltip">
                                                                                    {Constants.Common.compensatingDay}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon style={{color: 'gray', marginRight: '5px'}} icon={solid("circle-info")} />
                                                                        </OverlayTrigger>
                                                                    }
                                                                    {
                                                                        session.statusId == ATTENDANCE_STATUS.CANCELLED &&
                                                                        <OverlayTrigger
                                                                            placement="top"
                                                                            overlay={
                                                                                <Tooltip id="add-day-tooltip">
                                                                                    {Constants.Common.canceledDay}
                                                                                </Tooltip>
                                                                            }
                                                                        >
                                                                            <FontAwesomeIcon style={{color: 'gray', marginRight: '5px'}} icon={solid("circle-info")} />
                                                                        </OverlayTrigger>
                                                                    }
                                                                    <span>{session.sessionOn}</span>
                                                                    <button
                                                                        className="btn btn-link edit-day-button ms-2"
                                                                        onClick={() => {
                                                                            detailDayProps.openDetailDayModal(session);
                                                                        }}
                                                                        disabled={idleLocked}
                                                                    >
                                                                        <FontAwesomeIcon icon={solid("ellipsis-vertical")} />
                                                                    </button>
                                                                </div>
                                                            </th>
                                                        ))
                                                    }
                                                    {userProfile.currentRole !== ROLE.MENTOR &&
                                                        <th className="text-center">
                                                            <OverlayTrigger
                                                                placement="top"
                                                                overlay={
                                                                    <Tooltip id="add-day-tooltip">
                                                                        {Constants.Common.addDay}
                                                                    </Tooltip>
                                                                }
                                                            >
                                                                <button 
                                                                    className="btn btn-success rounded-circle d-block mx-auto" 
                                                                    style={{ 
                                                                        width: '30px', 
                                                                        height: '30px', 
                                                                        padding: '0',
                                                                        backgroundColor: '#28a745',
                                                                        borderColor: '#28a745'
                                                                    }}
                                                                    onClick={() => addDayProps.setShowAddDayModal(true)}
                                                                    disabled={idleLocked}
                                                                >
                                                                    <FontAwesomeIcon icon={solid("plus")} />
                                                                </button>
                                                            </OverlayTrigger>
                                                        </th>
                                                    }
                                                    <th className="text-center menotrStylesMobileItem">
                                                        Total a Fecha
                                                    </th>
                                                    <th className="text-center menotrStylesMobileItem">
                                                        %
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    historicAttendances.map((enrollment) =>
                                                        <tr
                                                            key={"S_" + enrollment.enrollmentId}
                                                            className={addClasses("enrollment", enrollment)}>
                                                            <td className="menotrStylesMobileItem d-none d-lg-table-cell">
                                                                {enrollment.correlative}
                                                            </td>
                                                            <td className="sticky">
                                                                {enrollment.fullName}
                                                            </td>
                                                            <td
                                                                className={`d-none d-lg-table-cell ${enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE ? 'greenActive' : ''}`}>
                                                                {enrollment.enrollmentStatus}
                                                            </td>
                                                            <td className="menotrStylesMobileItem d-none d-lg-table-cell">
                                                                {enrollment.enrollmentUpdatedFullDate}
                                                            </td>
                                                            {
                                                                enrollment.attendanceByDay.map((day, index) =>
                                                                    <td
                                                                        key={index}
                                                                        className="text-center">
                                                                        {
                                                                            <div className="d-flex flex-column">
                                                                                <button
                                                                                    type="button"
                                                                                    className="btn btn-default d-flex flex-column align-items-center"
                                                                                    disabled={true}>
                                                                                    <FontAwesomeIcon
                                                                                        size="lg"
                                                                                        icon={
                                                                                            enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE ?
                                                                                                (
                                                                                                    day.sessionId && day.attendanceId ?
                                                                                                        (
                                                                                                            day.isPresent ?
                                                                                                                regular("square-check")
                                                                                                                :
                                                                                                                regular("square-minus")
                                                                                                        )
                                                                                                        :
                                                                                                        regular("square")
                                                                                                )
                                                                                                :
                                                                                                (
                                                                                                    day.sessionId && day.attendanceId ?
                                                                                                        (
                                                                                                            day.isPresent ?
                                                                                                                regular("square-check")
                                                                                                                :
                                                                                                                regular("square-minus")
                                                                                                        )
                                                                                                        :
                                                                                                        solid("square")
                                                                                                )
                                                                                        } style={{
                                                                                            color: (
                                                                                                day.sessionId && day.attendanceId ?
                                                                                                    (
                                                                                                        enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE ?
                                                                                                            (
                                                                                                                day.isPresent ?
                                                                                                                    "#00e669"
                                                                                                                    :
                                                                                                                    "#ec5353")
                                                                                                            :
                                                                                                            (
                                                                                                                day.isPresent ?
                                                                                                                    "#00e66980"
                                                                                                                    :
                                                                                                                    "#ec535380"
                                                                                                            )
                                                                                                    )
                                                                                                    :
                                                                                                    styles.modal.body.noActive.icon.v.color
                                                                                            )
                                                                                        }}
                                                                                    />
                                                                                    <span
                                                                                        className="modal_header_info_card_span_v"
                                                                                        style={{
                                                                                            fontSize: "10px",
                                                                                            color: (
                                                                                                day.sessionId && day.attendanceId
                                                                                                    ?
                                                                                                    (
                                                                                                        day.isPresent ?
                                                                                                            "#00e669"
                                                                                                            :
                                                                                                            "#ec5353"
                                                                                                    )
                                                                                                    :
                                                                                                    styles.modal.body.noActive.icon.v.color
                                                                                            )
                                                                                        }}>
                                                                                        {
                                                                                            day.sessionId && day.attendanceId
                                                                                                ?
                                                                                                (
                                                                                                    day.isPresent ?
                                                                                                        "Presente"
                                                                                                        :
                                                                                                        "Ausente"
                                                                                                )
                                                                                                :
                                                                                                "N/A"
                                                                                        }
                                                                                    </span>
                                                                                </button>
                                                                                {
                                                                                    day.notes ?
                                                                                        <button
                                                                                            onClick={() => { showNoteModalLecture(enrollment, day.notes) }}
                                                                                            disabled={idleLocked}
                                                                                            style={{
                                                                                                ...styles.modal.body.addNote.v,
                                                                                                color: "#349dff"
                                                                                            }}
                                                                                        >
                                                                                            <span style={styles.modal.body.addNote.span.v}>
                                                                                                Nota
                                                                                            </span>
                                                                                        </button>
                                                                                        :
                                                                                        ""
                                                                                }
                                                                            </div>
                                                                        }
                                                                    </td>
                                                                )
                                                            }
                                                            {userProfile.currentRole !== ROLE.MENTOR &&
                                                                <td></td>
                                                            }
                                                            <td className="menotrStylesMobileItem">
                                                                <div className="d-flex justify-content-center">
                                                                    <div className="textMobile">
                                                                        {enrollment.attendanceSummary.attendedSessions}
                                                                    </div>
                                                                    <div className="textMobile px-1">
                                                                        /
                                                                    </div>
                                                                    <div className="textMobile">
                                                                        {enrollment.attendanceSummary.totalSessions}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-end menotrStylesMobileItem" >
                                                                <div className="d-flex">
                                                                    <div className="textMobile flex-grow-1">
                                                                        {enrollment.attendanceSummary.attendancePercentage}
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )}
                                                <tr className="sessionExtraStudentCount">
                                                    <td className="bg-white sticky">Estudiantes Visitantes</td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    {
                                                        sessions.map((session, index) =>
                                                            <td key={index} className="text-center px-2 studentCount">
                                                                <button
                                                                    className={session.extraStudentsCount < 1 ? "noExtraStuden" : ""}
                                                                    disabled={idleLocked}
                                                                    onClick={session.extraStudentsCount < 1 ? () => { } : () => { openExtraStudentModalLecture(session) }}>
                                                                    <FontAwesomeIcon icon={solid('person-walking-luggage')} />
                                                                    <span>{session.extraStudentsCount}</span>
                                                                </button>
                                                            </td>
                                                        )
                                                    }
                                                </tr>
                                                <tr className="sessionDocuments">
                                                    <td className="bg-white sticky">Documentos</td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    <td className="d-none d-lg-table-cell"></td>
                                                    {
                                                        sessions.map((session, index) =>
                                                            session.documents
                                                                ?
                                                                <td key={index} className="text-center px-2">
                                                                    <div className="d-flex flex-row-reverse">
                                                                        {
                                                                            session.documents.map((document) =>
                                                                                <button
                                                                                    key={document.id}
                                                                                    className="me-3 btn btnOutlinePurple rounded-pill d-flex align-items-center fotter-btn-mobile"
                                                                                    type="button"
                                                                                    style={styles.modal.footer.img.v}
                                                                                    disabled={idleLocked}
                                                                                    onClick={() => downloadFile(document)}
                                                                                >
                                                                                    <FontAwesomeIcon className="fotter-btn-icon-mobile" icon={regular("circle-down")} />
                                                                                </button>
                                                                            )
                                                                        }
                                                                    </div>
                                                                </td>
                                                                :
                                                                ""
                                                        )
                                                    }
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                    :
                                    <>
                                        <span>No hay información</span>
                                        <img width={120} src={noInfo} alt="no hay informacion" />
                                    </>
                            }
                        </Tab>

                        {(permissions.includes(PERMISSIONS_CODES.ATTENDANCE_PERMISSIONS.DELETE)
                            || userProfile.currentRole === ROLE.SUPER_ADMIN)
                            && (
                                <Tab
                                    className="historic-tab"
                                    eventKey="danger"
                                    title="Configuración Avanzada">
                                    {
                                        <div className="container">
                                            {
                                                sessions.length > 0 ?
                                                    <Button
                                                        disabled={idleLocked}
                                                        onClick={deleteSessions}
                                                        variant="danger">
                                                        {Constants.attendances.delete}
                                                    </Button>
                                                    : <Button
                                                        disabled={idleLocked}
                                                        onClick={createSessions}
                                                        variant="primary">
                                                        {Constants.schedules.generateSessions}
                                                    </Button>
                                            }
                                        </div>
                                    }
                                </Tab>
                            )
                        }
                    </Tabs>
                }
                footerContent={
                    (activeTab === 'historic' || (!selectedSession || !selectedAttendance || (userProfile.currentRole === ROLE.MENTOR && !selectedSession.isOnCurrentDay) || (userProfile.currentRole === ROLE.MENTOR && selectedSession.timeStatusId != TIME_STATUS.ON_TIME)))
                        ?
                        ""
                        :
                        <div className="w-100">
                            <div className="d-flex justify-content-between align-items-end pt-4 footer-content">
                                <button
                                    disabled={idleLocked}
                                    className="fotter-btn-mobile"
                                    style={idleLocked ? styles.modal.footer.extraStudent.disabled.v : styles.modal.footer.extraStudent.v}
                                    onClick={openExtraStudentModal}>
                                    <span className="d-none d-lg-flex pe-2">Estudiante Extra</span>
                                    <FontAwesomeIcon className="fotter-btn-icon-mobile" style={{ color: "rgb(143, 145, 218)", fontSize: "20px", marginBottom: "-1px" }} icon={solid("plus")} />
                                </button>
                                <div className="d-inline-grid mx-1">
                                    <div className="d-flex align-items-end end-buttons">
                                        {
                                            downloadDocuments
                                                ?
                                                <div className="d-flex flex-row-reverse">
                                                    {
                                                        downloadDocuments.map((document) =>
                                                            <button
                                                                key={document.id}
                                                                className="me-3 btn btnOutlinePurple rounded-pill d-flex align-items-center fotter-btn-mobile"
                                                                type="button"
                                                                style={styles.modal.footer.img.v}
                                                                disabled={idleLocked}
                                                                onClick={() => downloadFile(document)}
                                                            >
                                                                <FontAwesomeIcon className="fotter-btn-icon-mobile" icon={regular("circle-down")} />
                                                                <span className="d-none d-lg-flex ps-2">{document.name.length <= 20 ? document.name : document.name.slice(0, 20) + '...'}</span>
                                                            </button>
                                                        )
                                                    }
                                                </div>
                                                :
                                                ""
                                        }
                                        <FileUploadComponent
                                            allowedFiletypes={allowedFiletypes}
                                            onFilesChange={fileChange}
                                            contentStyle={styles.modal.footer.FileUpload.content.v}
                                            buttonStyle={styles.modal.footer.FileUpload.button.v}
                                            buttonClass={'fotter-btn-mobile'}
                                            listStyle={styles.modal.footer.FileUpload.list.v}
                                        />
                                        <button
                                            style={styles.modal.footer.save.v}
                                            onClick={() => { checkPoint() }}
                                            type="button"
                                            id="mainAction"
                                            className="rounded-pill btn btnPurple fotter-btn-mobile"
                                            disabled={
                                                idleLocked || !hasChanges(isNewAttendance).status
                                            }
                                        >
                                            <span className="d-flex">
                                                <FontAwesomeIcon className="fotter-btn-icon-mobile" icon={solid("check-circle")} />
                                            </span>
                                            <span className="d-none d-lg-flex ps-2">
                                                Guardar
                                            </span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            />
            <MiniModalController
                title="Notas"
                show={isShowNoteModal}
                onClose={closeNoteModal}
            >
                <ChatComponentController
                    chats={selectedNote}
                    onChange={changeNote}
                    lecture={isLecture}
                />
            </MiniModalController>
            <MiniModalController
                title="Estudiantes Visitantes"
                show={showExtraStudentModalLecture}
                onClose={closeExtraStudentModalLecture}
            >
                {
                    !_.isEmpty(extraStudentsListLecture) ?
                        <div className="pt-3">
                            <table className="specialTable attendanceTable extraStudentTable">
                                <thead>
                                    <tr>
                                        <th className="pb-2 text-align-center">
                                            {Constants.Common.identificationCode}
                                        </th>
                                        <th className="pb-2 text-align-center">
                                            Nombre
                                        </th>
                                        <th className="pb-2 text-align-center">
                                            Telefono
                                        </th>
                                        <th className="pb-2 text-align-center">
                                            Correlativo
                                        </th>
                                        <th className="pb-2 text-align-center">
                                            Notas
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        Object.values(extraStudentsListLecture).map((extraStudent, index: number) =>
                                            <tr key={"eS_" + index}>
                                                <td>
                                                    <p>
                                                        {extraStudent.identificationType}-{extraStudent.identificationCode}
                                                    </p>
                                                </td>
                                                <td>
                                                    {`${extraStudent.firstName ? extraStudent.firstName : ""} ${extraStudent.lastName ? extraStudent.lastName : ""}`}
                                                </td>
                                                <td>
                                                    {extraStudent.phone}
                                                </td>
                                                <td>
                                                    {extraStudent.correlative}
                                                </td>
                                                <td>
                                                    {extraStudent.observations}
                                                </td>
                                                <td className="border-bottom-0">
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                        :
                        ""
                }
            </MiniModalController>
            <MiniModalController
                title={Constants.Common.addDay}
                show={addDayProps.showAddDayModal}
                onClose={() => addDayProps.setShowAddDayModal(false)}
            >
                <addDayProps.AddDayForm />
            </MiniModalController>
            <MiniModalController
                title={Constants.Common.cancelDay}
                show={editDayProps.showEditDayModal}
                onClose={() => editDayProps.setShowEditDayModal(false)}
            >
                <editDayProps.EditDayForm />
            </MiniModalController>
            <MiniModalController
                title={Constants.Common.details}
                show={detailDayProps.showDetailDayModal}
                onClose={detailDayProps.closeDetailDayModal}
                >
                {detailDayProps.selectedDetailDay && (
                    <div className="d-flex flex-column">
                        <span><strong>Día:</strong> {detailDayProps.selectedDetailDay.sessionOn}</span>
                        <span style={{color: detailDayProps.selectedDetailDay.color}}><strong>Estatus:</strong> {detailDayProps.selectedDetailDay.status}</span>
                        <span><strong>Estatus de Tiempo:</strong> {detailDayProps.selectedDetailDay.timeStatus}</span>
                        <span><strong>Tipo de sesión:</strong> {detailDayProps.selectedDetailDay.type}</span>
                        <span><strong>Edificio:</strong> {detailDayProps.selectedDetailDay.building}</span>
                        <span><strong>Aula:</strong> {detailDayProps.selectedDetailDay.classroom}</span>
                        {detailDayProps.selectedDetailDay.notes && 
                            <span><strong>Descripción:</strong> {detailDayProps.selectedDetailDay.notes}</span>
                        }
                        {userProfile.currentRole === ROLE.SUPER_ADMIN && detailDayProps.selectedDetailDay.statusId != ATTENDANCE_STATUS.CANCELLED &&
                            <div className="text-center mt-3">
                                <button
                                    className="btn btn-danger"
                                    onClick={() => {
                                        detailDayProps.openCancellationOptionsModal(detailDayProps.selectedDetailDay);
                                        detailDayProps.closeDetailDayModal();
                                    }}
                                >
                                    <FontAwesomeIcon className="me-2" icon={solid("cancel")} />
                                    {Constants.Common.cancel}
                                </button>
                            </div>
                        }
                    </div>
                )}
            </MiniModalController>
            <MiniModalController
                title={Constants.Common.cancelOptions}
                show={cancellationOptionsProps.showCancellationOptionsModal}
                onClose={cancellationOptionsProps.closeCancellationOptionsModal}
            >
                <div className="d-flex flex-column align-items-center py-3">
                    <h5 className="mb-4">{Constants.Common.cancelConfirmOptions}</h5>
                    <div className="d-flex justify-content-center gap-3">
                        <Button 
                            variant="outline-danger"
                            onClick={() => cancellationOptionsProps.selectCancellationOption(false)}
                            disabled={idleLocked}
                        >
                            <FontAwesomeIcon className="me-2" icon={solid("ban")} />
                            {Constants.Common.cancelConfirmMessageReplacementButtonUndo}
                        </Button>
                        <Button
                            variant="outline-primary"
                            onClick={() => cancellationOptionsProps.selectCancellationOption(true)}
                            disabled={idleLocked}
                        >
                            <FontAwesomeIcon className="me-2" icon={solid("calendar-plus")} />
                            {Constants.Common.cancelConfirmMessageReplacementButton}
                        </Button>
                    </div>
                </div>
            </MiniModalController>
            <MiniModalController
                title={Constants.Common.cancelConfirm}
                show={cancellationConfirmProps.showCancellationConfirmModal}
                onClose={cancellationConfirmProps.cancelCancellation}
            >
                <div className="d-flex flex-column align-items-center py-3">
                    <h5 className="mb-4">{
                        cancellationOptionsProps.createReplacementSession
                        ? Constants.Common.cancelConfirmMessageReplacement
                        : Constants.Common.cancelConfirmMessage}
                    </h5>
                    <p className="text-center">{Constants.Common.cancelConfirmMessageUndo}</p>
                    <div className="d-flex justify-content-center gap-3 mt-3">
                        <Button 
                            variant="secondary"
                            onClick={cancellationConfirmProps.cancelCancellation}
                            disabled={idleLocked}
                        >
                            {Constants.Common.undo}
                        </Button>
                        <Button
                            variant="danger"
                            onClick={() => cancellationConfirmProps.executeCancellation()}
                            disabled={idleLocked}
                        >
                            {Constants.Common.cancelConfirm}
                        </Button>
                    </div>
                </div>
            </MiniModalController>
            <ExtraStudentModalCrontoller
                extraStudentsList={extraStudentsList}
                setExtraStudentsList={setExtraStudentsList}
                closeExtraStudentModal={closeExtraStudentModal}
                showExtraStudentModal={showExtraStudentModal}
            />
        </>
    )
}

export default AttendancesModalView