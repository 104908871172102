//imports
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { disableIdleLockSwitch, enableIdleLockSwitch, RootState } from '../../../../../globals'
import { monthRef } from '../../../../../common'
import { Course, Criteria, CriteriaCallbackResponse, Filters, FiltersControllerProps } from './interfaces';
import { LevelDTO } from '../../../../../models/LevelDTO';
import { ScheduleDTO } from '../../../../../models/ScheduleDTO';

//services
import HttpManager from '../../../../../services/HttpManager';
import CoursesService from '../../../../../services/campus/courses.service'
import PeriodsService from "../../.././../../services/campus/periods.service"
import { PeriodDTO } from '../../../../../models/PeriodDTO';

//view
import FiltersView from './filtersView'

//messages
const errorCoursesMessage = 'No se pudieron obtener los cursos';
const errorLevelsMessage = 'No se pudieron obtener los modulos';
const errorSchedulesMessage = 'No se pudieron obtener los horarios';
const errorPeriodsMessage = 'No se pudieron obtener los periodods';

const FiltersController = ({
    displayError,
    setSchedules,
    itemsPerPage
}: FiltersControllerProps) => {

    //init http
	const http = HttpManager.getInstance();

    //init dispatch
	const dispatch = useDispatch();

    //useSelector
    const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value);
    const campusRef : any = useSelector((state : RootState) => state.campusRef.value);

    //useState
    const [coursesOPT, setCoursesOPT] = useState<Course[]>([]);
    const [levelsOPT, setLevelsOPT] = useState<LevelDTO[]>([]);
    const [schedulesOPT, setSchedulesOPT] = useState<ScheduleDTO[]>([]);
    const [periodsOPT, setPeriodsOPT] = useState<PeriodDTO[]>([]);
    const [coursesValue, setCoursesValue] = useState<Course | "">("");
    const [levelsValue, setLevelsValue] = useState<LevelDTO | "">("");
    const [schedulesValue, setSchedulesValue] = useState<ScheduleDTO | "">("");
    const [periodsValue, setPeriodsValue] = useState<PeriodDTO | "">("");
    const [disableCourses, setDisableCourses] = useState<boolean>(true);
    const [disableLevels, setDisableLevels] = useState<boolean>(true);
    const [disableSchedules, setDisableSchedules] = useState<boolean>(true);
    const [disablePeriods, setDisablePeriods] = useState<boolean>(true);
    const [scheduleCriteria, setScheduleCriteria] = useState<Criteria>({});

    //useEffect
    useEffect( () => {
        getCourses();
        getPeriods();
    }, [])

    useEffect( () => {
        if (Object.keys(scheduleCriteria).length > 0) {
            searchSchedules(scheduleCriteria);
        }
    }, [scheduleCriteria])

    //obtener los cursos para carga inicial
    const getCourses = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            const result : any = await CoursesService.searchCourses(campusRef[currentUserCampus]);
            if ((result.status == 200 || result.status == 204) && result.data) {
                let courses : Course[] = result.data;
                courses = courses.map((course: Course) => {
                    return {
                        ...course,
                        label: course.name,
                        value: course.id,
                    }
                });
                setCoursesOPT(courses);
            }
        } catch (error) {
            console.log(error);
            displayError(errorCoursesMessage);
        } finally {
            setDisableCourses(false);
            dispatch(disableIdleLockSwitch())
        }
    }

    //cuando cambia un curso
    const changeCourses = async (course: Course) => {
        setCoursesValue(course);
        setLevelsValue('');
        setSchedulesValue('');
        setPeriodsValue('');
        toggleLockFilters(true);
        getLevels(course);
        setScheduleCriteria({
            courseId: course.id,
            callback: (res: CriteriaCallbackResponse) => {
                setDisableCourses(false);
                if (res.status == 200 && res.data.length) {
                    setDisableLevels(false);
                }
            }
        });
    }
    
    //obtener los modulos
    const getLevels = async (course: Course) => {
        try {
            dispatch(enableIdleLockSwitch());
            const result : any = await http.courseLevelService.getLevelsByCourseId(campusRef[currentUserCampus].id, course.id);
            if ((result.status == 200 || result.status == 204) && result.data) {
                let levels : LevelDTO[] = result.data;
                levels = levels.map((level: LevelDTO) => {
                    return {
                        ...level,
                        label: level.name,
                        value: level.id,
                    }
                });
                setLevelsOPT(levels);
            }
        } catch (error) {
            console.log(error);
            displayError(errorLevelsMessage);
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    //cuando cambia un modulo
    const changeLevels = async (level: LevelDTO) => {
        setLevelsValue(level);
        setSchedulesValue('');
        setPeriodsValue('');
        toggleLockFilters(true);
        setScheduleCriteria({
            courseId : scheduleCriteria.courseId,
            levelId: level.id,
            callback: (res: CriteriaCallbackResponse) => {
                setDisableCourses(false);
                setDisableLevels(false);
                if (res.status == 200 && res.data.length) {
                    const schedules = res.data.map((schedule: ScheduleDTO) => {
                        return {
                            ...schedule,
                            label: schedule.code,
                            value: schedule.scheduleId,
                        }
                    });
                    setSchedulesOPT(schedules);
                    toggleLockFilters(false);
                }
            }
        });
    }

    //cuando cambia un horario
    const changeSchedules = async (schedule: ScheduleDTO) => {
        setSchedulesValue(schedule);
        setSchedules([schedule]);
    }
    
    //obtener los periodos
    const getPeriods = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id);
            if ((result.status == 200 || result.status == 204) && result.data) {
                let periods : PeriodDTO[] = result.data;
                periods = periods.map((period: PeriodDTO) => {
                    return {
                        ...period,
                        value: period.id,
                        label: period.name
                    }
                });
                setPeriodsOPT(periods);
            }
        } catch (error) {
            console.log(error);
            displayError(errorPeriodsMessage);
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    //cuando cambia un periodo
    const changePeriods = async (period: PeriodDTO) => {
        setPeriodsValue(period);
        setSchedulesValue('');
        toggleLockFilters(true);
        setScheduleCriteria({
            courseId: scheduleCriteria.courseId,
            levelId: scheduleCriteria.levelId,
            periodId: period.id,
            callback: (res: CriteriaCallbackResponse) => {
                setDisableCourses(false);
                setDisableLevels(false);
                setDisablePeriods(false);
                if (res.status == 200 && res.data.length) {
                    const schedules = res.data.map((schedule: ScheduleDTO) => {
                        return {
                            ...schedule,
                            label: schedule.name,
                            value: schedule.code,
                        }
                    });
                    setSchedulesOPT(schedules);
                    toggleLockFilters(false);
                }
            }
        });
    }

    //buscar los horarios
    const searchSchedules = async (criteria: Criteria) => {
        let response : CriteriaCallbackResponse = {
            status:500,
            data:[]
        }
        const callback = criteria.callback;
        delete criteria.callback;
        try {
            dispatch(enableIdleLockSwitch());
            const result : any = await http.scheduleService.getSchedulesByCampusId(campusRef[currentUserCampus].id, itemsPerPage, criteria);
            if ((result.status == 200 || result.status == 204) && result.data.data) {
                let schedules: ScheduleDTO[] = result.data.data;
                setSchedules(schedules);
                response = {
                    status: result.status,
                    data: result.data.data
                }
            }
        } catch (error) {
            console.log(error);
            displayError(errorSchedulesMessage);
        } finally {
            dispatch(disableIdleLockSwitch());
            if(callback){
                callback(response);
            }
        }
    }

    //bloquer los filtros
    const toggleLockFilters = (toggle: boolean) => {
        setDisableCourses(toggle);
        setDisableLevels(toggle);
        setDisableSchedules(toggle);
        setDisablePeriods(toggle);
    }

    const filters : Filters = [
        {
            name: "Curso",
            options: coursesOPT,
            onChange: changeCourses,
            disable: disableCourses,
            value: coursesValue
        },
        {
            name: "Modulo",
            options: levelsOPT,
            onChange: changeLevels,
            disable: disableLevels,
            value: levelsValue
        },
        {
            name: "Periodo",
            options: periodsOPT,
            onChange: changePeriods,
            disable: disablePeriods,
            value: periodsValue
        },
        {
            name: "Horario",
            options: schedulesOPT,
            onChange: changeSchedules,
            disable: disableSchedules,
            value: schedulesValue
        }
    ]

    return (
        <FiltersView
            filters = {filters}
        />
    )
}

export default FiltersController