import { RefObject, ChangeEvent, useState }
	from "react"
import { solid, regular }
	from "@fortawesome/fontawesome-svg-core/import.macro"
import axios
	from "axios"
import { useLocation as UseLocation }
	from "react-router-dom"
import { sprintf }
	from "sprintf-js"

import store,
{
	setAuthenticated,
	setUserProfile,
	setBasicInfo
}
	from "./globals"
import dashboard
	from "./navigation/home/dashboard"
import wallet
	from "./navigation/wallet"
import settings
	from "./navigation/settings"
import notifications
	from "./navigation/notifications"
import dailyView
	from "./navigation/calendar/dailyView"
import weeklyView
	from "./navigation/calendar/weeklyView"
import monthlyView
	from "./navigation/calendar/monthlyView"
import employees
	from "./navigation/academy/employees"
import students
	from "./navigation/academy/students"
import courses
	from "./navigation/management/courses"
import levels
	from "./navigation/management/levels"
import schedules
	from "./navigation/management/schedules"
import attendances
	from "./navigation/v2/managment/attendances/attendancesController"
import assessments
	from "./navigation/management/assessments"
import periods
	from "./navigation/management/periods"
import AccountStatementController
	from "./navigation/v2/finances/accountStatement/accountStatementController";
import InvoiceController
	from "./navigation/v2/finances/invoices/invoiceController";
import PaymentController
	from "./navigation/v2/finances/payments/paymentController";
import CreditsController
	from "./navigation/v2/finances/credits/creditsController";
import campuses
	from "./navigation/campus/campuses"
import buildings
	from "./navigation/campus/buildings"
import classrooms
	from "./navigation/campus/classrooms"
import payments
	from "./navigation/finances/payments"
import owedTuitions
	from "./navigation/finances/owedTuitions"
import owedArticles
	from "./navigation/finances/owedArticles"
import catalog
	from "./navigation/store/catalog"
import inventory
	from "./navigation/store/inventory"
import roles
	from "./navigation/specialAccess/roles"
import {
articleClassifications,
categories,
tags
}
	from "./navigation/specialAccess/simpleDataSections"
import articleTypes
	from "./navigation/specialAccess/articleTypes"
import paymentMethods
	from "./navigation/specialAccess/paymentMethods"
import recipientAccounts
	from "./navigation/specialAccess/recipientAccounts"
import taxes
	from "./navigation/specialAccess/taxes"
import { articleStatus, ENROLLMENT_STATUS } from "./constants"
import _ from "lodash"
import staticData
	from "./assets/js/static.json"
//Values and functions commonly used across many modules---------------------------------------------------------------------
const pagesPerGrouping: number = 10//Pagination
export const count: number[] = [-1, 1]//for changing values in one unit via array index based on a condition--
export const dayHalf: string[] = ["am", "pm"]//for changing css float values via array index based on a condition
export const cssFloat: string[] = ["left", "right"]//for changing css float values via array index based on a condition
export const bootstrapFloat: string[] = ["pull-start", "pull-end"]//for changing css float values via array index based on a condition
export const retardWeekDayOffset: number[] = [6, 0, 1, 2, 3, 4, 5]
export const hashCode = (s: string) =>
(
	s.split("").reduce
		(
			(a, b) => {
				a = ((a << 5) - a) + b.charCodeAt(0)

				return a & a
			},
			0
		)
)
//Interface for custom validation--------------------------------------------------------------------------------------------
interface Validation {
	required?:
	{
		value: boolean
		message: string
	}

	pattern?:
	{
		value: string
		message: string
	}

	custom?:
	{
		isValid: (value: string) => boolean
		message: string
	}
}

export const getErrorMessage = (error: any | null) => {
	switch (error.response.status) {
		case 400:
			let message = "";
			let errors = error.response.data.errors;
			for (let e of Object.keys(errors)) {
				message += errors[e][0] + " "
			}
			return message.trim();
		case 401:
			return `No Autorizado: ${error.response.data.message}`;
		case 404:
			return `Not Found: ${error.response.data.message}`;
		case 409:
			return `Conflicto: ${error.response.data.message}`;
		case 500:
			return `Server Error: Ocurrió un error no controlado: ${error.message}`;
		default:
			return `Error: Ocurrió un error al realizar la operación: ${error.message}`;
	}
}

export const isValidString = (value: string | null | undefined): boolean => {
	return typeof value === "string" && value.trim() !== "";
}

//To compute permissions-----------------------------------------------------------------------------------------------------
export const getAccessPermissions = (currentUser: any, section?: string) => {
	const route: string = section || UseLocation().pathname.substring(1)
	const allowance: any = (currentUser.currentRole && currentUser.roleMap) &&
		(currentUser.roleMap[currentUser.currentRole] && currentUser.roleMap[currentUser.currentRole].permits[route])

	return allowance == null
		?
		{}
		:
		{
			read: (allowance.binary_quintet_RCUDN_permissions & accessMode.READ) > 0,
			create: (allowance.binary_quintet_RCUDN_permissions & accessMode.CREATE) > 0,
			update: (allowance.binary_quintet_RCUDN_permissions & accessMode.UPDATE) > 0,
			delete: (allowance.binary_quintet_RCUDN_permissions & accessMode.DELETE) > 0
		}
}
//For requiring a field on key-based form submission (pressing enter)
export const keyHandling = (event: KeyboardEvent, buttonRef: any) => {
	if (event && (event.keyCode === 13 || event.which === 13)) {
		event.preventDefault()
		validateField(event)
		buttonClickHandling(buttonRef)
	}
}

export const formatField = (event: any, valueUpdate: any) => {
	let value: string = event
		.target
		.value
		.replace(/\s+/g, " ")
		.trim()

	value = isEmpty(value) ? "" : value.charAt(0).toUpperCase() + value.slice(1)
	event.target.value = value

	valueUpdate
		({
			target:
			{
				name: event.target.id,
				value: value
			}
		})

	validateField(event)
}
//Displays a warning in case the element is not valid (empty or outside the min or max lengths) when required----------------
export const validateField = (event: any) => {
	let element = event.target

	element.setCustomValidity
		(
			(element.validity.valueMissing || element.validity.typeMismatch || element.validity.patternMismatch)
				?
				(
					"Requerido: " +
					(
						element.nodeName == "SELECT"
							?
							"Elija una opción"
							:
							("Introduzca un valor adecuado." + (" " + (element.dataset.description || "")))
					)
				)
				:
				(
					(element.validity.tooShort || element.validity.tooLong) ? "Introduzca un valor adecuado (" +
						(
							element.getAttribute("minLength") == null
								? element.getAttribute("maxLength") + " caracteres max.)"
								: element.getAttribute("minLength") +
								(
									element.getAttribute("maxLength") == null
										? " caracteres min."
										: " - " +
										element.getAttribute("maxLength") + " caracteres)"
								)
						)
						:
						(
							!(element.validity.rangeUnderflow || element.validity.rangeOverflow) ? "" : "Introduzca un valor adecuado (" +
								(
									(element.getAttribute("max") == null ? element.getAttribute("min") + " como mínimo)" : "entre " + element.getAttribute("min") + " y ") +
									element.getAttribute("max") + ")"
								)
						)
				)
		)
}
export const validateDecimalFields = (elementId: string, customMessage?: string) => {
	let element = document.getElementById(elementId) as HTMLInputElement
	const value = element.value;
	const sanitizedValue = value.replace(/[^0-9.]/g, "");// Remove any non-numeric characters except for a single decimal point
	const decimalCount = (sanitizedValue.match(/\./g) || []).length// Ensure there"s only one decimal point

	if (decimalCount > 1) {
		element.setCustomValidity("Introduzca un valor adecuado con solo 2 decimales")

		return
	}

	if (!/^\d*\.?\d{0,2}$/.test(sanitizedValue))//Ensure that the input value is a valid number with two decimal places
	{
		element.setCustomValidity("Introduzca un valor adecuado con solo 2 decimales")

		return
	}

	element.setCustomValidity("")// No custom validity message if the input is valid
}

export const buttonClickHandling = (ref: any) => {
	if (ref && ref.current) {
		ref.current.click()
	}
}

export const handleKeyDown = (event: any) => {
	const { key, target } = event
	const elementId = target.id
	let element = document.getElementById(elementId) as HTMLInputElement
	const value = element.value;

	if
		(//Allow typing digits, backspace, delete, arrow keys, and tab
		(
			(!/[0-9]/.test(key) && [".", "Backspace", "Delete", "ArrowLeft", "ArrowRight", "Tab"].indexOf(key) < 0)
			||
			(key === "." && value.includes("."))// Prevent typing more than 2 digits after the decimal point
		)
		||
		(value.includes(".") && value.split(".").length === 2 && key !== "Backspace")
	) {
		event.preventDefault()

		return
	}
}
//sets basic info setup across the app
export const basicSetup = (data: any, dispatch: any) => {
	let countryMap: any = {}
	let countryStateMap: any = {}
	let academicDegreeMap: any = {}
	let scheduleStatusMap: any = {}
	let employeeStatusMap: any = { "ES_0": { id: 0, name: "Deshabilitado" } }
	let studentStatusMap: any = { "SS_0": { id: 0, name: "Deshabilitado" } }
	let countryStateIndex0: any

	data.studentStatuses.map
		(
			(status: any) => {
				studentStatusMap["SS_" + status.id] = status
			}
		)

	data.employeeStatuses.map
		(
			(status: any) => {
				employeeStatusMap["ES_" + status.id] = status
			}
		)

	data.countries.map
		(
			(country: any) => {
				countryStateIndex0 = {}

				country.states.map
					(
						(state: any) => {
							let cityRef: any = {}
							let cities: any = state.cities.map
								(
									(city: any, index: number) => {
										cityRef["C_" + city.id] = { id: city.id, label: city.name }

										return cityRef["C_" + city.id]
									}
								)

							countryStateIndex0["S_" + state.id] = { ...state, cities: cities, cityRef: cityRef }
							countryStateMap["S_" + state.id] = countryStateIndex0["S_" + state.id]
						}
					)

				countryMap["C_" + country.id] =
				{
					...country, stateRef: countryStateIndex0
				}
			}
		)

	const dateData = data.date.split("-")
	const year: number = parseInt(dateData[0])
	const birthday = data.user.birthday == null ? null : data.user.birthday.replaceAll("-", "/")
	let currencyMap: any = {}
	let identificationTypeMap: any = {}
	let currentUser: any =
	{
		...data.user, birthday: birthday,
		birthdayValue: birthday == null ? null : new Date(birthday)
	}

	if (currentUser.country_id == null) {
		currentUser =
		{
			...currentUser, city: null,
			state_id: "",
			city_id: "",
			state_name: "",
			city_name: "",
			country_id: ""
		}
	}
	else {
		if (currentUser.state_id == null) {
			currentUser =
			{
				...currentUser, city: null,
				state_id: ""
			}
		}
		else {
			currentUser.state_name = ""

			if (currentUser.city_id == null) {
				currentUser.city = null
			}
			else {
				currentUser =
				{
					...currentUser, city: { ...currentUser.city, label: currentUser.city.name },
					city_name: ""
				}
			}
		}
	}

	try {
		data.identificationTypes.map
			(
				(identificationType: any) => identificationTypeMap["IT_" + identificationType.id] =
				{
					...identificationType, pattern:
					{
						value: identificationType.pattern,
						regexp: new RegExp(identificationType.pattern)
					}
				}
			)

		data.academicDegrees.map
			(
				(record: any) => { academicDegreeMap["AD_" + record.id] = record }
			)

		data.currencies.map
			(
				(record: any) => { currencyMap["C_" + record.id] = record }
			)

		dispatch
			(
				setUserProfile
					({
						...data.user, birthday: birthday,
						birthdayValue: birthday == null ? null : new Date(birthday)
					})
			)

		staticData.SCHEDULE_STATUSES.map
			(
				(record: any) => scheduleStatusMap["SS_" + record.id] = record
			)

		dispatch(setAuthenticated(true))
		dispatch
			(
				setBasicInfo
					({
						year: year,
						tags: data.tags,
						countryRef: countryMap,
						currencyRef: currencyMap,
						groups: data.groups,
						genders: data.genders,
						countries: data.countries,
						enrollmentStatusRef: data.enrollmentStatusRef,
						stateRef: countryStateMap,
						studentStatusRef: studentStatusMap,
						scheduleStatusRef: scheduleStatusMap,
						employeeStatusRef: employeeStatusMap,
						academicDegreeRef: academicDegreeMap,
						month: parseInt(dateData[1]) - 1,
						identificationTypes: data.identificationTypes,
						identificationTypeRef: identificationTypeMap,
						date: data.date.replaceAll("-", "/"),
						futureRange: [...Array(year + 50)].map((_, i) => (year - 50) + i),
						yearRange: [...Array(year)].map((_, i) => (year - 100) + i)
					})
			)
	}
	catch (e: any) {
		console.log(e)
	}
}
//currencies
export const currency: any =
{
	MXN: 1,
	HNL: 2,
	USD: 3
}
//Assignment activity types
export const activityType =
{
	REQUIREMENT: 0,
	OPEN_ANSWER: 1,
	LONG_OPEN_ANSWER: 2,
	UNIQUE_ANSWER: 3,
	MULTIPLE_OPTION: 4
}
//Assignment activity type descriptions
export const currencySuffix = ["_mxn", "_hnl", "_usd"]
export const invoiceStatus = ["Pendiente", "Pagada parcialmente", "Pagada por completo", "Vencida"]
export const activityDescription =
	["Requerimiento", "Respuesta abierta", "Respuesta abierta extensa", "Respuesta única", "Opción múltiple"]
//Requirement options--------------------------------------------------------------------------------------------------------
export const requirementOpt =
{
	OPTIONAL: 0,
	MANDATORY: 1
}
//Attendance statuses--------------------------------------------------------------------------------------------------------
export const attendanceStatus =
{
	ABSENT: 0,
	ON_TIME: 1,
	LATENESS: 2,
	COMPENSATION: 3
}
//Base roles-----------------------------------------------------------------------------------------------------------------
export const baseRole: any =
{
	SUPER_ADMIN: 0,
	DIRECTOR: 1,
	MENTOR: 3
}
//access permissions--------------------------------------------------------------------------------------------------------
export const accessMode: any =
{
	READ: 16,
	CREATE: 8,
	UPDATE: 4,
	DELETE: 2,
	NAVIGATE: 1
}
//check icons----------------------------------------------------------------------------------------------------------------
export const checkIcon =
	[
		"bUncheck",
		"bCheck"
	]
//UI states------------------------------------------------------------------------------------------------------------------
export const UIState: any =
{
	NORMAL: 0,
	LOCKED: 1,
	WARNING: 2,
	ERROR: 3,
	SUCCESS: 4
}

export const alertUI: any = ["", "alert-primary", "alert-warning", "alert-danger", "successButton theme-color-green"]
export const borderUI = ["", "", "border-warning", "border-danger", "border-success"]
export const captionUI = ["Guardar", "Procesando", "Advertencia", "Error", "Hecho"]
export const iconUI = [solid("check"), solid("ellipsis"), solid("exclamation"), solid("exclamation"), solid("check")]
export const buttonContentUI = ["", "", "", "", "statusCardInfo"]
export const colorUI = ["0D6EFD", "0D6EFD", "F0AD4E", "DC3545", "33A738"]
export const textUI = ["text-primary", "", "", "", ""]
export const buttonUI = ["btn btn-outline-primary", "btn btn-primary", "btn btn-warning", "btn btn-danger", "successButton theme-color-green"]
export const messageUI = ["", "", "", "Fallo al procesar los datos: ", "Información procesada exitosamente."]
//Basic datetime references and functions------------------------------------------------------------------------------------
export const monthRef =
	[
		"Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio", "Julio", "Agosto", "Septiembre", "Octubre", "Noviembre",
		"Diciembre"
	]

export const weekDays = ["Lunes", "Martes", "Miércoles", "Jueves", "Viernes", "Sábado", "Domingo"]
export const dayHours = [...Array(22).keys()].slice(7)

export const revertStringDate = (date: string, separation?: string, replacement?: string) => {
	const separator: string = separation || "-"
	const replacer: string = replacement || "/"
	const part: string[] = date.split(separator)

	return [part[2], part[1], part[0]].join(replacer)
}

export const toAcceptableDate = (date: Date) => {
	return sprintf("%04d-%02d-%02d", date.getFullYear(), (date.getMonth() + 1), date.getDate())
}

export const toAscendingDate = (date: Date) => {
	return sprintf("%02d/%02d/%04d", date.getDate(), (date.getMonth() + 1), date.getFullYear())
}
//Custom form validation.
type ErrorRecord<T> = Partial<Record<keyof T, string>>;
type Validations<T extends {}> = Partial<Record<keyof T, Validation>>;
export const useForm = <T extends Record<keyof T, any> = {}>
	(options?: { validations?: Validations<T>; initialValues?: Partial<T>; onSubmit?: () => void }) => {
	const [data, setData] = useState<T>((options?.initialValues || {}) as T)
	const [errors, setErrors] = useState<ErrorRecord<T>>({})
	// Needs to extend unknown so we can add a generic to an arrow function
	const handleChange = <S extends unknown>(key: keyof T, sanitizeFn?: (value: string) => S) =>
		(e: ChangeEvent<HTMLInputElement & HTMLSelectElement>) => {
			const value = sanitizeFn ? sanitizeFn(e.target.value) : e.target.value

			e.target.setCustomValidity((e.target.validity.valueMissing || e.target.validity.typeMismatch) ? "Requerido: introduzca un valor adecuado" : "")

			setData({ ...data, [key]: value })
		}

	const handleSubmit = async () => {
		const validations = options?.validations;

		if (validations) {
			let valid = true;
			const newErrors: ErrorRecord<T> = {};

			for (const key in validations) {
				const value = data[key];
				const validation = validations[key];

				if (validation?.required?.value && !value) {
					valid = false;
					newErrors[key] = validation?.required?.message;
				}

				const pattern = validation?.pattern;

				if (pattern?.value && !RegExp(pattern.value).test(value)) {
					valid = false;
					newErrors[key] = pattern.message;
				}

				const custom = validation?.custom;

				if (custom?.isValid && !custom.isValid(value)) {
					valid = false;
					newErrors[key] = custom.message;
				}
			}

			if (!valid) {
				setErrors(newErrors);
				return;
			}
		}

		setErrors({});

		if (options?.onSubmit) {
			options.onSubmit()
		}
	};

	return { data, handleChange, handleSubmit, errors }
};
//Comparison value search operators------------------------------------------------------------------------------------------
export const operators: any =
{
	"": { code: "", display: "..." },
	"equals": { code: "equals", display: "=" },
	"lt": { code: "lt", display: "<" },
	"gt": { code: "gt", display: ">" },
	"lte": { code: "lte", display: "<=" },
	"gte": { code: "gte", display: ">=" }
}

export const operatorList: any = Object.values(operators)

//Switchable themes----------------------------------------------------------------------------------------------------------
export const themes =
	[
		"theme-mode-light",
		"theme-mode-dark"
	];
//For file handling----------------------------------------------------------------------------------------------------------
export const imageTypes =
	[
		"image/bmp",
		"image/gif",
		"image/jpeg",
		"image/png",
		"image/webp"
	]
//Toggles display of overlaying components such as the sidebar and tooltips/popovers/titles----------------------------------
const useClickOutsideRef = (contentRef: RefObject<HTMLDivElement>, toggleRef: RefObject<HTMLButtonElement>) => {
	document.addEventListener
		(
			"mousedown", (event: MouseEvent) => {
			if (contentRef.current) {
				if (toggleRef.current && (contentRef.current && toggleRef.current.contains(event.target as HTMLElement))) {
					contentRef.current.classList.toggle("active")
				}
				else {
					if (contentRef.current && !contentRef.current.contains(event.target as HTMLElement)) {
						contentRef.current.classList.remove("active")
					}
				}
			}
		}
		)
}
//Basic validations----------------------------------------------------------------------------------------------------------
export const isEmpty = (data: any | undefined) => {
	const isArray: boolean = Array.isArray(data)

	return data == null ||
		(
			((isArray && data.length < 1) || (!isArray && (data + "").trim().length < 1))
			||
			(typeof data === "object" && (Object.keys(data).length < 1) && !(data instanceof Date))
		)
}

export const isNotEmpty = (data: any | undefined) => {
	return !isEmpty(data)
}

export const emptyFill = (data: any | undefined) => {
	return isEmpty(data) ? "-" : data;
}
//Basic menu restrictions----------------------------------------------------------------------------------------------------
export const baseMenu =
	[{
		block:
		{
			display: "Inicio",
			group: "start",
			icon: "home"
		},
		sections:
			[
				{
					display: "Panel",
					route: ""
				}/*,
		{
			display : "Noticias",
			route   : "newsfeed"
		},
		{
			display : "Calendario",
			route   : "calendar"
		}*/
			]
	}]

export const complementaryMenuRef: any =
{
	calendar:
	{
		display: "calendario",
		icon: "calendar",
		sections:
			[
				{
					display: "Por día",
					route: "dailyView"
				},
				{
					display: "Por semana",
					route: "weeklyView"
				}
			]
	},
	academy:
	{
		display: "Academia",
		icon: "users",
		sections:
			[
				{
					display: "Personal",
					route: "employees"
				},
				{
					display: "Alumnos",
					route: "students"
				}
			]
	},
	management:
	{
		display: "Administrativo",
		icon: "spreadsheet",
		sections:
			[
				{
					display: "Cursos",
					route: "courses"
				},
				{
					display: "Módulos",
					route: "levels"
				},
				{
					display: "Horarios",
					route: "schedules"
				},
				{
					display: "Periodos",
					route: "periods"
				},
				{
					display: "Evaluaciones",
					route: "assessments"
				},
				{
					display: "Asistencias",
					route: "attendances"
				}
			]
	},
	facilities:
	{
		display: "Campus",
		icon: "campus",
		sections:
			[
				{
					display: "Planteles",
					route: "campuses"
				},
				{
					display: "Edificios",
					route: "buildings"
				},
				{
					display: "Aulas",
					route: "classrooms"
				}
			]
	},
	store:
	{
		display: "Tienda",
		icon: "shoppingCart",
		sections:
			[
				{
					display: "Catálogo",
					route: "catalog"
				},
				{
					display: "Inventario",
					route: "inventory"
				}
			]
	},
	finances:
	{
		display: "Finanzas",
		icon: "finances",
		sections:
			[
				{
					display: "Facturas",
					route: "invoices"
				},
				{
					display: "Pagos",
					route: "payments"
				},
				{
					display: "Créditos",
					route: "credits"
				},
				{
					display: "Colegiaturas",
					route: "owedTuitions"
				},
				{
					display: "Artículos",
					route: "owedArticles"
				}
			]
	},
	specialAccess:
	{
		display: "Acceso especial",
		icon: "superAdmin",
		sections:
			[
				{
					display: "Roles",
					route: "roles"
				},
				{
					display: "Categorías",
					route: "categories"
				},
				{
					display: "Etiquetas",
					route: "tags"
				},
				{
					display: "Tipos de artículo",
					route: "articleTypes"
				},
				{
					display: "Clasificaciones de artículo",
					route: "articleClassifications"
				},
				{
					display: "Impuestos",
					route: "tags"
				},
				{
					display: "Métodos de pago",
					route: "paymentMethods"
				},
				{
					display: "Cuentas receptoras",
					route: "recipientAccounts"
				},
				{
					display: "Artículos",
					route: "articles"
				},
				{
					display: "Asignaciones",
					route: "assignments"
				},
			]
	}
}
//Basic navigation restriccions----------------------------------------------------------------------------------------------
export const baseNavigation: any =
	[
		{
			path: "",
			element: dashboard
		},
		{
			path: "settings",
			element: settings
		},
		{
			path: "notifications",
			element: notifications
		},
		{
			path: "wallet",
			element: wallet
		},
		{
			path: "statement",
			element: AccountStatementController
		},
	]

export const administrativeNavigation: any =
	[
		{
			path: "dailyView",
			element: dailyView
		},
		{
			path: "weeklyView",
			element: weeklyView
		},
		/*{
			path    : "monthlyView",
			element : monthlyView
		},*/
	]

export const administrativeNavigationRef: any =
{
	dailyView: dailyView,
	weeklyView: weeklyView
	//monthlyView : monthlyView
}

export const complementaryNavigationRef: any =
{
	dailyView: dailyView,
	weeklyView: weeklyView,
	employees: employees,
	students: students,
	courses: courses,
	levels: levels,
	schedules: schedules,
	periods: periods,
	attendances: attendances,
	campuses: campuses,
	buildings: buildings,
	classrooms: classrooms,
	invoices: InvoiceController,
	payments: PaymentController,
	credits: CreditsController,
	owedTuitions: owedTuitions,
	owedArticles: owedArticles,
	roles: roles,
	categories: categories,
	tags: tags,
	taxes: taxes,
	store: catalog,
	inventory: inventory,
	articleTypes: articleTypes,
	articleClassifications: articleClassifications,
	recipientAccounts: recipientAccounts,
	paymentMethods: paymentMethods,
	// assessments : assessments,
	// assignmentTypes : assignmentTypes,
	// assignments : assignments
}

export const generateSectionMap = (user: any) => {
	let roleMenuRef: any = {}
	let complementaryNavigation: any = []
	let roleMap: any = user.roleMap[user.currentRole]

	if (roleMap) {
		let permitMap: any = user.roleMap[user.currentRole].permits

		Object.keys(permitMap).map
			(
				(section: string) => {
					if
						(
						(
							permitMap[section] &&
							((permitMap[section].binary_quintet_RCUDN_permissions & accessMode.NAVIGATE) > 0)
						)
						&& complementaryNavigationRef[section]
					) {
						let sectionGroup = permitMap[section].section.group

						if (roleMenuRef[sectionGroup.code] == null) {
							roleMenuRef[sectionGroup.code] =
							{
								display: sectionGroup.name,
								sections: []
							}
						}

						roleMenuRef[sectionGroup.code].sections.push
							({
								display: permitMap[section].section.name,
								route: permitMap[section].section.code
							})

						complementaryNavigation.push
							({
								path: section,
								element: complementaryNavigationRef[section]
							})
					}
				}
			)
	}

	let complementaryMenu: any = Object.keys(roleMenuRef).map
		(
			(key: string) =>
			({
				sections: roleMenuRef[key].sections,
				block:
				{
					group: key,
					display: roleMenuRef[key].display,
					icon: complementaryMenuRef[key].icon,
				},
			})
		)

	return [complementaryMenu, complementaryNavigation]
}
//Pagination for section rsearch esults--------------------------------------------------------------------------------------
export const pageGrouping = (currentPage: number, lastPage: number) => {
	var pages = [];
	var pagesPerGroup = pagesPerGrouping < 1 ? 1 : pagesPerGrouping
	var pagesGroupsCount = lastPage > 0 ? Math.ceil(lastPage / pagesPerGroup) : 1
	var pagePosition = (currentPage - 1) / (pagesGroupsCount * pagesPerGroup)
	var pageGroup = Math.floor(pagePosition * pagesGroupsCount)
	var groupInit = pageGroup * pagesPerGroup
	var groupEnd = groupInit + pagesPerGroup

	if (groupEnd > lastPage) {
		groupEnd = lastPage
	}

	var i;

	for (i = groupInit; i < groupEnd; i++) {
		pages.push(i + 1)
	}

	return {
		pages: pages,
		currentPage: currentPage,
		lastPage: lastPage,
		groupInitPage: groupInit,
		groupLastPage: groupEnd + 1,
		pageGroup: pageGroup,
		lastGroup: pagesGroupsCount - 1
	}
}
//Common registry removal function structure---------------------------------------------------------------------------------
export const removeRecords = async (baseURL: string, recordIds: any) => {
	let url: string
	let payload: any =
	{
		headers:
		{
			"Content-Type": "application/json",
			Authorization: "Bearer " + store.getState().sessionToken.value
		}
	}

	if (recordIds.length < 2) {
		url = baseURL + "/" + recordIds[0]
	}
	else {
		url = baseURL
		payload.data = { ids: recordIds.join(",") }
	}

	return axios.delete(url, payload).then(response => response)
}
/*
	Convert a date string from DD/MM/YYYY format to a Date object.
	@param {string} dateString - The date string in DD/MM/YYYY format.
	@returns {Date} The Date object representing the input date.
*/
export const convertToDateObject = (dateString: string): Date => {
	const parts = dateString.split("/")
	const day = parseInt(parts[0], 10)
	const month = parseInt(parts[1], 10) - 1 // Subtract 1 because months are 0-indexed--------------------------------------
	const year = parseInt(parts[2], 10)

	return new Date(year, month, day)
}
/*
	Check if an invoice is due based on its due date.
	@param {string} inputDateString - The due date of the invoice in DD/MM/YYYY format.
	@returns {boolean} True if the invoice is due (the due date is before today), false otherwise.
*/
export const isDueInvoice = (inputDateString: string): boolean => {

	const today = new Date()// Today"s date----------------------------------------------------------------------------------

	today.setHours(0, 0, 0, 0)// Reset time to 00:00:00 for accurate comparison----------------------------------------------

	const inputDate = convertToDateObject(inputDateString)// Convert the input date string to a Date object------------------

	return inputDate < today// Compare the due date with today"s date--------------------------------------------------------
}
/*
	Get the latest invoice ID from an array of invoice IDs.
	@param {number[]} invoiceIds - An array of invoice IDs.
	@returns {number} The greatest invoice ID in the array.
*/
export const getLatestInvoiceId = (invoiceIds: number[]): number => {
	const greatestId = Math.max(...invoiceIds)// Using Math.max() with spread syntax to find the greatest ID-----------------

	return greatestId
}

/*
	Group objects by scheduleId or scheduleKey
	Groups objects by scheduleId or scheduleKey.
	@param {Object[]} tuitionRef - The array of objects to group.
	@returns {Object} - The object with the greatest invoice_id.
*/
export const getLatestInvoices = (tuitionRef: any): object => {
	/*
		Reduces the array of objects to find the object with the greatest invoice_id.
		@param {Object} prev - The previously evaluated object in the reduction.
		@param {Object} current - The current object being evaluated in the reduction.
		@returns {Object} - The object with the greatest invoice_id found so far.
	*/
	const objectWithGreatestInvoiceId = tuitionRef.reduce
		(
			(prev: any, current: any) => {
				return (prev.invoice_id > current.invoice_id) ? prev : current;
			}
		)

	return objectWithGreatestInvoiceId;
}

/*
	Generates a random password of a specified length.
	The password includes letters (both lowercase and uppercase) and numbers.
	@param {number} length - The length of the password to generate.
	@returns {string} A random password of the specified length.
*/
export const generateRandomPassword = (length: number) => {
	const charset: string = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789"
	let password: string = ""

	for (let i = 0; i < length; i++) {
		const randomIndex = Math.floor(Math.random() * charset.length)
		password += charset[randomIndex]
	}

	return password
}

export const URLBuild = (routeSteps: any[], routeParams: any[] = []): string => {
	return routeSteps.join("/").replace(/\s+/g, "") + (isEmpty(routeParams) ? "" : "?" + routeParams.join("&"));
}

export const formattedEnrollment = (schedule: any, enrollment: any) => {
	let invoicedKitRef: any = {}

	enrollment.kits.map
		(
			(kit: any) => {
				if (kit.pivot.invoice_id) {
					invoicedKitRef["IK_" + kit.id] = kit
				}
			}
		)

	return {
		...enrollment, stocked: true,
		refIndex: "S_" + enrollment.id,
		tuitionInvoiceIds: _.map
			(
				enrollment.tuitions.filter((tuition: any) => tuition.invoice_id),
				"invoice_id"
			),
		invoicedKits: Object.values(invoicedKitRef),
		extraKits:
			[
				...enrollment.kits.filter
					(
						(kit: any) => schedule.level.kitRef["K_" + kit.id] == null
							&& kit.invoice_id == null
					)
			],
		editableKits:
			[
				...enrollment.kits.filter
					(
						(kit: any) => kit.pivot.invoice_id == null &&
							(
								schedule.level.kitRef["K_" + kit.id] &&
								(
									schedule.level.kitRef["K_" + kit.id].type.bool_is_kit > 0 &&
									schedule.level.kitRef["K_" + kit.id].status_id == articleStatus.AVAILABLE
								)
							)
					)
			],
		availableKits: schedule.level.kits.length < 1 ? [] : schedule
			.level
			.kits
			.filter
			(
				(kit: any) =>
				(
					invoicedKitRef["IK_" + kit.id] == null &&
					(
						schedule.level.kitRef["K_" + kit.id] &&
						(
							schedule.level.kitRef["K_" + kit.id].type.bool_is_kit > 0 &&
							schedule.level.kitRef["K_" + kit.id].status_id == articleStatus.AVAILABLE
						)
					)
				)
			)
	}
}

export const enrollingSet = (course: any, level: any, schedule: any, enrollments: any, companyId: number) => {
	let formattedLevel: any =
	{
		...level, kits:
			[
				...level.kits.filter
					(
						(
							(kit: any) => kit.type.bool_is_kit &&
								(kit.status_id == articleStatus.AVAILABLE && kit.company_id == companyId)
						)
					)
			]
	}

	let formattedSchedule: any = { ...schedule, level: formattedLevel }

	return {
		...schedule, course: course,
		level_id: level.id,
		course_id: course.id,
		level: level,
		enrollments: enrollments.filter((enrollment: any) => enrollment.enrollmentStatusId == ENROLLMENT_STATUS.ACTIVE).map
			((enrollment: any) => formattedEnrollment(formattedSchedule, enrollment)),
		nonActiveEnrollments: enrollments.filter((enrollment: any) => enrollment.enrollmentStatusId != ENROLLMENT_STATUS.ACTIVE).map
			((enrollment: any) => formattedEnrollment(formattedSchedule, enrollment))
	}
}

export default useClickOutsideRef
