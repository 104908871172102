import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { AppDispatch, disableIdleLockSwitch, displayNotice, enableIdleLockSwitch, RootState } from '../../../../../globals';
import Constants, { FILTER_TYPES, studentStatus } from "../../../../../constants";
import { getErrorMessage } from '../../../../../common';

//components
import { Column } from '../../../../../components/commonTable';

//interfaces
import { ExtraStudentModalCrontollerProps, ItemCount, Page } from './interfaces'
import { StudentDTO } from '../../../../../models/StudentDTO';

//services
import HttpManager from '../../../../../services/HttpManager';

//view
import ExtraStudentModalView from './extraStudentModalView';
import { ExtraStudent } from '../modal/interfaces';

//consts
const pagesSize: number[] = [10];

const ExtraStudentModalCrontoller = ({
    closeExtraStudentModal,
    showExtraStudentModal,
    extraStudentsList,
    setExtraStudentsList
}: ExtraStudentModalCrontollerProps) => {

    //init HTTP
    const http = HttpManager.getInstance();

    //init dispatch
    const dispatch = useDispatch<AppDispatch>();

    //useSelector
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const studentStatuses: [] = useSelector((state: RootState) => state.rootReducer.catalog.studentStatuses);

    //useState
    const [students, setStudents] = useState<any>([]);
    const [searchValue, setSearchValue] = useState<string>("");
    // const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [pages, setPages] = useState<Page[]>([
        {
            "url": null,
            "label": "&laquo; Previous",
            "active": false
        },
        {
            "url": "",
            "label": "1",
            "active": true
        },
        {
            "url": null,
            "label": "Next &raquo;",
            "active": false
        }
    ]);
    const [itemCount, setItemCount] = useState<ItemCount>({
        start: 0,
        end: 0,
        total: 0
    });
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const [studentNotes, setStudentNotes] = useState<{ [key: string]: string }>({});

    useEffect(() => {
        fetchData();
    }, [filters]);

    const studentColumns: Column[] = [
        {
            id: "identificationCode",
            header: Constants.Common.identificationCode,
            accessor: "",
            render: (extraStudent: ExtraStudent) =>
                <p className="cellMobileStyles">
                    {extraStudent.identificationType}-{extraStudent.identificationCode}
                </p>
            ,
            filterType: FILTER_TYPES.INPUT_NUMBER,
            overrideCellStyle: (student: StudentDTO) => ({
                color: student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "150px",
                fontSize: "14px"
            }),
            overrideCellClasses: "cellMobileStyles",
            overrideHeaderClasses: "headerMobileStyles"
        },
        {
            id: "firstName",
            header: Constants.Common.names,
            accessor: "firstName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px",
                fontSize: "14px"
            }),
            overrideCellClasses: "cellMobileStyles",
            overrideHeaderClasses: "headerMobileStyles"
        },
        {
            id: "lastName",
            header: Constants.Common.lastNames,
            accessor: "lastName",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px",
                fontSize: "14px"
            }),
            overrideCellClasses: "cellMobileStyles",
            overrideHeaderClasses: "headerMobileStyles"
        },
        {
            id: "correlative",
            header: Constants.Common.correlative,
            accessor: "correlative",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (student: StudentDTO) => ({
                color: student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px",
                fontSize: "14px"
            }),
            overrideCellClasses: "cellMobileStyles onlyAdmin",
            overrideHeaderClasses: "headerMobileStyles onlyAdmin"
        },
        {
            id: "Note",
            header: Constants.Common.notes,
            accessor: "",
            render: (student: StudentDTO) => (
                !(extraStudentsList[student.id] && extraStudentsList[student.id].classId) ?
                    <textarea
                        onChange={(e) => {
                            setStudentNotes({
                                ...studentNotes,
                                [student.id]: e.target.value
                            })
                        }}
                        disabled={extraStudentsList[student.id] ? true : false}
                        value={studentNotes[student.id]}
                        id="description"
                        name="description"
                        placeholder={Constants.Common.notes}
                        style={{
                            resize: "vertical",
                            maxWidth: "100%",
                            border: "2px solid #7678BF",
                            borderRadius: "5px",
                            height: "40px",
                            padding: "5px",
                            borderColor: (
                                (studentNotes[student.id].length > 0) ?
                                    (
                                        extraStudentsList[student.id] ?
                                            "lightgrey"
                                            :
                                            "#7678BF"
                                    )
                                    :
                                    "#dc3545"
                            )
                        }}
                    />
                    :
                    <span>{extraStudentsList[student.id].observations}</span>
            ),
            overrideCellStyle: (student: StudentDTO) => ({
                color: student.statusId !== studentStatus.ACTIVE
                    ? Constants.Styles.text.colors.grayOpacity
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px",
                fontSize: "14px"
            }),
            overrideCellClasses: "cellMobileStyles",
            overrideHeaderClasses: "headerMobileStyles"
        },
        {
            id: "selectStudent",
            header: "",
            accessor: "",
            render: () => <React.Fragment></React.Fragment>,
            overrideCellStyle: () => ({
                color: Constants.Styles.text.colors.blackPrimary,
                borderBottom: 0,
                textAlign: "center",
                whiteSpace: "inherit",
                display: "flex",
                justifyContent: "center",
                width: "auto"
            }),
        }
    ];

    const studentsColumnWithLogic = studentColumns.map((column: any) => {
        if (column.id === "selectStudent" && column.render) {
            return {
                ...column,
                render: (student: StudentDTO) => (
                    !extraStudentsList[student.id] ?
                        <button type="button"
                            onClick={() => addExtraStudent(student)}
                            disabled={
                                idleLocked
                                || studentNotes[student.id] == ""
                                || student.statusId !== studentStatus.ACTIVE
                            }
                            className="btn btn-outline-success"
                            style={{
                                borderRadius: "25px",
                                display: "flex",
                                alignItems: "center",
                                height: "40px",
                                color: (
                                    (
                                        idleLocked
                                        || studentNotes[student.id] == ""
                                        || student.statusId !== studentStatus.ACTIVE
                                    ) ?
                                        "lightgray"
                                        :
                                        ""
                                ),
                                borderColor: (
                                    (
                                        idleLocked
                                        || studentNotes[student.id] == ""
                                        || student.statusId !== studentStatus.ACTIVE
                                    ) ?
                                        "gray"
                                        :
                                        ""
                                )
                            }} >
                            <FontAwesomeIcon icon={solid("plus")} />
                            <span
                                className="d-xs-none d-sm-none d-lg-none d-xl-block"
                                style={{
                                    paddingLeft: "10px"
                                }}>
                                {"Añadir"}
                            </span>
                        </button>
                        :
                        (
                            !extraStudentsList[student.id].classId ?
                                <button type="button"
                                    onClick={() => removeExtraStudent(student)}
                                    disabled={
                                        idleLocked
                                        || student.statusId !== studentStatus.ACTIVE
                                    }
                                    className="btn btn-outline-danger"
                                    style={{
                                        borderRadius: "25px",
                                        display: "flex",
                                        alignItems: "center",
                                        height: "40px"
                                    }} >
                                    <FontAwesomeIcon icon={solid("minus")} />
                                    <span
                                        className="d-xs-none d-sm-none d-lg-none d-xl-block"
                                        style={{
                                            paddingLeft: "10px"
                                        }}>
                                        {"Anular"}
                                    </span>
                                </button>
                                :
                                <div style={{ height: "40px", color: "#198754" }} className='w-100 d-flex justify-content-center align-items-center'>
                                    <FontAwesomeIcon icon={solid("check")} />
                                </div>
                        )

                ),
            };
        }
        return column;
    });

    //functions
    const addExtraStudent = (student: StudentDTO) => {
        if (!extraStudentsList[student.id]) {
            setExtraStudentsList({
                ...extraStudentsList,
                [student.id]: {
                    temporalId: student.id,
                    studentId: student.id,
                    correlative: student.correlative,
                    firstName: student.firstName,
                    lastName: student.lastName,
                    email: student.email,
                    phone: student.phone1,
                    observations: studentNotes[student.id]
                }
            })
        }
    }

    const addNewExtraStudent = (newStudent: ExtraStudent) => {
        if (!extraStudentsList[Object.values(newStudent).toString()]) {
            setExtraStudentsList({
                ...extraStudentsList,
                [Object.values(newStudent).toString()]: {
                    temporalId: Object.values(newStudent).toString(),
                    firstName: newStudent.firstName,
                    lastName: newStudent.lastName,
                    identificationTypeId: newStudent.identificationTypeId,
                    identificationType: newStudent.identificationCode,
                    identificationCode: newStudent.identificationType,
                    email: newStudent.email,
                    phone: newStudent.phone,
                    observations: newStudent.observations
                }
            })
        }
    }

    const removeExtraStudent = (student: StudentDTO) => {
        if (extraStudentsList[student.id]) {
            let newExtraStudentsList = extraStudentsList;
            delete newExtraStudentsList[student.id];
            setExtraStudentsList({ ...newExtraStudentsList });
        }
    }

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
    }

    const onClearFilters = () => {
        setSearchValue("");
        setFilters({});
    }

    const updateFilters = (prevFilters: any, columnId: string, value: any) => {
        const updatedFilters = { ...prevFilters };

        if (value === null) {
            if (updatedFilters[columnId]) {
                delete updatedFilters[columnId];
            }
        } else {
            updatedFilters[columnId] = value;
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }
        }

        return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
    };

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = updateFilters(prevFilters, columnId, value);
            return newFilters;
        });
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const getStudentsByCampusId = async () => {
        try {
            const response = await http.studentService.getStudentsByCampusId(
                currentCampus.id,
                itemsPerPage,
                filters
            );
            return response;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getPaginatedStudents = async (url: string) => {
        dispatch(enableIdleLockSwitch())
        try {
            const response = await http.studentService.getPaginatedStudents(url);
            const students = response.data;
            updatePaginationInfo(students);
            let notes: { [key: string]: string; } = {};
            students.data.map((student) => {
                notes[student.id] = "";
            });
            setStudentNotes(notes)
            setStudents(students.data);
            return students;
        } catch (err: any) {
            console.error(err);
        } finally {
            // setIsLoaded(true);
            dispatch(disableIdleLockSwitch());
        }
    }

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getPaginatedStudents(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
    }

    const onSubmit = (data: any) => {
        addNewExtraStudent(data)
        closeExtraStudentModal();
    }

    const fetchData = async () => {
        dispatch(enableIdleLockSwitch())
        try {
            const students = await getStudentsByCampusId();
            let notes: { [key: string]: string; } = {};
            students?.data.data.map((student) => {
                notes[student.id] = "";
            });
            setStudentNotes(notes)
            setStudents(students?.data.data);
            updatePaginationInfo(students?.data);
            dispatch(disableIdleLockSwitch());
            // setIsLoaded(true);
        }
        catch (error: any) {
            dispatch(disableIdleLockSwitch());
            console.log(error);
            onError(getErrorMessage(error));
        }
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                Error
            </h3>
        }))
    }

    const customCloseModal = () => {
        closeExtraStudentModal()
    }

    return (
        <ExtraStudentModalView
            closeExtraStudentModal={closeExtraStudentModal}
            showExtraStudentModal={showExtraStudentModal}
            pages={pages}
            handlePageSizeChange={handlePageSizeChange}
            pagesSize={pagesSize}
            itemCount={itemCount}
            searchValue={searchValue}
            onChangeSearch={onChangeSearch}
            onClickSearch={onClickSearch}
            onClearSearch={onClearSearch}
            studentsColumnWithLogic={studentsColumnWithLogic}
            students={students}
            filters={filters}
            onFilterChange={onFilterChange}
            onClearFilters={onClearFilters}
            idleLocked={idleLocked}
            onSubmit={onSubmit}
            customCloseModal={customCloseModal}
        />
    )
}

export default ExtraStudentModalCrontoller