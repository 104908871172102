import React, {
    useEffect,
    useRef,
    useState
} from "react";

import { useLocation, useNavigate } from "react-router-dom";
import {
    useDispatch,
    useSelector
} from "react-redux";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sprintf } from "sprintf-js";
import {
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";

import Constants,
{
    FILTER_TYPES,
    FINANCES_MODALS,
    OPERATORS,
    paymentStatus
} from "../../../../constants";
import {
    RootState,
    enableIdleLockSwitch,
    disableIdleLockSwitch,
    displayNotice,
    AppDispatch,
    setCurrentUserCampus,
    setCurrentUserCampusId
} from "../../../../globals";
import HttpManager from "../../../../services/HttpManager";
import {
    setCurrentCampus,
    setCurrentCampusId,
    setPermissions,
    UserState
} from "../../../../redux/reducers/sessionReducer";
import { setPaymentMethods, setRecipientAccounts } from "../../../../redux/reducers/catalogReducer";
import {
    handlePaymentXlsx,
    handleSelectedPayment,
    setIsCancelPayment,
    setIsNewInvoice,
    setIsPartialPayment,
    setModalContent,
    setRefundItems,
    setSelectedInvoice,
    setSelectedPayment,
    setShowModal,
    setStudentData
} from "../../../../redux/reducers/paymentsReducer";
import { handleSelectedInvoice, setSelectedCredit } from "../../../../redux/reducers/invoicesReducer";
import { Column } from "../../../../components/commonTable";
import PaymentView from "./paymentView";
import ButtonComponent from "../../../../components/buttons/button";
import TruncateTextComponent from "../../../../components/truncateText";
import { handleSelectedCredit } from "../../../../redux/reducers/creditsReducer";
import { styles } from "./style";
import { getErrorMessage } from "../../../../common";

const PaymentController = (props: any, route: any) => {
    const dispatch = useDispatch<AppDispatch>();
    const navigate = useNavigate();
    const location = useLocation();
    const pagesSize: number[] = [10, 20, 50, 100];
    const http = HttpManager.getInstance();
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const userData: UserState | null = useSelector((state: RootState) => state.rootReducer.sessionState.user);
    const currentRole: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentRole);
    const currentCampus: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentCampus);
    const campuses: any[] = useSelector((state: RootState) => state.rootReducer.catalog.campuses);
    const studentData: any = useSelector((state: RootState) => state.rootReducer.payments.studentData);
    const selectedInvoice: any = useSelector((state: RootState) => state.rootReducer.payments.selectedInvoice);
    const selectedPayment: any = useSelector((state: RootState) => state.rootReducer.payments.selectedPayment);
    const isPartialPayment: boolean = useSelector((state: RootState) => state.rootReducer.payments.isPartialPayment);
    const isNewInvoice: boolean = useSelector((state: RootState) => state.rootReducer.payments.isNewInvoice);
    const paymentStatuses: [] = useSelector((state: RootState) => state.rootReducer.catalog.paymentStatuses);
    const allowedPaymentMethods: [] = useSelector((state: RootState) => state.rootReducer.catalog.allowedPaymentMethods);
    const recipientAccountOptions: [] = useSelector((state: RootState) => state.rootReducer.catalog.recipientAccounts);
    const [isLoaded, setIsLoaded] = useState<boolean>(false);
    const [payments, setPayments] = useState<any>(null);
    const [pages, setPages] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [itemCount, setItemCount] = useState<any>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const [createdAtRange, setCreatedAtRange] = useState<{ start: Date | null; end: Date | null }>({
        start: null,
        end: null,
    });
    const [paymentDateRange, setPaymentDateRange] = useState<{ start: Date | null; end: Date | null }>({
        start: null,
        end: null,
    });
    const [amount, setAmount] = useState<{ option: string; quantity: number }>({
        option: "",
        quantity: 0,
    });
    const filtersUpdatedRef = useRef(false);

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
        clearQueryParams();
    }

    const onClearFilters = () => {
        setSearchValue("");
        setFilters({});
        clearQueryParams();
    }

    const updateFilters = (prevFilters: any, columnId: string, value: any) => {
        const updatedFilters = { ...prevFilters };

        if (columnId === "createdAt") {
            const { start, end } = value || {};
            if (value === null) {
                setCreatedAtRange({ start: null, end: null });
                delete updatedFilters[columnId];
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
            if (!start || !end || start > end) {
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
        }

        if (columnId === "paymentDate") {
            const { start, end } = value || {};
            if (value === null) {
                setPaymentDateRange({ start: null, end: null });
                delete updatedFilters[columnId];
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
            if (!start || !end || start > end) {
                return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
            }
        }

        const handleQuantityFilter = (column: string) => {
            const { option, quantity } = value || {};
            if (!option || quantity < 0) {
                delete updatedFilters[column];
                if (column === "paidAmount") setAmount({ option: "", quantity: 0 });
            }
        };

        if (["paidAmount"].includes(columnId)) {
            handleQuantityFilter(columnId);
        }

        if (value === null) {
            if (updatedFilters[columnId]) {
                delete updatedFilters[columnId];
            }
        } else {
            updatedFilters[columnId] = value;
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }
        }

        return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
    };

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = updateFilters(prevFilters, columnId, value);
            return newFilters;
        });
    };

    const paymentColumns: Column[] = [
        {
            id: "id",
            header: Constants.Common.id,
            accessor: "",
            render: (payment: any) => payment.id > 0
                ? <ButtonComponent className="wa-btn sm link"
                    tooltip={Constants.Common.paymentDetail}
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    disabled={idleLocked}
                    onClick={() => showPaymentDetail(payment)}
                >
                    {payment.id.toString()}
                </ButtonComponent>
                : "-",
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            filterType: FILTER_TYPES.INPUT_NUMBER,
            defaultFilterValue: filters?.id ?? "",
            filterEnabled: filters?.id > 0,
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                width: "200px",
            })
        },
        {
            id: "reference",
            header: Constants.Common.reference,
            accessor: "",
            filterType: FILTER_TYPES.INPUT_TEXT,
            render: (payment: any) => (
                <TruncateTextComponent
                    value={payment.reference ? payment.reference : "-"}
                    maxLength={10}
                    maxWidth={"100%"}
                />
            ),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            })
        },
        {
            id: "correlative",
            header: Constants.Common.correlative,
            accessor: "studentIdentityCode",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            }),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            })
        },
        {
            id: "firstName",
            header: Constants.Common.names,
            accessor: "studentFirstname",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "lastName",
            header: Constants.Common.lastNames,
            accessor: "studentLastname",
            filterType: FILTER_TYPES.INPUT_TEXT,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "invoiceId",
            header: Constants.Common.invoice,
            accessor: "",
            filterType: FILTER_TYPES.INPUT_NUMBER,
            render: (payment: any) => payment.invoiceId > 0
                ? <ButtonComponent className="wa-btn sm link"
                    tooltip={Constants.Common.invoiceDetail}
                    style={{ opacity: idleLocked ? 0.5 : 1 }}
                    disabled={idleLocked}
                    onClick={() => getInvoiceById(payment.invoiceId)}
                >
                    {payment.invoiceId.toString()}
                </ButtonComponent>
                : "-",
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "200px"
            })
        },
        {
            id: "paidAmount",
            header: Constants.Common.paidAmount,
            accessor: "",
            render: (payment) => `${payment.amount.moneyFormat}`,
            filterType: FILTER_TYPES.AMOUNT_RANGE,
            quantityRangeState: {
                option: amount.option,
                setOption: (option: string) => setAmount((prev) => ({ ...prev, option })),
                quantity: amount.quantity,
                setQuantity: (quantity: number) => setAmount((prev) => ({ ...prev, quantity })),
            },
            filterOptions: OPERATORS,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "right",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "paymentMethod",
            header: Constants.Common.paymentMethod,
            accessor: "paymentMethod",
            filterType: FILTER_TYPES.MULTISELECT,
            filterOptions: allowedPaymentMethods,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "recipientAccount",
            header: Constants.Common.recipientAccount,
            accessor: "recipientAccount",
            filterType: FILTER_TYPES.MULTISELECT,
            filterOptions: recipientAccountOptions,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            })
        },
        {
            id: "paymentStatus",
            header: Constants.Common.status,
            accessor: "",
            render: (data: any) => <span
                style={{
                    color: data.statusId === paymentStatus.CANCELLED
                        ? Constants.Styles.text.colors.red
                        : Constants.Styles.text.colors.blackPrimary,
                    font: Constants.Styles.text.fonts.robotoBold16,
                    marginBottom: 0
                }}>
                {data.status}
            </span>,
            filterType: FILTER_TYPES.MULTISELECT,
            filterOptions: paymentStatuses,
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            })
        },
        {
            id: "createdAt",
            header: Constants.Common.createdAt,
            accessor: "createdAt",
            filterType: FILTER_TYPES.DATE_RANGE,
            dateRangeState: {
                start: createdAtRange.start,
                end: createdAtRange.end,
                setStart: (date: Date | null) => setCreatedAtRange((prev) => ({ ...prev, start: date })),
                setEnd: (date: Date | null) => setCreatedAtRange((prev) => ({ ...prev, end: date })),
            },
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "paymentDate",
            header: Constants.Common.paymentDate,
            accessor: "paymentDate",
            filterType: FILTER_TYPES.DATE_RANGE,
            dateRangeState: {
                start: paymentDateRange.start,
                end: paymentDateRange.end,
                setStart: (date: Date | null) => setPaymentDateRange((prev) => ({ ...prev, start: date })),
                setEnd: (date: Date | null) => setPaymentDateRange((prev) => ({ ...prev, end: date })),
            },
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "left",
                whiteSpace: "inherit",
                width: "200px"
            }),
        },
        {
            id: "notes",
            header: Constants.Common.notes,
            accessor: "",
            filterType: FILTER_TYPES.INPUT_TEXT,
            render: (payment: any) => (
                <TruncateTextComponent
                    value={payment.notes}
                    maxLength={10}
                    maxWidth={"100%"}
                />
            ),
            overrideHeaderStyle: () => ({
                ...styles.tableHeaderStyle
            }),
            overrideCellStyle: (data: any) => ({
                color: data.statusId === paymentStatus.CANCELLED
                    ? Constants.Styles.text.colors.red
                    : Constants.Styles.text.colors.blackPrimary,
                textAlign: "center",
                whiteSpace: "inherit",
                width: "100px"
            }),
        },
        {
            id: "selectPayment",
            header: "",
            accessor: "",
            render: () => <React.Fragment></React.Fragment>,
            overrideCellStyle: () => ({
                textAlign: "right",
                borderBottom: 0
            }),
            overrideHeaderStyle: () => ({
                textAlign: "left",
                color: "black"
            })
        }
    ];

    const paymentColumnsWithLogic = paymentColumns.map((column: any) => {
        if (column.id === "selectPayment" && column.render) {
            return {
                ...column,
                render: (payment: any) => (
                    <OverlayTrigger overlay={
                        <Tooltip>
                            {Constants.Common.paymentDetail}
                        </Tooltip>
                    }>
                        <button
                            className="btn btn-default"
                            type="button"
                            disabled={idleLocked}
                            onClick={() => showPaymentDetail(payment)}
                        >
                            <FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
                        </button>
                    </OverlayTrigger>
                ),
            };
        }
        return column;
    });

    const fetchRoleData = async () => {
        try {
            const response = await http.roleService.getRoleData(currentRole.id);
            dispatch(setPermissions(response.data.permissions));
            return response.data;
        } catch (err: any) {
            console.error(err);
        }
    }

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const resetStateData = () => {
        dispatch(setRefundItems(new Set()));
        dispatch(setIsPartialPayment(false));
    }

    const onError = (message: string) => {
        dispatch(displayNotice({
            cornerClose: false,
            message: message,
            heading: <h3 style={{
                color: "#FF0000",
                display: "inline-block"
            }}>
                Error
            </h3>
        }));
    }

    const getStudentById = async (studentId: number) => {
        try {
            let response = await http.studentService.getStudentById(
                currentCampus.id,
                studentId
            );
            let student = response.data;
            return student;
        } catch (error) {
            console.log(error);
            onError(getErrorMessage(error));
        }
    }

    const setSelectedStudent = async (data: any) => {
        const studentData = await getStudentById(data.studentId);
        dispatch(setStudentData(studentData));
    }

    const getPaymentMethods = async () => {
        try {
            const response = await http.paymentMethodService
                .getPaymentMethodsByCountry(currentCampus.countryId);
            const paymentMethods = response.data;
            return paymentMethods;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getRecipientAccounts = async () => {
        try {
            const response = await http.recipientAccountService
                .getRecipientAccounts(currentCampus.countryId);
            const accounts = response.data;
            return accounts;
        } catch (err: any) {
            console.error(err);
        }
    }

    const getPaymentsByCampusId = async () => {
        try {
            const response = await http.paymentService.getPaymentsByCampusId(
                userData?.token ?? "",
                currentCampus.id,
                itemsPerPage,
                filters
            );
            const payments = response.data;
            return payments;
        } catch (err: any) {
            console.error(err);
        }
    }

    const handleDownloadReport = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handlePaymentXlsx({
                campusId: currentCampus.id,
                criteria: filters
            })).unwrap(); // unwrap para manejar correctamente errores en createAsyncThunk;
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const getPaginatedPayments = async (url: string) => {
        dispatch(enableIdleLockSwitch())
        try {
            const response = await http.paymentService.getPaginatedPayments(
                userData?.token ?? "", url);
            const payments = response.data;
            updatePaginationInfo(payments);
            setPayments(payments.data);
            return payments;
        } catch (err: any) {
            console.error(err);
        } finally {
            setIsLoaded(true);
            dispatch(disableIdleLockSwitch());
        }
    }

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getPaginatedPayments(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
        setCurrentPage(pagination.current_page);
    }

    const getInvoiceById = async (invoiceId: number) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedInvoice({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                studentId: studentData.id,
                invoiceId
            })).unwrap();
            let invoice = response.data;
            setSelectedStudent(invoice);
            dispatch(setSelectedInvoice(invoice));
            dispatch(setIsNewInvoice(false));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const showPaymentDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            const response = await dispatch(handleSelectedPayment({
                sessionToken: userData?.token ?? "",
                campusId: currentCampus.id,
                paymentId: rowData.id
            })).unwrap();
            const payment = {
                ...response.data,
                account: response.data.recipientAccount,
                amount: response.data.amount.value,
                files: response.data.documents
            };
            setSelectedStudent(payment);
            dispatch(setSelectedPayment(payment));
            dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const showCreditDetail = async (rowData: any) => {
        try {
            dispatch(enableIdleLockSwitch());
            let response = await dispatch(handleSelectedCredit({
                campusId: currentCampus.id,
                creditId: rowData.id
            })).unwrap();
            const credit = response.data;
            dispatch(setSelectedCredit(credit));
            dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT_DETAILS));
            dispatch(setShowModal(true));
        } catch (error) {
            onError("Hubo un error al tratar de obtener el documento!");
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const cancelInvoice = () => {
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_INVOICE));
    }

    const proccessPayment = () => {
        dispatch(setIsPartialPayment(true));
        dispatch(setIsNewInvoice(false));
        dispatch(setModalContent(FINANCES_MODALS.EMIT_PAYMENT));
    }

    const closeModalInvoiceDetail = () => {
        dispatch(setIsPartialPayment(false));
        dispatch(setShowModal(false));
        dispatch(setSelectedInvoice(null));
    }

    const handleCancelPayment = () => {
        setIsCancelPayment(true);
        dispatch(setModalContent(FINANCES_MODALS.CANCEL_PAYMENT));
    }

    const paymentDetailCloseModal = () => {
        dispatch(setShowModal(false));
    }

    const showPaymentUpdateModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.UPDATE_PAYMENT));
    }

    const updatePaymentCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL))
    }

    const onSuccessUpdatePayment = () => {
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
        showSuccessMessage();
        showPaymentDetail(selectedPayment);
    }

    const showSuccessMessage = () => {
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const cancelPaymentCloseModal = () => {
        setIsCancelPayment(false);
        dispatch(setModalContent(FINANCES_MODALS.PAYMENT_DETAIL));
    }

    const onSuccessCancelPayment = () => {
        fetchData();
        if (selectedInvoice) {
            getInvoiceById(selectedInvoice.id);
        }
        dispatch(setShowModal(true));
        showPaymentDetail(selectedPayment);
        showSuccessMessage();
    }

    const cancelEmitPayment = () => {
        if (isPartialPayment && !isNewInvoice) {
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        } else {
            dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
        }
    }

    const showApplyCreditModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.APPLY_CREDIT));
    }

    const onSuccessEmitPayment = () => {
        fetchData();
        getInvoiceById(selectedInvoice.id);
        dispatch(setShowModal(true));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }));
    }

    const closeModalEmitPayment = () => {
        dispatch(setShowModal(false));
    }

    const cancelInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.INVOICE));
    }

    const onSuccessCancelInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        dispatch(displayNotice({
            cornerClose: false,
            message: "Información procesada con éxito",
            heading: <h3 style={{
                color: "#00FF00", display: "inline-block"
            }}>
                Listo
            </h3>
        }))
    }

    const generateInvoiceCloseModal = () => {
        dispatch(setModalContent(FINANCES_MODALS.CHECKOUT));
    }

    const onSuccessGeneratePaidInvoice = () => {
        fetchData();
        dispatch(setShowModal(false));
        showSuccessMessage();
    }

    const getQueryParams = () => {
        const query = new URLSearchParams(location.search);
        const data = query.get("data");
        if (!data) {
            return { campusId: 0, invoiceId: 0 };
        }

        try {
            const decodedData = JSON.parse(decodeURIComponent(data));
            return {
                campusId: decodedData?.campus ?? 0,
                paymentId: decodedData?.payment ?? 0
            };
        } catch (error) {
            console.error("Error al parsear JSON de query params:", error);
            return { campusId: 0, invoiceId: 0 };
        }
    }

    const clearQueryParams = () => {
        const query = new URLSearchParams(location.search);

        query.forEach((value, key) => {
            query.delete(key);  // delete each parameter
        });

        window.history.replaceState(
            null,
            '',
            `${location.pathname}${location.hash}`  // holding hash, but delete all query params
        );
    }

    const handleCampusChange = (campusId: number) => {
        localStorage.setItem("currentCampus", `C_${campusId}`);
        dispatch(setCurrentUserCampusId(campusId));
        dispatch(setCurrentUserCampus(`C_${campusId}`));
        dispatch(setCurrentCampusId(campusId));
        const newCampus = campuses.find((campus: any) => campus.id === campusId);
        dispatch(setCurrentCampus(newCampus));
    }

    const fetchData = async () => {
        resetStateData();
        const messageError: string = "Ocurrió un error al obtener los pagos."
        dispatch(enableIdleLockSwitch())
        if (currentCampus && userData) {
            try {
                const [
                    paymentMethods,
                    recipientAccounts,
                    payments
                ] = await Promise.all([
                    getPaymentMethods(),
                    getRecipientAccounts(),
                    getPaymentsByCampusId()
                ]);
                setPayments(payments.data);
                updatePaginationInfo(payments);
                dispatch(disableIdleLockSwitch());
                dispatch(setPaymentMethods(paymentMethods));
                dispatch(setRecipientAccounts(recipientAccounts));
                setIsLoaded(true);
            }
            catch (error: any) {
                dispatch(disableIdleLockSwitch());
                if (error.status === 404) {
                    navigate("/");
                }
                else {
                    console.log(error);
                    onError(messageError);
                }
            }
        }
    }

    useEffect(() => {
        fetchRoleData();
    }, [currentRole]);

    useEffect(() => {
        if (selectedInvoice && Object.keys(selectedInvoice).length > 0) {
            dispatch(setShowModal(true));
            dispatch(setModalContent(FINANCES_MODALS.INVOICE));
        }
    }, [selectedInvoice]);

    useEffect(() => {
        if (userData?.token && currentCampus !== null) {
            const { campusId, paymentId } = getQueryParams();
            if (campusId > 0 && paymentId > 0 && !filtersUpdatedRef.current) {
                handleCampusChange(campusId);
                setFilters({ ...filters, "id": paymentId });
                filtersUpdatedRef.current = true;
                return;
            }
            if (filters?.search === "") {
                setCurrentPage(1);
                fetchData();
                return;
            }
            fetchData();
        } else {
            onError(
                "No hay planteles registrados o asignados al rol en uso por el usuario, " +
                "por lo que el acceso a este módulo permanecerá deshabilitado."
            )
        }
    }, [userData, currentCampus, itemsPerPage, filters]);

    useEffect(() => {
        if (filters.id) {
            fetchData();
        }
    }, [filters]);

    return !isLoaded || currentCampus === null || userData === null
        ? <React.Fragment></React.Fragment>
        : <PaymentView
            paymentProps={{
                payments,
                columns: paymentColumnsWithLogic,
                filters,
                onFilterChange,
                onClearFilters,
                reportFunction: handleDownloadReport
            }}
            paginationProps={{
                pages,
                pagesSize,
                itemCount,
                handlePageSizeChange
            }}
            searchProps={{
                search: searchValue,
                onChangeSearch,
                onClickSearch,
                onClearSearch
            }}
            invoiceDetailProps={{
                cancelInvoice,
                showPaymentDetail,
                showCreditDetail,
                proccessPayment,
                closeModal: closeModalInvoiceDetail
            }}
            emitPaymentProps={{
                isNewInvoice,
                isPartialPayment,
                closeModal: closeModalEmitPayment,
                cancelEmitPayment,
                showApplyCreditModal,
                onSuccess: onSuccessEmitPayment
            }}
            paymentDetailProps={{
                handleCancelPayment,
                closeModal: paymentDetailCloseModal,
                showPaymentUpdate: showPaymentUpdateModal
            }}
            paymentUpdateProps={{
                onSuccess: onSuccessUpdatePayment,
                closeModal: updatePaymentCloseModal
            }}
            cancelPaymentProps={{
                closeModal: cancelPaymentCloseModal,
                onSuccess: onSuccessCancelPayment
            }}
            cancelInvoiceProps={{
                closeModal: cancelInvoiceCloseModal,
                onSuccess: onSuccessCancelInvoice
            }}
            generateInvoiceProps={{
                onSuccess: onSuccessGeneratePaidInvoice,
                closeModal: generateInvoiceCloseModal
            }}
        />
}

export default PaymentController;