//imports
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { disableIdleLockSwitch, enableIdleLockSwitch, displayNotice, RootState, AppDispatch } from '../../../../globals';
import { Column } from '../../../../components/commonTable';
import { ScheduleDTO } from '../../../../models/ScheduleDTO';
import { DataInfoModal, ItemCount, Page } from './interfaces';
import { handleMentorsSalaryXlsx } from '../../../../redux/reducers/mentorsReducer';

//services
import HttpManager from '../../../../services/HttpManager';

//view
import AttendancesView from './attendancesView';
import Constants, { FILTER_TYPES, ROLE, scheduleStatus } from '../../../../constants';
import { setPermissions } from '../../../../redux/reducers/sessionReducer';

//messages
const noStudentsForSchedule = "No hay estudiantes registrados para este horario";
const noCampuses = "No hay planteles registrados o asignados al rol en uso por el usuario, por lo que el acceso a este módulo permanecerá deshabilitado.";
const initError = "La búsqueda iniclal de datos no pudo ser completada.";
const unlinkedClassroomsFoundMessage = "Algunos horarios pertenecen a salones sin edificio o periodo asociado, o el periodo asociado no pertenece a ningún plantel, " + "por lo que no se podrá acceder a esta sección.";

//constants
const pagesSize: number[] = [10, 20, 50, 100];
const mentorItemsSize: number = 100;

const AttendancesController = () => {

    //init dispatch
    const dispatch = useDispatch<AppDispatch>();

    //init http
    const http = HttpManager.getInstance();

    //useSelector
    const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value);
    const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value);
    const userProfile: any = useSelector((state: RootState) => state.userProfile.value);
    const campusRef: any = useSelector((state: RootState) => state.campusRef.value);
    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);
    const currentRole: any = useSelector((state: RootState) => state.rootReducer.sessionState.currentRole);

    //useState
    const [searchValue, setSearchValue] = useState<string>("");
    const [filters, setFilters] = useState<{ [key: string]: any }>({});
    const [sectionLoaded, setSectionLoaded] = useState<boolean>(false);
    const [schedules, setSchedules] = useState<ScheduleDTO[] | []>([]);
    const [userCampus, setUserCampus] = useState<number>(currentUserCampus);
    const [selectedSchedule, setSelectedSchedule] = useState<ScheduleDTO | null>(null);
    const [noStudentsEnrolledForSchedule, setNoStudentsEnrolledForSchedule] = useState<number>(0);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [dataInfoModal, setDataInfoModal] = useState<DataInfoModal>({});
    const [itemCount, setItemCount] = useState<ItemCount>({
        start: 0,
        end: 0,
        total: 0
    });
    const [pages, setPages] = useState<Page[]>([
        {
            "url": null,
            "label": "&laquo; Previous",
            "active": false
        },
        {
            "url": "",
            "label": "1",
            "active": true
        },
        {
            "url": null,
            "label": "Next &raquo;",
            "active": false
        }
    ]);
    const [itemsPerPage, setItemsPerPage] = useState<number>(pagesSize[0]);
    const [showReportModal, setShowReportModal] = useState<boolean>(false);
    const [startDate, setStartDate] = useState<Date | null>(new Date());
    const [endDate, setEndDate] = useState<Date | null>(null);

    const fetchRoleData = async () => {
        try {
            const response = await http.roleService.getRoleData(currentRole.id);
            dispatch(setPermissions(response.data.permissions));
            return response.data;
        } catch (err: any) {
            console.error(err);
        }
    }

    const onChangeSearch = (newSearchValue: string) => {
        setSearchValue(newSearchValue);
    }

    const onClickSearch = () => {
        setFilters({ ...filters, search: searchValue });
    }

    const onClearSearch = () => {
        setSearchValue("");
        setFilters({});
    }

    const onClearFilters = () => {
        setSearchValue("");
        setFilters({});
    }

    const updateFilters = (prevFilters: any, columnId: string, value: any) => {
        const updatedFilters = { ...prevFilters };

        if (value === null) {
            if (updatedFilters[columnId]) {
                delete updatedFilters[columnId];
            }
        } else {
            updatedFilters[columnId] = value;
        }

        if (Array.isArray(value)) {
            if (value.length === 0) {
                delete updatedFilters[columnId];
            } else {
                updatedFilters[columnId] = value;
            }
        }

        return JSON.stringify(updatedFilters) === JSON.stringify(prevFilters) ? prevFilters : updatedFilters;
    };

    const onFilterChange = (columnId: string, value: any) => {
        setFilters((prevFilters) => {
            const newFilters = updateFilters(prevFilters, columnId, value);
            return newFilters;
        });
    };

    const updatePaginationInfo = (pagination: any) => {
        const updatedPages = pagination.links.map((page: any) => ({
            ...page,
            onClick: () => {
                if (page.url) {
                    getSchedules(page.url);
                }
            }
        }));
        setItemCount({
            start: pagination.from,
            end: pagination.to,
            total: pagination.total
        });
        setPages(updatedPages);
    }

    //consts
    const columns: Column[] = [
        {
            id: "name",
            header: "Nombre",
            accessor: "name",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "period",
            header: "Periodo",
            accessor: "period",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "course",
            header: "Curso",
            accessor: "course",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "code",
            header: "Código",
            accessor: "code",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "level",
            header: "Módulo",
            accessor: "level",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "mentor",
            header: "Profesor",
            accessor: "mentor",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "building",
            header: "Edificio",
            accessor: "building",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "classroom",
            header: "Aula",
            accessor: "classroom",
            filterType: FILTER_TYPES.INPUT_TEXT
        },
        {
            id: "sessions",
            header: "Sesiones",
            accessor: "",
            render: (schedule: ScheduleDTO) => {
                return schedule.sessions.split(',').map((session: string) => {
                    return (
                        <span key={session} style={{ display: "block" }}>
                            {
                                session
                            }
                        </span>
                    );
                })
            }
        },
        {
            id: "status",
            header: "Estatus",
            accessor: "",
            render: (schedule: ScheduleDTO) => {
                return (
                    <span style={{ color: schedule.status === 'Activo' ? "#28a745" : "#de1f21" }}>
                        {
                            schedule.status
                        }
                    </span>
                );
            }
        },
        {
            id: "startDate",
            header: "Inicio",
            accessor: "startDate"
        },
        {
            id: "endDate",
            header: "Fin",
            accessor: "endDate"
        },
        {
            id: "billableMonths",
            header: "Meses a facturar",
            accessor: "billableMonths",
            overrideCellStyle: () => ({
                width: "100px",
                textAlign: "center"
            }),
            filterType: FILTER_TYPES.INPUT_TEXT
        },
    ];

    //functions
    const initFn = () => {
        getSchedules();
    };

    const getSchedules = async (url?: string) => {
        try {
            dispatch(enableIdleLockSwitch());
            setSelectedSchedule(null)
            setSchedules([]);

            const perPage: number = userProfile.currentRole === ROLE.MENTOR ? mentorItemsSize : itemsPerPage;
            const criteria: object | string = userProfile.currentRole === ROLE.MENTOR
                ? { "mentorId": userProfile.id, "statusId": scheduleStatus.ACTIVE }
                : filters;
            const result: any = url
                ? await http.scheduleService.getPaginatedSchedules(url, criteria)
                : await http.scheduleService.getSchedulesByCampusId(campusRef[currentUserCampus].id, perPage, criteria);
            const status = result.status;

            if (status == 200 || status == 204) {
                let unlinkedClassroomsFound: boolean = false;

                let schedulesData = result.data.data.map((schedule: ScheduleDTO) => {
                    if (schedule.building == null || schedule.period == null) {
                        unlinkedClassroomsFound = true
                        return;
                    }
                    return schedule
                });

                setSchedules(schedulesData);
                updatePaginationInfo(result.data)

                if (unlinkedClassroomsFound) {
                    displayError(unlinkedClassroomsFoundMessage);
                } else {
                    setSectionLoaded(true);
                }
            } else {
                displayError(initError)
            }
        }
        catch (error) {
            console.log(error);
            displayError(initError)
        } finally {
            dispatch(disableIdleLockSwitch())
        }
    }

    const displayError = (message: string) => {
        dispatch(
            displayNotice({
                cornerClose: false,
                message: message,
                heading:
                    <h3 style={{ color: "#FF0000", display: "inline-block" }}>
                        Error
                    </h3>
            })
        );
    };

    const displayNoResults = (message: string) => {
        dispatch(
            displayNotice({
                cornerClose: false,
                message: message,
                heading:
                    <h3 style={{ color: "#0000FF", display: "inline-block" }}>
                        No se encontraron resultados
                    </h3>
            })
        );
    };

    const miniMessage = (header: string, message: string) => {
        setDataInfoModal({
            title: header,
            content: (
                <span>
                    {message}
                </span>
            )
        })
        setShowInfoModal(true);
    }

    const closeInfoModal = () => {
        setShowInfoModal(false);
        setDataInfoModal({});
    }

    const handlePageSizeChange = (newPageSize: number) => {
        setItemsPerPage(newPageSize);
    }

    const scheduleClick = (schedule: ScheduleDTO) => {
        if (schedule.hasEnrollments) {
            setSelectedSchedule(schedule);
        } else {
            setNoStudentsEnrolledForSchedule(schedule.scheduleId)
            setTimeout(() => setNoStudentsEnrolledForSchedule(0), 2000)
        }
    }

    const handleDownloadReport = async () => {
        try {
            dispatch(enableIdleLockSwitch());
            await dispatch(handleMentorsSalaryXlsx({
                campusId: campusRef[currentUserCampus].id,
                criteria: {
                    status: 1,
                    startDate: startDate,
                    endDate: endDate
                }
            })).unwrap();
        } catch (error: any) {
            if (error.status == 400) {
                displayNoResults("No se encontró información para las fechas seleccionadas");
            } else {
                displayError("Hubo un error al tratar de obtener el documento!");
            }
        }
        finally {
            dispatch(disableIdleLockSwitch());
        }
    }

    const confirmDownloadReport = (callback: () => void) => {
        dispatch(displayNotice({
            procedure: async () => { callback() },
            message: `¿Desea descargar la planilla de profesores del día ${startDate ? new Date(startDate).toLocaleDateString('es-MX', { day: '2-digit', month: 'long', year: 'numeric' }) : ''} al día ${endDate ? new Date(endDate).toLocaleDateString('es-MX', { day: '2-digit', month: 'long', year: 'numeric' }) : ''}?`,
            heading: <h3 style={{ color: "#0000FF", display: "inline-block" }}>
                Confirme
            </h3>
        }))
    }
    //useEffect
    useEffect(() => {
        if (sessionToken) {
            currentUserCampus == null ? displayError(noCampuses) : initFn();
        }
    }, []);

    useEffect(() => {
        if ((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus)) {
            dispatch(enableIdleLockSwitch());
            setSectionLoaded(false);
            localStorage.setItem("currentCampus", currentUserCampus)
            setUserCampus(currentUserCampus)
            setSelectedSchedule(null)
            setSchedules([]);
            initFn();
        }
    }, [currentUserCampus, sectionLoaded]);

    useEffect(() => {
        if (sessionToken) {
            getSchedules();
        }
    }, [itemsPerPage]);

    useEffect(() => {
        initFn();
    }, [filters]);

    useEffect(() => {
        fetchRoleData();
    }, [currentRole]);

    return (
        <AttendancesView
            sectionLoaded={sectionLoaded}
            schedules={schedules}
            setSchedules={setSchedules}
            selectedSchedule={selectedSchedule}
            setSelectedSchedule={setSelectedSchedule}
            displayError={displayError}
            columns={columns}
            filters={filters}
            onFilterChange={onFilterChange}
            onClearFilters={onClearFilters}
            itemsPerPage={itemsPerPage}
            handlePageSizeChange={handlePageSizeChange}
            pages={pages}
            pagesSize={(userProfile.currentRole === ROLE.MENTOR) ? [mentorItemsSize] : pagesSize}
            itemCount={itemCount}
            showInfoModal={showInfoModal}
            dataInfoModal={dataInfoModal}
            closeInfoModal={closeInfoModal}
            miniMessage={miniMessage}
            scheduleClick={scheduleClick}
            idleLocked={idleLocked}
            userProfile={userProfile}
            reportProps={{
                handleDownloadReport: handleDownloadReport,
                title: Constants.Common.payroll,
                showModal: showReportModal,
                closeModal: () => { setShowReportModal(false) },
                openModal: () => { setShowReportModal(true) },
                startDate: startDate,
                endDate: endDate,
                onChange: (dates) => {
                    const [start, end] = dates;
                    setStartDate(start);
                    setEndDate(end);
                },
                confirmDownloadReport: confirmDownloadReport
            }}
            searchProps={{
                search: searchValue,
                onChangeSearch,
                onClickSearch,
                onClearSearch
            }}
        />
    );
}

export default AttendancesController