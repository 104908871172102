//imports
import UnderlineHeading from "../../../../components/underlineHeading";
import CommonTable from "../../../../components/commonTable";
import Card from "../../../../components/card";
import FiltersController from "./filters/filtersController";
import AttendancesModalController from "./modal/attendancesModalController";
import { AttendancesViewProps } from "./interfaces";
import MiniModalController from "../../../../components/modals/miniModal/miniModalController";
import PaginationComponent from "../../../../components/pagination/pagination";
import ReportButtonComponent from "../../../../components/buttons/reportButton/reportButton";
import Constants, { REPORT_TYPES } from "../../../../constants";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import ReactDatePicker from "react-datepicker";
import SearchInputComponent from "../../../../components/searchInput/searchInput";

//styles
import "./styles.scss";

const AttendancesView = ({
	sectionLoaded,
	schedules,
	setSchedules,
	selectedSchedule,
	setSelectedSchedule,
	displayError,
	columns,
	filters,
	onFilterChange,
	onClearFilters,
	itemsPerPage,
	handlePageSizeChange,
	pages,
	pagesSize,
	itemCount,
	showInfoModal,
	dataInfoModal,
	closeInfoModal,
	miniMessage,
	scheduleClick,
	idleLocked,
	userProfile,
	reportProps,
	searchProps
}: AttendancesViewProps) => {

	return (
		<>
			{
				!sectionLoaded ? ""
					:
					<div className="attendancesContainer">
						<h2 className="pageHeader">
							Asistencias
						</h2>
						<UnderlineHeading />
						{
							userProfile.currentRole != "mentor" &&
							<>
								<div className="downloadMentorsSalaryReportContainer">
									<button
										className="downloadMentorsSalaryReport btn btn-success"
										onClick={reportProps.openModal}
										disabled={idleLocked}
										type="button">
										<FontAwesomeIcon icon={solid("file-excel")} />
										<span className="ps-2 d-none d-lg-inline">
											{reportProps.title}
										</span>
									</button>
								</div>
								<div className="row pb-2 header-filters">
									<div className="col-md-8">
										<FiltersController
											displayError={displayError}
											setSchedules={setSchedules}
											itemsPerPage={itemsPerPage}
										/>
									</div>
									<div className="col-md-4 searchInputContainer" >
										<SearchInputComponent
											search={searchProps.search}
											onChangeSearch={searchProps.onChangeSearch}
											onClickSearch={searchProps.onClickSearch}
											onClearSearch={searchProps.onClearSearch}
										/>
									</div>
								</div>
							</>
						}
						<Card>
							<div className="containerTable col-lg-12">
								<CommonTable
									columns={columns}
									data={schedules}
									onRowClick={scheduleClick}
									filters={filters}
									onFilterChange={onFilterChange}
									onClearFilters={onClearFilters}
								>
								</CommonTable>
							</div>
						</Card>
						<br />
						{
							selectedSchedule ?
								<AttendancesModalController
									selectedSchedule={selectedSchedule}
									setSelectedSchedule={setSelectedSchedule}
									miniMessage={miniMessage}
								/>
								:
								""
						}
						<MiniModalController
							title={dataInfoModal.title ? dataInfoModal.title : ""}
							show={showInfoModal}
							onClose={closeInfoModal}
						>
							{
								dataInfoModal.content
							}
						</MiniModalController>
						<div className="attendancesPagination">
							<PaginationComponent
								pages={pages}
								onPageSizeChange={handlePageSizeChange}
								pageSizeVisible={true}
								pageSizes={pagesSize}
								itemCount={itemCount}
								style={{ paddingBottom: 0 }}
							/>
						</div>
						<MiniModalController
							title={reportProps.title}
							show={reportProps.showModal}
							onClose={reportProps.closeModal}
						>
							{
								<div className="d-flex flex-column align-items-center">
									<div className="d-flex my-3 datePickerContainer">
										<ReactDatePicker
											selectsRange
											startDate={reportProps.startDate}
											endDate={reportProps.endDate}
											selected={reportProps.startDate}
											onChange={reportProps.onChange}
											isClearable={true}
											showIcon
											className="gary-border"
										/>
									</div>
									<ReportButtonComponent
										onClick={() => reportProps.confirmDownloadReport(reportProps.handleDownloadReport)}
										reportType={REPORT_TYPES.XLSX}
										disabled={(!reportProps.startDate ? true : false) || (!reportProps.endDate ? true : false)}
									/>
								</div>
							}
						</MiniModalController>
					</div>
			}
		</>
	);
}

export default AttendancesView