import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	useCallback,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {Tab, Tabs, Tooltip}
	from "react-bootstrap"
import OverlayTrigger
	from "react-bootstrap/OverlayTrigger"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"
import {Popover}
	from "react-tiny-popover"
import _debounce
	from "lodash/debounce"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch
}
	from "./../../globals"
import
{
	baseRole,
	checkIcon,
	currency,
	getErrorMessage,
	isEmpty,
	isNotEmpty,
	monthRef,
	toAcceptableDate,
	UIState,
	validateField,
}
	from "./../../common"
import
{
	tunning,
	courseLevelStatus,
	employeeStatus,
	ENROLLMENT_STATUS
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import LeDatePicker
	from "./../../components/leDatePicker"
import UnderlineHeading
	from "./../../components/underlineHeading"
import {DataDisplayModal}
	from "./../../components/sectionContainer"
import CourseLevelsService
	from "./../../services/campus/courseLevels.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import StoreService
	from "./../../services/campus/store.service"
import AssignmentsService
	from "./../../services/assignments.service"
import courseImage
	from "./../../assets/images/course.png"
import userImage
	from "./../../assets/images/user.png"
import articleImage
	from "./../../assets/images/article.png"

import "./../../components/dropdown/style.css"
import "./style.css"

type State = {isButtonDisabled : boolean}

const initialState : State = {isButtonDisabled : true}

type Action=
{
	type    : "setIsButtonDisabled",
	payload : boolean
}

const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled": 
			return{
				...state,
				isButtonDisabled : action.payload
			}
		break;
	}
}

const LevelPrompt = (props : any)=>
{
	const levelSection : any=
	{
		MAIN_INFO        : 0,
		ENROLLED_MENTORS : 1,
		AVAILABLE_KITS   : 2,
		ASSIGNMENTS      : 3
	}

	const viewHeading : string[]=
	[
		"Principal",
		"Mentores",
		"Kits disponibles",
		"Actividades"
	]

	const levelStatus : any=
	[
		solid("circle-plus"),
		solid("circle-check"),
		solid("trash-arrow-up"),
	]

	const listItemUI=
	[
		"btn btn-default",
		"btn btn-primary",
		"btn btn-default bg-danger",
	]

	let   fileReader   : any
	const [detailState, trigger]                                = useReducer(reducer, initialState)
	const dispatch                                              = useDispatch()
	const idleLocked        : boolean                           = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                     = useSelector((state : RootState) => state.sessionToken.value)
	const userProfile       : any                               = useSelector((state : RootState) => state.userProfile.value)
	const basicInfo         : any                               = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any                               = useSelector((state : RootState) => state.currentUserCampus.value);
	const campusRef         : any                               = useSelector((state : RootState) => state.campusRef.value)
	const currentUser       : any                               = useSelector((state : RootState) => state.userProfile.value)
	const [userCampus, setUserCampus]                           = useState<number>(currentUserCampus)
	const [aSearchInProgress, setASearchInProgress]             = useState<boolean>(false)
	const noCampusesProvided : boolean                          = currentUserCampus == null
	const [mentorsToEnlist, setMentorsToEnlist]                 = useState<any>([])
	const [kitsToProvide, setKitsToProvide]                     = useState<any>([])
	const [assignmentsToLink, setAssignmentsToLink]             = useState<any>([])
	const [initMentors, setInitMentors]                         = useState<any>([])
	const [initKits, setInitKits]                               = useState<any>([])
	const [initAssignments, setInitAssignments]                 = useState<any>([])
	const [UIStatus, setUIStatus]                               = useState<number>(UIState.NORMAL)
	const [levelMentorCheck, setLevelMentorCheck]               = useState<any>({})
	const [levelMentorDeletion, setLevelMentorDeletion]         = useState<any>({})
	const [levelMentorAdding, setLevelMentorAdding]             = useState<any>({})
	const [levelMentorInit, setLevelMentorInit]                 = useState<any>({})
	const [mentorListSearch, setMentorListSearch]               = useState<any>("")
	const [levelKitCheck, setLevelKitCheck]                     = useState<any>({})
	const [levelKitDeletion, setLevelKitDeletion]               = useState<any>({})
	const [levelKitAdding, setLevelKitAdding]                   = useState<any>({})
	const [levelKitInit, setLevelKitInit]                       = useState<any>({})
	const [kitListSearch, setKitListSearch]                     = useState<any>("")
	const [levelAssignmentCheck, setLevelAssignmentCheck]       = useState<any>({})
	const [levelAssignmentDeletion, setLevelAssignmentDeletion] = useState<any>({})
	const [levelAssignmentAdding, setLevelAssignmentAdding]     = useState<any>({})
	const [levelAssignmentInit, setLevelAssignmentInit]         = useState<any>({})
	const [assignmentListSearch, setAssignmentListSearch]       = useState<any>("")
	const [editableLevel, setEditableLevel]                     = useState<any>(null)
	const [unchangedRecord, setUnchangedRecord]                 = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                   = useState<boolean>(false)
	const [saveSuccess, setSaveSuccess]                         = useState<boolean>(false)
	const [unfilledMainData, setUnfilledMainData]               = useState<boolean>(false)
	const [currentTab, setCurrentTab]                           = useState<string | null>("mainInfo")
	const [saveError, setSaveError]                             = useState<any>(null)
	const [currentSection, setCurrentSection]                   = useState<number>(levelSection.MAIN_INFO)
	const [isMentorPopoverOpen, setIsMentorPopoverOpen]         = useState<boolean>(false)
	const [isKitPopoverOpen, setIsKitPopoverOpen]               = useState<boolean>(false)
	const [isAssignmentPopoverOpen, setIsAssignmentPopoverOpen] = useState<boolean>(false)
	const [promptLoaded, setPromptLoaded]                       = useState<boolean>(false)
	const [oneBatchSaved, setOneBatchSaved]                     = useState<boolean>(false)
	let   recordFormRef                                               = useRef<HTMLFormElement >(null)
	let   nameRef                                               = useRef<HTMLInputElement>(null)
	let   mentorNameRef                                         = useRef<HTMLInputElement>(null)
	let   kitNameRef                                            = useRef<HTMLInputElement>(null)
	let   assignmentNameRef                                     = useRef<HTMLInputElement>(null)
	let   sessionMinHoursRef                                    = useRef<HTMLInputElement>(null)
	let   weekHoursRef                                          = useRef<HTMLInputElement>(null)
	let   codeRef                                               = useRef<HTMLInputElement>(null)
	let   subscriptionBasePriceHNLRef                           = useRef<HTMLInputElement>(null)
	let   subscriptionBasePriceMXNRef                           = useRef<HTMLInputElement>(null)
	let   subscriptionBasePriceUSDRef                           = useRef<HTMLInputElement>(null)
	let   monthlyBillingBasePriceHNLRef                         = useRef<HTMLInputElement>(null)
	let   monthlyBillingBasePriceMXNRef                         = useRef<HTMLInputElement>(null)
	let   monthlyBillingBasePriceUSDRef                         = useRef<HTMLInputElement>(null)
	let   subscriptionSurchargeBaseCostHNLRef                   = useRef<HTMLInputElement>(null)
	let   subscriptionSurchargeBaseCostMXNRef                   = useRef<HTMLInputElement>(null)
	let   subscriptionSurchargeBaseCostUSDRef                   = useRef<HTMLInputElement>(null)
	let   monthlyBillingSurchargeBaseCostHNLRef                 = useRef<HTMLInputElement>(null)
	let   monthlyBillingSurchargeBaseCostMXNRef                 = useRef<HTMLInputElement>(null)
	let   monthlyBillingSurchargeBaseCostUSDRef                 = useRef<HTMLInputElement>(null)
	let   secondSurchargeBaseCostHNLRef                         = useRef<HTMLInputElement>(null)
	let   secondSurchargeBaseCostMXNRef                         = useRef<HTMLInputElement>(null)
	let   secondSurchargeBaseCostUSDRef                         = useRef<HTMLInputElement>(null)
	let   imageRef                                              = useRef<HTMLInputElement>(null)
	let   mainInfoFormRef                                       = useRef<HTMLFormElement>(null)
	let   descriptionRef                                        = useRef<HTMLTextAreaElement>(null)
	let   tabDivRef                                             = useRef<HTMLDivElement>(null)
	const startingDate = new Date()
	const year         = startingDate.getFullYear()

	const unedit = ()=>
	{
		if(editableLevel && editableLevel.id == null)
		{
			props.closeFn()
		}

		setEditableLevel(null)
	}

	const dbQueryComplaint = ()=>
	{
		setSaveError("Se produjo un error al realizar una consulta a la base de datos")
	}

	const kitsProviding =(kits : any)=>
	{
		setKitsToProvide(kits.map((kit : any) => ({...kit, refIndex : "K_" + kit.id})))
	}

	const mentorsEnrollment =(mentors : any)=>
	{
		setMentorsToEnlist
		(
			mentors.filter
			(
				(mentor : any) => (mentor.id != userProfile.id)
			).map
			(
				(mentor : any)=>
				{
					let formattedMentor = {...mentor, refIndex : "M_" + mentor.id}

					if(props.selectedLevel.id && isNotEmpty(mentor.enrollments))
					{
						for(const anEnrollment of mentor.enrollments)
						{
							if
							(
								anEnrollment.schedule.level_id == props.selectedLevel.id
									&& anEnrollment.status_id == ENROLLMENT_STATUS.ACTIVE
							)
							{
								formattedMentor.activelyEnrolled = true
								break
							}
						}
					}

					return formattedMentor
				}
			)
		)
	}

	const assignmentsLinking = (asignments : any)=>
	{
		setAssignmentsToLink
		(
			asignments.map
			(
				(assignment : any)=>
				{
					if(assignment.id != props.selectedLevel.assignment_id)
					{
						return{...assignment, refIndex : "A_" + assignment.id}
					}
				}
			)
		)
	}

	const editLevel = async()=>
	{
		let lvMentorInit     : any = {}
		let lvKitInit        : any = {}
		let lvAssignmentInit : any = {}

		props.selectedLevel.mentors.map
		(
			(mentor : any)=>
			{
				lvMentorInit[mentor.refIndex] = mentor
			}
		)

		props.selectedLevel.kits.map
		(
			(kit : any)=>
			{
				lvKitInit[kit.refIndex] = kit
			}
		)

		props.selectedLevel.assignments.map
		(
			(assignment : any)=>
			{
				lvAssignmentInit[assignment.refIndex] = assignment
			}
		)

		setSaveError(null)
		dispatch(enableIdleLockSwitch())
		setUIStatus(UIState.LOCKED)

		try
		{
			let course : any =
				props.courseRef ? props.courseRef["C_" + props.selectedLevel.course_id] : props.selectedLevel.course
			let result : any = await AssignmentsService.searchAssignments
			(
				{
					q           : "",
					tagIds      : _.map(course.tags, "id"),
					categoryIds : [course.category_id]
				},
				undefined,
				100
			)

			if(result.status == 200 || result.status == 204)
			{
				assignmentsLinking(result.data)
				setInitAssignments(result.data)
				setAssignmentListSearch("")
				setLevelAssignmentCheck({})
				setLevelAssignmentDeletion({})
				setLevelAssignmentAdding({})
				setLevelAssignmentInit(lvAssignmentInit)

				if(noCampusesProvided)
				{
					setMentorListSearch("")
					setLevelMentorCheck({})
					setLevelMentorDeletion({})
					setLevelMentorAdding({})
					setLevelMentorInit(lvMentorInit)
					setKitListSearch("")
					setLevelKitCheck({})
					setLevelKitDeletion({})
					setLevelKitAdding({})
					setLevelKitInit(lvKitInit)
					setUIStatus(UIState.NORMAL)
					setEditableLevel({...props.selectedLevel, description : props.selectedLevel.description || ""})
				}
				else
				{
					let result0 : any = await EmployeesService.searchEmployees
						({q : "", roleIds : [baseRole.MENTOR], statusId : courseLevelStatus.ACTIVE}, undefined, 100)

					if(result0.status == 200 || result0.status == 204)
					{
						mentorsEnrollment(result0.data)
						setInitMentors(result0.data)

						let result1 : any = await StoreService.searchArticles
						(
							campusRef[currentUserCampus].id,
							{
								q          : "",
								categoryId : course.category_id,
								tagIds     : _.map(course.tags, "id"),
								onlyKits   : true
							},
							undefined,
							100
						)

						if(result1.status == 200 || result1.status == 204)
						{
							let deletionRef : any = {}

							let kits = props.selectedLevel.kits.map
							(
								(kit : any)=>
								{
									if(kit.company_id != campusRef[currentUserCampus].company_id)
									{
										deletionRef[kit.refIndex] = true
									}

									return {...kit, deletion : kit.company_id != campusRef[currentUserCampus].company_id}
								}
							)

							setLevelAssignmentCheck({})
							setLevelAssignmentDeletion({})
							setLevelAssignmentAdding({})
							setLevelAssignmentInit(lvAssignmentInit)
							kitsProviding(result1.data)
							setInitKits(result1.data)
							setMentorListSearch("")
							setLevelMentorCheck({})
							setLevelMentorDeletion({})
							setLevelMentorAdding({})
							setLevelMentorInit(lvMentorInit)
							setKitListSearch("")
							setAssignmentListSearch("")
							setUIStatus(UIState.NORMAL)
							setLevelKitCheck({})
							setLevelKitAdding({})
							setLevelKitInit(lvKitInit)
							setLevelKitDeletion(deletionRef)
							setEditableLevel
							({
								...props.selectedLevel, description : props.selectedLevel.description || "",
								kits : props.selectedLevel.kits.map
									((kit : any) => ({...kit, deletion : kit.company_id != campusRef[currentUserCampus].company_id}))
							})

						}
						else
						{
							dbQueryComplaint()
							setUIStatus(UIState.ERROR)
						}

						dispatch(disableIdleLockSwitch())
					}
					else
					{
						dbQueryComplaint()
						setUIStatus(UIState.ERROR)
					}
				}
			}
			else
			{
				dbQueryComplaint()
				dispatch(disableIdleLockSwitch())
			}
		}
		catch(error)
		{
			dbQueryComplaint()
			setUIStatus(UIState.ERROR)
			console.log(error)
			dispatch(disableIdleLockSwitch())
		}
	}

	const saveRecord = async (override? : boolean)=>
	{
		if(mainInfoFormRef && mainInfoFormRef.current)
		{
			if(!mainInfoFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)

					if(currentTab == "mainInfo")
					{
						mainInfoFormRef.current.reportValidity()
					}
					else
					{
						tabDivRef?.current?.focus()
						setUnfilledMainData(true)
					}

					setTimeout
					(
						() =>
						{
							setUnfilledFields(false)
							setUnfilledMainData(false)
						},
						3000
					)
				}
			}
			else
			{
				let mentorProcedures   : any = {}
				let kitProcedures      : any = {}
				let mentorAddingKeys   : any = Object.keys(levelMentorAdding)
				let mentorDeletionKeys : any = Object.keys(levelMentorDeletion)
				let kitAddingKeys      : any = Object.keys(levelKitAdding)
				let kitDeletionKeys    : any = Object.keys(levelKitDeletion)
				let assignmentRefs     : any = []

				if(mentorAddingKeys.length > 0)
				{
					mentorProcedures.idsAdding = mentorAddingKeys.join(",").replaceAll("M_", "")
				}

				if(mentorDeletionKeys.length > 0)
				{
					mentorProcedures.idsDeletion = mentorDeletionKeys.join(",").replaceAll("M_", "")
				}

				if(kitAddingKeys.length > 0)
				{
					kitProcedures.idsAdding = kitAddingKeys.join(",").replaceAll("K_", "")
				}

				if(kitDeletionKeys.length > 0)
				{
					kitProcedures.idsDeletion = kitDeletionKeys.join(",").replaceAll("K_", "")
				}

				/*
					mentors and kits linking modifications info is sent as adding and deletion lists (ids to add and to delete,
					if any in each case).
					Assignment modifications info is now sent as a sync list. Only linked assignment records to keep and update are sent,
					So the absent records noticed on backend collation are simply deleted.
				*/

				editableLevel.assignments.map
				(
					(item : any, index : number)=>
					{
						if(item && (levelAssignmentInit[item.refIndex] && levelAssignmentDeletion[item.refIndex] == null))
						{
							console.log(item)

							assignmentRefs.push
							({
								id                : item.id,
								points            : item.points,
								availabilityStart : toAcceptableDate(item.availabilityStartValue),
								availabilityEnd   : toAcceptableDate(item.availabilityEndValue)
							})
						}
					}
				)

				editableLevel.assignments.map
				(
					(item : any, index : number)=>
					{
						if(item && (levelAssignmentAdding[item.refIndex]))
						{
							assignmentRefs.push
							({
								id                : item.id,
								points            : item.points,
								availabilityStart : toAcceptableDate(item.availabilityStartValue),
								availabilityEnd   : toAcceptableDate(item.availabilityEndValue)
							})
						}
					}
				)

				if(saveError != null)
				{
					setSaveError(null)
				}

				setUIStatus(UIState.LOCKED)
				dispatch(enableIdleLockSwitch())

				try
				{
					let result = await CourseLevelsService.saveLevel(props.selectedLevel, editableLevel, mentorProcedures, kitProcedures, assignmentRefs)

					if(result.status != 200 && result.status != 204)
					{
						setSaveError("La información no pudo ser guardada")
						dispatch(disableIdleLockSwitch())
					}
					else
					{
						setSaveSuccess(true)
						setTimeout
						(
							()=>
							{
								if(override != true)
								{
									props.closeFn()
									setEditableLevel(null)
									props.afterCloseFn()
								}
								else
								{
									dispatch(disableIdleLockSwitch())

									if(!oneBatchSaved)
									{
										setOneBatchSaved(true)
									}
								}
								setSaveSuccess(false)
							},
							tunning.MODAL_DISMISS_DELAY
						)
					}
				}
				catch(error : any)
				{
					console.log(error)
					setSaveError(getErrorMessage(error))
					dispatch(disableIdleLockSwitch())
				}
			}
		}
	}

	const enrollMentor = (mentor : any)=>
	{
		if(levelMentorInit[mentor.refIndex] == null)
		{
			setEditableLevel({...editableLevel, mentors : [...editableLevel.mentors, mentor]})

			let adding              = levelMentorAdding
			adding[mentor.refIndex] = mentor

			setLevelMentorAdding(adding)
		}
		else
		{
			let deletion = levelMentorDeletion

			delete deletion[mentor.refIndex]

			setLevelMentorDeletion(deletion)

			setEditableLevel
			({
				...editableLevel, mentors : editableLevel.mentors.map
				(
					(enrolled : any) => ({...enrolled, deletion : enrolled.refIndex == mentor.refIndex ? false : mentor.refIndex})
				)
			})
		}
	}

	const provideKit = (kit : any)=>
	{
		if(levelKitInit[kit.refIndex] == null)
		{
			setEditableLevel({...editableLevel, kits : [...editableLevel.kits, kit]})

			let adding           = levelKitAdding
			adding[kit.refIndex] = kit

			setLevelKitAdding(adding)
		}
		else
		{
			let deletion = levelKitDeletion

			delete deletion[kit.refIndex]

			setLevelKitDeletion(deletion)

			setEditableLevel
			({
				...editableLevel, kits : editableLevel.kits.map
					((provided : any) => ({...provided, deletion : provided.refIndex == kit.refIndex ? false : kit.refIndex}))
			})
		}
	}

	const linkAssignment = (assignment : any)=>
	{
		let formattedAssignment=
		{
			...assignment, points : (assignment.pivot && assignment.pivot.available_points)   || assignment.points,
			availability_start    : (assignment.pivot && assignment.pivot.availability_start) || (year + "/01/01"),
			availability_end      : (assignment.pivot && assignment.pivot.availability_end  ) || (year + "/12/31"),
		}

		formattedAssignment.availabilityStartValue = new Date(formattedAssignment.availability_start + " 23:00:00")
		formattedAssignment.availabilityEndValue   = new Date(formattedAssignment.availability_end   + " 23:00:00")

		if(levelAssignmentInit[formattedAssignment.refIndex] == null)
		{
			setEditableLevel({...editableLevel, assignments : [...editableLevel.assignments, formattedAssignment]})

			let adding                           = levelAssignmentAdding
			adding[formattedAssignment.refIndex] = formattedAssignment

			setLevelAssignmentAdding(adding)
		}
		else
		{
			let deletion = levelAssignmentDeletion

			delete deletion[formattedAssignment.refIndex]

			setLevelAssignmentDeletion(deletion)

			setEditableLevel
			({
				...editableLevel, assignments : editableLevel.assignments.map
				(
					(linked : any)=>
						({...linked, deletion : linked.refIndex == formattedAssignment.refIndex ? false : formattedAssignment.refIndex})
				)
			})
		}
	}

	const handleDebounceKitSearch = async(campusId : number, term : string, course : any)=>
	{
		setASearchInProgress(true)

		try
		{
			let result : any = await StoreService.searchArticles
			(
				campusId,
				{
					q          : term,
					onlyKits   : true,
					categoryId : course.category_id,
					tagIds     : _.map(course.tags, "id")
				},
				undefined,
				100
			)

			if(result.status == 200 || result.status == 204)
			{	
				kitsProviding(result.data)
			}
			else
			{
				searchError()
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			searchError()
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setASearchInProgress(false)
		}
	}

	const debounceMentorFn     : any = useCallback(_debounce(handleDebounceMentorSearch    , 1000), [])
	const debounceKitFn        : any = useCallback(_debounce(handleDebounceKitSearch       , 1000), [])
	const debounceAssignmentFn : any = useCallback(_debounce(handleDebounceAssignmentSearch, 1000), [])
	const searchError          : any = () => {setSaveError("Se produjo un error al realizar la búsqueda")}

	async function handleDebounceMentorSearch(term : string)
	{
		setASearchInProgress(true)

		try
		{
			let result : any = await EmployeesService.searchEmployees
				({q : term, roleIds : [baseRole.MENTOR], statusId : employeeStatus.ACTIVE}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				mentorsEnrollment(result.data)
			}
			else
			{
				searchError()
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			searchError()
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setASearchInProgress(false)
		}
	}

	async function handleDebounceAssignmentSearch(campusId : number, term : string, course : any)
	{
		setASearchInProgress(true)

		try
		{
			let result : any = await AssignmentsService.searchAssignments
			(
				{
					q        : term,
					onlyKits : true,
					tagIds   : _.map(course.tags, "id")
				},
				undefined,
				100
			)

			if(result.status == 200 || result.status == 204)
			{
				assignmentsLinking(result.data)
			}
			else
			{
				searchError()
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			searchError()
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setASearchInProgress(false)
		}
	}

	const searchMentors = (term : string)=>
	{
		setMentorListSearch(term)
		debounceMentorFn(term)
	}

	const searchKits = (term : string)=>
	{
		setKitListSearch(term)
		debounceKitFn(campusRef[currentUserCampus].id, term, props.courseRef["C_" + editableLevel.course_id])
	}

	const searchAssignments = (term : string)=>
	{
		setAssignmentListSearch(term)
		debounceAssignmentFn(campusRef[currentUserCampus].id, term, props.courseRef["C_" + editableLevel.course_id].tags)
	}

	const handleRegistryChange = (e : any)=>
	{
		if(e && e.target)
		{
			setEditableLevel({...editableLevel, [e.target.name] : e.target.value})
		}
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			const courseChangeUpdate = async ()=>
			{
				if(editableLevel && editableLevel.id == null)
				{
					if(!promptLoaded)
					{
						setPromptLoaded(true)
					}
					else
					{
						setEditableLevel
						({
							...editableLevel, kits : [],
							assignments            : [],
							
						})

						dispatch(enableIdleLockSwitch())
						setUIStatus(UIState.LOCKED)

						try
						{
							let course : any = props.courseRef
								? props.courseRef["C_" + editableLevel.course_id] :
									props.selectedLevel.course

							let result : any = await AssignmentsService.searchAssignments
							(
								{
									q           : assignmentListSearch,
									tagIds      : _.map(course.tags, "id"),
									categoryIds : [course.category_id]
								},
								undefined,
								100
							)

							if(result.status == 200 || result.status == 204)
							{
								assignmentsLinking(result.data)
								setLevelAssignmentCheck({})
								setLevelAssignmentDeletion({})
								setLevelAssignmentAdding({})

								if(noCampusesProvided)
								{
									setUIStatus(UIState.NORMAL)
									dispatch(disableIdleLockSwitch())
								}
								else
								{
									let result0 : any = await StoreService.searchArticles
									(
										campusRef[currentUserCampus].id,
										{
											q           : kitListSearch,
											categoryId  : course.category_id,
											onlyKits    : true,
											tagIds      : course.tags
										},
										undefined,
										100
									)

									if(result0.status == 200 || result0.status == 204)
									{
										kitsProviding(result0.data)
										setLevelKitCheck({})
										setLevelKitDeletion({})
										setLevelKitAdding({})
										setUIStatus(UIState.NORMAL)
									}
									else
									{
										dbQueryComplaint()
										setUIStatus(UIState.ERROR)
									}

									dispatch(disableIdleLockSwitch())
								}
							}
							else
							{
								dbQueryComplaint()
								dispatch(disableIdleLockSwitch())
							}
						}
						catch(error)
						{
							dbQueryComplaint()
							setUIStatus(UIState.ERROR)
							console.log(error)
							dispatch(disableIdleLockSwitch())
						}
					}
				}
			}

			courseChangeUpdate()
		},
		[editableLevel?.course_id]
	)

	useEffect
	(
		()=>
		{
			setMentorsToEnlist(props.initMentors)
			setKitsToProvide(props.initKits)
			setAssignmentsToLink(props.initAssignments)

			if(props.selectedLevel.id == null)
			{
				setMentorListSearch       ("")
				setLevelMentorCheck       ({})
				setLevelMentorDeletion    ({})
				setLevelMentorAdding      ({})
				setLevelMentorInit        ({})
				setKitListSearch          ("")
				setLevelKitCheck          ({})
				setLevelKitDeletion       ({})
				setLevelKitAdding         ({})
				setLevelKitInit           ({})
				setAssignmentListSearch   ("")
				setLevelAssignmentCheck   ({})
				setLevelAssignmentDeletion({})
				setLevelAssignmentAdding  ({})
				setLevelAssignmentInit    ({})
				setUIStatus               (UIState.NORMAL)
				setEditableLevel          (props.selectedLevel)
			}
		},
		[]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const closeDetailPrompt = ()=>
	{
		props.closeFn()
		setEditableLevel(null)
	}

	const toggleMentorListSelection = (item : any)=>
	{
		let check = levelMentorCheck

		if(check[item.refIndex])
		{
			delete check[item.refIndex]
		}
		else
		{
			check[item.refIndex] = true
		}

		setLevelMentorCheck(check)
		setEditableLevel({...editableLevel})
	}

	const toggleKitListSelection = (item : any)=>
	{
		let check = levelKitCheck

		if(check[item.refIndex])
		{
			delete check[item.refIndex]
		}
		else
		{
			check[item.refIndex] = true
		}

		setLevelKitCheck(check)
		setEditableLevel({...editableLevel})
	}

	const toggleAssignmentListSelection = (item : any)=>
	{
		let check = levelAssignmentCheck

		if(check[item.refIndex])
		{
			delete check[item.refIndex]
		}
		else
		{
			check[item.refIndex] = true
		}

		setLevelAssignmentCheck(check)
		setEditableLevel({...editableLevel})
	}

	const toggleAllInMentorList = ()=>
	{
		let collationCheck = levelMentorCheck

		const allCheck = !(
			Object.keys(levelMentorCheck).length ==
			(
				Object.keys(levelMentorInit).length - Object.keys(levelMentorDeletion).length
				+ Object.keys(levelMentorAdding).length
			)
		)

		if(!allCheck)
		{
			setLevelMentorCheck({})
		}
		else
		{
			editableLevel.mentors.map
			(
				(mentor : any)=>
				{
					if(mentor.deletion != true && collationCheck[mentor.refIndex] == null)
					{
						collationCheck[mentor.refIndex] = true
					}
				}
			)

			setLevelMentorCheck(collationCheck)
		}

		setEditableLevel({...editableLevel})
	}

	const toggleAllInKitList = ()=>
	{
		let collationCheck = levelKitCheck

		const allCheck=
		!(
			Object.keys(levelKitCheck).length ==
			(
				Object.keys(levelKitInit).length - Object.keys(levelKitDeletion).length
				+ Object.keys(levelKitAdding).length
			)
		)

		if(!allCheck)
		{
			setLevelKitCheck({})
		}
		else
		{
			editableLevel.kits.map
			(
				(kit : any)=>
				{
					if(kit.deletion != true && collationCheck[kit.refIndex] == null)
					{
						collationCheck[kit.refIndex] = true
					}
				}
			)

			setLevelKitCheck(collationCheck)
		}

		setEditableLevel({...editableLevel})
	}

	const toggleAllInAssignmentList = ()=>
	{
		let collationCheck = levelKitCheck

		const allCheck=
		!(
			Object.keys(levelKitCheck).length ==
			(
				Object.keys(levelAssignmentInit).length - Object.keys(levelAssignmentDeletion).length
				+ Object.keys(levelAssignmentAdding).length
			)
		)

		if(!allCheck)
		{
			setLevelAssignmentCheck({})
		}
		else
		{
			editableLevel.assignments.map
			(
				(assignment : any)=>
				{
					if(assignment.deletion != true && collationCheck[assignment.refIndex] == null)
					{
						collationCheck[assignment.refIndex] = true
					}
				}
			)

			setLevelMentorCheck(collationCheck)
		}

		setEditableLevel({...editableLevel})
	}

	const undoMentorListRemoval = (key : string)=>
	{
		let deletion : any = levelMentorDeletion

		delete deletion[key]
		setLevelMentorDeletion(deletion)
		setEditableLevel
		({
			...editableLevel,
			mentors : editableLevel.mentors.map
			(
				(mentor : any)=>
				({
					...mentor,
					deletion : mentor.refIndex == key ? false : mentor.deletion
				})
			)
		})
	}

	const undoKitListRemoval = (key : string)=>
	{
		let deletion : any = levelMentorDeletion

		delete deletion[key]
		setLevelKitDeletion(deletion)
		setEditableLevel
		({
			...editableLevel,
			mentors : editableLevel.kits.map
			(
				(kit : any)=>
				({
					...kit,
					deletion : kit.refIndex == key ? false : kit.deletion
				})
			)
		})
	}

	const undoAssignmentListRemoval = (key : string)=>
	{
		let deletion : any = levelAssignmentDeletion

		delete deletion[key]
		setLevelAssignmentDeletion(deletion)
		setEditableLevel
		({
			...editableLevel, mentors : editableLevel.assignments.map
			(
				(assignment : any) => ({...assignment, deletion : assignment.refIndex == key ? false : assignment.deletion})
			)
		})
	}

	const mentorListRemoval = (target? : any)=>
	{
		let deletion : any = levelMentorDeletion
		let adding   : any = levelMentorAdding
		let check    : any = levelMentorCheck

		if(isNaN(target))
		{
			setEditableLevel
			({
				...editableLevel, mentors : editableLevel.mentors.filter
				(
					(mentor : any)=>
					{
						if(check[mentor.refIndex])
						{
							if(adding[mentor.refIndex])
							{
								delete adding[mentor.refIndex]
							}
						}

						return mentor.stocked || (adding[mentor.refIndex] && !check[mentor.refIndex])
					}
				).map
				(
					(mentor : any)=>
					{
						if(mentor.stocked && check[mentor.refIndex])
						{
							deletion[mentor.refIndex] = true
						}

						return {...mentor, deletion : check[mentor.refIndex] == true}
					}
				)
			})

			setLevelMentorAdding(adding)
			setLevelMentorDeletion(deletion)
			setLevelMentorCheck({})
		}
		else
		{
			let refIndex = editableLevel.mentors[target].refIndex

			if(check[refIndex])
			{
				delete(check[refIndex])
				setLevelMentorCheck(check)
			}

			if(adding[refIndex])
			{
				delete adding[refIndex]
				setLevelMentorAdding(adding)
			}
			else
			{
				deletion[refIndex] = true

				setLevelMentorDeletion(deletion)
			}

			if(editableLevel.mentors[target].stocked)
			{
				setEditableLevel
				({
					...editableLevel, mentors : editableLevel.mentors.map
					(
						(mentor : any, index : number)=>
						({
							...mentor, deletion : index == target ? true : mentor.deletion
						})
					)
				})
			}
			else
			{
				setEditableLevel({...editableLevel, mentors : editableLevel.mentors.filter((mentor : any, index : number) => index != target)})
			}
		}
	}

	const kitListRemoval = (target? : any)=>
	{
		let deletion : any = levelKitDeletion
		let adding   : any = levelKitAdding
		let check    : any = levelKitCheck

		if(isNaN(target))
		{
			setEditableLevel
			({
				...editableLevel, kits : editableLevel.kits.filter
				(
					(kit : any)=>
					{
						if(check[kit.refIndex])
						{
							if(adding[kit.refIndex])
							{
								delete adding[kit.refIndex]
							}
						}

						return kit.stocked || (adding[kit.refIndex] && !check[kit.refIndex])
					}
				).map
				(
					(kit : any)=>
					{
						if(kit.stocked && check[kit.refIndex])
						{
							deletion[kit.refIndex] = true
						}

						return {...kit, deletion : check[kit.refIndex] == true}
					}
				)
			})

			setLevelKitAdding(adding)
			setLevelKitDeletion(deletion)
			setLevelKitCheck({})
		}
		else
		{
			let refIndex = editableLevel.kits[target].refIndex

			if(check[refIndex])
			{
				delete(check[refIndex])
				setLevelKitCheck(check)
			}

			if(adding[refIndex])
			{
				delete adding[refIndex]
				setLevelKitAdding(adding)
			}
			else
			{
				deletion[refIndex] = true

				setLevelKitDeletion(deletion)
			}

			if(editableLevel.kits[target].stocked)
			{
				setEditableLevel
				({
					...editableLevel, kits : editableLevel.kits.map
						((kit : any, index : number) => ({...kit, deletion : index == target ? true : kit.deletion}))
				})
			}
			else
			{
				setEditableLevel
					({...editableLevel, kits : editableLevel.kits.filter((kit : any, index : number) => index != target)})
			}
		}
	}

	const assignmentListRemoval = (target? : any)=>
	{
		let deletion : any = levelAssignmentDeletion
		let adding   : any = levelAssignmentAdding
		let check    : any = levelAssignmentCheck

		if(isNaN(target))
		{
			setEditableLevel
			({
				...editableLevel, assignments : editableLevel.assignments.filter
				(
					(assignment : any)=>
					{
						if(check[assignment.refIndex])
						{
							if(adding[assignment.refIndex])
							{
								delete adding[assignment.refIndex]
							}
						}

						return assignment.stocked || (adding[assignment.refIndex] && !check[assignment.refIndex])
					}
				).map
				(
					(assignment : any)=>
					{
						if(assignment.stocked && check[assignment.refIndex])
						{
							deletion[assignment.refIndex] = true
						}

						return {...assignment, deletion : check[assignment.refIndex] == true}
					}
				)
			})

			setLevelAssignmentAdding(adding)
			setLevelAssignmentDeletion(deletion)
			setLevelAssignmentCheck({})
		}
		else
		{
			let refIndex = editableLevel.assignments[target].refIndex

			if(check[refIndex])
			{
				delete(check[refIndex])
				setLevelAssignmentCheck(check)
			}

			if(adding[refIndex])
			{
				delete adding[refIndex]
				setLevelAssignmentAdding(adding)
			}
			else
			{
				deletion[refIndex] = true

				setLevelAssignmentDeletion(deletion)
			}

			if(editableLevel.assignments[target].stocked)
			{
				setEditableLevel
				({
					...editableLevel, assignments : editableLevel.assignments.map
					(
						(assignment : any, index : number) => ({...assignment, deletion : index == target ? true : assignment.deletion})
					)
				})
			}
			else
			{
				setEditableLevel
				({
					...editableLevel, assignments : editableLevel.assignments.filter((assignment : any, index : number) => index != target)
				})
			}
		}
	}

	return <DataDisplayModal editableRecord={editableLevel} unfilledFields={unfilledFields} promptCloseFn={closeDetailPrompt}
	  secondAction={((props.courseRef && props.selectedLevel.id == null) && (() => saveRecord(true))) || null} form={recordFormRef}
	  headIcon={<CustomIcon name="level" />} unchangedRecord={unchangedRecord} allowedUpdate={props.allowedUpdate} UIStatus={UIStatus}
	  saveDataFn={saveRecord} entityName="Módulo" uneditFn={unedit} saveLock={detailState.isButtonDisabled} dataEditInit={editLevel}
	  cornerDismissalCallback={oneBatchSaved ? props.afterCloseFn : null} processingError={saveError} saveSuccess={saveSuccess}
	><div className="container"><fieldset>
		<legend style={{display : "flex"}}>
			<Popover isOpen={unfilledMainData && currentTab != "mainInfo"} positions={["bottom"]}
			  content={<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px" }}>
				<div className="requirementAlert">
					<FontAwesomeIcon icon={solid("exclamation")} />
				</div>
				&nbsp;
				Requerido: Aún hay campos por llenar
			  </div>}
			><button onClick={() => {setCurrentTab("mainInfo")}} disabled={idleLocked || currentTab == "mainInfo"} type="button"
			  className="btn btn-default"
			>
				<FontAwesomeIcon icon={solid("circle-info")} size="2x" />
				<span className="d-none d-md-inline-block" style={{paddingLeft : "10px"}}>
					Información
				</span>
			</button></Popover>
			{
				(noCampusesProvided) ? "" : <>
					{
						<button type="button"  className="btn btn-default " onClick={() => setCurrentTab("availableKits")}
						  disabled={idleLocked || currentTab == "availableKits"}
						>
							<FontAwesomeIcon icon={solid("boxes-packing")} size="2x" />
							<span className="d-none d-md-inline-block" style={{paddingLeft : "10px"}}>
								Kits disponibles
							</span>
						</button>
					}
					{
						<button type="button"  onClick={() => setCurrentTab("enrolledMentors")} className="btn btn-default"
						  disabled={idleLocked || currentTab == "enrolledMentors"}
						>
							<FontAwesomeIcon icon={solid("chalkboard-user")} size="2x" />
							<span className="d-none d-md-inline-block" style={{paddingLeft : "10px"}}>
								Profesores
							</span>
						</button>
					}
				</>
			}
			{/*<button onClick={() => {setCurrentSection(levelSection.ASSIGNMENTS)}} className="btn btn-default"
			  type="button" disabled={idleLocked || currentSection == levelSection.ASSIGNMENTS}
			>
				<FontAwesomeIcon icon={brands("wpforms")} size="2x" />
				<span className="d-none d-md-inline-block" style={{paddingLeft : "10px"}}>
					Actividades
				</span>
			</button>*/}
		</legend>
		<h3 className="d-block d-xl-none d-lg-none d-md-none">
			{viewHeading[currentSection]}
		</h3>
		<Tabs defaultActiveKey="mainInfo" activeKey={currentTab!!} onSelect={setCurrentTab} id="courseLevelForm"
		  className="mb-3 hidden displayNone"
		>
			<Tab eventKey="mainInfo" title=""><form ref={mainInfoFormRef} className="form"><div className="row" onSubmit=
			  {
				(event : FormEvent)=>
				{
					event.preventDefault()
					saveRecord()
				}
			  }
			>
				<div className="col-md-4 text-center">
					<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
						<img className="relatedPicture" src=
						  {
							(
								editableLevel == null
										?
									props.selectedLevel.course.image
										:
									(
										(props.courseRef && props.courseRef["C_" + editableLevel.course_id].image)
											|| editableLevel.course.image
									)
							)
								|| courseImage
						  }
						/>
					</div>
					<div className="card card-default" style={{padding : "15px", marginTop : "15px"}}>
						<div className="selectContainer">
							<label htmlFor="course_id">
								Curso
							</label>
							{
								editableLevel == null || props.courseRef == null
										?
									<div className="roView">
										<br />
										{props.selectedLevel.course.name}
									</div>
										:
									(
										editableLevel == null ? "" : <>
											<select onChange={handleRegistryChange} name="course_id" style={{height : "30px"}}
											  value={editableLevel.course_id}  className="btn btn-primary text-start" id="course_id"
											  disabled={idleLocked}
											>
											{
												Object.values(props.courseRef).map
												(
													(record : any, i : any) => <option key={i} value={record.id}>
														&emsp;
														{record.name}
													</option>
												)
											}
											</select>
											<div className="iconRight" style={{pointerEvents : "none"}}>
												<CustomIcon name="rowDown" />
											</div>
										</>
									)
							}
						</div>
						<div className="selectContainer">
							<label htmlFor="status_id">
								Estatus
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.status?.name}
								</div>
									:
								<>
									<select disabled={idleLocked} className="btn btn-primary text-start" style={{height : "30px"}}
									  value={editableLevel.status_id} id="status_id" name="status_id" onChange={handleRegistryChange}
									>{
										props.levelStatuses.map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}</select>
									<div className="iconRight" style={{pointerEvents : "none"}}>
										<CustomIcon name="rowDown" />
									</div>
								</>
							}
						</div>
					</div>
				</div>
				<div className="col-md-8"><div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableLevel == null ? "" : "*"}
								Nombre
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.name}
								</div>
									:
								<input onFocus={validateField} value={editableLevel.name} onBlur={validateField} ref={nameRef}
								  onChange={handleRegistryChange} style={{width : "100%"}} id="name" disabled={idleLocked}
								  name="name" type="text" maxLength={255} placeholder="Nombre" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="code">
								{editableLevel == null ? "" : "*"}
								Código
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.code}
								</div>
									:
								<input value={editableLevel.code} placeholder="Nombre" ref={codeRef} style={{width : "100%"}}
								  onFocus={validateField} disabled={idleLocked} id="code" onBlur={validateField} type="text"
								  maxLength={255} onChange={handleRegistryChange} name="code" required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="session_min_hours">
								{editableLevel == null ? "" : "*"}
								Horas mínimas / sesión
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.session_min_hours}
								</div>
									:
								<input id="session_min_hours" onFocus={validateField}
								   step="0.5" placeholder="Horas mínimas / sesión" name="session_min_hours" type="number"
								  onBlur={validateField} disabled={idleLocked} min="1" onChange={handleRegistryChange} max="15"
								  ref={sessionMinHoursRef} value={editableLevel.session_min_hours} style={{width : "100%"}}
								  required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="week_hours">
								{editableLevel == null ? "" : "*"}
								Horas semanales
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.week_hours}
								</div>
									:
								<input onChange={handleRegistryChange} style={{width : "100%"}} min="1" ref={weekHoursRef}
								  id="week_hours" disabled={idleLocked} type="number" placeholder="Horas semanales" step="0.5"
								  onBlur={validateField} value={editableLevel.week_hours} name="week_hours" onFocus={validateField}
								  max="105" required
								/>
							}
						</div></div>
						<div className="col-md-6">
							<div className="inputContainer">
								<label htmlFor="week_min_days">
									{editableLevel == null ? "" : "*"}
									Días mín./semana
								</label>
								{
									editableLevel == null
										?
									<div className="roView">
										<br />
										{props.selectedLevel.week_min_days}
									</div>
										:
									<input value={editableLevel.week_min_days} step="1" min="1" style={{width : "100%"}}
									  onBlur={validateField} placeholder="Días mín./semana" ref={weekHoursRef} max="6"
									  onFocus={validateField} disabled={idleLocked} onChange={handleRegistryChange}
									  type="number" name="week_min_days" id="week_min_days" required
									/>
								}
							</div>
						</div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="week_max_days">
								{editableLevel == null ? "" : "*"}
								Días máx./semana
							</label>
							{
								editableLevel == null
									?
								<div className="roView">
									<br />
									{props.selectedLevel.week_max_days}
								</div>
									:
								<input style={{width : "100%"}} id="week_max_days" value={editableLevel.week_max_days} max="7"
								  type="number" onChange={handleRegistryChange} placeholder="Días máx./semana" ref={weekHoursRef}
								  onFocus={validateField} step="1" onBlur={validateField} name="week_max_days" disabled={idleLocked}
								  min="1" required
								/>
							}
						</div></div>
					</div>
					{
						campusRef[currentUserCampus] && campusRef[currentUserCampus].company.currency_id != currency.MXN ? "" : <>
							<UnderlineHeading name={"Tarifas base 🇲🇽"} />
							<div className="row">
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_base_price_mxn">
										{editableLevel == null ? "" : "*"}
										Inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.subscription_base_price_mxn)}
											</div>
												:
											<input onBlur={validateField} disabled={idleLocked} min={0} onFocus={validateField}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.subscription_base_price_mxn} id="subscription_base_price_mxn"
											  name="subscription_base_price_mxn" ref={subscriptionBasePriceMXNRef} type="number"
											  placeholder="Precio de inscripción" onChange={handleRegistryChange} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											MXN
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_base_price_mxn">
										{editableLevel == null ? "" : "*"}
										Mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_base_price_mxn)}
											</div>
												:
											<input value={editableLevel.monthly_billing_base_price_mxn} disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  onFocus={validateField} min={0} onChange={handleRegistryChange} type="number"
											  placeholder="Precio de inscripción" name="monthly_billing_base_price_mxn"
											  id="monthly_billing_base_price_mxn" ref={monthlyBillingBasePriceMXNRef}
											  onBlur={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											MXN
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_surcharge_base_cost_mxn">
										{editableLevel == null ? "" : "*"}
										Recargo de inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
													?
												<div className="roView" style={{textAlign : "right"}}>
													{sprintf("%.02f", props.selectedLevel.subscription_surcharge_base_cost_mxn)}
												</div>
													:
												<input onChange={handleRegistryChange} min={0} type="number"
												  value={editableLevel.subscription_surcharge_base_cost_mxn} disabled={idleLocked}
												  placeholder="Precio de inscripción" onFocus={validateField}
												  id="subscription_surcharge_base_cost_mxn" name="subscription_surcharge_base_cost_mxn"
												  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
												  onBlur={validateField} ref={subscriptionSurchargeBaseCostMXNRef}
												  required
												/>
										}</div>
										<div style={{flexGrow : 0}}>
											MXN
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_surcharge_base_cost_mxn">
										{editableLevel == null ? "" : "*"}
										Recargo de mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_surcharge_base_cost_mxn)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} ref={monthlyBillingSurchargeBaseCostMXNRef}
											  value={editableLevel.monthly_billing_surcharge_base_cost_mxn} disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  name="monthly_billing_surcharge_base_cost_mxn" placeholder="Precio de inscripción"
											  id="monthly_billing_surcharge_base_cost_mxn" onBlur={validateField} type="number"
											  onFocus={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											MXN
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="second_surcharge_base_cost_mxn">
										{editableLevel == null ? "" : "*"}
										Segundo recargo
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.second_surcharge_base_cost_mxn)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.second_surcharge_base_cost_mxn} onFocus={validateField}
											  id="second_surcharge_base_cost_mxn" name="second_surcharge_base_cost_mxn"
											  placeholder="Precio de inscripción" ref={secondSurchargeBaseCostMXNRef}
											  onBlur={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											MXN
										</div>
									</div>
								</div></div>
							</div>
						</>
					}
					{
						campusRef[currentUserCampus] && campusRef[currentUserCampus].company.currency_id != currency.HNL ? "" : <>
							<UnderlineHeading name={"Tarifas base 🇭🇳"} />
							<div className="row">
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_base_price_hnl">
										{editableLevel == null ? "" : "*"}
										Inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											L
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.subscription_base_price_hnl)}
											</div>
												:
											<input onBlur={validateField} min={0} type="number" onFocus={validateField}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  onChange={handleRegistryChange} disabled={idleLocked} ref={subscriptionBasePriceHNLRef}
											  value={editableLevel.subscription_base_price_hnl} id="subscription_base_price_hnl"
											  placeholder="Precio de inscripción" name="subscription_base_price_hnl" required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											LPS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_base_price_hnl">
										{editableLevel == null ? "" : "*"}
										Mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											L
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_base_price_hnl)}
											</div>
												:
											<input value={editableLevel.monthly_billing_base_price_hnl} disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  placeholder="Precio de inscripción" onFocus={validateField} onBlur={validateField}
											  onChange={handleRegistryChange} ref={monthlyBillingBasePriceHNLRef} type="number"
											  id="monthly_billing_base_price_hnl" name="monthly_billing_base_price_hnl"
											  min={0} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											LPS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_surcharge_base_cost_hnl">
										{editableLevel == null ? "" : "*"}
										Recargo de inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											L
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.subscription_surcharge_base_cost_hnl)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  id="subscription_surcharge_base_cost_hnl" name="subscription_surcharge_base_cost_hnl"
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.subscription_surcharge_base_cost_hnl} onBlur={validateField}
											  ref={subscriptionSurchargeBaseCostHNLRef} placeholder="Precio de inscripción"
											  onFocus={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											LPS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_surcharge_base_cost_hnl">
										{editableLevel == null ? "" : "*"}
										Recargo de mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											L
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_surcharge_base_cost_hnl)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  id="monthly_billing_surcharge_base_cost_hnl" name="monthly_billing_surcharge_base_cost_hnl"
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.monthly_billing_surcharge_base_cost_hnl} onBlur={validateField}
											  ref={monthlyBillingSurchargeBaseCostHNLRef} placeholder="Precio de inscripción"
											  onFocus={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											LPS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="second_surcharge_base_cost_hnl">
										{editableLevel == null ? "" : "*"}
										Segundo recargo
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											L
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.second_surcharge_base_cost_hnl)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.second_surcharge_base_cost_hnl} ref={secondSurchargeBaseCostHNLRef}
											  placeholder="Precio de inscripción" onFocus={validateField} onBlur={validateField}
											  id="second_surcharge_base_cost_hnl" name="second_surcharge_base_cost_hnl"
											  required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											LPS
										</div>
									</div>
								</div></div>
							</div>
						</>
					}
					{
						campusRef[currentUserCampus] && campusRef[currentUserCampus].company.currency_id != currency.USD ? "" : <>
							<UnderlineHeading name={"Tarifas base 🇸🇻"} />
							<div className="row">
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_base_price_usd">
										{editableLevel == null ? "" : "*"}
										Inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.subscription_base_price_usd)}
											</div>
												:
											<input onBlur={validateField} min={0} type="number" onFocus={validateField}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  onChange={handleRegistryChange} disabled={idleLocked} ref={subscriptionBasePriceUSDRef}
											  value={editableLevel.subscription_base_price_usd} id="subscription_base_price_usd"
											  placeholder="Precio de inscripción" name="subscription_base_price_usd" required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											DLLS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_base_price_usd">
										{editableLevel == null ? "" : "*"}
										Mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_base_price_usd)}
											</div>
												:
											<input value={editableLevel.monthly_billing_base_price_usd} disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  placeholder="Precio de inscripción" onFocus={validateField} onBlur={validateField}
											  onChange={handleRegistryChange} ref={monthlyBillingBasePriceUSDRef} type="number"
											  id="monthly_billing_base_price_usd" name="monthly_billing_base_price_usd"
											  min={0} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											DLLS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="subscription_surcharge_base_cost_usd">
										{editableLevel == null ? "" : "*"}
										Recargo de inscripción
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.subscription_surcharge_base_cost_usd)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  id="subscription_surcharge_base_cost_usd" name="subscription_surcharge_base_cost_usd"
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.subscription_surcharge_base_cost_usd} onBlur={validateField}
											  ref={subscriptionSurchargeBaseCostUSDRef} placeholder="Precio de inscripción"
											  onFocus={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											DLLS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="monthly_billing_surcharge_base_cost_usd">
										{editableLevel == null ? "" : "*"}
										Recargo de mensualidad
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.monthly_billing_surcharge_base_cost_usd)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  id="monthly_billing_surcharge_base_cost_usd" name="monthly_billing_surcharge_base_cost_usd"
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.monthly_billing_surcharge_base_cost_usd} onBlur={validateField}
											  ref={monthlyBillingSurchargeBaseCostUSDRef} placeholder="Precio de inscripción"
											  onFocus={validateField} required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											DLLS
										</div>
									</div>
								</div></div>
								<div className="col-md-6"><div className="inputContainer" style={{textAlign : "left"}}>
									<label htmlFor="second_surcharge_base_cost_usd">
										{editableLevel == null ? "" : "*"}
										Segundo recargo
									</label>
									<br />
									<div style={{display : "flex", alignItems : "stretch"}}>
										<div style={{flexGrow : 0}}>
											$
										</div>
										<div style={{flexGrow : 1, padding : "0px 5px", textAlign : "left"}}>{
											editableLevel == null
												?
											<div className="roView" style={{textAlign : "right"}}>
												{sprintf("%.02f", props.selectedLevel.second_surcharge_base_cost_usd)}
											</div>
												:
											<input onChange={handleRegistryChange} min={0} type="number" disabled={idleLocked}
											  style={{textAlign : "right", position : "relative", display : "block", height : "23px"}}
											  value={editableLevel.second_surcharge_base_cost_usd} ref={secondSurchargeBaseCostUSDRef}
											  placeholder="Precio de inscripción" onFocus={validateField} onBlur={validateField}
											  id="second_surcharge_base_cost_usd" name="second_surcharge_base_cost_usd"
											  required
											/>
										}</div>
										<div style={{flexGrow : 0}}>
											DLLS
										</div>
									</div>
								</div></div>
							</div>
						</>
					}
					{
						(editableLevel == null && isEmpty(props.selectedLevel.description)) ? "" :  <div
						  style={{padding : "15px 0px 15px 15px"}}
						>
							<UnderlineHeading name={"Descripción"} />
							{
								editableLevel == null ? props.selectedLevel.description : <textarea disabled={idleLocked}
								  value={editableLevel.description} ref={descriptionRef} id="description" name="description"
								  onChange={handleRegistryChange} className="notesField form-control" onBlur={validateField}
								  maxLength={255}
								/>
							}
						</div>
					}
				</div>
			</div></form></Tab>
			<Tab eventKey="assignments" title=""><div style={{overflow : "auto"}}>
				{
					(editableLevel || props.selectedLevel.assignments.length < 1) ? "(Sin registros)" : props.selectedLevel.assignments.map
					(
						(assignment : any) => <div style={{padding : "5px", margin : "unset"}} key={"eA_" + assignment.id} className="card">
							{assignment.title}
							&nbsp;
							({assignment.pivot.available_points})
							[{assignment.pivot.availability_start}
								-
							{assignment.pivot.availability_end}]
							<div style={{paddingLeft : "15px"}}>
								{assignment.general_description}
							</div>
						</div>
					)
				}
				{
					editableLevel == null ? "" : <>
						<Popover positions={["bottom"]} isOpen={isAssignmentPopoverOpen} onClickOutside={() => setIsAssignmentPopoverOpen(false)}
						  content=
						  {
							<div style={{overflowY : "auto", maxHeight : "40vh", background : "var(--main-bg)", width : "85vw"}}>{
								<>
									{
										assignmentsToLink.filter((item : any) => item).map
										(
											(assignment : any, index : number) => <div key={"T_" + index}
											  style={{display : "inline-grid", padding : "5px", width : "100%"}}
											><div style={{display : "grid", margin : "0px 5px"}}><button type="button"
											  onClick={() => {linkAssignment(assignment)}} key={"T_" + index} className=
											  {
												assignment.unavailable!= null ? "btn-unstyled" : 
												listItemUI
												[
													( +(levelAssignmentInit[assignment.refIndex]     != null))
														+
													( +(levelAssignmentAdding[assignment.refIndex]   != null))
														+
													( +(levelAssignmentDeletion[assignment.refIndex] != null))
												]
											  }
											  disabled=
											  {
												(assignment.unavailable)
													||
												(idleLocked || levelAssignmentAdding[assignment.refIndex])
													||
												(levelAssignmentInit[assignment.refIndex] && levelAssignmentDeletion[assignment.refIndex] == null)
											  }
											  style=
											  {{
													whiteSpace   : "nowrap",
													overflow     : "hidden",
													textOverflow : "ellipsis",
													textAlign    : "left"
											  }}
											>
												<FontAwesomeIcon icon=
												  {
													assignment.unavailable
														? solid("calendar-xmark")
														:
													levelStatus
													[
														( +(levelAssignmentInit[assignment.refIndex] != null))
															+
														( +(levelAssignmentAdding[assignment.refIndex] != null))
															+
														( +(levelAssignmentDeletion[assignment.refIndex] != null))
													]
												  }
												/>
												<span style={{paddingLeft : "10px"}}>
													{assignment.title}
													&nbsp;
													({assignment.points})
													<span style={{display : "block", paddingLeft : "15px"}}>
													</span>
												</span>
											</button></div></div>
										)
									}
									<div className="text-center" style={{color : "#C7C7C7"}}>
										No hay más resultados.
									</div>
								</>
							}</div>
						  }
						><div className="inputContainer" style={{position : "sticky", left : "0"}}>
							<label htmlFor="listSearch" style={{position : "relative", display : "block"}}>
								Título, descripción general
								<div style={{height : "5px"}}>
									{!aSearchInProgress ? "" : <div className="loader" style={{flex : 1}}></div>}
								</div>
							</label>
							<input onChange={(e : any) => searchAssignments(e.target.value)} disabled={idleLocked} type="text"
							  onClick={() => setIsAssignmentPopoverOpen(!isAssignmentPopoverOpen)} id="assignmentListSearch"
							  style={{width : "100%"}} name="assignmentListSearch" placeholder="Título, descripción general"
							  maxLength={50} ref={assignmentNameRef} value={assignmentListSearch}
							/>
						</div></Popover>
						{
							editableLevel.assignments.length < 1 ? "" :
							<>
								<div className="text-end" style={{position : "sticky", left : "0"}}><button type="button"
								  disabled={idleLocked || Object.keys(levelAssignmentCheck).length < 1} className="btn btn-warning"
								  onClick={assignmentListRemoval} style={{borderRadius : "25px"}}
								>
									<CustomIcon name="bTrash" />
									<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
										Eliminar seleccionados
									</span>
								</button></div>
								<table className="standardTable">
									<thead><tr key="usersHeader">
										<th>
											{
												(
													(editableLevel.assignments.length > 0) &&
													(
														(Object.keys(levelAssignmentInit).length > Object.keys(levelAssignmentDeletion).length)
															||
														Object.keys(levelAssignmentAdding).length > 0
													)
												)
													&&
												<button disabled={idleLocked || saveSuccess} type="button" className="btn btn-default"
												  onClick={toggleAllInAssignmentList}
												>
													{<CustomIcon
														name=
														{
															Object.keys(levelAssignmentCheck).length ==
															(
																Object.keys(levelAssignmentInit).length
																- Object.keys(levelAssignmentDeletion).length
																+ Object.keys(levelAssignmentAdding).length
															) ? "bCheck" : "bUncheck"
														}
													/>}
												</button>
											}
										</th>
										<th style={{width : "1px"}}
										  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
										>
											id
										</th>
										<th>
											Título
										</th>
										<th>
											Descripción general
										</th>
										<th>
											Puntos
										</th>
										<th colSpan={2}>
											Dispoinbilidad
											<br />
											(Inicio-fin)
										</th>
										<th>
										</th>
									</tr></thead>
									{
										(editableLevel == null || editableLevel.assignments.length < 1) ? "" : <>
											<tbody>{
												editableLevel.assignments.filter((item : any) => item).map
												(
													(item : any, index : number) => (levelAssignmentInit[item.refIndex] == null || levelAssignmentDeletion[item.refIndex]) ? "" :
													<tr key={"user" + index}>
														<td><button onClick={() => toggleAssignmentListSelection(item)}
														  disabled={idleLocked} className="btn btn-default" type="button"
														>
															<CustomIcon name={checkIcon[ +(levelAssignmentCheck[item.refIndex]!= null) ]} />
														</button></td>
														<td>
															{item.id}
														</td>
														<td>
															{item.title || "-"}
														</td>
														<td>
															{item.general_description || "-"}
														</td>
														<td>
															<input type="number" value={item.points} name={"points" + index}
															  onBlur={validateField} min={1} onFocus={validateField} step={1}
															  disabled={idleLocked} id={"points" + index} onChange=
															  {
																(e : any) => setEditableLevel
																({
																	...editableLevel, assignments : editableLevel.assignments.map
																	(
																		(assignment : any, index0 : number)=>
																		({
																			...assignment, availablePoints: index0 == index
																				? e.target.value
																					: item.points
																		})
																	)
																})
															  }
															/>
														</td>
														<td>
															{<LeDatePicker name={"availabilityStartValue" + index} months={monthRef}
															  value={item.availabilityStartValue} years={basicInfo.futureRange}
															  id={"availabilityStartValue" + item.id} disabled={idleLocked}
															  onFocus={validateField}
															  onBlur={validateField} onChange=
															  {
																(e : any) => setEditableLevel
																({
																	...editableLevel, assignments : editableLevel.assignments.map
																	(
																		(assignment : any, index0 : number)=>
																		({
																			...assignment, availabilityStartValue : index0 == index
																				? e.target.value
																					: item.availabilityStartValue
																		})
																	)
																})
															  }
															  required
															/>}
														</td>
														<td>
															<LeDatePicker years={basicInfo.futureRange} disabled={idleLocked}
															  value={item.availabilityEndValue} id={"availabilityEndValue" + item.id}
															  onFocus={validateField} onBlur={validateField} months={monthRef}
															  name={"availabilityEndValue" + index} onChange=
															  {
																(e : any)=>
																{
																	setEditableLevel
																	({
																		...editableLevel, assignments : editableLevel.assignments.map
																		(
																			(assignment : any, index0 : number)=>
																			({
																				...assignment, availabilityEndValue : index0 == index
																					? e.target.value
																						: item.availabilityEndValue
																			})
																		)
																	})
																}
															  }
															  required
															/>
														</td>
														<td />
														<td><div style={{display : "flex"}}><button disabled={idleLocked}
														  style={{display : "table-column"}} className="button btn btn-default"
														  onClick={() => assignmentListRemoval(index)} type="button"
														>
															<FontAwesomeIcon icon={solid("times")} />
														</button></div></td>
													</tr>
												)
											}</tbody>
											<tbody className="uncommonTable">{
												editableLevel.assignments.filter((item : any) => item).map
												(
													(item : any, index : number) => levelAssignmentAdding[item.refIndex] == null ? "": <tr key={"user" + index}>
														<td><button type="button" disabled={idleLocked} className="btn btn-default"
														  onClick={() => toggleAssignmentListSelection(item)}
														>
															<CustomIcon name={checkIcon[ +(levelAssignmentCheck[item.refIndex]!= null) ]} />
														</button></td>
														<td>
															{item.id}
														</td>
														<td>
															{item.title || "-"}
														</td>
														<td>
															{item.general_description || "-"}
														</td>
														<td>
															<input type="number" value={item.availablePoints} min={1} step={1}
															  disabled={idleLocked} id={"availablePoints" + index} onChange=
															  {
																(e : any)=>
																{
																	setEditableLevel
																	({
																		...editableLevel, assignments : editableLevel.assignments.map
																		(
																			(assignment : any, index0 : number)=>
																			({
																				...assignment, points: index0 == index
																						? e.target.value
																							: item.availablePoints
																			})
																		)
																	})
																}
															  }
															/>
														</td>
														<td>
															<LeDatePicker id={"availabilityStartValue" + item.id} months={monthRef}
															  value={item.availabilityStartValue} years={basicInfo.futureRange}
															  name={"availabilityStartValue" + item.id} onBlur={validateField}
															  onFocus={validateField} onChange=
															  {
																(e : any)=> setEditableLevel
																({
																	...editableLevel, assignments : editableLevel.assignments.map
																	(
																		(assignment : any, index0 : number)=>
																		({
																			...assignment, availabilityStartValue : index0 == index
																				? e.target.value
																					: item.availabilityStartValue
																		})
																	)
																})
															  } required
															/>
														</td>
														<td>
															<LeDatePicker months={monthRef} name={"availabilityEndValue" + item.id}
															  value={item.availabilityEndValue} years={basicInfo.futureRange}
															  onFocus={validateField} id={"availabilityEndValue" + item.id}
															  onBlur={validateField} onChange=
															  {
																(e : any)=> setEditableLevel
																({
																	...editableLevel, assignments : editableLevel.assignments.map
																	(
																		(assignment : any, index0 : number)=>
																		({
																			...assignment, availabilityEndValue : index0 == index
																				? e.target.value
																					: item.availabilityEndValue
																		})
																	)
																})
															  } required
															/>
														</td>
														<td><div style={{display : "flex"}}><button className="button btn btn-default"
														  type="button" disabled={idleLocked} style={{display : "table-column"}}
														  onClick={() => assignmentListRemoval(index)}
														>
															<FontAwesomeIcon icon={solid("times")} />
														</button></div></td>
													</tr>
												)
											}</tbody>
											<tbody className="bg-danger tableDanger">{
												editableLevel.assignments.filter((item : any) => item).map
												(
													(item : any, index : number) => levelAssignmentDeletion[item.refIndex] == null ?  "" : <tr key={"user" + index}>
														<td />
														<td>
															{item.id}
														</td>
														<td>
															{item.title || "-"}
														</td>
														<td>
															{item.general_description || "-"}
														</td>
														<td>
															{item.availablePoints || "-"}
														</td>
														<td>
															{item.availability_start || "-"}
														</td>
														<td>
															{item.availability_end || "-"}
														</td>
														<td><div style={{display : "flex"}}><button disabled={idleLocked}
														  onClick={() => undoAssignmentListRemoval(item.refIndex)} type="button"
														  style={{display : "table-column"}} className="button btn btn-default"
														>
															<FontAwesomeIcon icon={solid("trash-arrow-up")}/>
														</button></div></td>
													</tr>
												)
											}</tbody>
										</>
									}
								</table>
							</>
						}
					</>
				}
			</div></Tab>
			<Tab eventKey="enrolledMentors" title=""><div style={{overflow : "auto"}}>
				{
					editableLevel == null 
							?
					(
						props.selectedLevel.mentors.length < 1 ? "(Sin registros)" : props.selectedLevel.mentors.map
						(
							(mentor : any) => <div style={{padding : "5px", margin : "unset"}} key={"eM_" + mentor.id} className="card">
								{mentor.first_name + " " + mentor.last_name}
							</div>
						)
					)
							:
					<>
						<Popover positions={["bottom"]} isOpen={isMentorPopoverOpen} onClickOutside={() => setIsMentorPopoverOpen(false)}
						  content=
						  {<div style={{overflowY : "auto", maxHeight : "40vh", background : "var(--main-bg)", width : "85vw"}}>{
							<>
								{
									mentorsToEnlist.filter((mentor : any) => mentor).map
									(
										(mentor : any, index : number) => <div key={"T_" + index}
											  style={{display : "inline-grid", padding : "5px", width : "100%"}}
											>
											<div style={{display : "grid", margin : "0px 5px"}}>{
												mentor.activelyEnrolled
														?
													<div
													  className="badge bg-danger"
													  style=
													  {{
														height : "37px",
														fontSize : "16px",
														fontWeight : "400",
														padding : "unset",
														display : "flex",
														alignItems : "stretch"
													  }}
													>
														<OverlayTrigger overlay=
														  {<Tooltip id="tooltip-activos">
															Este mentor está matriculado activamente como alumno en un horario perteneciente al mismo módulo, por lo que no será posible su vinculación
														  </Tooltip>}
														>
															<div
															  className="badge bg-dark"
															  style=
															  {{
																padding : "10px",
																fontSize : "18px"
															  }}
															>
																<FontAwesomeIcon icon={solid("ban")} />
																&nbsp;
																<FontAwesomeIcon icon={solid("circle-info")} />
															</div>
														</OverlayTrigger>
														<div
														  key={"T_" + index}
														  style=
														  {{
																display : "inline-block",
																whiteSpace   : "nowrap",
																overflow     : "hidden",
																textOverflow : "ellipsis",
																textAlign : "left",
																padding : "12px 10px",
																flexGrow : 1
														  }}
														>
															&nbsp;
															{mentor.first_name + " " + mentor.last_name}
														</div>
													</div>
														:
													<button key={"T_" + index}
													  onClick={() => enrollMentor(mentor)} type="button" className=
													  {
														mentor.unavailable!= null ? "btn-unstyled" : listItemUI
														[
															( +(levelMentorInit[mentor.refIndex] != null))
																+
															( +(levelMentorAdding[mentor.refIndex] != null))
																+
															( +(levelMentorDeletion[mentor.refIndex] != null))
														]
													  } disabled=
													  {
														(mentor.unavailable)
															||
														(idleLocked || levelMentorAdding[mentor.refIndex])
															||
														(levelMentorInit[mentor.refIndex] && levelMentorDeletion[mentor.refIndex] == null)
													  } style=
													  {{
															whiteSpace   : "nowrap",
															overflow     : "hidden",
															textOverflow : "ellipsis",
															textAlign : "left"
													  }}
													>
														<FontAwesomeIcon icon=
														  {
															mentor.unavailable ? solid("calendar-xmark") : levelStatus
															[
																( +(levelMentorInit[mentor.refIndex] != null))
																	+
																( +(levelMentorAdding[mentor.refIndex] != null))
																	+
																( +(levelMentorDeletion[mentor.refIndex] != null))
															]
														  }
														/>
														<span style={{paddingLeft : "10px"}}>
															{mentor.first_name + " " + mentor.last_name}
														</span>
													</button>
											}</div>
										</div>
									)
								}
								<div className="text-center" style={{color : "#C7C7C7"}}>
									No hay más resultados.
								</div>
							</>
						  }</div>}
						><div className="inputContainer" style={{position : "sticky", left : "0"}}>
							<label htmlFor="listSearch" style={{position : "relative", display : "block"}}>
								Nombres, apellidos, correo, ...
								<div style={{height : "5px"}}>
									{!aSearchInProgress ? "" : <div className="loader" style={{flex : 1}}></div>}
								</div>
							</label>
							<input value={mentorListSearch} name="mentorListSearch" id="mentorListSearch" disabled={idleLocked}
							  style={{width : "100%"}} maxLength={50} onChange={(e : any) => searchMentors(e.target.value)}
							  onClick={() => setIsMentorPopoverOpen(!isMentorPopoverOpen)} type="text" ref={mentorNameRef}
							  placeholder="Nombres, apellidos, correo"
							/>
						</div></Popover>
						{
							editableLevel.mentors.length < 1 ? "" : <>
								<div className="text-end" style={{position : "sticky", left : "0"}}><button type="button"
								  disabled={idleLocked || Object.keys(levelMentorCheck).length < 1} className="btn btn-warning"
								  style={{borderRadius : "25px"}} onClick={mentorListRemoval}
								>
									<CustomIcon name="bTrash" />
									<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
										Eliminar seleccionados
									</span>
								</button></div>
								<table className="standardTable">
									<thead><tr key="usersHeader">
										<th>{
											!(
												(editableLevel.mentors.length > 0) &&
												(
													(Object.keys(levelMentorInit).length > Object.keys(levelMentorDeletion).length)
														||
													Object.keys(levelMentorAdding).length > 0
												)
											) ? "" : <button disabled={idleLocked || saveSuccess} type="button"
											  className="btn btn-default" onClick={toggleAllInMentorList}
											>{<CustomIcon name=
											  {
												Object.keys(levelMentorCheck).length ==
												(
													Object.keys(levelMentorInit).length
													- Object.keys(levelMentorDeletion).length
													+ Object.keys(levelMentorAdding).length
												) ? "bCheck" : "bUncheck"
											  }
											/>}</button>
										}</th>
										<th style={{width : "1px"}}
										  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
										>
											id
										</th>
										<th>
											<FontAwesomeIcon  icon={solid("image")} />
										</th>
										<th>
											Nombres
										</th>
										<th>
											Apellidos
										</th>
										<th>
											Código de identidad
										</th>
										<th>
											Email
										</th>
										<th />
									</tr></thead>
									<tbody>{
										editableLevel.mentors.filter((item : any) => item).map
										(
											(item : any, index : number) => (levelMentorInit[item.refIndex] == null || levelMentorDeletion[item.refIndex]) ? "" : <tr key={"user" + index}>
												<td><button onClick={() => toggleMentorListSelection(item)} disabled={idleLocked}
												  className="btn btn-default"type="button"
												>
													<CustomIcon name={checkIcon[ +(levelMentorCheck[item.refIndex]!= null) ]} />
												</button></td>
												<td>
													{item.id}
												</td>
												<td style={{width : "1px"}}>
													<img src={item.image != null ? item.image : userImage}
													  className="detailPicture"
													/>
												</td>
												<td>
													{item.first_name || "-"}
												</td>
												<td>
													{item.last_name || "-"}
												</td>
												<td>
													{item.mentor?.identity_code || "-"}
												</td>
												<td>
													{item.email}
												</td>
												<td><div style={{display : "flex"}}><button className="button btn btn-default"
												  style={{display : "table-column"}} onClick={() => mentorListRemoval(index)}
												  disabled={idleLocked} type="button"
												>
													<FontAwesomeIcon  icon={solid("times")} />
												</button></div></td>
											</tr>
										)
									}</tbody>
									<tbody className="uncommonTable">{
										editableLevel.mentors.filter((item : any) => item).map
										(
											(item : any, index : number) => levelMentorAdding[item.refIndex] == null ? "" : <tr key={"user" + index}>
												<td><button onClick={() => toggleMentorListSelection(item)} type="button"
												   disabled={idleLocked} className="btn btn-default"
												>
													<CustomIcon name={checkIcon[ +(levelMentorCheck[item.refIndex]!= null) ]} />
												</button></td>
												<td>
													{item.id}
												</td>
												<td style={{width : "1px"}}>
													<img className="detailPicture"
													  src={item.image != null ? item.image : userImage}
													/>
												</td>
												<td>
													{item.first_name || "-"}
												</td>
												<td>
													{item.last_name || "-"}
												</td>
												<td>
													{item.employee?.identity_code || "-"}
												</td>
												<td>
													{item.email}
												</td>
												<td><div style={{display : "flex"}}><button disabled={idleLocked}
												  style={{display : "table-column"}} className="button btn btn-default"
												  onClick={() => mentorListRemoval(index)} type="button"
												>
													<FontAwesomeIcon icon={solid("times")} />
												</button></div></td>
											</tr>
										)
									}</tbody>
									<tbody className="bg-danger tableDanger">{
										editableLevel.mentors.filter((item : any) => item).map
										(
											(item : any, index : number) => levelMentorDeletion[item.refIndex] == null
													?
												""
													:
												<tr key={"user" + index}>
													<td />
													<td>
														{item.id}
													</td>
													<td style={{width : "1px"}}>
														<img
														  className="detailPicture"
														  src={item.image != null ? item.image : userImage}
														/>
													</td>
													<td>
														{item.first_name || "-"}
													</td>
													<td>
														{item.last_name || "-"}
													</td>
													<td>
														{item.employee?.identity_code || "-"}
													</td>
													<td>
														{item.email}
													</td>
													<td><div style={{display : "flex"}}><button className="button btn btn-default"
													  onClick={() => undoMentorListRemoval(item.refIndex)} disabled={idleLocked}
													  style={{display : "table-column"}} type="button"
													>
														<FontAwesomeIcon icon={solid("trash-arrow-up")} />
													</button></div></td>
												</tr>
										)
									}</tbody>
								</table>
							</>
						}
					</>
				}
			</div></Tab>
			<Tab eventKey="availableKits" title=""><div style={{overflow : "auto"}}>{
				editableLevel == null
						?
					(
						props.selectedLevel.kits.length < 1 ? "(Sin registros)" : props.selectedLevel.kits.map
						(
							(kit : any)=>
							{return <div style={{padding : "5px", margin : "unset"}} key={"eM_" + kit.id} className="card">
								[{kit.type.name}]
								&nbsp;
								{kit.name}
							</div>}
						)
					)
						: 
					<>
						<Popover isOpen={isKitPopoverOpen} onClickOutside={() => setIsKitPopoverOpen(false)} positions={["bottom"]}
						  content=
						  {<div style={{overflowY : "auto", maxHeight : "40vh", background : "var(--main-bg)", width : "85vw"}}>
							{
								<>
									{
										(kitsToProvide == null || kitsToProvide.length < 1) ? "" : kitsToProvide.filter((kit : any, index : number) => kit).map
										(
											(kit : any, index : number) => <div key={"TK_" + index}
											  style={{display : "inline-grid", padding : "5px", width : "100%"}}
											><div style={{display : "grid", margin : "0px 5px"}}><button type="button"
											  key={"TK_" + index} onClick={() => {provideKit(kit)}} className=
											  {
												kit.unavailable!= null ? "btn-unstyled" : listItemUI
												[
													( +(levelKitInit[kit.refIndex] != null))
														+
													( +(levelKitAdding[kit.refIndex] != null))
														+
													( +(levelKitDeletion[kit.refIndex] != null))
												]
											  } disabled=
											  {
												(kit.unavailable)
													||
												(idleLocked || levelKitAdding[kit.refIndex])
													||
												(levelKitInit[kit.refIndex] && levelKitDeletion[kit.refIndex] == null)
											  } style=
											  {{
												whiteSpace   : "nowrap",
												overflow     : "hidden",
												textOverflow : "ellipsis",
												textAlign : "left"
											  }}
											>
												<FontAwesomeIcon icon=
												  {
													kit.unavailable ? solid("calendar-xmark") : levelStatus
													[
														( +(levelKitInit[kit.refIndex] != null))
															+
														( +(levelKitAdding[kit.refIndex] != null))
															+
														( +(levelKitDeletion[kit.refIndex] != null))
													]
												  }
												/>
												<span style={{paddingLeft : "10px"}}>
													[{kit.type.name}]
													&nbsp;
													{kit.name}
												</span>
											</button></div></div>
										)
									}
									<div className="text-center" style={{color : "#C7C7C7"}}>
										No hay más resultados.
									</div>
								</>
							}</div>
						  }
						><div className="inputContainer" style={{position : "sticky", left : "0"}}>
							<label htmlFor="listSearch" style={{position : "relative", display : "block"}}>
								Nombre, descripción
								<div style={{height : "5px"}}>
									{!aSearchInProgress ? "" : <div className="loader" style={{flex : 1}}></div>}
								</div>
							</label>
							<input value={kitListSearch} onChange={(e : any) => {searchKits(e.target.value)}} type="text"
							  ref={kitNameRef} placeholder="Nombre, descripción" id="kitListSearch" disabled={idleLocked}
							  onClick={()=>{setIsKitPopoverOpen(!isKitPopoverOpen)}} name="kitListSearch" maxLength={50}
							  style={{width : "100%"}}
							/>
						</div></Popover>
						{
							editableLevel.kits.length < 1 ? "" : <>
								<div className="text-end" style={{position : "sticky", left : "0"}}><button type="button"
								  style={{borderRadius : "25px"}} onClick={kitListRemoval} className="btn btn-warning"
								  disabled={idleLocked || Object.keys(levelKitCheck).length < 1}
								>
									<CustomIcon name="bTrash" />
									<span className="d-none d-sm-inline-block" style={{paddingLeft : "10px"}}>
										Eliminar seleccionados
									</span>
								</button></div>
								<table className="standardTable">
									<thead><tr key="usersHeader">
										<th>{
											!(
												(editableLevel.kits.length > 0) &&
												(
													(Object.keys(levelKitInit).length > Object.keys(levelKitDeletion).length)
														||
													Object.keys(levelKitAdding).length > 0
												)
											)
												? "" : <button disabled={idleLocked || saveSuccess} className="btn btn-default"
											  onClick={toggleAllInKitList} type="button"
											>{
												<CustomIcon name=
													{
														Object.keys(levelKitCheck).length ==
														(
															Object.keys(levelKitInit).length
															- Object.keys(levelKitDeletion).length
															+ Object.keys(levelKitAdding).length
														) ? "bCheck" : "bUncheck"
													}
												/>
											}</button>
										}</th>
										<th style={{width : "1px"}}
										  ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}
										>
											id
										</th>
										<th>
											<FontAwesomeIcon  icon={solid("image")} />
										</th>
										<th>
											Nombre
										</th>
										<th>
											Descripción
										</th>
										<th />
									</tr></thead>
									{
										(editableLevel == null || editableLevel.kits.length < 1) ? "" : <>
											<tbody>{
												editableLevel.kits.filter((item : any) => item).map
												(
													(item : any, index : number) => (levelKitInit[item.refIndex] == null || levelKitDeletion[item.refIndex]) ? "" :<tr key={"user" + index}>
														<td><button onClick={() => toggleKitListSelection(item)} type="button"
														  disabled={idleLocked} className="btn btn-default"
														>
															<CustomIcon name={checkIcon[ +(levelKitCheck[item.refIndex]!= null) ]} />
														</button></td>
														<td>
															{item.id}
														</td>
														<td style={{width : "1px"}}>
															<img src={item.image != null ? item.image : articleImage}
															  className="detailPicture"
															/>
														</td>
														<td>
															{item.name || "-"}
														</td>
														<td>
															{item.description || "-"}
														</td>
														<td><div style={{display : "flex"}}><button disabled={idleLocked}
														  className="button btn btn-default" style={{display : "table-column"}}
														  onClick={() => kitListRemoval(index)} type="button"
														>
															<FontAwesomeIcon icon={solid("times")}/>
														</button></div></td>
													</tr>
												)
											}</tbody>
											<tbody className="uncommonTable">{
												editableLevel.kits.filter((item : any) => item).map
												(
													(item : any, index : number) => levelKitAdding[item.refIndex] == null ? "" : <tr key={"user" + index}>
														<td><button disabled={idleLocked} onClick={() => toggleKitListSelection(item)}
														  className="btn btn-default" type="button"
														>
															<CustomIcon name={checkIcon[ +(levelKitCheck[item.refIndex]!= null) ]} />
														</button></td>
														<td>
															{item.id}
														</td>
														<td style={{width : "1px"}}>
															<img src={item.image != null ? item.image : articleImage}
															  className="detailPicture"
															/>
														</td>
														<td>
															{item.name || "-"}
														</td>
														<td>
															{item.description || "-"}
														</td>
														<td><div style={{display : "flex"}}><button disabled={idleLocked}
														  onClick={() => kitListRemoval(index)} style={{display : "table-column"}}
														  className="button btn btn-default" type="button"
														>
															<FontAwesomeIcon icon={solid("times")} />
														</button></div></td>
													</tr>
												)
											}</tbody>
											<tbody className="bg-danger tableDanger">{
												editableLevel.kits.filter((item : any) => item).map
												(
													(item : any, index : number) => levelKitDeletion[item.refIndex] == null ? "" : <tr key={"user" + index}>
														<td />
														<td>
															{item.id}
														</td>
														<td style={{width : "1px"}}>
															<img src={item.image != null ? item.image : articleImage}
															  className="detailPicture"
															/>
														</td>
														<td>
															{item.name || "-"}
														</td>
														<td>
															{item.description || "-"}
														</td>
														<td style={{textAlign : "center"}}>{
															item.company_id != campusRef[currentUserCampus].company_id
																	?
																<div style={{paddingTop : "10px"}}>
																	<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Marcado automáticamente para borrado por no pertenecer al plantel actual</Tooltip>}>
																		<span>
																			<FontAwesomeIcon icon={solid("circle-info")} />
																		</span>
																	</OverlayTrigger>
																</div>
																	:
																<button
																  disabled={idleLocked}
																  className="button btn btn-default"
																  style={{display : "table-column"}}
																  onClick={() => undoKitListRemoval(item.refIndex)}
																  type="button"
																>
																	<FontAwesomeIcon  icon={solid("trash-arrow-up")} />
																</button>
														}</td>
													</tr>
												)
											}</tbody>
										</>
									}
								</table>
							</>
						}
					</>
			}</div></Tab>
		</Tabs>
	</fieldset></div></DataDisplayModal>
}

export default LevelPrompt
