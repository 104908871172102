import {
    createAsyncThunk
} from '@reduxjs/toolkit';
import HttpManager from '../../services/HttpManager';

export const handleMentorsSalaryXlsx = createAsyncThunk(
    "attendances/handleMentorsSalaryXlsx",
    async ({ campusId, criteria }: any, { rejectWithValue }) => {
        try {
            const response = await http.fileService.getMentorsSalaryReport(campusId, criteria);
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `Reporte Nómina de Profesores.xlsx`);
            document.body.appendChild(link);
            link.click();
            link.remove();
            return { campusId, criteria };
        } catch (error: any) {
            return rejectWithValue(error.response);
        }
    }
);

const http = HttpManager.getInstance();