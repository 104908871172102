import {
	useState,
	useRef,
	useReducer,
	useEffect
}
	from "react"
import { FontAwesomeIcon }
	from "@fortawesome/react-fontawesome"
import { solid }
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {
	OverlayTrigger,
	Tooltip
}
	from "react-bootstrap"
import Multiselect
	from "multiselect-react-dropdown"
import {
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import {
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
	dismissNotice
}
	from "./../../globals"

import {
	getAccessPermissions,
	isEmpty,
	isNotEmpty,
	generateRandomPassword,
	pageGrouping,
	UIState,
	getErrorMessage,
}
	from "./../../common"
import
Constants,
{ employeeStatus }
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer
	from "./../../components/sectionContainer"
import EmployeeInfoModal
	from "./employeeInfoModal"
import StudentInfoModal
	from "./studentInfoModal"
import MiniModalController from "../../components/modals/miniModal/miniModalController"
import ReactDatePicker from "react-datepicker";
import { default as SelectComponent } from "react-select";
import RolesService
	from "./../../services/roles.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import HttpManager from "../../services/HttpManager";
import CatalogService
	from "./../../services/catalog.service"
import defaultUserImage
	from "./../../assets/images/user.png"
import staticData from '../.././assets/js/static.json'
import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"
import './employees.scss';
import { sprintf } from "sprintf-js"

import { OtherIncomeDTO } from "../../models/OtherIncomeDTO"
import { icon } from "@fortawesome/fontawesome-svg-core"

type State =
	{
		isButtonDisabled: boolean
	}

const initialState: State =
{
	isButtonDisabled: true
}

type Action = { type: "setIsButtonDisabled", payload: boolean }
const reducer = (state: State, action: Action): State => {
	switch (action.type) {
		case "setIsButtonDisabled":
			return { ...state, isButtonDisabled: action.payload }
			break;
	}
}

const Employees = () => {
	const ROLES: number = 0
	const ACADEMIC_DEGREES: number = 1

	const http = HttpManager.getInstance();
	const [extendedFilterShown, setExtendedFilterShown] = useState<boolean>(false)
	const [roles, setRoles] = useState<any[]>([])
	const [state, trigger] = useReducer(reducer, initialState)
	const dispatch = useDispatch()
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [initSetup, setInitSetup] = useState<number | null>(null)
	const [data, setData] = useState<any[]>([])
	const [readOnlyData, setReadOnlyData] = useState<any[]>([])
	const [academicDegrees, setAcademicDegrees] = useState<any>([])
	const [warningReport, listWarnings] = useState<any>({})
	const currentUser: any = useSelector((state: RootState) => state.userProfile.value)
	const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value)
	const sessionToken: string | null = useSelector((state: RootState) => state.sessionToken.value)
	const basicInfo: any = useSelector((state: RootState) => state.basicInfo.value)
	const currentUserCampus: any = useSelector((state: RootState) => state.currentUserCampus.value)
	const employeeStatuses: any = Object.values(basicInfo.employeeStatusRef)
	const campusRef: any = useSelector((state: RootState) => state.campusRef.value)
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [roleView, setRoleView] = useState<boolean>(false)
	const [moduleReady, setModuleReady] = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [UIStatus, setUIStatus] = useState<number>(UIState.NORMAL)
	const [selectedRecords, setSelectedRecords] = useState<number>(0)
	const [validRoles, setValidRoles] = useState<number>(0)
	const [minYear, setMinYear] = useState<number>(0)
	const [newEmployee, setNewEmployee] = useState<any>({})
	const [selectedEmployee, setSelectedEmployee] = useState<any>(null)
	const [editableEmployee, setEditableEmployee] = useState<any>(null)
	const [selectedStudent, setSelectedStudent] = useState<any>(null)
	const [yearsToAlmostPresent, setYearsToAlmostPresent] = useState<any>([])
	const [minDate, setMinDate] = useState<any>(null)
	const [maxDate, setMaxDate] = useState<any>(null)
	const [date, setDate] = useState<any>(null)
	const [pagination, setPagination] = useState<any>(null)
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
	const [saveError, setSaveError] = useState<any>(null)
	const [currentDate, setCurrentDate] = useState<any>(null)
	const [recordsSelection, setRecordsSelection] = useState<any>([])
	const [currentTab, setCurrentTab] = useState<string | null>("mainInfo")
	const count: number[] = [-1, 1]
	let searchFormRef = useRef<HTMLFormElement>(null)
	const allowed: any = getAccessPermissions(currentUser)
	const defaultSearch =
	{
		q: "",
		statusId: employeeStatus.ACTIVE,
		tags: [],
	}
	const [customSearch, setCustomSearch] = useState<any>(defaultSearch)
	const [otherIncomeModal, setOtherIncomeModal] = useState<any>
		({
			show: false,
			employeeId: null,
			campusId: null
		})
	const [editOtherIncomeModal, setEditOtherIncomeModal] = useState<any>({
		show: false,
		employeeId: null,
		campusId: null
	})
	const [otherReceipts, setOtherReceipts] = useState<any>([]);

	const [newOtherIncome, setNewOtherIncome] = useState<any>({
		concept: null,
		fare: "",
		hours: "",
		applicationDate: null,
		notes: ""
	});
	const [newOtherReceipts, setNewOtherReceipts] = useState<any>([]);
	const [editableOtherReceipt, setEditableOtherReceipt] = useState<any>({
		concept: null,
		fare: "",
		hours: "",
		applicationDate: null,
		notes: ""
	});

	const showPrompt = (recordData: any) => {
		setUIStatus(UIState.NORMAL)
		setSaveError(null)
		setRoleView(false)

		let index: string
		let roleMap: any = {}

		recordData.roles.map
			(
				(role: any) => {
					index = "R_" + role.id

					if (roleMap["R_" + role.id] == null) {
						roleMap["R_" + role.id] =
						{
							...role,
							valid: true
						}
					}

					if (Object.keys(campusRef).length > 1) {
						if (roleMap["R_" + role.id].campuses == null) {
							roleMap["R_" + role.id].campuses = []
						}

						if (campusRef["C_" + role.pivot.campus_id]) {
							roleMap["R_" + role.id].campuses.push(campusRef["C_" + role.pivot.campus_id])
						}
					}
				}
			)

		let data =
		{
			...recordData, roles: Object.values(roleMap),
			password: generateRandomPassword(10),
			imagePreview: recordData.image
		}

		setValidRoles(recordData.roles.length)
		setCurrentTab("mainInfo")

		let cautionDate = new Date(date + " 23:00:00")
		let maximumDate = new Date(cautionDate)

		maximumDate.setFullYear(maximumDate.getFullYear() - 18)
		setMaxDate(maximumDate)
		setMinDate(new Date(sprintf("%04d-01-01 23:00:00", minYear)))

		if (recordData.id == null) {
			setEditableEmployee(data)
		}

		setSelectedEmployee(data)
	}

	const handleSearchChange = (e: any) => {
		if (e && e.target) {
			setCustomSearch
				({
					...customSearch,
					[e.target.name]: e.target.value
				})
		}
	}

	const updateRoleCampuses = (index: number, role: any) => {
		let roles = editableEmployee.roles
		let passingRoles = 0

		roles.splice(index, 1, role)
		setEditableEmployee
			({
				...editableEmployee, roles: roles.map
					(
						(roleItem: any) => {
							if (roleItem.valid) {
								passingRoles++
							}

							return roleItem
						}
					)
			})

		setValidRoles(passingRoles)
	}

	const procedureComplaint = (message: string, success: boolean = false) => {
		dispatch
			(
				displayNotice
					({
						cornerClose: false,
						message: message,
						heading: <h3 style={{ color: success ? "#008000" : "#FF0000", display: "inline-block" }}>
							{success ? "Éxito" : "Error"}
						</h3>
					})
			)
	}

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
		(
			() => {
				const onLoad = async () => {
					if (currentUserCampus == null) {
						procedureComplaint
							(
								"No hay planteles registrados o asignados al rol en uso por el usuario, " +
								"por lo que el acceso a este módulo permanecerá deshabilitado."
							)
					}
					else {
						const roleRetrievalError: string = "El catálogo de roles no pudo ser extraído"

						dispatch(enableIdleLockSwitch())

						try {
							const result = await RolesService.searchRoles()

							if (result.status == 200 || result.status == 204) {
								setRoles
									(
										result.data.map
											(
												(role: any, index: number) =>
												({
													...role, campuses: [],
													valid: true
												})
											)
									)
								setInitSetup(ROLES)
							}
							else {
								procedureComplaint(roleRetrievalError)
							}
						}
						catch (error) {
							console.log(error)
							procedureComplaint(roleRetrievalError)
							dispatch(disableIdleLockSwitch())
						}
					}
				}

				if (sessionToken) {
					onLoad()
				}
			},
			[]
		)

	useEffect
		(
			() => {
				const onLoad = async () => {
					switch (initSetup) {
						case ROLES:
							if (roles.length < 1) {
								dispatch(disableIdleLockSwitch())
								procedureComplaint
									(
										"No hay roles registrados, " +
										"por lo que el acceso a este módulo permanecerá deshabilitado"
									)
							}
							else {
								const degreeRetrievalError: string = "Los grados académicos no pudieron ser extraídos"

								try {
									const expand = true
									const result = await CatalogService.getAcademicDegrees(sessionToken)

									if (result.status == 200 || result.status == 204) {
										setAcademicDegrees(result.data)
										setInitSetup(ACADEMIC_DEGREES)
									}
									else {
										procedureComplaint(degreeRetrievalError)
									}
								}
								catch (error) {
									procedureComplaint(degreeRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							break;
						case ACADEMIC_DEGREES:
							if (academicDegrees.length < 1) {
								dispatch(disableIdleLockSwitch())
								procedureComplaint
									(
										"No hay grados académicos en el registro, " +
										"por lo que el acceso a este módulo permanecerá deshabilitado"
									)
							}
							else {
								setNewEmployee
									({
										country_id: basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
										nationality_id: basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].id,
										status_id: employeeStatus.ACTIVE,
										statate_id: employeeStatus.ACTIVE,
										gender_id: "",
										identification_type_id: basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id,
										birthday: null,
										birthdayValue: "",
										tags: [],
										roles: [],
										identification_code: "",
										id_clase_365: "",
										city: [],
										address: "",
										zipcode: "",
										first_name: "",
										last_name: "",
										observations: "",
										password: "",
										phone1: "",
										phone2: "",
										email: "",
										facebook: "",
										instagram: ""
									})

								search()
							}
							break;
					}
				}

				onLoad()
			},
			[initSetup]
		)

	useEffect(() => setTotalRecords(data.length), [data])

	useEffect
		(
			() => {
				if (clearSearchRequested && _.isEqual(defaultSearch, customSearch)) {
					search()
				}
			},
			[customSearch, clearSearchRequested]
		)

	useEffect
		(
			() => {
				if ((sectionLoaded && currentUserCampus) && (currentUserCampus != userCampus)) {
					dispatch(enableIdleLockSwitch())
					setSectionLoaded(false)
					setNewEmployee
						({
							...newEmployee, country_id: campusRef[currentUserCampus].company.country_id,
							nationality_id: campusRef[currentUserCampus].company.country_id,
							identification_type_id: basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id
						})
					localStorage.setItem("currentCampus", currentUserCampus)
					setUserCampus(currentUserCampus)
					search()
				}
			},
			[currentUserCampus, sectionLoaded]
		)

	useEffect
		(
			() => {
				if (moduleReady) {
					if (basicInfo.countries.length < 1) {
						procedureComplaint("No hay países registrados, por lo que el acceso a este módulo permanecerá deshabilitado")
					}
					else {
						search()
					}
				}
			},
			[moduleReady]
		)

	useEffect
		(
			() => {
				trigger
					({
						type: "setIsButtonDisabled",
						payload: (idleLocked || UIStatus == UIState.SUCCESS)
					})
			},
			[UIStatus, idleLocked]
		)
	//-----------------------------------------------------------------------------------------------------------------------
	const clearSearch = () => {
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async (page?: any) => {
		const searchError = "La búsqueda no pudo ser completada"

		try {
			dispatch(enableIdleLockSwitch())

			if (extendedFilterShown) {
				setExtendedFilterShown(false)
			}

			const result = await EmployeesService.searchEmployees
				(customSearch, isNaN(page) ? 1 : (page || 1))

			const minimumYear: number = result.data.date.split("-")[0] - 168

			if (result.status == 200 || result.status == 204) {
				setPagination(pageGrouping(result.data.current_page, result.data.last_page))
				setCurrentDate(result.data.date)
				setMinYear(minimumYear)
				setYearsToAlmostPresent([...Array(150)].map((_, i) => (minimumYear) + i))

				let normalData: any = []
				let disabledData: any = []
				let date: any
				let dataToPush: any

				result.data.data.map
					(
						(record: any, index: number) => {
							date = record.birthday == null ? undefined : record.birthday.replaceAll("-", "/")

							dataToPush =
							{
								...record, birthday: date || "",
								status_id: !record.bool_enabled ? 0 : record.employee.status_id,
								birthdayValue: date == null ? "" : new Date(date + " 23:00:00")
							}

							if (record.id == currentUser.id) {
								disabledData.push(dataToPush)
							}
							else {
								if (dataToPush.state_id == null) {
									dataToPush =
									{
										...dataToPush, city: null,
										state_id: ""
									}
								}
								else {
									dataToPush.state_name = ""

									if (dataToPush.city_id == null) {
										dataToPush.city = null
									}
									else {
										dataToPush =
										{
											...dataToPush, city: { ...dataToPush.city, label: dataToPush.city.name },
											city_name: ""
										}
									}
								}

								//adding boolean data type to toggle checking used by batch operations (multiple updating/deletion)--------------------------
								normalData.push
									({
										selected: false,
										data: { ...dataToPush }
									})
							}
						}
					)

				setReadOnlyData(disabledData)
				setData(normalData)

				setSelectedRecords(0)
				setSectionLoaded(true)
			}
			else {
				procedureComplaint(searchError)
			}
		}
		catch (error) {
			console.log(error)
			procedureComplaint(searchError)
		}
		finally {
			dispatch(disableIdleLockSwitch())
			setClearSearchRequested(false)
		}
	}

	const toggleRecordSelection = (position: number) => {
		setData
			(
				(current: any) => current.map
					(
						(record: any, index: number) => {
							if (index == position) {
								let value = !record.selected

								setSelectedRecords(selectedRecords + count[+value])

								return { ...record, selected: value }
							}

							return record
						}
					)
			)
	}

	const toggleSelectAll = () => {
		const selected = selectedRecords < totalRecords

		setData
			(
				(current: any) => current.map
					(
						(record: any, index: number) => {
							return { ...record, selected: selected };
						}
					)
			)

		setSelectedRecords(totalRecords * (+selected))
	}

	const closePrompt = () => {
		setSelectedEmployee(null)
		setEditableEmployee(null)
	}

	const showSuccessMessage = () => {
		dispatch(displayNotice({
			cornerClose: false,
			message: "Información procesada con éxito",
			heading: <h3 style={{
				color: "#00FF00", display: "inline-block"
			}}>
				Listo
			</h3>
		}));
	}

	const confirmRemoval = (recordId?: any) => {
		dispatch
			(
				displayNotice
					({
						cornerClose: true,
						message: "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
						heading: <h3 style={{ color: "#0000FF", display: "inline-block" }}>
							Confirme
						</h3>,
						procedure: async () => {
							const removalError: string = "El comando no pudo ser procesado"
							const recordIds: number[] = !isNaN(recordId) ? [recordId] : data.filter((item: any) => item.selected)
								.map((item: any) => item.data.id)

							dispatch(enableIdleLockSwitch())
							dispatch(dismissNotice())

							try {
								const result = recordsSelection.length < 2
									? await http.employeeService.deleteEmployee(campusRef[currentUserCampus].id, recordId)
									: await EmployeesService.removeEmployees(recordIds);

								if (result.status == 200 || result.status == 204) {
									showSuccessMessage();
									setCustomSearch(defaultSearch)
									setData(data.map((record: any) => { return { selected: false, data: record.data } }))
									setSelectedRecords(0)
									search()
								}
							}
							catch (error) {
								procedureComplaint(getErrorMessage(error));
							}
							finally {
								dispatch(disableIdleLockSwitch())
							}
						}
					})
			)
	}

	const roleCampusesUpdate = (role: any, index: number, tags: any) => {
		updateRoleCampuses
			(
				index,
				{
					...role, valid: tags.length > 0,
					campuses: tags
				}
			)
	}

	// Abre modal y carga ingresos adicionales
	const openOtherIncomesModal = async (employeeId: number) => {
		const campusId = campusRef[currentUserCampus].id;
		const data: any = await getOtherIncomes(campusId, employeeId);
		if (data && data.value) {
			setOtherIncomeModal({ show: true, employeeId, campusId });
			setOtherReceipts(data.value);
		}
	}

	// Cierra modal de ingresos
	const closeOtherIncomesModal = () => {
		setOtherIncomeModal({ show: false, employeeId: null, campusId: null });
		setNewOtherReceipts([]);
		setNewOtherIncome({
			concept: null,
			fare: "",
			hours: "",
			applicationDate: null,
			notes: ""
		})
	}

	// Cierra modal de edición de ingresos
	const closeEditOtherIncomesModal = () => {
		setEditOtherIncomeModal({ show: false, employeeId: null, campusId: null });
		setEditableOtherReceipt({
			concept: null,
			fare: "",
			hours: "",
			applicationDate: null,
			notes: ""
		});
	}

	// Obtiene ingresos adicionales desde API
	const getOtherIncomes = async (campusId: number, employeeId: number): Promise<OtherIncomeDTO[] | undefined> => {
		const errorMsg = "Error al obtener otros ingresos";
		try {
			dispatch(enableIdleLockSwitch());
			const result = await http.otherIncomesService.getOtherIncomes(campusId, employeeId);
			if ([200, 204].includes(result.status)) return result.data;
			console.error(errorMsg, result);
			procedureComplaint(errorMsg);
		} catch (error) {
			console.error(errorMsg, error);
			procedureComplaint(errorMsg);
		} finally {
			dispatch(disableIdleLockSwitch());
		}
	}

	// Agrega nuevo ingreso a la lista
	const addOtherIncome = () => {
		const newIncome = {
			...newOtherIncome,
			conceptId: newOtherIncome.concept?.id,
			applicationDate: newOtherIncome.applicationDate
				? new Date(newOtherIncome.applicationDate).toLocaleDateString('en-CA').replace(/-/g, '/')
				: null,
			fare: parseFloat(newOtherIncome.fare),
			hours: parseInt(newOtherIncome.hours)
		};

		setNewOtherReceipts((prev: any) => [...prev, newIncome]);
		setNewOtherIncome({ concept: null, fare: "", hours: "", applicationDate: null, notes: "" });
	}

	// Elimina ingreso de la lista
	const removeOtherIncome = (index: number) => {
		setNewOtherReceipts((prev: any) => prev.filter((_: any, i: number) => i !== index));
	}

	// Prepara ingreso para edición
	const editOtherIncome = (income: OtherIncomeDTO) => {
		const [day, month, year] = income.applicationDate.split('/');
		const formattedDate = new Date(`${year}-${month}-${day}T12:00:00`);

		setEditableOtherReceipt({
			...income,
			applicationDate: formattedDate,
			concept: (() => {
				const selectedConcept = staticData.OTHER_INCOMES.find(r => r.id === income.conceptId);
				return selectedConcept ? { value: selectedConcept.id, label: selectedConcept.name, ...selectedConcept } : null;
			})()
		});

		setEditOtherIncomeModal({
			show: true,
			employeeId: otherIncomeModal.employeeId,
			campusId: otherIncomeModal.campusId
		});
	}

	// Guarda nuevos ingresos
	const addOtherIncomes = async () => {
		const errorMsg = "Error al guardar ingresos";
		try {
			dispatch(enableIdleLockSwitch());
			const { campusId, employeeId } = otherIncomeModal;
			const result = await http.otherIncomesService.addOtherIncomes(campusId, employeeId, newOtherReceipts);

			if ([200, 204].includes(result.status) && result.data) {
				setOtherReceipts(result.data.value);
				setNewOtherReceipts([]);
				procedureComplaint("Ingresos agregados correctamente", true);
			} else {
				console.error(errorMsg, result);
				procedureComplaint(errorMsg);
			}
		} catch (error) {
			console.error(errorMsg, error);
			procedureComplaint(errorMsg);
		} finally {
			dispatch(disableIdleLockSwitch());
		}
	}

	// Actualiza ingreso existente
	const updateOtherIncome = async () => {
		const errorMsg = "Error al actualizar ingreso";
		try {
			dispatch(enableIdleLockSwitch());
			const { campusId, employeeId } = editOtherIncomeModal;
			const updatedIncome = {
				...editableOtherReceipt,
				applicationDate: editableOtherReceipt.applicationDate
					? new Date(editableOtherReceipt.applicationDate).toLocaleDateString('en-CA').replace(/-/g, '/')
					: '',
				fare: parseFloat(editableOtherReceipt.fare),
				hours: parseInt(editableOtherReceipt.hours)
			};

			const result = await http.otherIncomesService.updateOtherIncome(campusId, employeeId, updatedIncome);

			if ([200, 204].includes(result.status)) {
				setOtherReceipts(result.data.value);
				closeEditOtherIncomesModal();
				procedureComplaint("Ingreso actualizado correctamente", true);
			} else {
				console.error(errorMsg, result);
				procedureComplaint(errorMsg);
			}
		} catch (error) {
			console.error(errorMsg, error);
			procedureComplaint(errorMsg);
		} finally {
			dispatch(disableIdleLockSwitch());
		}
	}

	const renderIncomeForm = (income: any, setIncome: any, isEdit = false) => {
		return (
			<div className="otherIncomeModal-form-container mb-3">
				<SelectComponent
					options={staticData.OTHER_INCOMES.map(income => ({ value: income.id, label: income.name, ...income }))}
					onChange={(income: any) => setIncome((prev: any) => ({ ...prev, concept: income, conceptId: income.id }))}
					isDisabled={idleLocked}
					value={income.concept}
					styles={{ container: (base) => ({ ...base, marginBottom: "15px" }) }}
				/>
				<div className="row">
					{['tarifa', 'horas'].map((field, i) => (
						<div className="col-12" key={field}>
							<div className="inputContainer">
								<label htmlFor={field}>{field === 'tarifa' ? 'Tarifa por hora' : 'Horas'} *</label>
								<input
									type="number"
									id={field}
									value={income[field === 'tarifa' ? 'fare' : 'hours']}
									placeholder={field === 'tarifa' ? 'Tarifa' : 'Horas'}
									onChange={(e) => {
										setIncome((prev: any) => ({ ...prev, [field === 'tarifa' ? 'fare' : 'hours']: e.target.value }));
									}}
									min="1"
									disabled={idleLocked}
									required
								/>
							</div>
							{parseInt(income[field === 'tarifa' ? 'fare' : 'hours']) <= 0 && (
								<span className="text-danger" style={{ fontSize: "12px" }}>El valor debe ser mayor que 0</span>
							)}
						</div>
					))}
				</div>
				<div className="row">
					<div className="col-12">
						<div className="selectContainer">
							<label>Fecha de Aplicación *</label>
							<ReactDatePicker
								selected={income.applicationDate}
								onChange={(date: any) => setIncome((prev: any) => ({ ...prev, applicationDate: date }))}
								disabled={idleLocked}
								placeholderText="Selecciona una fecha"
								required
								className="otherIncomeDatepicker"
							/>
						</div>
					</div>
					<div className="col-12">
						<div className="inputContainer" style={{ height: "auto" }}>
							<textarea
								value={income.notes}
								placeholder="Nota *"
								onChange={(e) => setIncome((prev: any) => ({ ...prev, notes: e.target.value }))}
								disabled={idleLocked}
								rows={3}
								required={!isEdit}
								minLength={!isEdit ? 10 : undefined}
								style={{ width: '100%', borderRadius: '5px', height: "47px", minHeight: "47px" }}
							/>
							{income.notes.length < 10 && income.notes.length > 0 && (
								<span className="text-danger" style={{ fontSize: "12px" }}>Mínimo 10 caracteres</span>
							)}
						</div>
					</div>
				</div>
				{!isEdit && (
					<div className="row">
						<div className="col-md-12">
							<button
								type="button"
								className="btn btn-purple"
								disabled={income.fare.length < 1 || income.fare <= 0 || income.hours.length < 1 || income.hours <= 0 || !income.applicationDate || !income.concept || !income.notes || income.notes.length < 10 || idleLocked}
								onClick={() => addOtherIncome()}
							>
								Añadir
							</button>
						</div>
					</div>
				)}
			</div>
		);
	};

	const renderIncomeList = (incomes: any[], isNew = false) => (
		<div className={`${isNew ? 'd-flex flex-wrap gap-2' : 'ticket-container'} mb-3`}>
			{incomes.map((receipt, index) => (
				<div key={isNew ? index : receipt.id} className={`${isNew ? 'd-flex align-items-center justify-content-center p-2 rounded-3 border' : 'ticket d-flex'}`}>
					<div className={isNew ? 'line-height-1' : 'ticket-body'}>
						{Object.entries({
							Concepto: receipt.concept?.name || receipt.concept,
							Tarifa: receipt.fare,
							Horas: receipt.hours,
							'Fecha de aplicación': receipt.applicationDate,
							Notas: receipt.notes
						}).map(([key, value]) => (
							<div key={key}><strong>{key}: </strong>{value}</div>
						))}
					</div>
					<div className={isNew ? 'd-flex align-items-start h-100' : 'ticket-header'}>
						<button
							type="button"
							className={`btn ${isNew ? 'btn-danger' : 'btn-primary'} d-flex align-items-center justify-content-center`}
							style={{ width: "20px", height: "20px", borderRadius: "50%", padding: "0", marginLeft: isNew ? "5px" : "0" }}
							onClick={() => isNew ? removeOtherIncome(index) : editOtherIncome(receipt)}
						>
							<FontAwesomeIcon
								style={{ fontSize: "12px" }}
								icon={isNew ? solid("close") : solid("pen")}
							/>
						</button>
					</div>
				</div>
			))}
		</div>
	);

	const renderModalContent = (modal: any, setModal: any, income: any, setIncome: any, incomes: any[], isEdit = false) => (
		<div className="row m-0 otherIncomeModal-container">
			<div className={`mb-3 ${!isEdit && otherReceipts.length > 0 ? "col-md-6" : "col-12"}`}>
				{!isEdit && <span className="mb-3 d-block otherIncomeModal-title">Agregar</span>}
				{renderIncomeForm(income, setIncome, isEdit)}
				{!isEdit && newOtherReceipts.length > 0 && renderIncomeList(newOtherReceipts, true)}
			</div>
			{!isEdit && otherReceipts.length > 0 && (
				<div className="concepts-list col-md-6 mb-3">
					<span className="mb-3 d-block otherIncomeModal-title">Ingresos</span>
					{renderIncomeList([...otherReceipts].reverse())}
				</div>
			)}
			<div className="w-100 d-flex justify-content-end px-0 py-2 position-sticky bottom-0 bg-white">
				<button
					type="button"
					className="btn btn-purple-dark"
					disabled={isEdit ?
						!income.fare || income.fare <= 0 || income.hours <= 0 || income.hours.length < 1 || !income.applicationDate || !income.conceptId || income.notes.length < 10 || idleLocked :
						newOtherReceipts.length === 0 || idleLocked
					}
					onClick={() => isEdit ? updateOtherIncome() : addOtherIncomes()}
				>
					{isEdit ? 'Actualizar' : 'Guardar'}
				</button>
			</div>
		</div>
	);

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
			searchFn={search}
			allowed={allowed}
			pagination={pagination}
			removalFn={confirmRemoval}
			customSearch={customSearch}
			clearSearchFn={clearSearch}
			defaultSearch={defaultSearch}
			searchFormRef={searchFormRef}
			selectedRecords={selectedRecords}
			searchChangeFn={handleSearchChange}
			extendedFilterShown={extendedFilterShown}
			searchIcon={<CustomIcon name="employee" />}
			clearSearchRequested={clearSearchRequested}
			addingPromptFn={() => showPrompt(newEmployee)}
			setExtendedFilterShown={setExtendedFilterShown}
			generalPlaceHolder={"Nombre, teléfono, observaciones, etc."}
			extendedFilterViewClosing={() => setExtendedFilterShown(false)}
			filterSummary=
			{
				[
					customSearch.statusId < 0 ? null :
						{
							label: "Estatus",
							display: <p style={{ fontSize: "16px", margin: 0, textAlign: "center" }}>
								{basicInfo.employeeStatusRef["ES_" + customSearch.statusId].name}
							</p>
						},
					customSearch.tags.length < 1 ? null :
						{
							label: "Intereses",
							display: <div style={{ display: "flex", flexFlow: "wrap" }}>{
								customSearch.tags.map
									(
										(tag: any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
											{tag.name}
										</div>
									)
							}</div>
						},
				].filter((setting: any) => setting)
			}
			advancedSearchForm=
			{<div className="container"><div className="row">
				<div className="col-sm-6"><fieldset>
					<legend><label style={{ position: "relative", top: "4px" }}>
						Estatus
					</label></legend>
					<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
						type="button" onClick={() => setCustomSearch({ ...customSearch, statusId: -1 })} style={{ textAlign: "left" }}
					>
						<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
						<span style={{ paddingLeft: "5px" }}>
							Mostrar todo
						</span>
					</button>
					{
						Object.values(employeeStatuses).map
							(
								(status: any) => <button onClick={() => setCustomSearch({ ...customSearch, statusId: status.id })}
									disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
									type="button" key={"S_" + status.id} style={{ textAlign: "left" }}
								>
									<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
									<span style={{ paddingLeft: "5px" }}>
										{status.name}
									</span>
								</button>
							)
					}
				</fieldset></div>
				<div className="col-sm-6"><fieldset>
					<legend><label style={{ position: "relative", top: "4px" }}>
						Intereses
					</label></legend>
					<Multiselect onSelect={(tags) => setCustomSearch({ ...customSearch, tags: tags })} displayValue="name"
						onRemove={(tags) => setCustomSearch({ ...customSearch, tags: tags })} placeholder="Seleccione" disable={idleLocked}
						emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
					/>
				</fieldset></div>
			</div></div>}
		>
			{
				Object.keys(warningReport).map
					(
						(code: string) => <div className="callout callout-warning" key={"warn_" + code}
							style={{ padding: "unset" }}
						><table style={{ width: "100%" }}><tbody><tr>
							<td style={{ padding: "16px", verticalAlign: "middle" }}>
								<FontAwesomeIcon icon={solid("triangle-exclamation")} />
							</td>
							<td style={{ width: "100%" }}>
								{warningReport[code]}
							</td>
							<td style={{ verticalAlign: "top" }}><button className="btn btn-default" style={{ padding: "unset" }}
								type="button" onClick=
								{
									() => {
										let warn = warningReport
										delete warn[code]
										listWarnings({ ...warn })
									}
								}
							>
								<FontAwesomeIcon icon={solid("times")} style={{ margin: "4px" }} />
							</button></td>
						</tr></tbody></table></div>
					)
			}
			{
				(data.length < 1 && readOnlyData.length < 1)
					?
					<>
						<hr />
						<div style={{ padding: "25px" }}>
							{Constants.noCampusSearchResults}
						</div>
						<hr />
					</>
					:
					<table className="commonTable">
						<thead><tr key="usersHeader">
							{
								data.length < 1 ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
									disabled={idleLocked || saveSuccess} type="button"
								>
									{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
								</button></th>
							}
							<th ref={el => { if (el) { el.style.setProperty("border-bottom", "1px solid #000000", "important") } }}>
								Id
							</th>
							<th>
								Nombre(s)
							</th>
							<th>
								Apellidos
							</th>
							<th>
								Teléfonos
							</th>
							<th>
								Correo
							</th>
							<th>
								Roles
							</th>
							<th>
								Intereses
							</th>
							<th>
								Estatus
							</th>
							<th />
						</tr></thead>
						<tbody>
							{
								readOnlyData.map
									(
										(record: any, index: number) => <tr key={"user" + index}>
											{data.length < 1 ? "" : <td />}
											<td ref={el => { if (el) el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important") }}>
												{record.id}
											</td>
											<td>
												{record.first_name || "-"}
											</td>
											<td>
												{record.last_name || "-"}
											</td>
											<td>
												{[record.phone1, record.phone2].filter((phone: string) => isNotEmpty(phone)).join(", ") || "-"}
											</td>
											<td>
												{record.email}
											</td>
											<td>{
												record.roles && record.roles.filter((item: any) => campusRef["C_" + item.pivot.campus_id]).map
													(
														(item: any, index: number) => <div key={"role_" + item.id + "_" + item.pivot.campus_id}
															style={{ width: "max-content" }}>{
																item.name + " " +
																(campusRef["C_" + item.pivot.campus_id].name || "")
															}</div>
													)
											}</td>
											<td>{
												isEmpty(record.tags) ? "-" : record.tags.map
													(
														(item: any, index: number) => <div className="badge rounded-pill bg-primary" key={"crc_" + item.id}>
															{item.name}
														</div>
													)
											}</td>
											<td>
												{basicInfo.employeeStatusRef["ES_" + record.status_id].name}
											</td>
											<td><div style={{ display: "flex", justifyContent: "flex-end" }}><OverlayTrigger
												overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
													className="button btn btn-default" type="button" disabled={idleLocked} onClick=
													{
														() => showPrompt
															({
																...record, phone1: record.phone1 || "",
																phone2: record.phone2 || "",
																observations: record.employee.observations || "",
																campus_id: record.employee.campus.id,
																identity_code: record.employee.identity_code,
																id_clase_365: record.id_clase_365 || "",
																zipcode: record.zipcode || "",
																bool_read_only: 1
															})
													} style={{ display: "table-column", color: "var(--txt-color)", paddingTop: "10px" }}
												>
													<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
												</button></OverlayTrigger></div></td>
										</tr>
									)
							}
							{
								data.map
									(
										(record: any, index: number) => <tr key={"user" + index}>
											<td><button className="btn btn-default" onClick={() => toggleRecordSelection(index)} type="button"
												disabled={idleLocked}
											>
												{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
											</button></td>
											<td>
												{record.data.id}
											</td>
											<td>
												{record.data.first_name || "-"}
											</td>
											<td>
												{record.data.last_name || "-"}
											</td>
											<td>
												{[record.data.phone1, record.data.phone2].filter((phone: string) => isNotEmpty(phone)).join(", ") || "-"}
											</td>
											<td>
												{record.data.email}
											</td>
											<td>{
												record.data.roles && record
													.data
													.roles
													.filter((item: any) => campusRef["C_" + item.pivot.campus_id])
													.map
													(
														(item: any, index: number) => <div style={{ width: "max-content" }}
															key={"role_" + item.id + "_" + item.pivot.campus_id}
														>
															<b>
																{item.name}
																&nbsp;
																|
															</b>
															&nbsp;
															{(campusRef["C_" + item.pivot.campus_id].name || "")}
														</div>
													)
											}</td>
											<td>{
												isEmpty(record.data.tags) ? "-" : record.data.tags.map
													(
														(item: any, index: number) => <div className="badge rounded-pill bg-primary"
															key={"crc_" + item.id}
														>
															{item.name}
														</div>
													)
											}</td>
											<td>
												{basicInfo.employeeStatusRef["ES_" + record.data.status_id].name}
											</td>
											<td>
												<div style={{ display: "flex", justifyContent: "flex-end" }}>
													{
														record.data.student ? "" : <OverlayTrigger
															overlay={<Tooltip id="tooltip-nuevo-alumno">Enlistar como alumno</Tooltip>}
														>
															<button type="button" className="button btn btn-default" disabled={idleLocked}
																style={{ display: "table-column", color: "var(--txt-color)", paddingTop: "10px" }}
																onClick=
																{
																	() => setSelectedStudent
																		({
																			...record.data, id: null,
																			contacts: [],
																			enrollments: [],
																			imagePreview: record.data.image,
																			academic_degree_id: academicDegrees[0].id,
																			phone1: record.data.phone1 || "",
																			phone2: record.data.phone2 || "",
																			id_clase_365: record.data.id_clase_365 || "",
																			zipcode: record.data.zipcode || "",
																			observations: record.data.employee.observations || ""
																		})
																}
															>
																<CustomIcon name="addStudent" />
															</button>
														</OverlayTrigger>
													}
													<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver Detalle</Tooltip>}><button
														style={{ display: "table-column", color: "var(--txt-color)", paddingTop: "10px" }}
														type="button" className="button btn btn-default" disabled={idleLocked} onClick=
														{
															() => showPrompt
																({
																	...record.data, phone1: record.data.phone1 || "",
																	phone2: record.data.phone2 || "",
																	id_clase_365: record.data.id_clase_365 || "",
																	zipcode: record.data.zipcode || "",
																	observations: record.data.employee.observations || ""
																})
														}
													>
														<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
													</button></OverlayTrigger>
													{
														record.data.roles?.some((role: any) => role.code === 'mentor') ? (
															<OverlayTrigger
																overlay={
																	<Tooltip id="tooltip-activos">Otros Ingresos</Tooltip>
																}>
																<button
																	className="button btn btn-default"
																	style={{ display: "table-column", color: "var(--txt-color)" }}
																	type="button"
																	onClick={() => openOtherIncomesModal(record.data.id)}
																	disabled={idleLocked}>
																	<FontAwesomeIcon icon={solid("money-bill")} flip="horizontal" />
																</button>
															</OverlayTrigger>
														) : <div style={{ display: "table-column", width: "47px" }}></div>
													}
													<OverlayTrigger
														overlay={
															<Tooltip id="tooltip-activos">
																Borrar
															</Tooltip>
														}>
														<button
															className="button btn btn-default"
															style={{ display: "table-column" }}
															type="button"
															onClick={() => confirmRemoval(record.data.id)}
															disabled={idleLocked}
														>
															<CustomIcon name="bTrash" />
														</button>
													</OverlayTrigger>
												</div>
											</td>
										</tr>
									)
							}
						</tbody>
					</table>
			}
		</SectionContainer>
		{
			selectedEmployee == null ? "" : <EmployeeInfoModal closeFn={setSelectedEmployee} afterCloseFn={search}
				academicDegrees={academicDegrees} selectedEmployee={selectedEmployee} allowedUpdate={allowed.update}
				roles={roles} date={currentDate}
			/>
		}
		{
			selectedStudent == null ? "" : <StudentInfoModal selectedStudent={selectedStudent} allowedUpdate={allowed.update}
				academicDegrees={academicDegrees} closeFn={setSelectedStudent} afterCloseFn={search} date={currentDate}
			/>
		}
		<MiniModalController
			title="Otros Ingresos"
			show={otherIncomeModal.show}
			onClose={closeOtherIncomesModal}
			className="otherIncomeModal"
		>
			{renderModalContent(otherIncomeModal, setOtherIncomeModal, newOtherIncome, setNewOtherIncome, otherReceipts)}
		</MiniModalController>
		<MiniModalController
			title="Actualizar Ingreso"
			show={editOtherIncomeModal.show}
			onClose={closeEditOtherIncomesModal}
			className="otherIncomeModal"
		>
			{renderModalContent(editOtherIncomeModal, setEditOtherIncomeModal, editableOtherReceipt, setEditableOtherReceipt, [], true)}
		</MiniModalController>
	</>
}

export default Employees
