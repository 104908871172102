import { AxiosRequestConfig } from "axios";
import { axiosInstance } from "./axiosInstance";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/campus/";

export class FileService {
    config = (sessionToken: string | null) => {
        return {
            headers:
            {
                Accept: "application/pdf",
                "Content-Type": "application/json",
                Authorization: `Bearer ${sessionToken}`
            }
        };
    }

    configPdfFile = (): AxiosRequestConfig => {
        return {
            responseType: "blob",   // Set the responseType to 'blob' to receive a binary file
            headers: {
                Accept: "application/pdf",
            }
        };
    }

    configXlsxFile = (): AxiosRequestConfig => {
        return {
            responseType: "blob", // Set the responseType to 'blob' to receive a binary file
            headers: {
                Accept: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
            }
        };
    };

    async getInvoicePDF(campusId: number, studentId: number, invoiceId: number) {
        const response = await axiosInstance.get(
            `${RESOURCE_URL_V2}${campusId}/students/${studentId}/invoices/${invoiceId}/_pdf`,
            this.configPdfFile()
        );
        return response;
    }

    async getPaymentPDF(campusId: number, paymentId: number) {
        const response = await axiosInstance.get(
            `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/_pdf`,
            this.configPdfFile()
        );
        return response;
    }

    async getPaymentAttachment(campusId: number, paymentId: number, documentId: number) {
        const uri = `${RESOURCE_URL_V2}${campusId}/payments/${paymentId}/documents/${documentId}/_file`;
        const response = await axiosInstance.get(uri,
            {
                responseType: 'blob', // Importante para manejar archivos                
            }
        );
        return response;
    }

    async getPaymentReport(campusId: number, criteria?: any) {
        let uri = `${RESOURCE_URL_V2}${campusId}/payments/_report`;
        if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
            try {
                const criteriaJson = JSON.stringify(criteria);
                uri = `${uri}?criteria=${encodeURIComponent(criteriaJson)}`;
            } catch (error) {
                console.error("Error serializando criteria as JSON:", error);
                throw new Error("Invalid criteria format");
            }
        }
        let response = await axiosInstance.get(uri, this.configXlsxFile());
        return response;
    }

    async getCreditsReport(campusId: number, criteria?: any) {
        let uri = `${RESOURCE_URL_V2}${campusId}/credits/_report`;
        if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
            try {
                const criteriaJson = JSON.stringify(criteria);
                uri = `${uri}?criteria=${encodeURIComponent(criteriaJson)}`;
            } catch (error) {
                console.error("Error serializando criteria as JSON:", error);
                throw new Error("Invalid criteria format");
            }
        }
        let response = await axiosInstance.get(uri, this.configXlsxFile());
        return response;
    }

    async getMentorsSalaryReport(campusId: number, criteria?: any) {
        let uri = `${RESOURCE_URL_V2}${campusId}/attendances/_report`;
        if (criteria && typeof criteria === 'object' && Object.keys(criteria).length > 0) {
            try {
                const criteriaJson = JSON.stringify(criteria);
                uri = `${uri}?criteria=${encodeURIComponent(criteriaJson)}`;
            } catch (error) {
                console.error("Error serializando criteria as JSON:", error);
                throw new Error("Invalid criteria format");
            }
        }
        let response = await axiosInstance.get(uri, this.configXlsxFile());
        return response;
    }
}
