import
{
	useCallback,
	useEffect,
	useRef,
	useState
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import Multiselect
	from "multiselect-react-dropdown"
import {Popover}
	from "react-tiny-popover"
import _
	from "lodash"
import _debounce
	from "lodash/debounce"
import {sprintf}
	from "sprintf-js"

import
{
	RootState,
	enableIdleLockSwitch,
	disableIdleLockSwitch,
	displayNotice,
	dismissNotice
}
	from "./../../globals"
import CustomIcon
	from "./../../components/customIcon"
import SectionContainer, {UserPrompt}
	from "./../../components/sectionContainer"
import {SelectReact}
	from "./../../components/select"
import LeDatePicker from
	"./../../components/leDatePicker"
import SchedulePrompt
	from "./schedulePrompt"
import EnrollmentPrompt
	from "./enrollments/enrollmentPrompt"
import
{
	UIState,
	pageGrouping,
	baseRole,
	weekDays,
	currencySuffix,
	getAccessPermissions,
	monthRef,
	isEmpty,
	revertStringDate,
	isNotEmpty,
	toAscendingDate,
	enrollingSet
}
	from "./../../common"
import Constants,
{
	employeeStatus,
	scheduleStatus
}
	from "./../../constants"
import HttpManager
	from "./../../services/HttpManager"
import SimpleDataService
	from "./../../services/simpleData.service"
import CatalogService
	from "./../../services/catalog.service"
import CoursesService
	from "./../../services/campus/courses.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import StudentsService
	from "./../../services/campus/students.service"
import SchedulesService
	from "./../../services/campus/schedules.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import PeriodsService
	from "./../../services/campus/periods.service"
import {styles}
	from "./style"
import staticData
	from "./../../assets/js/static.json"
import "./../../components/dropdown/style.css"
import "./style.css"

const Schedules = ()=>
{
	const NO_SETUP          = 0
	const CATEGORIES        = 1
	const LEVEL_STATUSES    = 2
	const COURSES           = 3
	const BUILDINGS         = 4
	const PERIODS           = 5
	const ENDING            = 6

	const [levelStatuses, setLevelStatuses]                       = useState<any[]>([])
	const [periods, setPeriods]                                   = useState<any[]>([])
	const [mentors, setMentors]                                   = useState<any[]>([])
	const [buildings, setBuildings]                               = useState<any[]>([])
	const [categories, setCategories]                             = useState<any[]>([])
	const [courseRef, setCourseRef]                               = useState<any>({})
	const [buildingRef, setBuildingRef]                           = useState<any>({})
	const [periodRef, setPeriodRef]                               = useState<any>({})
	const [categoryRef, setCategoryRef]                           = useState<any>({})
	const [classroomCount, setClassroomCount]                     = useState<number>(0)
	const dispatch                                                = useDispatch()
	const [initSetup, setInitSetup]                               = useState<number>(NO_SETUP)
	const [wrongValueDetail, setWrongValueDetail]                 = useState<string | null>(null)
	const [levelsCount, setLevelsCount]                           = useState<number>(0)
	const [kitsCount, setKitsCount]                               = useState<number>(0)
	const [studentsCount, setStudentsCount]                       = useState<number>(0)
	const [totalRecords, setTotalRecords]                         = useState<number>(0)
	const [data, setData]                                         = useState<any[]>([])
	const [courses, setCourses]                                   = useState<any[]>([])
	const [mentorsList, setMentorsList]                           = useState<any>([])
	const [mentorRefList, setMentorRefList]                       = useState<any>([])
	const [mentorPopoverOpen, setMentorPopoverOpen]               = useState<boolean>(false)
	const [mentorListDisplay, setMentorListDisplay]               = useState<boolean>(false)
	const [mentorSearchInProgress, setMentorSearchInProgress]     = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields]                     = useState<boolean>(false)
	const idleLocked        : boolean                             = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null                       = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean                             = useSelector((state : RootState) => state.themeToggle.value)
	const campusRef         : any                                 = useSelector((state : RootState) => state.campusRef.value)
	const currentUserCampus : any                                 = useSelector((state : RootState) => state.currentUserCampus.value)
	const currentUser       : any                                 = useSelector((state : RootState) => state.userProfile.value)
	const basicInfo         : any                                 = useSelector((state : RootState) => state.basicInfo.value)
	const [sectionLoaded, setSectionLoaded]                       = useState<boolean>(false)
	const [searchError, setSearchError]                           = useState<string | null>(null)
	const [UIStatus, setUIStatus]                                 = useState<number>(UIState.NORMAL)
	const [clearSearchRequested, setClearSearchRequested]         = useState<boolean>(false)
	const [extendedFilterShown, setExtendedFilterShown]           = useState<boolean>(false)
	const [userCampus, setUserCampus]                             = useState<number>(currentUserCampus)
	const [selectedRecords, setSelectedRecords]                   = useState<number>(0)
	const [warningReport, listWarnings]                           = useState<any>({})
	const [searchMisconfiguration, setSearchMisconfiguration]     = useState<any>(null)
	const [selectedUser, setSelectedUser]                         = useState<any>(null)
	const [currentDate, setCurrentDate]                           = useState<any>(null)
	const [selectedSchedule, setSelectedSchedule]                 = useState<any>(null)
	const [selectedEnrollment, setSelectedEnrollment]             = useState<any>(null)
	const [defaultNewSchedule, setDefaultNewSchedule]             = useState<any>(null)
	const [pagination, setPagination]                             = useState<any>(null)
	const [saveSuccess, setSaveSuccess]                           = useState<boolean>(false)
	const [firstUsableBuildingIndex, setFirstUsableBuildingIndex] = useState<any>(null)
	const [firstUsableCourseIndex, setFirstUsableCourseIndex]     = useState<any>(null)
	const [firstUsableLevelIndex, setFirstUsableLevelIndex]       = useState<any>(null)
	let   courseSelRef                                            = useRef<Multiselect | null>(null)
	let   buildingSelRef                                          = useRef<Multiselect | null>(null)
	const [recordsSelection, setRecordsSelection]                 = useState<any>([])
	const count        : number[]                                 = [-1, 1]
	let   searchFormRef                                           = useRef<HTMLFormElement>(null)
	const allowed      : any                                      = getAccessPermissions(currentUser)
	const enrollmentAllowance                                     = getAccessPermissions(currentUser, "enrollments")
	const [suffix, setSuffix]                                     = useState<string | null>
		(currencySuffix[campusRef[currentUserCampus].company.currency_id - 1])
	const owedKitsSearch = ["No", "Sí", "Mostrar todo"]
	const assignedKitsSearch = ["Sin kits", "Con kits", "Mostrar todo"]
	const [defaultSearch, setDefaultSearch]                       = useState<any>
	({
		q          : "",
		categoryId : -1,
		statusId   : scheduleStatus.ACTIVE,
		tags       : [],
		mentors    : [],
		courses    : [],
		buildings  : [],
		mentorRef  : {},
		startValue : "",
		owedKits   : 2,
		assignedKits : 2,
		endValue   : ""
	})
	const [customSearch, setCustomSearch] = useState<any>(defaultSearch)

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}

	const showPrompt = async (recordData : any)=>
	{
		if(recordData.id && recordData.status_id == scheduleStatus.ACTIVE)
		{
			const promptError : string = "Información relativa al registro no pudo ser extraída"

			dispatch(enableIdleLockSwitch())

			try
			{
				let result : any = await SchedulesService.searchSchedules
				({
					startValue   : recordData.startValue,
					endValue     : recordData.endValue,
					classroomIds : [recordData.classroom.id],
					exceptionId  : recordData.id,
					statusId : scheduleStatus.ACTIVE
				})

				if(result.status == 200 || result.status == 204)
				{
					setSelectedSchedule({...recordData, matchingHoursData : result.data})
				}
				else
				{
					procedureComplaint(promptError)
				}
			}
			catch(error)
			{
				procedureComplaint(promptError)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
		else
		{
			const data = {...recordData, matchingHoursData : []}

			setSelectedSchedule(data)
		}
	}

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const subTermsUpdate = (term : any, index : number, tags : any, listingField : string, subListingField : string)=>
	{
		let items = customSearch[listingField]

		items.splice(index, 1, {...term, [subListingField] : tags})

		handleSearchChange
		({
			target:
			{
				name  : listingField,
				value : items
			}
		})
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(basicInfo.tags.length < 1)
				{
					procedureComplaint
					(
						"No existen etiquetas para ser asignadas a los cursos creados en el registro, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					if(currentUserCampus == null)
					{
						dispatch(disableIdleLockSwitch())
						procedureComplaint("No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado.")
					}
					else
					{
						const catalogRetrievalError : string = "El catálogo de categorías de cursos no pudo ser extraído"

						dispatch(enableIdleLockSwitch())

						try
						{
							const result = await SimpleDataService.getRecords("categories")

							if(result.status == 200 || result.status == 204)
							{
								let categoryMap : any = {};

								result.data.map((category : any) => categoryMap["C_" + category.id] = category)
								setCategoryRef(categoryMap)
								setCategories(result.data)
								setInitSetup(CATEGORIES)
							}
							else
							{
								procedureComplaint(catalogRetrievalError)
							}
						}
						catch(error)
						{
							procedureComplaint(catalogRetrievalError)
						}
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case CATEGORIES:
						if(categories.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay categorías en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const levelStatusRetrievalError : string = "los estatuses para módulos no pudieron ser extraídos. Refrésque la página o contacte al administrador del sistema"

							dispatch(enableIdleLockSwitch())

							try
							{
								const result = await CatalogService.getLevelStatuses(sessionToken)

								if(result.status == 200 || result.status == 204)
								{
									setLevelStatuses(result.data)

									setInitSetup(LEVEL_STATUSES)
								}
								else
								{
									procedureComplaint(levelStatusRetrievalError)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(levelStatusRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case LEVEL_STATUSES:
						if(levelStatuses.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay estatuses de módulo en el registro, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							const courseRetrievalError :string  = "La lista de cursos registrados no pudo ser extraída"

							try
							{
								const expand : number = 1
								const result : any    = await CoursesService.searchCourses
									(campusRef[currentUserCampus], {statusId : 1}, expand)

								if(result.status == 200 || result.status == 204)
								{
									let levelsWithNoKits : boolean = false
									let courseIndex      : any     = {}
									let lvCount          : number  =  0
									let kitCounting      : number  =  0
									let firstCourseIndex : number  = -1
									let firstLvIndex     : number  = -1
									let mentorList       : any     = []
									let levelIndex       : any
									let formattedCourse  : any
									let kitIndex         : any
									let mentorIndex      : any

									setCourses
									(
										result.data.map
										(
											(course : any, index : number)=>
											{
												levelIndex = {}

												course.levels.map
												(
													(level : any, index0 : number)=>
													{
														mentorIndex = {}
														kitIndex    = {}

														if(level.kits.length < 1)
														{
															if(!levelsWithNoKits)
															{
																levelsWithNoKits = true;
															}
														}
														else
														{
															level.kits.map((kit : any) => kitIndex["K_" + kit.id] = kit)
														}

														level.mentors.map
														(
															(mentor : any)=>
															{
																mentorIndex["M_" + mentor.id] = {...mentor, label : mentor.first_name + " " + mentor.last_name}

																mentorList.push(mentorIndex["M_" + mentor.id])
															}
														)

														kitCounting += level.kits.length

														levelIndex["L_" + level.id]=
														{
															...level, kitRef : kitIndex,
															mentorRef        : mentorIndex,
															mentors          : Object.values(mentorIndex)
														}

														if(firstLvIndex < 0)
														{
															firstCourseIndex = index
															firstLvIndex     = index0
														}

														lvCount++
													}
												)

												formattedCourse=
												{
													...course, levels : Object.values(levelIndex),
													refIndex          : "C_" + course.id,
													levelRef          : levelIndex
												}

												courseIndex["C_" + course.id] = formattedCourse

												return formattedCourse
											}
										)
									)

									setKitsCount(kitCounting)
									setMentors(mentorList)
									setLevelsCount(lvCount)
									setFirstUsableCourseIndex(firstCourseIndex)
									setFirstUsableLevelIndex(firstLvIndex)
									setCourseRef(courseIndex)
									setInitSetup(COURSES)

									if(levelsWithNoKits)
									{
										listWarnings({...warningReport, courseKits : "Hay módulos sin kits asociados."})
									}
								}
								else
								{
									procedureComplaint(courseRetrievalError)
									dispatch(disableIdleLockSwitch())
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(courseRetrievalError)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case COURSES:
						if(mentors.length < 1)
						{
							listWarnings({...warningReport, professors : "No se encontraron profesores asignados a módulos de curso."})
						}

						try
						{
							let formattedBuilding                  : any
							let   formattedBuildings               : any
							let   buildingIndex                    : any
							let   classroomIndex                   : any
							let   firstCampusWithClassroomsIndex   : number
							const searchParams                     : any    = null
							let   classroomCounting                : number =  0
							let   expand                           : string = "classrooms"
							let   firstBuildingWithClassroomsIndex : number = -1
							const result                           : any=
								await BuildingsService.searchBuildings
									(campusRef[currentUserCampus].id, searchParams, expand)

							if(result.status == 200 || result.status == 204)
							{
								buildingIndex = {}

								if(result.data.length < 1)
								{
									formattedBuildings = []

									listWarnings
									({
										...warningReport, campuses : "No hay edificios en el registro, " +
											"por lo que no se podrán agregar horarios dentro de los cursos y módulos creados"
									})
								}
								else
								{
									firstCampusWithClassroomsIndex = -1
									formattedBuildings             = result.data.map
									(
										(building : any, index : number)=>
										{
											classroomCounting += building.classrooms.length
											classroomIndex     = {}

											formattedBuilding=
											{
												...building, classrooms : building.classrooms.map
												(
													(classroom : any, index0 : number)=>
													{
														classroomIndex["C_" + classroom.id] = {...classroom, label : classroom.name}

														if(firstCampusWithClassroomsIndex < 0)
														{
															firstCampusWithClassroomsIndex = index
														}

														if(firstBuildingWithClassroomsIndex < 0)
														{
															firstBuildingWithClassroomsIndex = index
														}

														return classroomIndex["C_" + classroom.id]
													}
												)
											}

											buildingIndex["B_" + formattedBuilding.id] = {...formattedBuilding, classroomRef : classroomIndex}

											return buildingIndex["B_" + formattedBuilding.id]
										}
									)
								}

								setBuildings(formattedBuildings)
								setBuildingRef(buildingIndex)

								if(classroomCounting < 1)
								{
									setInitSetup(NO_SETUP)
									dispatch(disableIdleLockSwitch())
									procedureComplaint
									(
										"No se encontraron aulas para ser utilizadas en los horarios, " +
											"por lo que el acceso a este módulo permanecerá deshabilitado"
									)
								}
								else
								{
									setFirstUsableBuildingIndex(firstBuildingWithClassroomsIndex)
									setClassroomCount(classroomCounting)
									setInitSetup(BUILDINGS)
								}
							}
							else
							{
								listWarnings({...warningReport, students : "los edificios  no pudieron ser extraídos."})
								setInitSetup(BUILDINGS)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students : "los planteles  no pudieron ser extraídos. " +
									"No se podrán agregar horarios dentro de los cursos y módulos creados"
							})

							setInitSetup(BUILDINGS)
						}
					break;
					case BUILDINGS:
						try
						{
							const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								let periodMap : any = {};

								setPeriods
								(
									result.data.map
									(
										(period : any)=>
										{
											periodMap["P_" + period.id] = {...period, label : period.name}

											return periodMap["P_" + period.id]
										}
									)
								)

								setPeriodRef(periodMap)

								if(result.data.length < 1)
								{
									dispatch(disableIdleLockSwitch())
									procedureComplaint("No hay periodos registrados, por lo que el acceso a esta sección permanecerá dehabilitado.")
								}
								else
								{
									setInitSetup(PERIODS)
								}
							}
							else
							{
								listWarnings
								({
									...warningReport, students : "Los periodos no pudieron ser extraídos. " +
										"No se podrá acceder a horarios y matrículas"
								})

								setInitSetup(ENDING)
							}
						}
						catch(error)
						{
							console.log(error)
							listWarnings
							({
								...warningReport, students : "Los periodos no pudieron ser extraídos. " +
									"No se podrá acceder a horarios y matrículas"
							})

							setInitSetup(ENDING)
						}
					break;
					case PERIODS:

						try
						{
							const result = await StudentsService.getStudentsCount(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								setStudentsCount(result.data.count)

								if(result.data.count < 1)
								{
									listWarnings({...warningReport, students : "No hay alumnos registrados, por lo que no se podrá acceder a las matrículas."})
								}
							}
							else
							{
								listWarnings({...warningReport, students : "El conteo de estudiantes no pudo ser extraído. No se podrá acceder a las matrículas."})
							}

							setInitSetup(ENDING)
						}
						catch(error)
						{
							console.log(error)
							listWarnings({...warningReport, students : "El conteo de estudiantes no pudo ser extraído. No se podrá acceder a las matrículas."})

							setInitSetup(ENDING)
						}
					break;
					case ENDING:
						if
						(
							(periods.length > 0 && (currentUserCampus && classroomCount > 0)) &&
								(firstUsableBuildingIndex >= 0 && firstUsableCourseIndex >= 0)
						)
						{
							setDefaultNewSchedule
							({
								name                           : "",
								code                           : "",
								notes                          : "",
								startValue                     : "",
								endValue                       : "",
								status_update_motive           : "",
								students_minimum               : 15,
								students_limit                 : 50,
								hours                          : {},
								status_id                      : scheduleStatus.ACTIVE,
								campus_id                      : campusRef[currentUserCampus].id,
								building_id                    : buildings[firstUsableBuildingIndex].id,
								course                         : courses[firstUsableCourseIndex],
								course_id                      : courses[firstUsableCourseIndex].id,
								level                          : courses[firstUsableCourseIndex].levels[0],
								level_id                       : courses[firstUsableCourseIndex].levels[0].id,
								billable_months                :  1,
								first_surcharge_month_day      : 20,
								second_surcharge_month_day     : 21,
								subscription_price             : courses[firstUsableCourseIndex].levels[0]["subscription_base_price" + suffix],
								monthly_billing_price          : courses[firstUsableCourseIndex].levels[0]["monthly_billing_base_price" + suffix],
								subscription_surcharge_cost    : courses[firstUsableCourseIndex].levels[0]["subscription_surcharge_base_cost" + suffix],
								monthly_billing_surcharge_cost : courses[firstUsableCourseIndex].levels[0]["monthly_billing_surcharge_base_cost" + suffix],
								second_surcharge_cost          : courses[firstUsableCourseIndex].levels[0]["second_surcharge_base_cost" + suffix],
								mentor_fee                     : 0
							})
						}

						setSectionLoaded(true)

						search()
					break;
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				search()
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		() => setMentorRefList(Object.keys(customSearch.mentorRef)),
		[customSearch.mentorRef]
	)

	useEffect
	(
		()=>
		{
			if(customSearch.period && customSearch.period.id)
			{
				setCustomSearch
				({
					...customSearch, endValue : new Date(customSearch.period.end   + " 23:00:00"),
					startValue                : new Date(customSearch.period.start + " 23:00:00")
				})
			}
		},
		[customSearch.period]
	)

	useEffect
	(
		()=>
		{
			if
			(
				(customSearch.period && customSearch.period.id) &&
				(
					customSearch.startValue &&
					(
						(customSearch.startValue < new Date(customSearch.period.start + " 23:00:00")) ||
						(customSearch.startValue > new Date(customSearch.period.end   + " 23:00:00"))
					)
				)
			)
			{
				setCustomSearch({...customSearch, period : ""})
			}
		},
		[customSearch.startValue]
	)

	useEffect
	(
		()=>
		{
			if
			(
				(customSearch.period && customSearch.period.id) &&
				(
					customSearch.endValue &&
					(
						(customSearch.endValue < new Date(customSearch.period.start + " 23:00:00")) ||
						(customSearch.endValue > new Date(customSearch.period.end   + " 23:00:00"))
					)
				)
			)
			{
				setCustomSearch({...customSearch, period : ""})
			}
		},
		[customSearch.endValue]
	)

	useEffect
	(
		()=>
		{
			if(currentUserCampus && (currentUserCampus != userCampus))
			{
				dispatch(enableIdleLockSwitch())
				setCustomSearch(defaultSearch)
				setSectionLoaded(false)
				setCourses([])
				setKitsCount(0)
				setMentors([])
				setPeriods([])
				setLevelsCount(0)
				setFirstUsableCourseIndex(null)
				setFirstUsableLevelIndex(null)
				setCourseRef({})
				listWarnings({})
				setSuffix(currencySuffix[campusRef[currentUserCampus].company.currency_id - 1])
				localStorage.setItem("currentCampus", currentUserCampus)
				setUserCampus(currentUserCampus)
				setInitSetup(LEVEL_STATUSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)

	useEffect
	(
		()=>
		{
			if(clearSearchRequested)
			{
				if(_.isEqual(defaultSearch, customSearch))
				{
					setCustomSearch(defaultSearch)
					search()
				}
			}
			else
			{
				if
				(
					((customSearch.startValue && customSearch.endValue) && (customSearch.startValue >= customSearch.endValue)) ||
					(
						(isEmpty(customSearch.startValue) && customSearch.endValue) ||
							(customSearch.startValue && isEmpty(customSearch.endValue))
					)
				)
				{
					if(isEmpty(searchMisconfiguration))
					{
						setSearchMisconfiguration("Es necesario proporcionar ambos valores apropiados y diferentes en las fechas de inicio y fin para filtrar por por intervalo")
						setUIStatus(UIState.WARNING)
					}
				}
				else
				{
					if(isNotEmpty(searchMisconfiguration))
					{
						setSearchMisconfiguration(null)
						setUIStatus(UIState.NORMAL)
					}
				}
			}
		},
		[customSearch, clearSearchRequested, extendedFilterShown]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const debounceFn : any = useCallback(_debounce(handleDebounceFn, 1000), [])

	async function handleDebounceFn(campusId : number, term : string)
	{
		setMentorSearchInProgress(true)

		try
		{
			let result : any = await EmployeesService.searchEmployees
				({q : term, roleIds : [baseRole.MENTOR], statusId : employeeStatus.ACTIVE}, undefined, 100)

			if(result.status == 200 || result.status == 204)
			{
				setMentorsList(result.data)
			}
			else
			{
				setSearchError("Se produjo un error al realizar la búsqueda")
				setUIStatus(UIState.ERROR)
			}
		}
		catch(error)
		{
			setSearchError("Se produjo un error al realizar la búsqueda")
			setUIStatus(UIState.ERROR)
			console.log(error)
		}
		finally
		{
			setMentorSearchInProgress(false)
		}
	}

	const searchMentors = (term : string)=>
	{
		setCustomSearch({...customSearch, mentorSearchQuery : term})
		debounceFn(campusRef[currentUserCampus].id, term)
	}

	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}

	const search = async(page? : any)=>
	{
		let proceedingSearch : boolean = true
		const searchError    : string  = "La búsqueda no pudo completarse"

		if(searchFormRef && searchFormRef.current)
		{
			if(!searchFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					proceedingSearch = false

					setUnfilledFields(true)
					searchFormRef.current.reportValidity()

					setTimeout
					(
						() => setUnfilledFields(false),
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						(isEmpty(customSearch.startValue) && isNotEmpty(customSearch.endValue)) ||
							(isNotEmpty(customSearch.startValue) && isEmpty(customSearch.endValue))
					) || (customSearch.startValue > customSearch.endValue)
				)
				{
					proceedingSearch = false

					if(wrongValueDetail == null)
					{
						proceedingSearch = false

						setWrongValueDetail("La fecha de inicio debe ser menor a  a la fecha de fin")
						searchFormRef.current.reportValidity()

						setTimeout(() => setWrongValueDetail(null), 3000)
					}
				}
			}
		}

		if(proceedingSearch)
		{
			try
			{
				dispatch(enableIdleLockSwitch())

				if(extendedFilterShown)
				{
					setExtendedFilterShown(false)
				}

				const result = await SchedulesService.searchSchedules(customSearch, isNaN(page) ? 1 : (page || 1))

				if(result.status == 200 || result.status == 204)
				{
					let mentorRef    : any
					let kitRef       : any
					let hours        : any
					let currentHours : number
					let refIndex     : string
					let startMinutes : number
					let startHours   : number
					let endMinutes   : number
					let endHours     : number
					let weekSessions : any
					let mentors      : any
					setCurrentDate(new Date(result.data.date + " 23:00:00"))
					setPagination(pageGrouping(result.data.current_page, result.data.last_page))
					setData
					(
						result.data.data.map
						(
							(schedule : any)=>
							{
								hours        = {}
								weekSessions = []

								schedule.sessions.map
								(
									(session : any)=>
									{
										currentHours = session.start_hours
										currentHours = session.start_hours
										startMinutes = (session.start_hours % 10)
										startHours   = (session.start_hours - startMinutes) / 10
										endMinutes   = (session.end_hours % 10)
										endHours     = (session.end_hours - endMinutes) / 10

										weekSessions.push
										(
											weekDays[session.week_day].substr(0, 2) + sprintf
											(
												" %02d:%02d-%02d:%02d",
												startHours, startMinutes * 6, endHours  , endMinutes * 6
											)
										)

										while(currentHours < session.end_hours)
										{
											hours[sprintf("DH%d%03d", session.week_day, currentHours)] = true
											currentHours                                              += 5
										}
									}
								)

								kitRef    = {}
								mentorRef = {}
								mentors   = schedule.level.mentors.map
								(
									(mentor : any)=>
									{
										refIndex            = "M_" + mentor.id
										mentorRef[refIndex] = {}

										if(mentor.sessions)
										{
											mentor.sessions.map
											(
												(session : any)=>
												{
													let currentHours : any = session.start_hours

													while(currentHours < session.end_hours)
													{
														mentorRef[refIndex][sprintf("DH%d%03d", session.week_day, currentHours)]=
															session.schedule_id

														currentHours += 5
													}
												}
											)
										}

										return{...mentor, label : mentor.first_name + " " + mentor.last_name}
									}
								)

								schedule.level.kits.map((kit : any) => kitRef["K_" + kit.id] = kit)

								return{
									selected : false,
									data:
									{
										...schedule, hours : hours,
										weekSessions       : weekSessions,
										period             : {...schedule.period, label : schedule.period.name},
										classroom          : {...schedule.classroom, label : schedule.classroom.name},
										mentor             : isEmpty(schedule.mentor)
											? null
												: {...schedule.mentor, label : schedule.mentor.first_name + " " + schedule.mentor.last_name},
										level:
										{
											...schedule.level, kitRef : kitRef,
											mentorRef                 : mentorRef,
											mentors                   : mentors
										},
									}
								}
							}
						)
					)

					setSelectedRecords(0)
				}
				else
				{
					procedureComplaint(searchError)
				}
			}
			catch(error)
			{
				console.log(error)

				procedureComplaint(searchError)
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
				setClearSearchRequested(false)
			}
		}
	}

	const toggleRecordSelection = (position : number)=>
	{
		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					if(index == position)
					{
						let value = !record.selected

						setSelectedRecords(selectedRecords + count[ +value ])

						return {...record, selected : value};
					}

					return record;
				}
			)
		)
	}

	const toggleSelectAll = ()=>
	{
		const selected = selectedRecords < totalRecords

		setData
		(
			(current : any) => current.map
			(
				(record : any, index : number)=>
				{
					return {...record, selected : selected};
				}
			)
		);

		setSelectedRecords(totalRecords * (+selected))
	}

	const confirmRemoval = (recordId? : any)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : true,
				message     : "¿Eliminar registro" + (!isNaN(recordId) || recordsSelection.length < 2 ? "" : "s") + "?",
				heading     : <h3 style={{color : "#0000FF", display : "inline-block"}}>
					Confirme
				</h3>,
				procedure   : async ()=>
				{
					const removalError : string   = "El comando no pudo ser procesado. " +
						"Asegúrese de que ninguna otra información dependa de los registros en específico"
					const recordIds    : number[] = !isNaN(recordId)
						? [recordId]
							: data.filter((item : any, index : number) => item.selected).map((item : any, index : number) => item.data.id)

					dispatch(enableIdleLockSwitch())
					dispatch(dismissNotice())

					try
					{
						const result = await SchedulesService.removeSchedules(campusRef[currentUserCampus].id, recordIds)

						if(result.status == 200 || result.status == 204)
						{
							setCustomSearch(defaultSearch)
							setData(data.map((record : any) => {return {selected : false, data : record.data}}))
							setSelectedRecords(0)
							search()
						}
						else
						{
							console.log(result.status)
							procedureComplaint(removalError)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(removalError)
					}
					finally
					{
						dispatch(disableIdleLockSwitch())
					}
				}
			})
		)
	}

	const promptEnrolling = async(course : any, level : any, schedule : any)=>
	{
		let retrievalError : string = "La lista de matrículas no pudo ser extraída"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await HttpManager.getInstance().enrollmentService.searchEnrollments(schedule.id)

			if(result.status == 200 || result.status == 204)
			{
				setSelectedEnrollment(enrollingSet(course, level, schedule, result.data, campusRef[currentUserCampus].company_id))
			}
			else
			{
				procedureComplaint(retrievalError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(retrievalError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	return !sectionLoaded || pagination == null ? <></> : <>
		<SectionContainer
		  searchFn={search}
		  allowed={allowed}
		  pagination={pagination}
		  removalFn={confirmRemoval}
		  customSearch={customSearch}
		  clearSearchFn={clearSearch}
		  defaultSearch={defaultSearch}
		  searchFormRef={searchFormRef}
		  allowedUpdate={allowed.update}
		  unfilledFields={unfilledFields}
		  selectedRecords={selectedRecords}
		  searchChangeFn={handleSearchChange}
		  searchError={searchMisconfiguration}
		  extendedFilterShown={extendedFilterShown}
		  clearSearchRequested={clearSearchRequested}
		  generalPlaceHolder={"Nombre, código, notas"}
		  searchIcon={<CustomIcon name={"schedule"} />}
		  setExtendedFilterShown={setExtendedFilterShown}
		  addingPromptFn={() => showPrompt(defaultNewSchedule)}
		  filterSummary=
		  {
			[
				{
					label   : "Estatus",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.statusId < 0 ? "Mostrar todo" : basicInfo.scheduleStatusRef["SS_" + customSearch.statusId].name}
					</p>
				},
				customSearch.tags.length < 1 ? null :
				{
					label   : "Etiquetas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.tags.map
						(
							(tag : any) => <div key={"tf_" + tag.id} className="badge rounded-pill bg-primary">
								{tag.name}
							</div>
						)
					}</div>
				},
				customSearch.period == null || customSearch. period.id == null ? null :
				{
					label   : "Periodo",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{customSearch.period.name}
					</p>
				},
				(
					(isEmpty(customSearch.endValue) || isEmpty(customSearch.startValue)) ||
					(customSearch.startValue >= customSearch.endValue)
				) ? null :
				{
					label   : "Intérvalo",
					display : toAscendingDate(customSearch.startValue) + " - " + toAscendingDate(customSearch.endValue)
				},
				{
					label: "Kits adeudados",
					display: <p style={{ fontSize: "16px", margin: 0, textAlign: "center" }}>
						{owedKitsSearch[customSearch.owedKits]}
					</p>
				},
				{
					label: "Kits asignados",
					display: <p style={{ fontSize: "16px", margin: 0, textAlign: "center" }}>
						{assignedKitsSearch[customSearch.assignedKits]}
					</p>
				},
				customSearch.categoryId < 1 ? null :
				{
					label   : "Categoría",
					display : <p style={{fontSize: "16px", margin: 0, textAlign: "center"}}>
						{categoryRef["C_" + customSearch.categoryId].name}
					</p>
				},
				customSearch.courses.length < 1 ? null : 
				{
					label   : "Cursos-Módulos",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.courses.map
						(
							(course : any) => <div style={{background : "#A0A0A0", borderRadius : "15px"}} key={"C_" + course.id}>
								<span className="badge rounded-pill bg-primary">
									{course.name}
								</span>
								{
									course.levels.map
									(
										(level : any) => <span key={"L_" + level.id} className="badge rounded-pill bg-info">
											{level.name}
										</span>
									)
								}
							</div>
						)
					}</div>
				},
				customSearch.buildings.length < 1 ? null : 
				{
					label   : "Edificios-Aulas",
					display : <div style={{display : "flex", flexFlow : "wrap"}}>{
						customSearch.buildings.map
						(
							(building : any) => <div style={{background : "#A0A0A0", borderRadius : "15px" }} key={"B_" + building.id}>
								<span className="badge rounded-pill bg-primary">
									{building.name}
								</span>
								{
									building.classrooms.map
									(
										(classroom : any) => <span key={"C_" + classroom.id} className="badge rounded-pill bg-info">
											{classroom.name}
										</span>
									)
								}
							</div>
						)
					}</div>
				},
				isEmpty(mentorRefList) ? null :
				{
					label   : "Profesores",
					display : <div>{
						mentorRefList.length < 2
								?
							(customSearch.mentorRef[mentorRefList[0]] == null ? "" : customSearch.mentorRef[mentorRefList[0]].first_name + " " + customSearch.mentorRef[mentorRefList[0]].last_name)
								:
							<>
								<Popover isOpen={mentorListDisplay} onClickOutside={() => setMentorListDisplay(false)}
								  positions={["bottom"]} content=
								  {<div style=
									{{
										overflowY     : "auto",
										maxHeight     : "40vh",
										background    : "var(--main-bg)",
										width         : "440px",
										display       : "flex",
										flexDirection : "column"
									  }}
								  >{
									(mentorRefList.length < 1) ? "" : mentorRefList.filter((key : any) => key && customSearch.mentorRef[key]).map
									(
										(key : string) => <div key={key}>
											{customSearch.mentorRef[key].first_name + " " + customSearch.mentorRef[key].last_name}
										</div>
									)
								  }</div>}
								><button onClick={() => setMentorListDisplay(!mentorListDisplay)} className="btn btnVeryPeri"
								  disabled={idleLocked} type="button"
								>
									[ {mentorRefList.length} ]
									&emsp;
									<FontAwesomeIcon icon={solid("square-caret-down")} />
								</button></Popover>
							</>
					}</div>
				}
			].filter((setting : any) => setting)
		  }
		  extendedFilterViewClosing=
		  {
			()=>
			{
				if
				(
					customSearch.startValue >= customSearch.endValue ||
					(
						(isEmpty(customSearch.startValue) && customSearch.endValue) ||
							(customSearch.startValue && isEmpty(customSearch.endValue))
					)
				)
				{
					setCustomSearch({...customSearch, startValue : "", endValue : ""})
				}

				setExtendedFilterShown(false)
			}
		  }
		  advancedSearchForm=
		  {<div className="container">
			<div className="row">
				<div className="col-sm-6">
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Estatus
						</label></legend>
						<button className="form-control btn btn-default" disabled={idleLocked || customSearch.statusId < 0}
						  type="button" onClick={() => setCustomSearch({...customSearch, statusId : -1})} style={{textAlign : "left"}}
						>
							<FontAwesomeIcon icon={customSearch.statusId < 0 ? solid("circle-dot") : solid("circle")} />
							<span style={{paddingLeft : "5px"}}>
								Mostrar todo
							</span>
						</button>
						{
							staticData.SCHEDULE_STATUSES.map
							(
								(status : any) => <button onClick={() => setCustomSearch({...customSearch, statusId : status.id})}
								  disabled={idleLocked || customSearch.statusId == status.id} className="form-control btn btn-default"
								  type="button" key={"S_" + status.id} style={{textAlign : "left"}}
								>
									<FontAwesomeIcon icon={customSearch.statusId == status.id ? solid("circle-dot") : solid("circle")} />
									<span style={{paddingLeft : "5px"}}>
										{status.name}
									</span>
								</button>
							)
						}
					</fieldset>
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Periodo
						</label></legend>
						<SelectReact value={customSearch.period} className="btn btn-primary text-start" children={periods}
						  onChange={handleSearchChange} disabled={idleLocked} clearable={true} style={{height : "30px"}}
						  placeholder="Seleccione..." id="period" name="period"
						/>
						<div className="row">
							<div className="col-sm-6" ><div className="inputContainer" style={{paddingTop : "23px", width : "100%"}}>
								<label htmlFor="start" style={{position : "relative"}}>
									Inicio
								</label>
								<Popover positions={["bottom"]} isOpen={wrongValueDetail != null} content=
								  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
									<div className="requirementAlert">
										<FontAwesomeIcon icon={solid("exclamation")}/>
									</div>
									&nbsp;
									{wrongValueDetail}
								  </div>}
								><div>
									<LeDatePicker id="startValue" years={basicInfo.futureRange} value={customSearch.startValue}
									  months={monthRef} name="startValue" required={customSearch.endValue} portalId="startValue"
									  onChange={(e : any) => handleSearchChange(e)} disabled={idleLocked}
									/>
								</div></Popover>
							</div></div>
							<div className="col-md-6" ><div className="inputContainer" style={{paddingTop : "23px", width : "100%"}}>
								<label htmlFor="end" style={{position : "relative"}}>
									Fin
								</label><div>
									<LeDatePicker value={customSearch.endValue} years={basicInfo.futureRange} months={monthRef}
									  name="endValue" portalId="endValue" required={customSearch.startValue} id="endValue"
									  onChange={(e : any) => handleSearchChange(e)} disabled={idleLocked}
									/>
								</div>
							</div></div>
						</div>
					</fieldset>
				</div>
				<div className="col-sm-6">
					<fieldset>
						<legend><label style={{ position: "relative", top: "4px" }}>
							Kits asignados
						</label></legend>
						<div
						  className="selectContainer"
						  style=
						  {{
							height : "unset",
							marginBottom : "unset"
						  }}
						>
							<select
							  onChange={handleSearchChange}
							  id="assignedKits"
							  style={{marginTop : "unset"}}
							  value={customSearch.assignedKits}
							  name="assignedKits"
							>{
								assignedKitsSearch.map
								(
									(key: any, index: number) => <option key={"select_" + key + "_schedule"} value={index}>
										{key}
									</option>
								)
							}</select>
							<div className="iconRight">
								<CustomIcon name={"rowDown"} />
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend><label style={{ position: "relative", top: "4px" }}>
							Con adeudo de kits
						</label></legend>
						<div
						  className="selectContainer"
						  style=
						  {{
							height : "unset",
							marginBottom : "unset"
						  }}
						>
							<select
							  onChange={handleSearchChange}
							  id="owedKits"
							  style={{marginTop : "unset"}}
							  value={customSearch.owedKits}
							  name="owedKits"
							>{
								owedKitsSearch.map
								(
									(key: any, index: number) => <option key={"select_" + key + "_schedule"} value={index}>
										{key}
									</option>
								)
							}</select>
							<div className="iconRight">
								<CustomIcon name={"rowDown"} />
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Categoría
						</label></legend>
						<div className="selectContainer" style={{marginBottom : "unset", height : "32px"}}>
							<select value={customSearch.categoryId} disabled={idleLocked} name="categoryId"
							  className="btn btn-primary text-start" onChange={handleSearchChange}
							  style={{height : "30px", marginTop : "unset"}} id="categoryId"
							>
								<option value={-1}>
									Todas
								</option>
								{
									categories.map
									(
										(record : any, i : any) => <option key={i} value={record.id}>
											{record.name}
										</option>
									)
								}
							</select>
							<div className="iconRight" style={{pointerEvents : "none"}}>
								<CustomIcon name="rowDown" />
							</div>
						</div>
					</fieldset>
					<fieldset>
						<legend><label style={{position : "relative", top : "4px"}}>
							Etiquetas
						</label></legend>
						<Multiselect onSelect={(tags : any) => setCustomSearch({...customSearch, tags : tags})} displayValue="name"
						  onRemove={(tags : any) => setCustomSearch({...customSearch, tags : tags})} placeholder="Seleccione"
						  emptyRecordMsg="No hay más opciones" options={basicInfo.tags} selectedValues={customSearch.tags}
						/>
					</fieldset>
				</div>
			</div>
			<div className="row">
				<div className="col-sm-6"><fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Cursos
					</label></legend>
					<div style={{padding : "0px 15px"}} className="nestedSelection">
						<Multiselect onSelect={(courses : any) => setCustomSearch({...customSearch, courses : [...courses]})}
						  displayValue="name" selectedValues={[...customSearch.courses]} emptyRecordMsg="No hay más opciones"
						  onRemove={(courses : any) => setCustomSearch({...customSearch, courses : [...courses]})}
						  options={courses} placeholder="Seleccione..." ref={courseSelRef}
						/>
					</div>
					{
						customSearch.courses.length < 1 ? "" : <>
							<hr />
							{
								customSearch.courses.filter((course : any) => course).map
								(
									(course : any, index : any) => <div key={"C_" + course.id} style={{padding : "5px 10px"}}>
										<div style=
										  {{
											background   : "#F5F5F5",
											height       : "38px",
											borderRadius : (courseRef["C_" + course.id].levels.length < 2 ? "15px" : "15px 15px 0px 0px")
										  }}
										><table style={{width : "100%"}}><tbody><tr>
											<td style={{padding : "8px 15px", width : "100%"}}>
												{course?.name}
											</td>
											<td><button className="btn btn-default" disabled={idleLocked} type="button" onClick=
											  {
												()=>
												{
													let courses = customSearch.courses

													courses.splice(index, 1)

													if(courseSelRef && courseSelRef.current)
													{
														courseSelRef
															.current
															.resetSelectedValues()
															.then
															(
																()=>
																{
																	handleSearchChange
																	({
																		target:
																		{
																			name  : "courses",
																			value : courses
																		}
																	})
																}
															)
													}
												}
											  }
											>
												<FontAwesomeIcon icon={solid("times")} />
											</button></td>
										</tr></tbody></table></div>
										{
											courseRef["C_" + course.id].levels.length < 2 ? "" : <fieldset style=
											  {{
												borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px", position : "relative",
												borderTop    : "unset"
											  }}
											>
												<legend style={{paddingLeft : "15px", position : "absolute"}}><label
												  style={{position : "relative", paddingTop : "2px"}}
												>
													{Constants.Common.modules}
												</label></legend>
												<div style={{paddingTop : "30px"}}>
													<Multiselect options={courseRef["C_" + course.id].levels} selectedValues={[...course.levels]}
													  emptyRecordMsg="No hay más opciones" placeholder="Especifique..." displayValue="name"
													  onRemove={(levels : any) => subTermsUpdate(course, index, levels, "courses", "levels")}
													  onSelect={(levels : any) => subTermsUpdate(course, index, levels, "courses", "levels")}
													/>
												</div>
											</fieldset>
										}
									</div>
								)
							}
						</>
					}
				</fieldset></div>
				<div className="col-sm-6"><fieldset>
					<legend><label style={{position : "relative", top : "4px"}}>
						Edificios
					</label></legend>
					<div style={{padding : "0px 15px"}} className="nestedSelection">
						<Multiselect placeholder="Seleccione..." displayValue="name" ref={buildingSelRef} options={buildings}
						  onSelect={(buildings : any) => setCustomSearch({...customSearch, buildings : buildings})}
						  onRemove={(buildings : any) => setCustomSearch({...customSearch, buildings : buildings})}
						  selectedValues={[...customSearch.buildings]} emptyRecordMsg="No hay más opciones"
						/>
					</div>
					{
						customSearch.buildings.length < 1 ? "" : <>
							<hr />
							{
								customSearch.buildings.filter((building : any) => building).map
								(
									(building : any, index : any) => <div key={"B_" + building.id} style={{padding : "5px 10px"}}>
										<div style=
										  {{
											background   : "#F5F5F5",
											height       : "38px",
											borderRadius : (buildingRef["B_" + building.id].classrooms.length > 1
												? "15px 15px 0px 0px" : "15px")
										  }}
										><table style={{width : "100%"}}><tbody><tr>
											<td style={{padding : "8px 15px", width : "100%"}}>
												{building?.name}
											</td>
											<td><button className="btn btn-default" disabled={idleLocked} type="button" onClick=
											  {
												()=>
												{
													let buildings = customSearch.buildings

													buildings.splice(index, 1)

													if(buildingSelRef && buildingSelRef.current)
													{
														buildingSelRef
															.current
															.resetSelectedValues()
															.then
															(
																()=>
																{
																	handleSearchChange
																	({
																		target:
																		{
																			name  : "buildings",
																			value : buildings
																		}
																	})
																}
															)
													}
												}
											  }
											>
												<FontAwesomeIcon icon={solid("times")} />
											</button></td>
										</tr></tbody></table></div>
										{
											buildingRef["B_" + building.id].classrooms.length < 2 ? "" : <fieldset style=
											  {{
												borderTop    : "unset",
												borderRadius : "0px 0px 15px 15px",
												padding      :"0px 10px 10px",
												position     : "relative"
											  }}
											>
												<legend style={{paddingLeft : "15px", position : "absolute"}}><label
												  style={{position : "relative", paddingTop : "2px"}}
												>
													Aulas
												</label></legend>
												<div style={{paddingTop : "30px"}}>
													<Multiselect displayValue="name" options={[...building.classrooms]}
													  selectedValues={buildingRef["B_" + building.id].classrooms}
													  onRemove={(classrooms : any) => subTermsUpdate(building, index, classrooms, "buildings", "classrooms")}
													  onSelect={(classrooms : any) => subTermsUpdate(building, index, classrooms, "buildings", "classrooms")}
													  emptyRecordMsg="No hay más opciones" placeholder="Especifique..."
													/>
												</div>
											</fieldset>
										}
									</div>
								)
							}
						</>
					}
				</fieldset></div>
			</div>
			<fieldset>
				<legend><label htmlFor="start" style={{position : "relative", top : "4px"}}>
					Profesores
				</label></legend>
				<Popover isOpen={mentorPopoverOpen} onClickOutside={() => setMentorPopoverOpen(false)} positions={["top"]}
				  content=
				  {<div style=
					{{
						overflowY     : "auto",
						maxHeight     : "40vh",
						background    : "var(--main-bg)",
						width         : "440px",
						display       : "flex",
						flexDirection : "column-reverse"
					  }}
				  >
					{
						(mentorsList.length < 1) ? "" : mentorsList.filter((mentor : any) => mentor).map
						(
							(mentor : any, index : number) => <div
							  key={"M_" + index}
							  style={{display : "inline-grid", padding : "5px", width : "100%"}}
							>
								<div style={{display : "grid", margin : "0px 5px"}}>
									<button
									  type="button"
									  key={"M_" + index}
									  disabled={idleLocked || customSearch.mentorRef["M_" + mentor.id]}
									  className={"btn btn-" + (customSearch.mentorRef["M_" + mentor.id] == null ? "default" : "primary")}
									  onClick={() => setCustomSearch({...customSearch, mentorRef : {...customSearch.mentorRef, ["M_" + mentor.id] : mentor}})}
									  style=
									  {{
										whiteSpace    : "nowrap",
										overflow      : "hidden",
										textOverflow  : "ellipsis",
										textAlign     : "left",
									  }}
									>
										<span style={{paddingLeft : "10px"}}>
											{mentor.first_name + " " + mentor.last_name}
										</span>
									</button>
								</div>
							</div>
						)
					}
					<div className="text-center" style={{color : "#C7C7C7"}}>
						No hay más resultados.
					</div>
				  </div>}
				><div>
					<input name="employeeSearchQuery" onChange={(e : any) => searchMentors(e.target.value)} id="mentorSearchQuery"
					   style={{flexGrow : 1, background : "#F3F8FF", width : "100%", height : "44px", border : "1px solid #8F91DA"}}
					  onClick={() => setMentorPopoverOpen(!mentorPopoverOpen)} placeholder="Nombre, teléfono, etc." type="text"
					  value={customSearch.mentorSearchQuery || ""} maxLength={50} disabled={idleLocked || clearSearchRequested}
					/>
					<div style={{height : "10px"}}>
						{!mentorSearchInProgress ? "" : <div className="loader"></div>}
					</div>
				</div></Popover>
				{
					mentorRefList.length < 1 ? "" : <>
						<hr />
						{
							mentorRefList.filter((key : any) => key && customSearch.mentorRef[key]).map
							(
								(key : any) => <div key={key}
								  style={{display : "flex", alignItems : "stretch", border : "1px solid #C7C7C7", borderRadius : "15px", padding : "10px"}}
								>
									<p style={{flexGrow	 : 1, wordBreak : "break-word", margin : "unset"}}>
										{customSearch.mentorRef[key].first_name + " " + customSearch.mentorRef[key].last_name}
									</p>
									<button style={{...styles.btnCloseModal, flexGrow : 0}} type="button" onClick=
									  {
										()=>
										{
											let mentorMap : any = {...customSearch.mentorRef}
											delete mentorMap[key]
											setCustomSearch({...customSearch, mentorRef : mentorMap})
										}
									  }
									>
										<FontAwesomeIcon style={{ height: "100%", position : "relative", bottom : "1px"}}
										  icon={solid("times")}
										/>
									</button>
								</div>
							)
						}
					</>
				}
			</fieldset>
		  </div>}
		>
			{
				Object.keys(warningReport).map
				(
					(code : string) => <div style={{padding : "unset", position : "sticky", left : "0px"}} key={"warn_" + code}
					  className="callout callout-warning"
					><table style={{width : "100%"}}><tbody><tr>
						<td style={{padding : "16px", verticalAlign : "middle"}}>
							<FontAwesomeIcon icon={solid("triangle-exclamation")} />
						</td>
						<td style={{width : "100%"}}>
							{warningReport[code]}
						</td>
						<td style={{verticalAlign : "top"}}><button className="btn btn-default" style={{padding : "unset"}}
						  type="button" onClick=
						  {
							()=>
							{
								let warn = warningReport

								delete warn[code]
								listWarnings({...warn})
							}
						  }
						>
							<FontAwesomeIcon icon={solid("times")} style={{margin : "20px 15px 0px"}}

							/>
						</button></td>
					</tr></tbody></table></div>
				)
			}
			{
				(data == null || data.length < 1)
					?
				<>
					<hr />
					<div style={{padding : "25px"}}>
						Sin resultados para este plantel.
					</div>
					<hr />
				</>
					:
				<table className="specialTable">
					<thead><tr key="levelsHeader">
						{
							!allowed.delete ? "" : <th><button className="btn btn-default" onClick={toggleSelectAll}
							  disabled={idleLocked || saveSuccess} type="button"
							>
								{<CustomIcon name={selectedRecords == totalRecords ? "bCheck" : "bUncheck"} />}
							</button></th>
						}
						<th>
							id
						</th>
						<th>
							Curso
						</th>
						<th>
							{Constants.Common.module}
						</th>
						<th>
							Nombre
						</th>
						<th>
							Código
						</th>
						<th>
							Sesiones
						</th>
						<th>
							Estatus
						</th>
						<th>
							Periodo
						</th>
						<th>
							Inicio
						</th>
						<th>
							Fin
						</th>
						<th>
							Meses a facturar
						</th>
						<th>
							Edificio
						</th>
						<th>
							Aula
						</th>
						<th>
							Profesor
						</th>
						<th>
						</th>
					</tr></thead>
					{
						data.map
						(
							(record : any, index : number) => <tbody
							  key={"campus" + index}
							  className=
							  {
								"course " +
									((selectedSchedule == null && selectedEnrollment == null) ? "" : "selectedRecord")
							  }
							><tr>
								{
									!allowed.delete ? "" : <td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
										<button type="button" disabled={idleLocked} onClick={() => toggleRecordSelection(index)}
										  className="btn btn-default"
										>
											{<CustomIcon name={record.selected ? "bCheck" : "bUncheck"} />}
										</button>
									</td>
								}
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.id}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.level?.course?.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									[{record.data.level?.code}]
									&nbsp;
									{record.data.level?.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.code}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>{
									record.data.sessions == null ? "" : record.data.weekSessions.map
									(
										(session : any) => <div key={"S_" + session}>
											{session}
										</div>
									)
								}</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{basicInfo.scheduleStatusRef["SS_" + record.data.status_id].name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.period?.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{revertStringDate(record.data.start_date)}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{revertStringDate(record.data.end_date)}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.billable_months}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.classroom?.building?.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.classroom?.name}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}>
									{record.data.mentor == null ? "" : (record.data.mentor.first_name + " " + record.data.mentor.last_name)}
								</td>
								<td style={{outline : "10px solid #F3F8FF", background : "#F3F8FF"}}><div
								  style={{display : "flex", float : "right"}}
								>
									{
										(!enrollmentAllowance.read || studentsCount < 1) ? "" : <button
										  className="button btn btn-default" disabled={idleLocked} type="button" onClick=
											{() => promptEnrolling(record.data.level.course, record.data.level, record.data)}
										>
											<CustomIcon
											  name={"student"}
											  style={{fill : record.data.enrolled_students > 0 ? "#349EFF" : "#000000"}}
											/>
										</button>
									}
									<button style={{color : "var(--txt-color)", paddingTop : "10px"}} disabled={idleLocked}
									  className="button btn btn-default" type="button" onClick=
									  {
										() => showPrompt
										({
											...record.data, notes : record.data.notes || "",
											course                : record.data.level.course,
											course_id             : record.data.level.course_id,
											level_id              : record.data.level.id,
											building_id           : record.data.classroom.building_id,
											campus_id             : record.data.classroom.building.campus_id,
											startValue            : new Date(record.data.start_date + " 23:00:00"),
											endValue              : new Date(record.data.end_date + " 23:00:00")
										})
									  }
									>
										<FontAwesomeIcon flip="horizontal" icon={solid("eye")} />
									</button>
									{
										!allowed.delete ? "" : <button onClick={() => confirmRemoval(record.data.id)}
										  disabled={idleLocked || (record.data.students && record.data.students.length > 0)}
										  className="button btn btn-default" title=
										  {
											(record.data.students && record.data.students.length < 1)
												? ""
													: "Debe remover los alumnos del curso antes de poder remover el curso"
										  }
										>
											<CustomIcon name={"bTrash"} />
										</button>
									}
								</div></td>
							</tr></tbody>
						)
					}
				</table>
			}
		</SectionContainer>
		{
			selectedSchedule == null ? "" : <SchedulePrompt selectedSchedule={selectedSchedule} courseRef={courseRef}
			  buildingRef={buildingRef} closeFn={() => {setSelectedSchedule(null)}} periods={periods} afterCloseFn={search}
			  allowedUpdate={allowed.update} currentDate={currentDate}
			/>
		}
		{
			selectedEnrollment == null || selectedUser ? "" : <EnrollmentPrompt closeFn={() => {setSelectedEnrollment(null)}}
			  selectedEnrollment={selectedEnrollment} profileViewingFn={setSelectedUser} allowance={enrollmentAllowance}
			  afterCloseFn={search}
			/>
		}
		{
			selectedUser == null ? "" : <UserPrompt promptCloseFn={()=> setSelectedUser(null)} selectedUser={selectedUser} />
		}
	</>
}

export default Schedules
