import { AxiosResponse } from "axios";
import { axiosInstance } from "./axiosInstance"
import { OtherIncomeDTO } from "../../models/OtherIncomeDTO";
const RESOURCE_URL_V2 = "/admin/campus/";

export class OtherIncomesService {

	getOtherIncomes = async (campusId: number, employeeId: number): Promise<AxiosResponse<OtherIncomeDTO[]>> => {
		let uri = `${RESOURCE_URL_V2}${campusId}/employees/${employeeId}/_otherIncomes`;
		const response = await axiosInstance.get<OtherIncomeDTO[]>(uri);
		return response;
	}

    addOtherIncomes = async (campusId: number, employeeId: number, otherIncomes: OtherIncomeDTO[]): Promise<AxiosResponse> => {
        let uri = `${RESOURCE_URL_V2}${campusId}/employees/${employeeId}/_otherIncomes`;
        const response = await axiosInstance.post(uri, {
            incomes: otherIncomes
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    }

    updateOtherIncome = async (campusId: number, employeeId: number, otherIncome: any): Promise<AxiosResponse> => {
        let uri = `${RESOURCE_URL_V2}${campusId}/employees/${employeeId}/_otherIncomes/${otherIncome.id}`;
        const response = await axiosInstance.put(uri, otherIncome, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return response;
    }
    
}