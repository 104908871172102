import
{
	useState,
	useRef,
	useReducer,
	useEffect,
	FormEvent
}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import
{
	Tab,
	Tabs,
	Form
}
	from "react-bootstrap"
import Multiselect
	from "multiselect-react-dropdown"
import {Popover}
	from "react-tiny-popover"
import
{
	useDispatch,
	useSelector
}
	from "react-redux"
import _
	from "lodash"

import
{
	RootState,

	enableIdleLockSwitch,
	disableIdleLockSwitch,

	displayNotice,
}
	from "./../../globals"

import
{
	buttonClickHandling,
	imageTypes,
	isEmpty,
	monthRef,
	toAscendingDate,
	UIState,
	validateField,
	getErrorMessage,
	formatField,
}
	from "./../../common"
import
{
	tunning,
	country
}
	from "./../../constants"
import CustomIcon
	from "./../../components/customIcon"
import {DataDisplayModal}
	from "./../../components/sectionContainer"
import {SelectReact}
	from "./../../components/select"
import UnderlineHeading
	from "./../../components/underlineHeading"
import LeDatePicker
	from "./../../components/leDatePicker"
import EmployeesService
	from "./../../services/campus/employees.service"
import defaultUserImage
	from "./../../assets/images/user.png"

import "./../../components/dropdown/style.css"
import "./../../components/input/style.css"
import {sprintf} from "sprintf-js"
import HttpManager from "../../services/HttpManager"

type State=
{
	isButtonDisabled : boolean
}

const initialState : State=
{
	isButtonDisabled : true
}

type Action   = {type : "setIsButtonDisabled", payload : boolean}
const reducer = (state : State, action : Action) : State=>
{
	switch(action.type)
	{
		case "setIsButtonDisabled":
			return{ ...state, isButtonDisabled : action.payload}
		break;
	}
}

const EmployeeInfoModal = (props : any)=>
{
	const {selectedEmployee, allowedUpdate, closeFn, afterCloseFn, date, roles} = props

	let   fileReader : any
	const [state, trigger] = useReducer(reducer, initialState)
	const dispatch = useDispatch()
	const idleLocked        : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	const basicInfo         : any = useSelector((state : RootState) => state.basicInfo.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any = useSelector((state : RootState) => state.campusRef.value)
	const currencies = useSelector((state: RootState) => state.rootReducer.catalog.currencies);
	const employeeStatuses  : any = Object.values(basicInfo.employeeStatusRef)
	const [campuses, setCampuses] = useState<any>(Object.values(campusRef))
	const [editableEmployee, setEditableEmployee] = useState<any>(null)
	const [unfilledMainData, setUnfilledMainData] = useState<boolean>(false)
	const [wrongValueOnFields, setWrongValueOnFields] = useState<boolean>(false)
	const [noCityProvided, setNoCityProvided] = useState<boolean>(false)
	const [unfilledFields, setUnfilledFields] = useState<boolean>(false)
	const [unchangedRecord, setUnchangedRecord] = useState<boolean>(false)
	const [saveSuccess, setSaveSuccess] = useState<boolean>(false)
	const [showPassword, setShowPassword] = useState(false)
	const [saveError, setSaveError] = useState<any>(null)
	const [minDate, setMinDate] = useState<any>(null)
	const [maxDate, setMaxDate] = useState<any>(null)
	const [warningDate, setWarningDate] = useState<any>(null)
	const [currentTab, setCurrentTab] = useState<string | null>("roles")
	const [UIStatus, setUIStatus] = useState<number>(UIState.NORMAL)
	const [noRolesProvided, setNoRolesProvided] = useState<boolean>(false)
	const [wrongRolesProvided, setWrongRolesProvided] = useState<boolean>(false)
	const [validRoles, setValidRoles] = useState<number>(selectedEmployee.roles.length)
	const [banks, setBanks] = useState<any>([]);
	let   recordFormRef = useRef<HTMLFormElement >(null)
	let   firstNameRef = useRef<HTMLInputElement>(null)
	let   lastNameRef = useRef<HTMLInputElement>(null)
	let   zipcodeRef = useRef<HTMLInputElement>(null)
	let   imageRef = useRef<HTMLInputElement>(null)
	let   observationsRef = useRef<HTMLTextAreaElement>(null)
	let   countrySelRef = useRef<HTMLSelectElement>(null)
	let   stateSelRef = useRef<HTMLSelectElement>(null)
	let   stateNameRef = useRef<HTMLInputElement>(null)
	let   cityNameRef = useRef<HTMLInputElement>(null)
	let   addressRef = useRef<HTMLInputElement>(null)
	let   emailRef = useRef<HTMLInputElement>(null)
	let   nationalitySelRef : any = useRef<HTMLSelectElement>(null)
	let   identificationCodeRef = useRef<HTMLInputElement>(null)
	let   passwordRef = useRef<HTMLInputElement>(null)
	let   phone1Ref = useRef<HTMLInputElement>(null)
	let   phone2Ref = useRef<HTMLInputElement>(null)
	let   tabDivRef = useRef<HTMLDivElement>(null)
	let   roleSelRef = useRef<Multiselect | null>(null)
	const minYear                : any = +date.split("-")[0] - 154
	const linkInfo               : any=
	{
		facebook  : "Facebook",
		instagram : "Instagram"
	}

	const http = HttpManager.getInstance();

	const yearsToAlmostPresent : any = [...Array(150)].map((_, i) => (minYear) + i)
	const toggleShowPassword = ()=> setShowPassword(!showPassword)

	const COUNTRY_CURRENCY_MAP = {
		[country.MEXICO]: "1",
		[country.HONDURAS]: "2",
		[country.EL_SALVADOR]: "3"
	};

	const getDefaultCurrency = (countryId: number) => {
		return COUNTRY_CURRENCY_MAP[countryId] || "3";
	};

	const imageLoad = (event : any)=>
	{
		if(event && event.target && event.target.files && event.target.files.length > 0)
		{
			if(event.target.files[0].size > 5242880 || imageTypes.indexOf(event.target.files[0].type) < 0)
			{
				setSaveError
				(
					<>
						Sólo se permiten imágenes con las siguientes características:
						<br />
						&emsp;Tamaño &lt;= 5MB, &nbsp;Formato: [BMP, GIF, JPEG, PNG, WEBP]
					</>
				)

				setUIStatus(UIState.ERROR)

				if(imageRef != null && imageRef.current != null)
				{
					imageRef.current.value = ""
				}
			}
			else
			{
				let employee : any   = {...editableEmployee, image : event.target.files[0]}
				fileReader           = new FileReader()
				fileReader.onloadend = (event : any)=>
				{
					setEditableEmployee({...employee, imagePreview : fileReader.result})

					if(imageRef != null && imageRef.current != null)
					{
						imageRef.current.value = ""
					}
				}

				fileReader.readAsDataURL(event.target.files[0])
			}
		}
	}

	const imageDisposal = ()=>
	{
		if(imageRef != null && imageRef.current != null)
		{
			imageRef.current.value = ""
		}

		setEditableEmployee
		({
			...editableEmployee, image : null,
			imagePreview               : null,
		})
	}

	const updateRoleCampuses = (index : number, role : any)=>
	{
		let roles        = editableEmployee.roles
		let passingRoles = 0

		roles.splice(index, 1, role)
		setEditableEmployee
		({
			...editableEmployee, roles : roles.map
			(
				(roleItem : any)=>
				{
					if(roleItem.valid)
					{
						passingRoles++
					}

					return roleItem
				}
			)
		})

		setValidRoles(passingRoles)
	}

	const handleRegistryChange = (event: any) => {
		const { name, value } = event.target;
		setEditableEmployee({
			...editableEmployee,
			[name]: value
		});
	};

	const unedit = ()=>
	{
		if(editableEmployee && editableEmployee.id == null)
		{
			closeFn()
		}

		setEditableEmployee(null)
	}

	const editEmployee = ()=>
	{
		setEditableEmployee
		(
			_.cloneDeep
			({
				...selectedEmployee, password : "",
				nationality_id : selectedEmployee.nationality_id || campusRef[currentUserCampus].company.country_id,
				country_id  : selectedEmployee.country_id || campusRef[currentUserCampus].company.country_id,
				identification_type_id : selectedEmployee.identification_type_id || basicInfo.countryRef["C_" + campusRef[currentUserCampus].company.country_id].preferred_identification_type_id
			})
		)
		setValidRoles(selectedEmployee.roles.length)
	}

	const roleCampusesUpdate = (role : any, index : number, tags : any)=>
	{
		updateRoleCampuses
		(
			index,
			{
				...role,valid : tags.length > 0,
				 campuses     : tags
			}
		)
	}

	const saveRecord = ()=>
	{
		if(recordFormRef && recordFormRef.current)
		{
			if(!recordFormRef.current.checkValidity())
			{
				if(!unfilledFields)
				{
					setUnfilledFields(true)

					if(currentTab != "roles")
					{
						recordFormRef.current.reportValidity()
					}
					else
					{
						tabDivRef?.current?.focus()
						setUnfilledMainData(true)
					}

					setTimeout
					(
						() =>
						{
							setUnfilledMainData(false)
							setUnfilledFields(false)
						},
						3000
					)
				}
			}
			else
			{
				if
				(
					(
						basicInfo.countryRef["C_" + editableEmployee.country_id].states.length > 0 &&
						basicInfo.countryRef["C_" + editableEmployee.country_id].stateRef["S_" + editableEmployee.state_id].cities.length > 0
					)
						&&
					(editableEmployee.city == null || editableEmployee.city.id == null)
				)
				{
					if(!unfilledFields)
					{
						setUnfilledFields(true)

						if(currentTab != "roles")
						{
							setNoCityProvided(true)
						}
						else
						{
							setUnfilledMainData(true)
							tabDivRef?.current?.focus()
						}

						setTimeout
						(
							() =>
							{
								setUnfilledMainData(false)
								setUnfilledFields(false)
								setNoCityProvided(false)
							},
							3000
						)
					}
				}
				else
				{
					if(editableEmployee.roles.length < 1)
					{
						if(!unfilledFields && !noRolesProvided)
						{
							tabDivRef?.current?.focus()
							setUnfilledFields(true)
							setNoRolesProvided(true)

							setTimeout
							(
								() =>
								{
									setNoRolesProvided(false)
									setUnfilledFields(false)
								},
								3000
							)
						}
					}
					else
					{
						if((campuses.length > 1) && (validRoles < editableEmployee.roles.length))
						{
							if(!unfilledFields && !wrongRolesProvided && !wrongValueOnFields)
							{
								tabDivRef?.current?.focus()
								setWrongRolesProvided(true)
								setWrongValueOnFields(true)

								setTimeout
								(
									() =>
									{
										setWrongRolesProvided(false)
										setWrongValueOnFields(false)
									},
									3000
								)
							}
						}
						else
						{
							if(saveError != null)
							{
								setSaveError(null)
							}

							setUIStatus(UIState.LOCKED)
							dispatch(enableIdleLockSwitch());

							EmployeesService.saveEmployee(editableEmployee).then
							(
								()=>
								{
									setSaveSuccess(true)
									setUIStatus(UIState.SUCCESS)

									setTimeout
									(
										()=>
										{
											closeFn()
											afterCloseFn()
											setSaveSuccess(false)
											setUIStatus(UIState.NORMAL)
											setEditableEmployee(null)
										},
										tunning.MODAL_DISMISS_DELAY
									)
								},
								(error : any)=>
								{
									console.log(error)
									setUIStatus(UIState.ERROR)
									dispatch(disableIdleLockSwitch())
									setSaveError(getErrorMessage(error))
								}
							)
						}
					}
				}
			}
		}
	}

	const getBanksByCountry = async (countryId:number) => {
        try {
			dispatch(enableIdleLockSwitch());
            let response = await http.banksService.getBanksByCountry(countryId);
            return response.data;
        } catch (error) {
            console.log(error);
			dispatch(displayNotice({
				cornerClose: false,
				message: getErrorMessage(error),
				heading:
				<h3 style={{
					color: "#FF0000",
					display: "inline-block"}}>
					Error
				</h3>
			}));
        } finally {
			dispatch(disableIdleLockSwitch())
		}
    }

	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			trigger
			({
				type    : "setIsButtonDisabled",
				payload : (idleLocked || UIStatus == UIState.SUCCESS)
			})
		},
		[UIStatus, idleLocked]
	)

	useEffect
	(
		()=>
		{
			let cautionDate = new Date(date + " 23:00:00")
			let maximumDate = new Date(cautionDate)

			maximumDate.setFullYear(maximumDate.getFullYear() - 18)
			cautionDate.setFullYear(cautionDate.getFullYear() - 13)
			setMaxDate(maximumDate)
			setWarningDate(cautionDate)
			setMinDate(new Date(sprintf("%04d-01-01 23:00:00", minYear)))

			if(selectedEmployee.id == null)
			{
				setEditableEmployee({...selectedEmployee})
			}
		},
		[]
	)

	useEffect(() => {
		if (editableEmployee && editableEmployee.country_id && selectedEmployee.id == null && !editableEmployee.employee) {
			const isMentor = editableEmployee.roles?.some((role: any) => role.code === 'mentor');
			if (isMentor) {
				const defaultCurrency = getDefaultCurrency(editableEmployee.country_id);
				setEditableEmployee({
					...editableEmployee,
					employee: {
						...editableEmployee.employee,
						currency_id: defaultCurrency
					}
				});
			}
		}
	}, [editableEmployee?.country_id, editableEmployee?.roles]);

	useEffect(() => {
		const setBanksByCountry = async () => {
			if(selectedEmployee.country_id)
			{
				let banks = await getBanksByCountry(selectedEmployee.country_id);
				setBanks(banks);
			}
		}
		setBanksByCountry();
	}, [selectedEmployee])

	//-----------------------------------------------------------------------------------------------------------------------
	return <DataDisplayModal
	  unfilledFields={unfilledFields}
	  saveLock={state.isButtonDisabled}
	  promptCloseFn={closeFn}
	  allowedUpdate={!selectedEmployee.bool_read_only && allowedUpdate}
	  uneditFn={unedit}
	  UIStatus={UIStatus}
	  dataEditInit={editEmployee}
	  entityName="Empleado"
	  wrongValueOnFields={wrongValueOnFields}
	  editableRecord={editableEmployee}
	  saveSuccess={saveSuccess}
	  saveDataFn={saveRecord}
	  form={recordFormRef}
	  unchangedRecord={unchangedRecord}
	  processingError={saveError}
	  headIcon={<CustomIcon name="employee" />}
	><form ref={recordFormRef} className="form" onSubmit=
	  {
		(event : FormEvent)=>
		{
			event.preventDefault()
			saveRecord()
		}
	  }
	><div className="container"><div className="row">
		<div className="col-md-4 text-center">
			<div style={{maxWidth : "250px", maxHeight : "250px", display : "inline-block"}}>
				<img className="relatedPicture" src=
				  {
					(editableEmployee == null ? selectedEmployee.imagePreview : editableEmployee.imagePreview)
						|| defaultUserImage
				  }
				/>
				{
					editableEmployee == null ? "" : <>
						<input accept="image/*" type="file" style={{display : "none"}}
						  onChange={imageLoad} name="image" id="image"
						  ref={imageRef}
						/>
						{
							editableEmployee.image == null ? "" : <button className="btn btn-danger rounded-pill"
							  onClick={imageDisposal} type="button" disabled={idleLocked} style=
							  {{
								position : "relative",
								bottom   : "40px",
								left     : "15%"
							  }}
							>
								<FontAwesomeIcon icon={solid("times")} size="2x" fill="unset"/>
							</button>
						}
						<button onClick={() => buttonClickHandling(imageRef)} className="btn rounded-pill" type="button"
						  disabled={idleLocked} style=
						  {{
							border     : "1px solid var(--main-bg-color)",
							color      : "var(--secondary-txt-color)",
							position   : "relative",
							background : "#C7C7C7",
							height     : "56px",
							bottom     : "40px",
							left       : "16%"
						  }}
						>
							<FontAwesomeIcon style={{color : "#000000"}} icon={solid("camera")} fill="unset" size="2x" />
						</button>
					</>
				}
			</div>
			<br />
			<br />
			<div className="inputContainer text-start">
				<label htmlFor="status_id" style={{position : "relative"}}>
					Correlativo
				</label>
				<div className="roView">
					{selectedEmployee.identity_code}
				</div>
			</div>
			<div className="inputContainer text-start">
				<label htmlFor="correlativo_clase_365">
					Correlativo Clase365
				</label>
				<div className="roView">
					<br />
					{selectedEmployee?.correlativo_clase_365 || "-"}
				</div>
			</div>
			<div className="selectContainer">
				<label htmlFor="status_id">
					Estatus
				</label>
				{
					editableEmployee == null
						?
					<div className="roView">
						<br />
						{basicInfo.employeeStatusRef["ES_" + selectedEmployee.status_id].name}
					</div>
						:
					<>
						<select value={editableEmployee.status_id} className="btn btn-primary text-start" id="status_id"
						  disabled={idleLocked} onChange={handleRegistryChange} name="status_id"
						  style={{height : "30px"}}
						>{
							employeeStatuses.map
							(
								(record : any, i : any) => <option key={i} value={record.id}>
									{record.name}
								</option>
							)
						}</select>
						<div className="iconRight" style={{pointerEvents : "none"}}>
							<CustomIcon name={"rowDown"} />
						</div>
					</>
				}
			</div>
			{
				basicInfo.tags.length < 1 ? "" : <div className="card card-default" style={{padding : "15px"}}>
					<div className="underlineHeadingContainer" style={{marginBottom : "unset"}}>
						Intereses
					</div>
					{
						editableEmployee == null
							?
						<div style={{textAlign : "left", display : "flex", flexFlow : "wrap"}}>{
							selectedEmployee.tags.length < 1 ? "(Ninguno)" : selectedEmployee.tags.map
							(
								(record : any, index: number) => <div key={"cr_" + record.id}
								  className="badge rounded-pill bg-primary prompt"
								  style={{display : "inline-block", padding : "5px", margin : "5px"}}
								>
									{record.name}
								</div>
							)
						}</div>
							:
						<>
							<label htmlFor="tags" style={{position : "unset"}}>
								Actualice
							</label>
							<Multiselect displayValue="name" selectedValues={editableEmployee.tags} disable={idleLocked}
							  options={basicInfo.tags} emptyRecordMsg="No hay más opciones" placeholder="Seleccione"
							  onSelect={(tags) => setEditableEmployee({...editableEmployee, tags: tags})}
							  onRemove={(tags) => setEditableEmployee({...editableEmployee, tags: tags})}
							/>
						</>
					}
				</div>
			}
		</div>
		<div className="col-md-8">
			<div
				ref={tabDivRef}
				tabIndex={-1}
				style={{ display: "flex", alignItems: "stretch", flexFlow: "row", borderBottom: "1px solid var(--main-txt-color)" }}>
				<Popover
					positions={["bottom"]}
					isOpen={noRolesProvided || wrongRolesProvided}
					content={
						<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px" }}>
							<div className="requirementAlert">
								<FontAwesomeIcon icon={solid("exclamation")} />
							</div>
							&nbsp;
							Requerido:
							{
								noRolesProvided
									?
									"Agreque al menos un rol"
									:
									"Debe incluirse al menos un plantel para cada rol"
							}
						</div>
					}>
					<button
						disabled={idleLocked || currentTab == "roles"}
						style={{ flex: 1 }}
						className="btn btn-default"
						onClick={() => { setCurrentTab("roles") }}>
						<CustomIcon name="role" />
						<span
							className="d-none d-sm-inline-block"
							style={{ paddingLeft: "10px" }}>
							Roles
						</span>
					</button>
				</Popover>
				<Popover
					positions={["bottom"]}
					isOpen={unfilledMainData && currentTab == "roles"}
					content={
						<div style={{ background: "var(--main-bg)", padding: "5px 5px 0px 5px" }}>
							<div className="requirementAlert">
								<FontAwesomeIcon icon={solid("exclamation")} />
							</div>
							&nbsp;
							Requerido: Aun hay información sin completar
						</div>
					}>
					<button
						disabled={idleLocked || currentTab == "mainInfo"}
						style={{ flex: 1, display: false? 'none' : 'block' }}
						className="btn btn-default"
						onClick={() => { setCurrentTab("mainInfo") }}>
						<FontAwesomeIcon icon={solid("user")} />
						<span
							className="d-none d-sm-inline-block"
							style={{ paddingLeft: "10px" }}>
							Información principal
						</span>
					</button>
				</Popover>
			</div>
			<Tabs defaultActiveKey="roles" activeKey={currentTab!!} onSelect={setCurrentTab} id="employeeform"
			  className="mb-3 hidden displayNone"
			>
				<Tab eventKey="roles" title="">{
					editableEmployee != null
						?
					<>
						{
							roles.length <= editableEmployee.roles.length ? "" : <>
								{campuses.length > 1 ? "" : "Plantel: " + campuses[0].name}
								<div style={{padding : "0px 15px"}} className="nestedSelection">
									<Multiselect displayValue="name" placeholder="Seleccione..." id="role" ref={roleSelRef}
									  disable={idleLocked} onSelect=
									  {
										(roles)=>
										{
											setEditableEmployee({...editableEmployee, roles : roles})
											setValidRoles(validRoles + 1)
										}
									}
									  onRemove={(tags) => setEditableEmployee({...editableEmployee, roles : roles})}
									  emptyRecordMsg="No hay más opciones" selectedValues={[...editableEmployee.roles]}
									  options={roles}
									/>
								</div>
								<hr />
							</>
						}
						{
							{...editableEmployee}.roles.map
							(
								(role : any, index : number)=>
								{
									if(role)
									{
										return<div key={"sR_" + role.id} style={{padding : "5px 10px"}}>
											<div style={{background : "#F5F5F5", borderRadius : (campuses.length > 1 ? "15px 15px 0px 0px" : "15px")}}>
												<table style={{width : "100%"}}><tbody><tr>
													<td style={{padding : "8px 15px", width : "100%"}}>
														{role?.name}
													</td>
													<td><button className="btn btn-default" disabled={idleLocked}
													  type="button" onClick=
													  {
														()=>
														{
															let roles        = editableEmployee.roles
															let passingRoles = 0

															roles.splice(index, 1)

															if(roleSelRef && roleSelRef.current)
															{
																roleSelRef
																	.current
																	.resetSelectedValues()
																	.then
																	(
																		()=>
																		{
																			setEditableEmployee
																			({
																				...editableEmployee, roles : roles.map
																				(
																					(roleItem : any)=>
																					{
																						if(roleItem.valid)
																						{
																							passingRoles++
																						}

																						return roleItem
																					}
																				)
																			})

																			setValidRoles(passingRoles)
																		}
																	)
															}
														}
													  }
													>
														<FontAwesomeIcon icon={solid("times")} />
													</button></td>
												</tr></tbody></table>
											</div>
											{
												(campuses.length < 2) ? "" : <fieldset
												  style={{borderTop : "unset", borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px", position : "relative"}}
												>
													<legend style={{paddingLeft : "15px", position : "absolute"}}>
														<label style={{position : "relative", paddingTop : "2px"}}>
															Planteles
														</label>
													</legend>
													<div style={{paddingTop : "30px"}}>
														<Multiselect options={campuses} emptyRecordMsg="No hay más opciones"
														  onSelect={(tags : any) => roleCampusesUpdate(role, index, tags)}
														  onRemove={(tags : any) => roleCampusesUpdate(role, index, tags)}
														  selectedValues={[...role.campuses]} placeholder="Especifique..."
														  displayValue="name" disable={idleLocked}
														/>
													</div>
												</fieldset>
											}
										</div>
									}
								}
							)
						}
					</>
						:
					selectedEmployee.roles.map
					(
						(role : any, index : any) => <div key={"sR_" + role.id} style={{padding : "5px 10px"}}>
							<div style=
							  {{
								height       : "38px",
								background   : "#F5F5F5",
								padding      : "8px 15px",
								borderRadius : ((role.campuses && role.campuses.length > 0) ? "15px 15px 0px 0px" : "15px"),
							  }}
							>
								{role.name}
							</div>
							{
								(role.campuses == null || role.campuses.length < 1) ? "" : <fieldset
								  style={{borderTop : "unset", borderRadius : "0px 0px 15px 15px", padding :"0px 10px 10px"}}
								>
									<legend style={{paddingLeft : "15px", position : "absolute"}}><label
									  style={{position : "relative", paddingTop : "5px"}}
									>
										Planteles &emsp;
									</label></legend>
									<div style={{paddingTop : "35px"}}>{
										role.campuses.filter((campus : any) => campus).map
										(
											(campus : any, index : number) => <span className="badge bg-primary rounded-pill"
											  key={"sR_" + role.id + "c" + campus.id}
											>
												{campus.name}
											</span>
										)
									}</div>
								</fieldset>
							}
						</div>
					)
				}</Tab>
				<Tab eventKey="mainInfo" title="">
					<br />
					<UnderlineHeading name={"Datos personales"} />
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableEmployee == null ? "" : "*"}
								Nombres
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee.first_name}
									</div>
										:
									<input
									  style={{width : "100%"}}
									  onChange={handleRegistryChange}
									  ref={firstNameRef}
									  maxLength={50}
									  placeholder="Nombres"
									  type="text"
									  id="first_name"
									  disabled={idleLocked}
									  value={editableEmployee.first_name}
									  name="first_name"
									  onFocus={validateField}
									  onBlur={(event : any) => formatField(event, handleRegistryChange)}
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableEmployee == null ? "" : "*"}
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee.last_name}
									</div>
										:
									<input
									  value={editableEmployee.last_name}
									  onChange={handleRegistryChange}
									  placeholder="Apellidos"
									  style={{width : "100%"}}
									  onFocus={validateField}
									  name="last_name"
									  maxLength={50}
									  type="text"
									  id="last_name"
									  onBlur={(event : any) => formatField(event, handleRegistryChange)}
									  disabled={idleLocked}
									  ref={lastNameRef}
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="identification_type_id">
								Tipo de identificación
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{
											(
												(selectedEmployee && selectedEmployee.identification_type)
													&&
												selectedEmployee.identification_type.name
											)
												|| ""
										}
									</div>
										:
									<>
										<select
										  disabled={idleLocked}
										  id="identification_type_id"
										  className="btn btn-primary text-start"
										  value={editableEmployee.identification_type_id}
										  name="identification_type_id" style={{height : "30px"}}
										  onChange=
										  {
											(event : any)=>
											{
												setEditableEmployee
												({
													...editableEmployee, identification_type_id : event.target.value,
													identification_code : !basicInfo
														.identificationTypeRef["IT_" + event.target.value]
														.pattern
														.regexp
														.test(editableEmployee.identification_code)
																?
															""
																:
															editableEmployee.identification_code
												})
											}
										  }
										>{
											basicInfo.identificationTypes?.map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableEmployee == null ? "" : "*"}
								Código de identificación
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee.identification_code || "-"}
									</div>
										:
									<input
									  onFocus={validateField}
									  disabled={idleLocked}
									  placeholder="Código"
									  type="text"
									  value={editableEmployee.identification_code}
									  name="identification_code"
									  ref={identificationCodeRef}
									  id="identification_code"
									  onBlur={validateField}
									  style={{width : "100%"}}
									  data-description={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].description}
									  pattern={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].pattern.value}
									  minLength={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].min_length}
									  maxLength={basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].max_length}
									  onChange=
									  {
										(event : any)=>
										{
											if
											(
												(
													basicInfo.identificationTypeRef["IT_" + editableEmployee.identification_type_id].bool_only_digits > 0
														? /[0-9]*/
															: /[a-zA-Z0-9\-]*/
												)
												.test(event.target.value)
											)
											{
												setEditableEmployee({...editableEmployee, identification_code : event.target.value})
												validateField(event)
											}
										}
									  }
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="name">
								Fecha de nacimiento
							</label>
							<div style={{paddingTop : "18px"}}>{
								editableEmployee == null
										?
									(
										(selectedEmployee == null || isEmpty(selectedEmployee.birthdayValue)) ? "-" : toAscendingDate
											(selectedEmployee.birthdayValue)
									)
										:
									<LeDatePicker
									  onBlur={validateField}
									  value={editableEmployee.birthdayValue}
									  months={monthRef}
									  name="birthdayValue"
									  id="birthdayValue"
									  maxDate={maxDate}
									  years={yearsToAlmostPresent}
									  onFocus={validateField}
									  disable={idleLocked}
									  minDate={minDate}
									  onChange={handleRegistryChange}
									  required
									/>
							}</div>
						</div></div>
						<div className="col-md-6"><div className="selectContainer">
							<label htmlFor="gender_id">
								Género
							</label>
							{
								editableEmployee == null
									?
								<div className="roView">
									<br />
									{selectedEmployee?.gender?.name}
								</div>
									:
								<>
								<select
								  name="gender_id"
								  disabled={idleLocked}
								  className="btn btn-primary text-start"
								  onChange={handleRegistryChange}
								  style={{height : "30px"}}
								  value={editableEmployee.gender_id}
								  id="gender_id"
								  onBlur={validateField}
								  onFocus={validateField}
								  required
								>
									<option value="">
										Seleccione...
									</option>
									{
										basicInfo.genders?.map
										(
											(record : any, i : any) => <option key={i} value={record.id}>
												{record.name}
											</option>
										)
									}
								</select>
								<div className="iconRight" style={{pointerEvents : "none"}}>
									<CustomIcon name="rowDown" />
								</div>
							</>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableEmployee == null ? "" : "*"}
								Teléfono 1
							</label>
							{
								editableEmployee == null
									?
								<div className="roView">
									<br />
									{selectedEmployee?.phone1 || "-"}
								</div>
									:
								<input
								  onFocus={validateField}
								  id="phone1" type="tel"
								  placeholder="Teléfono"
								  minLength={8}
								  maxLength={50}
								  onBlur={(event : any) => formatField(event, handleRegistryChange)}
								  ref={phone1Ref}
								  value={editableEmployee.phone1}
								  disabled={idleLocked}
								  style={{width : "100%"}}
								  name="phone1"
								  onKeyDown={(event: any) => {
									if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
										event.preventDefault()
									}
								}}
								  onChange=
								  {
									(event : any)=>
									{
										if(isNaN(event.target.value))
										{
											event.preventDefault()
										}
										else
										{
											setEditableEmployee({...editableEmployee, phone1 : event.target.value})
										}
									}
								  }
								  required
								/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Teléfono 2
							</label>
							{
								editableEmployee == null
									?
								<div className="roView">
									<br />
									{selectedEmployee?.phone2 || "-"}
								</div>
									:
								<input onFocus={validateField}
								  id="phone2"
								  name="phone2"
								  maxLength={50}
								  onBlur={(event : any) => formatField(event, handleRegistryChange)}
								  ref={phone2Ref}
								  value={editableEmployee.phone2}
								  disabled={idleLocked}
								  placeholder="Teléfono"
								  type="tel"
								  minLength={8}
								  style={{width : "100%"}}
								  onKeyDown={(event: any) => {
									if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
										event.preventDefault()
									}
								}}
								  onChange=
								  {
									(event : any)=>
									{
										if(isNaN(event.target.value))
										{
											event.preventDefault()
										}
										else
										{
											setEditableEmployee({...editableEmployee, phone2 : event.target.value})
										}
									}
								  }
								/>
							}
						</div></div>
					</div>
					<br />
					<UnderlineHeading name={"Ubicación"} />
					<div className="row">
						<div className="col-md-6">
							<div className="selectContainer"><label htmlFor="nationality_id">
								{editableEmployee ? "*" : ""}
								Nacionalidad
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee?.nationality?.name || "-"}
									</div>
										:
									<>
										<select value={editableEmployee.nationality_id} ref={nationalitySelRef}
										  onChange={handleRegistryChange} disabled={idleLocked}
										  id="nationality_id" name="nationality_id" className="btn btn-primary text-start"
										  style={{height : "30px"}}
										>{
											basicInfo.countries?.filter((country : any) => country.bool_campus_exclusive_use < 1).map
											(
												(record : any, i : any) => <option key={i} value={record.id}>
													{record.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
							}
						</div></div>
						<div className="col-md-6">
							<div className="selectContainer">
								<label htmlFor="country_id">
									{editableEmployee == null ? "" : "*"}
									País de residencia
								</label>
								{
									editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee?.country?.name || "-"}
									</div>
										:
									<>
										<select
										  className="btn btn-primary text-start"
										  value={editableEmployee.country_id}
										  style={{height : "30px"}}
										  disabled={idleLocked}
										  ref={countrySelRef}
										  name="country_id"
										  id="country_id"
										  onChange=
										  {
											async(event : any) => {
												const newCountryId = Number(event.target.value);
												const isMentor = editableEmployee.roles?.some((role: any) => role.code === 'mentor');
												
												setEditableEmployee
												({
													...editableEmployee, 
													country_id : newCountryId,
													state_id   : "",
													state_name : "",
													city       : null,
													city_name  : "",
													employee   : {
														...editableEmployee.employee,
														...(isMentor ? {
															bank_id     : "",
															currency_id : "",
															bank_clabe  : newCountryId === 1 ? editableEmployee.employee?.bank_clabe : ""
														} : {})
													}
												})
												if (isMentor) {
													let banks = await getBanksByCountry(newCountryId);
													setBanks(banks);
												}
											}
										  }
										>{
											basicInfo.countries?.filter((country : any) => country.bool_campus_exclusive_use < 1 && [1, 2, 60].includes(country.id)).map
											(
												(country : any, i : any) => <option key={i} value={country.id}>
													{country.name}
												</option>
											)
										}</select>
										<div className="iconRight" style={{pointerEvents : "none"}}>
											<CustomIcon name="rowDown" />
										</div>
									</>
								}
							</div>
						</div>
						<div className="col-md-6">
							<div style={{margin : "unset", width : "100%"}}
							  className=
							  {
								(
									(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id]?.states?.length < 1)
										||
									(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="state_id">
									{editableEmployee == null ? "" : "*"}
									Estado/Depto./Provincia...
								</label>
								<div className="roView" style={{width : "100%"}}>{
									editableEmployee == null
											?
										<>
											<br />
											{
												(
													(selectedEmployee && selectedEmployee.state)
															?
														selectedEmployee.state.name
															:
														selectedEmployee.state_name
												)
													|| "-"
											}
										</>
											:
										(
											(
												(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id]?.states?.length < 1)
													||
												(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length < 1)
											)
													?
												<>
													<br />
													<input
													  type="text"
													  maxLength={50}
													  id="state_name"
													  name="state_name"
													  ref={stateNameRef}
													  disabled={idleLocked}
													  onBlur={(event : any) => formatField(event, handleRegistryChange)}
													  onFocus={validateField}
													  style={{position : "relative", width : "100%"}}
													  onChange={handleRegistryChange}
													  value={editableEmployee.state_name}
													  required
													/>
												</>
													:
												<>
													<select
													  id="state_id"
													  name="state_id"
													  ref={stateSelRef}
													  disabled={idleLocked}
													  onBlur={validateField}
													  onFocus={validateField}
													  style={{height : "30px"}}
													  className="btn btn-primary text-start"
													  defaultValue={editableEmployee.state_id}
													  onChange=
													  {
														(event : any) => setEditableEmployee
														({
															...editableEmployee, state_id : event.target.value,
															state_name                    : "",
															city                          : null,
															city_name                     : ""
														})
													  }
													  required
													>
														<option value="">
															&emsp;
															Seleccione...
														</option>
														{
															basicInfo.countryRef["C_" + editableEmployee.country_id] &&
															basicInfo.countryRef["C_" + editableEmployee.country_id].states?.map
															(
																(record : any, i : number) => <option
																  value={record.id}
																  key={record.id}
																>
																	{record.name}
																</option>
															)
														}
													</select>
													<div className="iconRight" style={{pointerEvents : "none"}}>
														<CustomIcon name="rowDown" />
													</div>
												</>
										)
								}</div>
							</div>
						</div>
						<div className="col-md-6">
							<div className=
							  {
								(
									(editableEmployee == null && basicInfo.countryRef["C_" + selectedEmployee.country_id]?.states?.length < 1)
										||
									(editableEmployee && basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length < 1)
								)
									? "inputContainer"
										: "selectContainer"
							  }
							>
								<label htmlFor="city">
									{editableEmployee == null ? "" : "*"}
									Ciudad/Municipio/Distrito
								</label>
									<div className="roView" style={{width : "100%", paddingTop : "15px"}}>{
										editableEmployee == null
												?
											<div style={{paddingTop : "10px"}}>{
												(
													(
														selectedEmployee && selectedEmployee.city
															? selectedEmployee.city.name
																: selectedEmployee.city_name
													) || "-"
												)
											}</div>
												:
											(
												(
													(
														basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length > 0
															&&
														(editableEmployee.state_id == null || basicInfo.countryRef["C_" + editableEmployee.country_id]?.stateRef["S_" + editableEmployee.state_id] == null)
													)
														||
													(
														basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length < 1
															&&
														isEmpty(editableEmployee.state_name)
													)
												)
													?
														<div style={{paddingTop : "10px"}}>
															&lt;Proporcione estado &gt;
														</div>
													:
												(
													(
														basicInfo.countryRef["C_" + editableEmployee.country_id]?.states?.length < 1
															||
														basicInfo.countryRef["C_" + editableEmployee.country_id]?.stateRef["S_" + editableEmployee.state_id]?.cities?.length < 1
													)
															?
														<input
														  type="text"
														  maxLength={50}
														  id="city_name"
														  name="city_name"
														  ref={cityNameRef}
														  disabled={idleLocked}
														  onBlur={(event : any) => formatField(event, handleRegistryChange)}
														  onFocus={validateField}
														  style={{width : "100%"}}
														  onChange={handleRegistryChange}
														  value={editableEmployee.city_name}
														  required
														/>
															:
														<Popover
														  positions={["bottom"]}
														  isOpen={noCityProvided}
														  content=
														  {<div style={{background : "var(--main-bg)", padding : "5px 5px 0px 5px"}}>
															<div className="requirementAlert">
																<FontAwesomeIcon icon={solid("exclamation")}/>
															</div>
															&nbsp;
															Requerido: Elija una opción
														  </div>}
														>
															<div style={{width : "100%"}}>
																<SelectReact
																  id="city"
																  name="city"
																  placeholder="Ciudad"
																  disabled={idleLocked}
																  value={editableEmployee.city}
																  onChange={handleRegistryChange}
																  children={Object.values(basicInfo.countryRef["C_" + editableEmployee.country_id]?.stateRef["S_" + editableEmployee.state_id]?.cityRef)}
																/>
															</div>
														</Popover>
												)
											)
									}</div>
							</div>
						</div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								{editableEmployee == null ? "" : "*"}
								Dirección actual
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee.address || "-"}
									</div>
										:
									<input
									  value={editableEmployee.address}
									  onChange={handleRegistryChange}
									  name="address" maxLength={255}
									  placeholder="Dirección actual"
									  style={{width : "100%"}}
									  onFocus={validateField}
									  onBlur={(event : any) => formatField(event, handleRegistryChange)}
									  disabled={idleLocked}
									  pattern="(?=.*\d)\S+(\s+\S+)+"
									  data-description="La dirección debe conformarse por al menos 2 (dos) palabras y contener al menos 1 (un) dígito"
									  type="text"
									  ref={addressRef}
									  id="address"
									  required
									/>
							}
						</div></div>
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="name">
								Código postal
							</label>
							{
								editableEmployee == null
										?
									<div className="roView">
										<br />
										{selectedEmployee.zipcode || "-"}
									</div>
										:
									<input onBlur={validateField} max={999999999} step={1} name="zipcode" type="number"
									  onChange={handleRegistryChange} placeholder="Código postal" min={10000} id="zipcode"
									  value={editableEmployee.zipcode} onFocus={validateField} style={{width : "100%"}}
									  ref={zipcodeRef}
									/>
							}
						</div></div>
						{
							Object.keys(linkInfo).map
							(
								(record : any, index : number) => <div className="col-md-6" key={record}><div
								  className="inputContainer"
								>
									<label htmlFor={record}>
										{linkInfo[record]}
									</label>
									{
										editableEmployee == null
											?
										<div className="roView">
											<br />
											{(selectedEmployee && selectedEmployee[record]) || "-"}
										</div>
											:
										<input value={editableEmployee[record]} maxLength={50}
										  style={{width : "100%"}} placeholder={linkInfo[record]}
										  onChange={handleRegistryChange}
										  type="text" name={record} disabled={idleLocked}
										  id={record}
										/>
									}
								</div></div>
							)
						}
					</div>
					<div className="row">
						<div className="col-md-6"><div className="inputContainer">
							<label htmlFor="email">
								{editableEmployee == null ? "" : "*"}
								Correo electrónico
							</label>
							{
								editableEmployee == null
									?
								<div className="roView">
									<br />
									{selectedEmployee.email}
								</div>
									:
								<input onChange={handleRegistryChange} placeholder="Correo electrónico"
								  onBlur={validateField} ref={emailRef} id="email"
								  value={editableEmployee.email} maxLength={50} style={{width : "100%"}} name="email"
								  onFocus={validateField} disabled={idleLocked}
								  type="email" required
								/>
							}
						</div></div>
						{
							editableEmployee == null ? "" : <div className="col-md-6"><div className="inputContainer">
								<label htmlFor="name">
									{editableEmployee.id == null ? "*" : ""}
									Contraseña
								</label>
								{
									editableEmployee == null
											?
										<div className="roView">
											<br />
											{selectedEmployee.password}
										</div>
											:
											<div style={{display: "flex", justifyContent: "center"}}>
												<input value={editableEmployee.password} name="password" maxLength={50}
												  required={editableEmployee.id == null} minLength={6} ref={passwordRef}
												  placeholder="Contraseña" style={{ width: "100%" }} id="password"
												  type={showPassword ? "text" : "password"} // Toggle between "text" and "password"
												  onFocus={(event) => {if(editableEmployee.id == null)validateField(event)}}
												  onBlur={(event) => {if(editableEmployee.id == null)validateField(event)}}
												  onChange={handleRegistryChange} disabled={idleLocked}
												/>
												{
													editableEmployee.id ? <></> : <button disabled={idleLocked}
													  style={{position : "absolute", bottom : 0, border : "transparent", backgroundColor : "transparent"}}
													  type="button" onClick={toggleShowPassword}
													>
														<FontAwesomeIcon icon={solid("eye")} flip="horizontal"/>
													</button>
												}
										</div>
								}
							</div></div>
						}
					</div>
					<br />
					{
						((editableEmployee && editableEmployee.roles && editableEmployee.roles.some((role : any) => role.code === 'mentor') || selectedEmployee && selectedEmployee.roles && selectedEmployee.roles.some((role : any) => role.code === 'mentor')))?
						<>
							<UnderlineHeading name={"Datos bancarios"} />
							<div className="row">
								<div className="col-md-6">
									<div className="selectContainer">
										<label htmlFor="employee.bank_id">
											{editableEmployee == null ? "" : "*"}
											Banco
										</label>
										{
											editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee?.employee?.bank?.name || "-"}
											</div>
												:
											<>
												<select
												className="btn btn-primary text-start"
												value={editableEmployee.employee?.bank_id}
												style={{height : "30px"}}
												disabled={idleLocked || !editableEmployee.country_id}
												name="employee.bank_id"
												id="employee.bank_id"
												onBlur={validateField}
												onFocus={validateField}
												onChange={(e) => setEditableEmployee({
													...editableEmployee, 
													employee: {
														...editableEmployee.employee,
														bank_id: e.target.value
													}
												})}
												required
												>
													<option value="">Seleccione...</option>
													{
														banks.map
														(
															(bank : any) => <option key={bank.id} value={bank.id}>
																{bank.name}
															</option>
														)
													}
												</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="inputContainer">
										<label htmlFor="employee.bank_account">
											{editableEmployee == null ? "" : "*"}
											Cuenta bancaria
										</label>
										{
											editableEmployee == null
												?
											<div className="roView">
												<br />
												{selectedEmployee?.employee?.bank_account || "-"}
											</div>
												:
											<input
											value={editableEmployee.employee?.bank_account}
											onChange={(e) => setEditableEmployee({
												...editableEmployee, 
												employee: {
													...editableEmployee.employee,
													bank_account: e.target.value
												}
											})}
											name="employee.bank_account"
											maxLength={16}
											placeholder="Cuenta bancaria"
											style={{width : "100%"}}
											type="text"
											id="employee.bank_account"
											disabled={idleLocked}
											onBlur={validateField}
											onFocus={validateField}
											required
											pattern="[a-zA-Z0-9]{0,16}"
											onKeyDown={(event: any) => {
												if (!/[a-zA-Z0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
													event.preventDefault()
												}
											}}
											/>
										}
									</div>
								</div>
								{(editableEmployee?.country_id === 1 || (!editableEmployee && selectedEmployee?.country_id === 1)) && (
									<div className="col-md-6">
										<div className="inputContainer">
											<label htmlFor="employee.bank_clabe">
												{editableEmployee == null ? "" : "*"}
												Clabe bancaria
											</label>
											{
												editableEmployee == null
													?
												<div className="roView">
													<br />
													{selectedEmployee?.employee?.bank_clabe || "-"}
												</div>
													:
												<input
												value={editableEmployee.employee?.bank_clabe}
												onChange={(e) => setEditableEmployee({
													...editableEmployee, 
													employee: {
														...editableEmployee.employee,
														bank_clabe: e.target.value
													}
												})}
												name="employee.bank_clabe"
												maxLength={18}
												minLength={18}
												placeholder="Clabe bancaria"
												style={{width : "100%"}}
												type="text"
												id="employee.bank_clabe"
												disabled={idleLocked}
												pattern="[0-9]{18}"
												onBlur={validateField}
												onFocus={validateField}
												onKeyDown={(event: any) => {
													if (!/[0-9]/.test(event.key) && event.key !== 'Backspace' && event.key !== 'Delete' && event.key !== 'ArrowLeft' && event.key !== 'ArrowRight') {
														event.preventDefault()
													}
												}}
												required
												/>
											}
										</div>
									</div>
								)}
								<div className="col-md-6">
									<div className="selectContainer">
										<label htmlFor="employee.currency_id">
											{editableEmployee == null ? "" : "*"}
											Moneda
										</label>
										{
											editableEmployee == null
												?
											<div className="roView">
												<br />
												{`${selectedEmployee?.employee?.currency?.code} - ${selectedEmployee?.employee?.currency?.name}` || "-"}
											</div>
												:
											<>
												<select
												  className="btn btn-primary text-start"
												  value={editableEmployee.employee?.currency_id}
												  style={{height: "30px"}}
												  disabled={idleLocked}
												  name="employee.currency_id"
												  id="employee.currency_id"
												  onBlur={validateField}
												  onFocus={validateField}
												  onChange=
												  {
													(e)=>setEditableEmployee
													({
														...editableEmployee, 
														employee: {
															...editableEmployee.employee,
															currency_id: e.target.value
														}
													})
												  }
												  required
												>
													<option value="">Seleccione...</option>
													{currencies?.map((currency: any) => (
														<option 
															key={currency.id} 
															value={currency.id}
															selected={currency.id === getDefaultCurrency(editableEmployee.country_id)}
														>
															{currency.code} - {currency.name}
														</option>
													))}
												</select>
												<div className="iconRight" style={{pointerEvents : "none"}}>
													<CustomIcon name="rowDown" />
												</div>
											</>
										}
									</div>
								</div>
								<div className="col-md-6">
									<div className="" style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
										<label htmlFor="bool_accounting" style={{ marginRight: '10px', flex: '1', position: 'relative' }}>
											Factura
										</label>
										{
											editableEmployee == null
												?
											<div className="roView" style={{ flex: '1' }}>
												{selectedEmployee?.employee?.bool_accounting ? "Sí" : "No"}
											</div>
												:
												<div>
												<Form.Switch
													id="bool_accounting"
													name="bool_accounting"
													className="employee-invoice-switch"
													checked={editableEmployee.employee?.bool_accounting || false}
													onChange={(e) => setEditableEmployee({
														...editableEmployee, 
														employee: {
															...editableEmployee.employee,
															bool_accounting: e.target.checked
														}
													})}
													disabled={idleLocked}
													style={{
														margin: '0',
														cursor: 'pointer',
													}}
												/>
												<span style={{fontSize: '12px'}}>No / Sí</span>
											</div>
										}
									</div>
								</div>
							</div>
							<br />
						</>
						:
						null
					}
					<UnderlineHeading name={"Observaciones"} />
					{
						editableEmployee == null ? (selectedEmployee.observations || "-") : <textarea
						  id="observations"
						  onChange={handleRegistryChange}
						  onBlur={(event : any) => formatField(event, handleRegistryChange)}
						  value={editableEmployee.observations}
						  className="notesField form-control"
						  disabled={idleLocked}
						  ref={observationsRef}
						  name="observations"
						/>
					}
				</Tab>
			</Tabs>
		</div>
	</div></div></form></DataDisplayModal>
}

export default EmployeeInfoModal
