//imports
import { default as SelectComponent } from "react-select";
import { FiltersViewProps, Filter } from "./interfaces";
import Constants from "../../../../../constants";

//styles
import "./styles.scss";

const FiltersView = ({
    filters
}: FiltersViewProps) => {

    return (
        <>
            <div className="attendancesFilters flexCenter">
                <div className="attendancesFiltersContent d-flex align-items-center">
                    {
                        filters.map((filter: Filter, i: number) =>
                            <div
                                key={i}
                                className="filterCard d-flex align-items-start">
                                <h5 className="title">
                                    {filter.name + ':'}
                                </h5>
                                <SelectComponent
                                    options = {filter.options}
                                    onChange = {(newValue: any)=>{
                                        filter.onChange(newValue)
                                    }}
                                    isDisabled = {filter.disable}
                                    value = {filter.value}
                                    classNames={{
                                        control: (state) => ("selectControl"),
                                        dropdownIndicator: (state) => ("selectDropdownIndicator"),
                                        option: (state) => ("selectOption")
                                    }}
                                ></SelectComponent>
                            </div>
                        )
                    }
                </div>
            </div>
        </>
    )
}

export default FiltersView