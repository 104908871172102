import axios
	from "axios"
import store
	from "./../../globals"
import _
	from "lodash"

import {isNotEmpty, removeRecords, toAcceptableDate}
	from "./../../common"
import {academicDegree, studentStatus}
	from "./../../constants"

const RESOURCE_URL = process.env.REACT_APP_API_URL +"/admin/campus/"

class StudentsService
{
	sessionToken = store.getState().sessionToken.value
	basicInfo = store.getState().basicInfo.value
	campusId = store.getState().currentUserCampusId.value
	currentUser = store.getState().userProfile.value

	constructor()
	{
		store.subscribe
		(
			()=>
			{
				this.sessionToken = store.getState().sessionToken.value
				this.basicInfo = store.getState().basicInfo.value
				this.campusId = store.getState().currentUserCampusId.value
				this.currentUser = store.getState().userProfile.value
			}
		)
	}

	async searchStudents
		(campusId : number, search? : any, page? : number, resultsLimit? : number, expand? : string)
	{
		let params = []
		let user : any = this.currentUser

		if(page)
		{
			params.push("page=" + page)
		}
		else
		{
			if(resultsLimit)
			{
				params.push("limit=" + resultsLimit)
			}
		}

		if(expand != null)
		{
			params.push("expand=" + expand)
		}

		if(search.statusId >= 0 || user.currentRole != "superAdmin")
		{
			params.push("statusId=" + (user.currentRole != "superAdmin" ? studentStatus.ACTIVE : search.statusId))
		}

		if(search)
		{

			if(isNotEmpty(search.tags))
			{
				params.push("tagIds=" + _.map(search.tags, "id").join(","))
			}

			if(isNotEmpty(search.monthlyBalance) && !isNaN(search.monthlyQuantity))
			{
				params.push("monthlyBalance=" + search.monthlyBalance)
				params.push("monthlyQuantity=" + search.monthlyQuantity)
			}

			if(isNotEmpty(search.kitsBalance) && !isNaN(search.kitsQuantity))
			{
				params.push("kitsBalance=" + search.kitsBalance)
				params.push("kitsQuantity=" + search.kitsQuantity)
			}

			if(search.activeSchedule < 2)
			{
				params.push("activeSchedule=" + search.activeSchedule)
			}

			if(isNotEmpty(search?.q))
			{
				params.push("q=" + search.q)
			}
		}

			

		return axios.get
		(
			RESOURCE_URL + campusId + "/students" + (params.length > 0 ? "?" + params.join("&") : ""),
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async getStudentsCount(campusId : number)
	{
		let params = [];

		return axios.get
		(
			RESOURCE_URL + campusId + "/students/_count",
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	async getStudentInfo(studentId : number)
	{
		return axios.get
		(
			RESOURCE_URL + this.campusId + "/students/" +studentId,
			{
				headers:
				{
					"Content-Type" : "application/json",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
		.then
		(
			response=>
			{
				return response;
			}
		);
	}

	saveStudent(user : any, statusIdUpdate? : boolean)
	{
		let payload : FormData = new FormData()

		let record : any=
		{
			nationalityId        : user.nationality_id,
			countryId            : user.country_id,
			genderId             : user.gender_id,
			identificationTypeId : user.identification_type_id + "",
			identificationCode   : user.identification_code + "",
			firstName            : user.first_name + "",
			lastName             : user.last_name + "",
			academicDegreeId     : user.academic_degree_id,
			lastSchoolName       : user.last_school_name + "",
			currentOccupation    : user.current_occupation + "",
			email                : user.email + "",
			contacts             : JSON.stringify(user.contacts),
			links                : JSON.stringify
			({
				facebook  : user.facebook  || "",
				instagram : user.instagram || "",
			})
		}

		for(let field of ["password", "observations", "zipcode", "address", "phone1", "phone2"])
		{
			if(isNotEmpty(user[field]))
			{
				record[field] = user[field] + ""
			}
		}

		if(isNotEmpty(user.id_clase_365))
		{
			record.idClase365 = user.id_clase_365 + ""
		}

		if(isNotEmpty(user.correlativo_clase_365))
		{
			record.correlativoClase365 = user.correlativo_clase_365 + ""
		}

		if(user.birthdayValue)
		{
			record.birthday = toAcceptableDate(user.birthdayValue)
		}

		if(record.academicDegreeId == academicDegree.OTHER)
		{
			record.specificAcademicDegree = user.specific_academic_degree + ""
		}

		if(this.basicInfo.countryRef["C_" + user.country_id].states.length < 1)
		{
			if(isNotEmpty(user.state_name))
			{
				record.stateName = user.state_name + ""
			}

			if(isNotEmpty(user.city_name))
			{
				record.cityName = user.city_name + ""
			}
		}
		else
		{
			record.stateId = user.state_id

			if(this.basicInfo.countryRef["C_" + user.country_id].stateRef["S_" + user.state_id].cities.length < 1)
			{
				if(isNotEmpty(user.city_name))
				{
					record.cityName = user.city_name + ""
				}
			}
			else
			{
				record.cityId = user.city.id
			}
		}

		if(statusIdUpdate)
		{
			record.statusId = user.status_id
		}

		if(user.tags.length > 0)
		{
			record.tagIds = _.map(user.tags, "id").join(",")
		}

		Object.keys(record).map((key : string) => {payload.append(key, record[key])})

		if(user.image)
		{
			payload.append("image", user.image == user.imagePreview ? "" : user.image)
		}

		return axios.post
		(
			RESOURCE_URL + this.campusId + "/students" +
			(
				user.id == null
					? ""
						: "/" + user.id + "?_method=PUT"
			),
			payload,
			{
				headers:
				{
					Accept         : "application/json, text/plain, */*",
					"Content-Type" : "multipart/form-data",
					Authorization  : "Bearer " + this.sessionToken
				}
			}
		)
	}

	async getStudentById(campusId : number, id: number)
	{
		try
		{
			let response = await axios.get
			(
				RESOURCE_URL + campusId + "/students/" + id,
				{
					headers:
					{
						"Content-Type"  : "application/json",
						"Authorization" : "Bearer " + this.sessionToken
					}
				}
			)

			return response
		}
		catch(err)
		{
			console.log(err)
		}
	}

	async removeStudents(campusId : number, recordIds : any)
	{
		return removeRecords(RESOURCE_URL +campusId + "/students", recordIds)
	}
}

export default new StudentsService()