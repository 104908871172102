import { solid } from "@fortawesome/fontawesome-svg-core/import.macro";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useSelector } from "react-redux";
import { RootState } from "../../../globals";
import Constants, { REPORT_TYPES } from "../../../constants";
import { ReportType } from "../../../types";

export interface ReportButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    onClick: () => void;
    reportType: ReportType;
}

const ReportButtonComponent: React.FC<ReportButtonProps> = ({
    onClick,
    reportType = 1,
    disabled
}) => {

    const idleLocked: boolean = useSelector((state: RootState) => state.idleLockSwitch.value);

    const renderButtonType = () => {
        let content = null;
        switch (reportType) {
            case REPORT_TYPES.XLSX:
                content = <button
                    style={{
                        width: "225px",
                        textAlign: "left"
                    }}
                    onClick={() => onClick()}
                    className="btn btn-success"
                    disabled={idleLocked || disabled}
                    type="button"
                >
                    <FontAwesomeIcon icon={solid("file-excel")} />
                    <span style={{ paddingLeft: "10px" }}>
                        Descargar reporte (.xlsx)
                    </span>
                </button>;
                break;
            case REPORT_TYPES.PDF:
                content = <button
                    style={{
                        width: "100%",
                        textAlign: "left"
                    }}
                    onClick={() => onClick()}
                    className="btn btn-danger"
                    disabled={idleLocked || disabled}
                    type="button"
                >
                    <FontAwesomeIcon icon={solid("file-pdf")} />
                    <span style={{ paddingLeft: "10px" }}>
                        {Constants.Common.pdfReport}
                    </span>
                </button>;
                break;
        }
        return content;
    }

    return (renderButtonType())
}

export default ReportButtonComponent;