import { axiosInstance } from "./axiosInstance";

const RESOURCE_URL_V2 = process.env.REACT_APP_API_URL_V2 + "/admin/banks/";

export class BanksService {

    getBanksByCountry = async (countryId: number) => {
        let uri = `${RESOURCE_URL_V2}country/${countryId}`;
        let response = await axiosInstance.get(uri);
        return response;
    }

}