import React, {useState, useEffect, forwardRef, ForwardedRef}
	from "react"
import {FontAwesomeIcon}
	from "@fortawesome/react-fontawesome"
import {solid, regular}
	from "@fortawesome/fontawesome-svg-core/import.macro"
import {useDispatch, useSelector}
	from "react-redux"
import _
	from "lodash"
import {Tooltip}
	from "react-bootstrap"
import OverlayTrigger
	from "react-bootstrap/OverlayTrigger"
import {Popover}
	from "react-tiny-popover"
import {sprintf}
	from "sprintf-js"
import
{
	RootState,
	enableIdleLockSwitch, disableIdleLockSwitch,
	displayNotice
}
	from "./../../globals"
import LeDatePicker
	from "./../../components/leDatePicker"
import HttpManager
	from "./../../services/HttpManager"
import CoursesService
	from "./../../services/campus/courses.service"
import PeriodsService
	from "./../../services/campus/periods.service"
import BuildingsService
	from "./../../services/campus/buildings.service"
import CalendarService
	from "./../../services/campus/calendar.service"
import EmployeesService
	from "./../../services/campus/employees.service"
import {baseRole, weekDays, monthRef, retardWeekDayOffset, toAcceptableDate, toAscendingDate, count, attendanceStatus, enrollingSet, isEmpty}
	from "./../../common"
import {ENROLLMENT_STATUS, weekDay}
	from"./../../constants"
import CalendarSideFilter, {unrestrictedResultsDisplay}
	from "./calendarSideFilter"
import EnrollmentPrompt
	from "./../management/enrollments/enrollmentPrompt"
import {UserPrompt}
	from "./../../components/sectionContainer"
import {scheduleStatus}
	from "./../../constants"

import "./../../components/dropdown/style.css"

const WeeklyView = ()=>
{
	const COURSES : number = 0
	const MENTORS : number = 1
	const PERIODS : number = 2
	const ENDING  : number = 3

	const rangeFilterRef : any=
	{
		capacity:
		{
			label    : "Capacidad",
			limit    : 125,
			gradient : "#00FF00 0%, #0000FF 56%, #FFFF00 80%, #FF0000 100%"
		},
		attendance:
		{
			label    : "Asistencia",
			limit    : 100,
			gradient : "#FF0000 0%, #FFFF00 50%, #0000FF 75%, #00FF00 100%"
		},
		subsMin:
		{
			label    : "Ins/Min",
			limit    : 125,
			gradient : "#FF0000 0%, #FFFF00 75%, #0000FF 100%"
		},
	}

	const icons : any = [solid("chevron-left"), solid("chevron-right")];

	const [courses, setCourses] = useState<any[]>([])
	const [periods, setPeriods] = useState<any[]>([])
	const [mentors, setMentors] = useState<any[]>([])
	const [classroomRef, setClassroomRef] = useState<any>({})
	const [courseRef, setCourseRef] = useState<any>({})
	const [distributionRef, setDistributionRef] = useState<any>({})
	const [periodRef, setPeriodRef] = useState<any>({})
	const [startDay, setStartDay] = useState<string | null>(null)
	const [endDay, setEndDay] = useState<string | null>(null)
	const [inThisWeek, setInThisWeek] = useState<string | null>(null)
	const [infoShown, setInfoShown] = useState<string | null | undefined>(null)
	const [currentDay, setCurrentDay] = useState<any>(null)
	const dispatch = useDispatch()
	const [initSetup, setInitSetup] = useState<number | null>(null)
	const [totalRecords, setTotalRecords] = useState<number>(0)
	const [currentWeekDays, setCurrentWeekDays] = useState<any>(null)
	const [today, setToday] = useState<any>(null)
	const [data, setData] = useState<any[]>([])
	const [buildings, setBuildings] = useState<any[]>([])
	const [weekDates, setWeekDates] = useState<any[]>([])
	const [yearsRange, setYearsRange] = useState<any>([])
	const [minDate, setMinDate] = useState<any>(null)
	const [maxDate, setMaxDate] = useState<any>(null)
	const [buildingRef, setBuildingRef] = useState<any>({})
	const [selectedUserProfile, setSelectedUserProfile] = useState<any>(null)
	const [selectedEnrollment, setSelectedEnrollment] = useState<any>(null)
	const idleLocked        : boolean = useSelector((state : RootState) => state.idleLockSwitch.value)
	const sessionToken      : string | null = useSelector((state : RootState) => state.sessionToken.value)
	const themeToggle       : boolean = useSelector((state : RootState) => state.themeToggle.value)
	const currentUserCampus : any = useSelector((state : RootState) => state.currentUserCampus.value)
	const campusRef         : any = useSelector((state : RootState) => state.campusRef.value)
	const basicInfo         : any = useSelector((state : RootState) => state.basicInfo.value)
	const [currencySymbol, setCurrencySymbol] = useState<string>(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
	const [userCampus, setUserCampus] = useState<number>(currentUserCampus)
	const [unrestrictedDisplay, setUnrestrictedDisplay] = useState<boolean>(true)
	const [availabilityDisplay, setAvailabilityDisplay] = useState<boolean>(true)
	const [noResultsFound, setNoResultsFound] = useState<boolean>(false)
	const [sectionLoaded, setSectionLoaded] = useState<boolean>(false)
	const [clearSearchRequested, setClearSearchRequested] = useState<boolean>(false)
	const [notThisWeek, setNotThisWeek] = useState<boolean>(false)
	const [notAWeekBefore, setNotAWeekBefore] = useState<boolean>(false)
	const [notAWeekAfter, setNotAWeekAfter] = useState<boolean>(false)
	const defaultSearch : any =
	{
		q : "",
		period : null,
		capacity : [0, 126],
		attendance : [0, 101],
		subsMin : [0, 126],
		buildings : [],
		courses : [], 
		mentors : [],
		onlyPendingTuitions : false,
		onlyPendingKits : false
	}

	const [customSearch, setCustomSearch]       = useState<any>(defaultSearch)
	const [currentSearch, setCurrentSearch]     = useState<any>(defaultSearch)
	const [dayDistribution, setDayDistribution] = useState<any>([])
	const [scheduleRef, setScheduleRef]         = useState<any>([])
	var   errorMessage   : string

	const handleSearchChange = (e : any)=>
	{
		if(e && e.target)
		{
			setCustomSearch({...customSearch, [e.target.name] : e.target.value})
		}
	}

	const infoShownSetting = (key? : any)=>
	{
		setInfoShown(((typeof key != "string") || (infoShown && key == infoShown)) ? null : key)
	}

	const procedureComplaint = (message : string)=>
	{
		dispatch
		(
			displayNotice
			({
				cornerClose : false,
				message     : message,
				heading     : <h3 style={{color : "#FF0000", display : "inline-block"}}>
					Error
				</h3>
			})
		)
	}
	
	const clearSearch = ()=>
	{
		setCustomSearch(defaultSearch)
		setClearSearchRequested(true)
	}
	//FX---------------------------------------------------------------------------------------------------------------------
	useEffect
	(
		()=>
		{
			if(clearSearchRequested && _.isEqual(defaultSearch, customSearch))
			{
				setClearSearchRequested(false)
				search(customSearch.period)
			}
		},
		[customSearch, clearSearchRequested]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				if(currentUserCampus == null)
				{
					procedureComplaint
					(
						"No hay planteles registrados o asignados al rol en uso por el usuario, " +
							"por lo que el acceso a este módulo permanecerá deshabilitado."
					)
				}
				else
				{
					dispatch(enableIdleLockSwitch())

					errorMessage = "La lista de cursos registrados no pudo ser extraída"

					try
					{
						const expand           : number = 1
						const searchParameters : any    = null
						const result           : any    = await CoursesService.searchCourses
							(campusRef[currentUserCampus], searchParameters, expand)

						if(result.status == 200 || result.status == 204)
						{
							let courseIndex : any = {}

							setCourses
							(
								result.data.map
								(
									(course : any)=>
									{
										courseIndex["C_" + course.id] = course

										return{...course, levels : []}
									}
								)
							)

							setCourseRef(courseIndex)
							setInitSetup(COURSES)
						}
						else
						{
							procedureComplaint(errorMessage)
						}
					}
					catch(error)
					{
						console.log(error)
						procedureComplaint(errorMessage)
						dispatch(disableIdleLockSwitch())
					}
				}
			}

			if(sessionToken)
			{
				onLoad()
			}
		},
		[]
	)

	useEffect
	(
		()=>
		{
			const onLoad = async()=>
			{
				switch(initSetup)
				{
					case COURSES:
						errorMessage = "La lista de profesores no pudo ser extraída"

						try
						{
							const result = await EmployeesService.searchEmployees
								({roleIds : [baseRole.MENTOR]})

							if(result.status == 200 || result.status == 204)
							{
								setMentors
								(
									result.data.map
									(
										(mentor : any) => ({ ...mentor, name : mentor.first_name + " " + mentor.last_name})
									)
								)

								setInitSetup(MENTORS)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break
					case MENTORS:
						errorMessage = "La lista de periodos registrados no pudo ser extraída"
					
						try
						{
							const result = await PeriodsService.searchPeriods(campusRef[currentUserCampus].id)

							if(result.status == 200 || result.status == 204)
							{
								let periodIndex : any = {}

								setPeriods
								(
									result.data.map
									(
										(period : any)=>
										{
											periodIndex["P_" + period.id] = period

											return{
												...period, label : period.name +
												" [" +
													monthRef[parseInt(period.start.split("-")[1]) - 1] + " - " +
													monthRef[parseInt(period.end.split("-")[1]) - 1] +
												"]"
											}
										}
									)
								)

								setPeriodRef(periodIndex)
								setInitSetup(PERIODS)
							}
							else
							{
								procedureComplaint(errorMessage)
							}
						}
						catch(error)
						{
							console.log(error)
							procedureComplaint(errorMessage)
							dispatch(disableIdleLockSwitch())
						}
					break
					case PERIODS:
						if(periods.length < 1)
						{
							dispatch(disableIdleLockSwitch())
							procedureComplaint
							(
								"No hay periodos registrados, " +
									"por lo que el acceso a este módulo permanecerá deshabilitado"
							)
						}
						else
						{
							errorMessage = "El listado de edificios y aulas no pudo ser extraído"

							try
							{
								let expand   : string = "classrooms"
								const result : any    = await BuildingsService.searchBuildings
									(campusRef[currentUserCampus].id, {}, expand)

								if(result.status == 200 || result.status == 204)
								{
									let buildingIndex : any = {}

									setBuildings
									(
										result.data.map
										(
											(building : any)=>
											{
												buildingIndex["B_" + building.id] = building

												return {...building, classrooms : []}
											}
										)
									)

									let start      : number =  70
									let end        : number = 220
									let j          : number = 0
									let dayArrange : any    = []
									/*
										Making spaces for half hours from 7:00 to 21:00
										Creating a structure to fit the visual representation that will be used in each classroom.
										Putting a mark where the breaks don't allow locating classs sessions
										(13:00 to 14:00 and 18:00 to 21:00)
									*/
									for(let i : number = start; i < end; i += 5)
									{
										dayArrange.push({ref : null})
									}

									setBuildingRef(buildingIndex)
									setYearsRange(basicInfo.futureRange)
									setDayDistribution(dayArrange)
									setCustomSearch(defaultSearch)
									setInitSetup(ENDING)
								}
								else
								{
									procedureComplaint(errorMessage)
								}
							}
							catch(error)
							{
								console.log(error)
								procedureComplaint(errorMessage)
								dispatch(disableIdleLockSwitch())
							}
						}
					break;
					case ENDING:
						setSectionLoaded(true)
						search(customSearch.period)
					break
				}
			}

			onLoad()
		},
		[initSetup]
	)

	useEffect(() => {setTotalRecords(data.length)}, [data])

	useEffect
	(
		()=>
		{
			if(currentUserCampus && (currentUserCampus != userCampus))
			{
				setSectionLoaded(false)
				setCustomSearch(defaultSearch)
				localStorage.setItem("currentCampus", currentUserCampus)
				setCurrencySymbol(basicInfo.currencyRef["C_" + campusRef[currentUserCampus].company.currency_id].symbol)
				setUserCampus(currentUserCampus)
				setClassroomRef({})
				setScheduleRef({})
				setWeekDates([])
				setInitSetup(COURSES)
			}
		},
		[currentUserCampus, sectionLoaded]
	)
	//-----------------------------------------------------------------------------------------------------------------------
	const periodSearch = (event : any)=>
	{
		if(event && event.target)
		{
			const period = event.target.value

			setCustomSearch({...customSearch, period : period})

			let startYear       : number
			let endYear         : number
			let baseStart       : Date
			let baseEnd         : Date
			let noPeriod        : boolean = period == null || period.id == null
			let currentStartDay : number
			let currentEndDay   : number

			if(noPeriod)
			{
				baseStart = new Date(basicInfo.futureRange[0] + "/01/01 23:00:00")
				baseEnd   = new Date(basicInfo.futureRange[basicInfo.futureRange.length - 1] + "/12/31 23:00:00")
			}
			else
			{
				baseStart = new Date(period.start.replaceAll("-", "/") + " 23:00:00")
				baseEnd   = new Date(period.end.replaceAll("-", "/") + " 23:00:00")
			}

			currentStartDay = retardWeekDayOffset[baseStart.getDay()]
			currentEndDay   = retardWeekDayOffset[baseEnd.getDay()]

			if(currentStartDay != weekDay.MONDAY)
			{
				baseStart.setDate(baseStart.getDate() - currentStartDay)
			}

			if(currentEndDay != weekDay.SUNDAY)
			{
				baseEnd.setDate(baseEnd.getDate() + (weekDay.SUNDAY - currentEndDay))
			}

			startYear = baseStart.getFullYear()
			endYear   = baseEnd.getFullYear()

			setMinDate(baseStart)
			setMaxDate(baseEnd)
			setYearsRange
			(
				startYear == endYear
					? [startYear]
						: Array((endYear - startYear) + 1).fill(undefined).map((element, index) => index + startYear)
			)

			search(period, currentDay)
		}
	}

	const search = async(period : any, date? : any)=>
	{
		const searchError : string  = "La búsqueda no pudo ser completada"
		let   proceedingSearch : boolean = true
		let   newSearch : any = {...customSearch, period : period}

		if(period && period.id)
		{
			const periodStart : any    = new Date(period.start + " 23:00:00")
			const periodEnd   : any    = new Date(period.end   + " 23:00:00")
			const startDiff   : number = Math.abs(Math.floor((date - periodStart) / 86400000))
			const endDiff     : number = Math.abs(Math.floor((date - periodEnd) / 86400000))
			const minDiff     : number = Math.min(startDiff, endDiff)

			if(minDiff > 7)
			{
				proceedingSearch = false

				if(minDiff == startDiff)
				{
					if(!notAWeekBefore)
					{
						setNotAWeekBefore(true)
						setClassroomRef({})
						setTimeout(() => setNotAWeekBefore(false), 3000)
					}
				}
				else
				{
					if(!notAWeekAfter)
					{
						setNotAWeekAfter(true)
						setClassroomRef({})
						setTimeout(() => setNotAWeekAfter(false), 3000)
					}
				}
			}
		}

		if(!proceedingSearch)
		{
			
		}
		else
		{
			try
			{
				const dateData : any = date

				dispatch(enableIdleLockSwitch())

				const result = await CalendarService.getWeeklyView(date, newSearch, rangeFilterRef)

				if(result.status == 200 || result.status == 204)
				{
					setInfoShown(null)
					setCustomSearch(newSearch)
					setCurrentSearch(newSearch)

					if(dateData == "today" && result.data.itIsToday == false)
					{
						setNotThisWeek(true)
						setClassroomRef({})
						setTimeout(() => setNotThisWeek(false), 3000)
					}
					else
					{
						let scheduleIndex : any = {}
						let classroomIndex : any = {}
						let studentIndex : any = {}
						let attendanceIndex : any = {}
						let dayAttendanceCount : any = {}
						let sessionIndex : any
						let tuitionIndebtedCount : number
						let kitIndebtedCount : number
						let unlinkedKitCount : number
						let recordDay : string
						let i : number
						let index : number
						let startingHour : number
						let day : Date =  new Date(result.data.start + " 23:00:00")
						let date : string =  result.data.start
						let currentWeekDaysList : any = [result.data.start]
						let currentWeekDates : any = [toAscendingDate(day)]
						let displayUnrestricted : boolean = unrestrictedResultsDisplay(newSearch)

						setUnrestrictedDisplay(displayUnrestricted)
						setToday(new Date(result.data.today + " 23:00:00"))
						setCurrentDay
						(
							result.data.date >= result.data.start && result.data.date <= result.data.end
								? new Date(result.data.date + " 23:00:00")
									: new Date(result.data.start + " 23:00:00")
						)

						setInThisWeek
						(
							result.data.date && result.data.dateIsCurrent
								? toAscendingDate(new Date(result.data.date + " 23:00:00"))
									: null
						)

						do
						{
							day.setDate(day.getDate() + 1)

							date = toAcceptableDate(day)

							currentWeekDates.push(toAscendingDate(day))
							currentWeekDaysList.push(date)
						}
						while(date != result.data.end)

						result.data.classrooms.map
						(
							(classroom : any)=>
							{
								classroomIndex["C_" + classroom.id]=
								{
									...classroom, dayList : [[], [], [], [], [], [], []],
									dayPlan:
									[
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution),
										_.cloneDeep(dayDistribution)
									],
								}
							}
						)

						result.data.schedules.map
						(
							(schedule : any)=>
							{
								sessionIndex       = {}
								studentIndex       = {}
								attendanceIndex    = {}
								dayAttendanceCount = {}
								tuitionIndebtedCount = 0
								kitIndebtedCount = 0
								unlinkedKitCount = 0
								schedule.sessions.map
								(
									(session : any)=>
									{
										startingHour = session.start_hours
										i = startingHour
										do
										{
											index = (i - 70) / 5
											classroomIndex["C_" + schedule.classroom_id].dayPlan[session.week_day][index].ref=
												"S_" + session.schedule_id
											i += 5
										}
										while(i < session.end_hours)
										sessionIndex["D_" + session.week_day] = session
									}
								)
								schedule
									.enrollments
									.sort((a : any, b : any) => +(a.created_at < b.created_at) - +(a.created_at > b.created_at))
									.map
									(
										(enrollment : any)=>
										{
											if
											(
												(+enrollment.expiredMonthlyEnrollmentBalance > 0)
													&& enrollment.status_id != ENROLLMENT_STATUS.NOT_STARTED
											)
											{
												tuitionIndebtedCount++
											}

											if(+enrollment.kitsEnrollmentBalance > 0)
											{
												kitIndebtedCount++
											}

											if(studentIndex["S_" + enrollment.student_id] == null)
											{
												studentIndex["S_" + enrollment.student_id] = {...enrollment.student, enrollments : [enrollment]}

												if(enrollment.status_id == ENROLLMENT_STATUS.ACTIVE && enrollment.kits.length < 1)
												{
													unlinkedKitCount++
												}
											}
											else
											{
												studentIndex["S_" + enrollment.student_id].enrollments.push(enrollment)
											}
										}
									)

								schedule.attendances.map
								(
									(attendance : any)=>
									{
										recordDay = attendance.attendance_datetime.split("T")[0]

										if(dayAttendanceCount[recordDay] == null)
										{
											dayAttendanceCount[recordDay] = 0
										}

										if
										(
											attendance.compensation_datetime == null &&
												(attendance.status_id > attendanceStatus.ABSENT && attendance.status_id < attendanceStatus.COMPENSATION)
										)
										{
											dayAttendanceCount[recordDay]++

											attendanceIndex[recordDay + "_" + attendance.student_id]=
											{
												studentId : attendance.student_id,
												statusId : attendance.status_id
											}
										}
									}
								)

								scheduleIndex["S_" + schedule.id]=
								{
									...schedule, sessionRef : sessionIndex,
									students : Object.values(studentIndex),
									attendanceRef : attendanceIndex,
									tuitionIndebtedCount : tuitionIndebtedCount,
									kitIndebtedCount : kitIndebtedCount,
									unlinkedKitCount : unlinkedKitCount,
									dayAttendanceCountRef : dayAttendanceCount
								}
							}
						)

						let availableSpacesCount : number = 0
						let availableSpace       : any    = null

						if(displayUnrestricted)//Use of any of the following filters makes the available hours space display to be skipped
						{
							Object.values(classroomIndex).map
							(
								(classroom : any)=>
								{
									classroom.dayPlan.map
									(
										(day : any, dayIndex : number)=>
										{
											if(availableSpace)
											{
												scheduleIndex["A_" + availableSpace.id] = availableSpace

												availableSpacesCount++
											}

											availableSpace = null

											day.map
											(
												(halfHour : any, hourIndex : number)=>
												{
													if(halfHour.ref == null && halfHour.forbidden == null)
													{
														if(availableSpace == null)
														{
															availableSpace=
															{
																id          : availableSpacesCount,
																start_hours : (hourIndex * 5) + 70,
																end_hours   : (hourIndex * 5) + 75,
															}
														}
														else
														{
															availableSpace.end_hours = (hourIndex * 5) + 75
														}

														halfHour.ref = "A_" + availableSpace.id
													}
													else
													{
														if(availableSpace && halfHour.ref)
														{
															scheduleIndex["A_" + availableSpace.id] = availableSpace
															availableSpace                          = null

															availableSpacesCount++
														}
													}
												}
											)
										}
									)
								}
							)
						}

						if(availableSpace)
						{
							scheduleIndex["A_" + availableSpace.id] = availableSpace

							availableSpacesCount++
						}

						availableSpace = null

						let lastAddedIndex : number = -1

						Object.values(classroomIndex).map
						(
							(classroom : any)=>
							{
								classroom.dayPlan.map
								(
									(day : any, dayIndex : number)=>
									{
										lastAddedIndex = -1

										day.map
										(
											(halfHour : any)=>
											{
												if
												(
													(halfHour.forbidden == null && halfHour.ref != null) &&
														(lastAddedIndex < 0 || classroom.dayList[dayIndex][lastAddedIndex] != halfHour.ref)
												)
												{
													classroom.dayList[dayIndex].push(halfHour.ref)

													lastAddedIndex++
												}
											}
										)
									}
								)
							}
						)

						if(Object.keys(scheduleIndex).length < 1 || (!unrestrictedResultsDisplay(customSearch) && Object.keys(scheduleIndex).length < 1))
						{
							if(!noResultsFound)
							{
								setNoResultsFound(true)

								setTimeout
								(
									() => setNoResultsFound(false),
									3000
								)
							}
						}

						setWeekDates(currentWeekDates)
						setClassroomRef(classroomIndex)
						setScheduleRef(scheduleIndex)
						setCurrentWeekDays(currentWeekDaysList)
						setStartDay(result.data.start)
						setEndDay  (result.data.end)
						setSectionLoaded(true)
					}
				}
				else
				{
					procedureComplaint(searchError)
				}
			}
			catch(error : any)
			{
				if(error.response.status == 400)
				{
					setNotThisWeek(true)
					setClassroomRef({})
					setTimeout(() => setNotThisWeek(false), 3000)
				}
				else
				{
					console.log(error)
					procedureComplaint(searchError)
				}
			}
			finally
			{
				dispatch(disableIdleLockSwitch())
			}
		}
	}

	var _startX      : number = 0;
	var _startY      : number = 0;
	var _offsetX     : number | undefined = 0;
	var _offsetY     : number | undefined = 0;
	var _dragElement : any

	const onMouseDown = (event : any)=>
	{
		document.onmousemove = onMouseMove;
		_startX      = event.clientX;
		_startY      = event.clientY;
		_offsetX     = document.getElementById("dragScrollArea")?.offsetLeft;
		_offsetY     = document.getElementById("dragScrollArea")?.offsetTop;
		_dragElement = document.getElementById("dragScrollArea");
	}

	const onMouseMove = (event : any)=>
	{
		let changeX : number = _offsetX + event.clientX - _startX
		let changeY : number = _offsetY + event.clientY - _startY

		if(changeX <= 0)
		{
			_dragElement.style.left = changeX + "px";
		}

		if(changeY <= 0)
		{
			_dragElement.style.top = changeY + "px";
		}
	}

	const onMouseUp = (event : any)=>
	{
		document.onmousemove = null;
		_dragElement         = null;
	}

	document.onmousedown = onMouseDown;
	document.onmouseup   = onMouseUp;

	const promptEnrolling = async(course : any, level : any, schedule : any)=>
	{
		let retrievalError : string = "La lista de matrículas no pudo ser extraída"

		try
		{
			dispatch(enableIdleLockSwitch())

			const result = await HttpManager.getInstance().enrollmentService.searchEnrollments(schedule.id)

			if(result.status == 200 || result.status == 204)
			{
				setSelectedEnrollment(enrollingSet(course, level, schedule, result.data, campusRef[currentUserCampus].company_id))
			}
			else
			{
				procedureComplaint(retrievalError)
			}
		}
		catch(error)
		{
			console.log(error)
			procedureComplaint(retrievalError)
		}
		finally
		{
			dispatch(disableIdleLockSwitch())
		}
	}

	const DateInput = forwardRef
	(
		({value, onClick} : any, ref : ForwardedRef<HTMLButtonElement>)=>
		{
			const dayLabel   : string = weekDays[retardWeekDayOffset[currentDay.getDay()]]
			const monthLabel : string = monthRef[currentDay.getMonth()]
			const yearLabel  : string = currentDay.getFullYear() + ""

			return (currentDay) &&
				<button className="text-center btn btn-default" ref={ref} disabled={idleLocked} onClick={onClick} type="button"
				  style={{paddingTop : "15px", height : "100%", border : "1px solid #C7C7C7"}}
				>
					<h4>
						<span className="d-xl-none d-lg-none d-md-none d-sm-none d-xs-none">
							Calendario
						</span>
						<span style={{display : "inline-block", color : "#8F91DA"}}>
							<span className="d-none d-md-inline-block">
								{dayLabel.substring(0, 2)}
							</span>
							<span className="d-none d-lg-inline-block">
								{dayLabel.substring(2)}
							</span>
							&nbsp;
							{sprintf("%02d", currentDay.getDate())}
							&nbsp;
							<span>
								{monthLabel.substring(0, 3)}
							</span>
							<span className="d-none d-lg-inline-block">
								{monthLabel.substring(3)}
							</span>
							&nbsp;
							<span className="d-none d-lg-inline-block">
								{yearLabel.substring(0, 2)}
							</span>
							<span>
								{yearLabel.substring(2)}
							</span>
						</span>
					</h4>
				</button>
		}
	)

	return !sectionLoaded ? "" : <>
		<div style={{margin : "0px", padding : "15px"}}>
			<table><tbody><tr>
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell"
				  style={{verticalAlign : "bottom", paddingRight : "5px"}}
				><h1>
					Calendario
				</h1></td>
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell" style={{width : "50px"}} />
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell" style={{paddingTop : "5px"}}>
					<Popover positions={["bottom"]} isOpen={notThisWeek} content=
					  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
						</div>
						&nbsp;
						La semana se encuentra fuera del periodo seleccionado
					  </div>}
					><button style={{width : "125px", height : "50px", border : "1px solid #000000", whiteSpace : "nowrap"}}
					  className="btn btn-default rounded-pill" onClick={() => search(customSearch.period, "today")} disabled={idleLocked} type="button"
					>
						Esta semana
					</button></Popover>
				</td>
				<td style={{width : "50px"}} className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell" />
				<td className="d-none d-sm-table-cell d-md-table-cell d-lg-table-cell d-xl-table-cell"><Popover positions={["bottom"]}
				  isOpen={notAWeekBefore ||notAWeekAfter} content=
				  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
					<div className="requirementAlert">
						<FontAwesomeIcon icon={solid("exclamation")}/>
					</div>
					&nbsp;
					El día {notAWeekBefore ? "anterior" :  "posterior"} se encuentra fuera del periodo seleccionado
				  </div>}
				><div style={{display : "flex"}}>{
					count.map
					(
						(step : number, index : number) => <button key={"D" + step} style={{color : "#5154AE"}} type="button"
						  className="btn btn-default" disabled={idleLocked} onClick=
						  {
							()=>
							{
								let date = new Date(currentDay)

								date.setDate(currentDay.getDate() + (step * 7))
								search(customSearch.period, new Date(date))
							}
						  }
						><b>
							<FontAwesomeIcon icon={icons[index]} size="2x" />
						</b></button>
					)
				}</div></Popover></td>
				<td>{
					((yearsRange == null || yearsRange.length < 1) || currentDay == null) ? "" : <LeDatePicker months={monthRef}
					  minDate={minDate} disabled={idleLocked} name="birthdayValue" customInput={<DateInput />} maxDate={maxDate}
					  value={currentDay} onChange={(e : any) => search(customSearch.period, e.target.value)} id="birthdayValue" years={yearsRange} required
					/>
				}</td>
			</tr></tbody></table>
			<div style={{display : "inline-block", position : "fixed", zIndex : 2, background : "var(--second-bg)", width : "310px", paddingTop : "15px"}}>
				<table><tbody><tr>
					<td style={{width : "100%"}}><Popover positions={["bottom"]} isOpen={noResultsFound} content=
					  {<div style={{background : "var(--second-bg)", padding : "5px 5px 0px 5px"}}>
						<div className="requirementAlert">
							<FontAwesomeIcon icon={solid("exclamation")}/>
						</div>
						&nbsp;
						Sin resultados
					  </div>}
					><button
					  style={{border : "1px solid #000000", width : "100%"}}
					  onClick={() => search(customSearch.period, currentDay)}
					  disabled={idleLocked}
					  className="btn btn-default"
					  type="button"
					>
						Buscar
						&nbsp;
						<FontAwesomeIcon icon={solid("magnifying-glass-arrow-right")} />
					</button></Popover></td>
					<td><div style={{width : "50px"}}>{
						_.isEqual(currentSearch, customSearch) && _.isEqual(currentSearch, defaultSearch) ? "" : <button type="button" className="btn btn-default"
						  disabled={idleLocked} onClick={clearSearch}
						>
							<FontAwesomeIcon icon={solid("times")} />
						</button>
					}</div></td>
				</tr></tbody></table>
			</div>
		</div>
		<div style={{display : "flex", width : "100vw", height : "calc(100vh - 149px)"}}>
			<CalendarSideFilter distributionRef={distributionRef} searchFn={search} periods={periods} buildingRef={buildingRef}
			  courses={courses} courseRef={courseRef} buildings={buildings} customSearch={customSearch} scheduleRef={scheduleRef}
			  yearsRangeSetting={setYearsRange} mentors={mentors} searchChangeFn={handleSearchChange} periodSearch={periodSearch}
			  unrestrictedDisplay={unrestrictedDisplay} availabilityDisplay={availabilityDisplay}
			  setAvailabilityDisplay={setAvailabilityDisplay}
			/>
			<div style={{height : "calc(100vh - 149px)", width : "calc(100vw - 340px)", overflow : "auto"}}><div
			  style={{width : "1775px"}}
			>{
				currentWeekDays == null || currentWeekDays.length < 1 ? "" : <div style={{height : "100%", width : "100%"}}>
					<div style={{display : "flex", alignItems : "stretch"}}>{
						weekDays.map
						(
							(date : string, index : number) => <div key={"D" + date} style=
							  {{
								background : weekDates[index] != inThisWeek ? "#F2F2FF" : "#232552",
								color      : weekDates[index] != inThisWeek ? "#000000" : "#FFFFFF",
								textAlign  : "center",
								border     : "1px solid  #FFFFFF",
								padding    : "5px",
								width      : "14%",
								flex       : 1
							  }}
							>
								{date}
								<br />
								{weekDates[index]}
							</div>
						)
					}</div>
					{
						Object.keys(classroomRef).length < 1
							?
						<div style={{display : "flex", alignItems : "stretch"}}>
							{weekDays.map((date : string) => <div style={{flex : 1, height : "100px"}} key={date} />)}
						</div>
							:
						Object.values(classroomRef).map
						(
							(classroom : any) => <div key={"C_" + classroom.id} style={{display : "flex", alignItems : "stretch"}}>{
								currentWeekDays.map
								(
									(date : string, dayIndex : number) => <div key={"C_" + classroom.id + " " + dayIndex}
									  style={{width : "14.3%", verticalAlign : "top", flex : 1, alignSelf : "stretch"}}
									>{
										(
											(
												((startDay == null || endDay == null) || (date < startDay || date > endDay))
													||
												(
													(currentSearch.period && currentSearch.period.id) &&
														((date < currentSearch.period.start) || (date > currentSearch.period.end))
												)
											)
												||
											classroom.dayList[dayIndex].length < 1
										) ? "" :
										<div style=
										{{
											border        : "1px solid #006666",
											borderRadius  : "15px",
											width         : "100%",
											height        : "100%",
										}}>
											<div style=
												{{
													display      : "flex",
													border       : "1px solid #006666",
													borderRadius : "15px 15px 0px 0px"
												}}
											>
												<div style=
												  {{
													background : "#0000BB",
													width      : "25px",
													borderTopLeftRadius : "15px"
												  }}
												>
												</div>
												<div style={{width : "50%", paddingLeft : "5px"}}>
													{classroom.building.name}
												</div>
												<div style={{background : "#00BB00", width : "25px"}} />
												<div style=
												  {{
													paddingLeft  : "5px",
													whiteSpace   : "nowrap",
													overflow     : "hidden",
													textOverflow : "ellipsis"
												  }}
												>
													{classroom.name}
												</div>
											</div>
											<div className="weekDayClassroom">
											<div>
											</div>
											{
												classroom.dayList[dayIndex].map
												(
													(key : string, index : number) => <React.Fragment key={key}>
														{
															index < 1 ? "" : <div style={{textAlign : "center", width : "100%"}}>
																<hr style={{background : "#C7C7C7", width : "90%", margin : "auto", height : "3px"}} />
															</div>
														}
														{
															scheduleRef[key].sessions == null
																	?
																(
																	!availabilityDisplay ? "" : <div style={{flex : 1, width : "100%"}}>
																		<div
																		  style={{background : "#B8FFBC", height : "100%", padding : "15px 0px 0px 15px", border : "5px dashed #2F316B"}}
																		>
																			<div style={{fontWeight : "900"}}>
																				LIBRE
																			</div>
																			<div />
																			<div style={{display : "flex"}}>{
																				sprintf
																				(
																					"%02d:%02d-%02d:%02d",
																					(
																						scheduleRef[key].start_hours-
																							(scheduleRef[key].start_hours % 10)
																					) / 10,
																					(scheduleRef[key].start_hours % 10) * 6,
																					(
																						scheduleRef[key].end_hours-
																							(scheduleRef[key].end_hours % 10)
																					) / 10,
																					(scheduleRef[key].end_hours % 10) * 6
																				)
																			}</div>
																			<div style={{width  : "100%", height : "25px"}} />
																		</div>
																	</div>
																)
																	:
																<div style={{flex : 1, width : "100%"}}>
																	<div style={{height : "100%"}}>
																		<div style={{padding : "15px"}}>
																			<div style={{display : "flex"}}>
																				<div style=
																				  {{
																					fontWeight : "900",
																					color      : "#C32722",
																					width      : "50%"
																				  }}
																				>
																					<div className={"badge bg-" + (scheduleRef[key].status_id == scheduleStatus.ACTIVE ? "primary" : "secondary")}>
																						{scheduleRef[key].status.name}
																					</div>
																					{scheduleRef[key].level.course.name}
																				</div>
																				<div style={{width : "50%"}}>{
																					(
																						scheduleRef[key].mentor &&
																						(
																							scheduleRef[key].mentor.first_name +
																								" " +
																							scheduleRef[key].mentor.last_name
																						)
																					)
																						|| "Mentor no asignado"
																				}</div>
																			</div>
																			<div><div style={{color : "#8F91DA"}}>
																				{scheduleRef[key].level.name}
																				&nbsp;
																				{scheduleRef[key].name}
																			</div></div>
																			<div style={{display : "flex"}}>
																				<div style={{width : "50%"}}>{
																					sprintf
																					(
																						"%02d:%02d-%02d:%02d",
																						(
																							scheduleRef[key].sessionRef["D_" + dayIndex].start_hours-
																								(scheduleRef[key].sessionRef["D_" + dayIndex].start_hours % 10)
																						) / 10,
																						(scheduleRef[key].sessionRef["D_" + dayIndex].start_hours % 10) * 6,
																						(
																							scheduleRef[key].sessionRef["D_" + dayIndex].end_hours-
																								(scheduleRef[key].sessionRef["D_" + dayIndex].end_hours % 5)
																						) / 10,
																						(scheduleRef[key].sessionRef["D_" + dayIndex].end_hours % 10) * 6
																					)
																				}</div>
																				<div style=
																				  {{
																					width         : "50%",
																					textAlign     : "right",
																					color         : "#FF6600",
																					textTransform : "uppercase"
																				  }}
																				>
																					<div>
																						{periodRef["P_" + scheduleRef[key].period.id].name}
																					</div>
																				</div>
																			</div>
																		</div>
																		<div style={{color : "#8F91DA", display : "flex", alignItems : "stretch", width : "100%"}}>
																			<Popover
																			  onClickOutside={infoShownSetting}
																			  isOpen={infoShown == key + "/" + date + ".capacity"}
																			  content=
																			  {<div style={{background : "var(--second-bg)", padding : "5px"}}>
																				<div style=
																				  {{
																					position : "absolute",
																					top : "0px",
																					right : "0px",
																					width : "38px",
																					height : "38px"
																				  }}
																				>
																					<button
																					  type="button"
																					  className="button btn btn-default active"
																					  onClick={infoShownSetting}
																					  style=
																					  {{
																						position : "relative",
																						left : "18px",
																						bottom : "18px",
																						width : "38px",
																						height : "38px",
																						borderRadius : "100%",
																						background : "var(--second-bg)"
																					  }}
																					>
																						<FontAwesomeIcon icon={solid("times")}/>
																					</button>
																				</div>
																				<fieldset>
																					<legend style={{color : "#8F91DA"}}>
																						Capacidad
																					</legend>
																					{scheduleRef[key].enrolled_students}/{scheduleRef[key].classroom.students_limit}
																				</fieldset>
																			  </div>}
																			>
																				<div style={{flexGrow : 1, textAlign : "center"}}>
																					<OverlayTrigger overlay=
																					  {<Tooltip id="tooltip-activos">
																						Capacidad
																					  </Tooltip>}
																					>
																						<button
																						  type="button"
																						  disabled={idleLocked}
																						  className="btn btn-default"
																						  style={{width : "100%", padding : "unset", textAlign : "center"}}
																						  onClick={() => setInfoShown(key + "/" + date + ".capacity")}
																						>
																							<FontAwesomeIcon icon={solid("users")} />
																						</button>
																					</OverlayTrigger>
																				</div>
																			</Popover>
																			<Popover
																			  onClickOutside={() => infoShownSetting()}
																			  isOpen={infoShown == key + "/" + date + ".subsMin"}
																			  positions={["bottom"]}
																			  content=
																			  {<div style={{background : "var(--second-bg)", padding : "5px"}}>
																				<div style=
																				  {{
																					position : "absolute",
																					top : "0px",
																					right : "0px",
																					width : "38px",
																					height : "38px"
																				  }}
																				>
																					<button
																					  type="button"
																					  className="button btn btn-default active"
																					  onClick={infoShownSetting}
																					  style=
																					  {{
																						position : "relative",
																						left : "18px",
																						bottom : "18px",
																						width : "38px",
																						height : "38px",
																						borderRadius : "100%",
																						background : "var(--second-bg)"
																					  }}
																					>
																						<FontAwesomeIcon icon={solid("times")}/>
																					</button>
																				</div>
																				<fieldset>
																					<legend style={{color : "#8F91DA"}}>
																						Ins/min
																					</legend>
																					{scheduleRef[key].enrolled_students}/{scheduleRef[key].students_minimum}
																				</fieldset>
																			  </div>}
																			>
																				<div style={{flexGrow : 1, textAlign : "center"}}>
																					<OverlayTrigger overlay=
																					  {<Tooltip id="tooltip-activos">
																						Inscritos/Mínimo
																					  </Tooltip>}
																					>
																						<button
																						  type="button"
																						  disabled={idleLocked}
																						  className="btn btn-default"
																						  style={{width : "100%", padding : "unset", textAlign : "center"}}
																						  onClick={() => infoShownSetting(key + "/" + date + ".subsMin")}
																						>
																							<FontAwesomeIcon icon={solid("address-book")} />
																						</button>
																					</OverlayTrigger>
																				</div>
																			</Popover>
																			<Popover
																			  onClickOutside={infoShownSetting}
																			  isOpen={infoShown == key + "/" + date + ".attendance"}
																			  content=
{<div style={{background : "var(--second-bg)", padding : "5px", maxWidth : "100vh", maxHeight : "100vh", overflow : "auto"}}>
	<div style=
	  {{
		position : "absolute",
		top : "0px",
		right : "0px",
		width : "38px",
		height : "38px"
	  }}
	>
		<button
		  type="button"
		  className="button btn btn-default active"
		  onClick={infoShownSetting}
		  style=
		  {{
			position : "relative",
			left : "18px",
			bottom : "18px",
			width : "38px",
			height : "38px",
			borderRadius : "100%",
			background : "var(--second-bg)"
		  }}
		>
			<FontAwesomeIcon icon={solid("times")}/>
		</button>
	</div>
	<fieldset style={{padding : "unset"}}>
		<legend style={{color : "#8F91DA"}}>
			Asistencia
		</legend>
		{
			(scheduleRef[key].dayAttendanceCountRef[date] == null || scheduleRef[key].dayAttendanceCountRef[date] < 1)
					?
				<div style={{padding : "15px"}}>
					Sin registros
				</div>
					:
				<div style={{maxHeight : "275", overflowY : "auto"}}><table className="specialTable">
					<thead><tr>
						<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
							Matrícula
						</th>
						<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
							Nombre
						</th>
					</tr></thead>
					<tbody>{
						Object.values(scheduleRef[key].attendanceRef).sort((recordA : any, recordB : any) => recordB.statusId - recordA.statusId).map
						(
							(record : any)=>
							{
								const inDebt : boolean=
								(
									(
										+scheduleRef[key].studentRef["S_" + record.studentId].tuition_debt -
											+scheduleRef[key].studentRef["S_" + record.studentId].tuition_debt_settlement
									) > 0 ||
									(
										+scheduleRef[key].studentRef["S_" + record.studentId].kit_debt -
											+scheduleRef[key].studentRef["S_" + record.studentId].kit_debt_settlement
									) > 0
								)

								const color : any =
								{
									color:
									(
										scheduleRef[key].attendanceRef[date + "_" + record.studentId] != null &&
											scheduleRef[key].attendanceRef[date + "_" + record.studentId].statusId > attendanceStatus.ABSENT
									)
										? (inDebt ? "#FFC1BF" : "#C7C7C7")
											: (inDebt ? "#BA0000" : "#000000")
								}

								return <tr>
									<td style={color} ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
										{scheduleRef[key].studentRef["S_" + record.studentId].id}
									</td>
									<td style={color} ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}}>
										{scheduleRef[key].studentRef["S_" + record.studentId].student.first_name}
										&nbsp;
										{scheduleRef[key].studentRef["S_" + record.studentId].student.last_name}
									</td>
								</tr>
							}
						)
					}</tbody>
				</table>
			</div>
		}
	</fieldset>
</div>}
																		>
																			<div style={{flexGrow : 1, textAlign : "center"}}>
																				<OverlayTrigger overlay=
																				  {<Tooltip id="tooltip-activos">
																					Asistencia
																				  </Tooltip>}
																				>
																					<button
																					  type="button"
																					  disabled={idleLocked}
																					  className="btn btn-default"
																					  style={{width : "100%", padding : "unset", textAlign : "center"}}
																					  onClick={() => infoShownSetting(key + "/" + date + ".attendance")}
																					>
																						<FontAwesomeIcon icon={solid("list-check")} />
																					</button>
																				</OverlayTrigger>
																			</div>
																		</Popover>
																		<Popover
																		  onClickOutside={infoShownSetting}
																		  isOpen={infoShown == key + "/" + date + ".tuitionDebt"}
																		  content=
																		  {<div style={{background : "var(--second-bg)", padding : "5px", maxWidth : "100vh", maxHeight : "100vh", overflow : "auto"}}>
																			<div style=
																			  {{
																				position : "absolute",
																				top : "0px",
																				right : "0px",
																				width : "38px",
																				height : "38px"
																			  }}
																			>
																				<button
																				  type="button"
																				  className="button btn btn-default active"
																				  onClick={infoShownSetting}
																				  style=
																				  {{
																					position : "relative",
																					left : "18px",
																					bottom : "18px",
																					width : "38px",
																					height : "38px",
																					borderRadius : "100%",
																					background : "var(--second-bg)"
																				  }}
																				>
																					<FontAwesomeIcon icon={solid("times")}/>
																				</button>
																			</div>
																			<fieldset style={{padding : "unset"}}>
																				<legend style={{color : "#8F91DA"}}>
																					Colegiaturas
																					{
																						scheduleRef[key].tuitionIndebtedCount < 6 ? "" : " (" + scheduleRef[key].tuitionIndebtedCount + ")"
																					}
																				</legend>
{
	scheduleRef[key].tuitionIndebtedCount < 1
			?
		<div style={{padding : "15px"}}>
			Sin registros
		</div>
			:
		<div>
			<table className="specialTable">
				<thead><tr>
					<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
						Matrícula
					</th>
					<th>
						&nbsp;
						Nombre
					</th>
					<th ref={el => {if(el){el.style.setProperty("border-bottom", "1px solid #000000", "important")}}}>
						Saldo
					</th>
				</tr></thead>
				<tbody>{
					scheduleRef[key]
						.students
						.filter
						(
							(student : any) => ((+student.enrollments[0].expiredMonthlyEnrollmentBalance) > 0)
								&& student.enrollments[0].status_id != ENROLLMENT_STATUS.NOT_STARTED
						)
						.sort((a : any, b : any)=> b.enrollments[0].expiredMonthlyEnrollmentBalance - a.enrollments[0].expiredMonthlyEnrollmentBalance)
						.map
						(
							(student : any)=>
							{
								const style : any=
								{
									color : (+student.enrollments[0].expiredMonthlyEnrollmentBalance > 0 ? "#BA0000" : "#000000"),
									outline : "5px solid " + (student.enrollments[0].status_id == ENROLLMENT_STATUS.ACTIVE ? "unset" : "#C7C7C7")
								}

								return <tr key={"SE_" + student.id} style={{background : student.enrollments[0].status_id == ENROLLMENT_STATUS.ACTIVE ? "unset" : "#C7C7C7"}}>
									<td
									  style={style}
									  ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
									>
										{student.id}
									</td>
									<td style={{...style, paddingRight : "5px"}}>
										{student.first_name}
										&nbsp;
										{student.last_name}
									</td>
									<td
									  style={{...style, textAlign : "right"}}
									  ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}
									>
										<div style={{display : "flex", alignItems : "stretch", whiteSpace : "nowrap"}}>
											<span style={{paddingRight : "5px"}}>
												{campusRef[currentUserCampus].company.currency.symbol}
											</span>
											<span style={{flexGrow : 1}}>{
												sprintf
												(
													"%.02f " + campusRef[currentUserCampus].company.currency.code,
													+student.enrollments[0].expiredMonthlyEnrollmentBalance
												)
											}</span>
										</div>
									</td>
								</tr>
							}
						)
				}</tbody>
			</table>
		</div>
}
																			</fieldset>
																		  </div>}
																		>
																			<div style={{flexGrow : 1, textAlign : "center"}}>
																				<OverlayTrigger overlay=
																				  {<Tooltip id="tooltip-activos">
																					Colegiaturas
																				  </Tooltip>}
																				>
																					<button
																					  type="button"
																					  disabled={idleLocked}
																					  className="btn btn-default"
																					  style={{width : "100%", padding : "unset", textAlign : "center"}}
																					  onClick={() => infoShownSetting(key + "/" + date + ".tuitionDebt")}
																					>
																						<FontAwesomeIcon icon={solid("money-bills")} />
																					</button>
																				</OverlayTrigger>
																			</div>
																		</Popover>
																		<Popover
																		  onClickOutside={infoShownSetting}
																		  isOpen={infoShown == key + "/" + date + ".kitDebt"}
																		  content=
																		  {<div style={{background : "var(--second-bg)", padding : "5px", maxWidth : "100vh", maxHeight : "100vh", overflow : "auto"}}>
																			<div style=
																			  {{
																				position : "absolute",
																				top : "0px",
																				right : "0px",
																				width : "38px",
																				height : "38px"
																			  }}
																			>
																				<button
																				  type="button"
																				  className="button btn btn-default active"
																				  onClick={infoShownSetting}
																				  style=
																				  {{
																					position : "relative",
																					left : "18px",
																					bottom : "18px",
																					width : "38px",
																					height : "38px",
																					borderRadius : "100%",
																					background : "var(--second-bg)"
																				  }}
																				>
																					<FontAwesomeIcon icon={solid("times")}/>
																				</button>
																			</div>
																			<fieldset style={{padding : "unset"}}>
																				<legend style={{color : "#8F91DA"}}>
																					Saldos Kits
																				</legend>
{
	(scheduleRef[key].kitIndebtedCount < 1 && scheduleRef[key].unlinkedKitCount < 1)
			?
		<div style={{padding : "15px"}}>
			Sin registros
		</div>
			:
		<div style={{maxHeight : "275", overflowY : "auto"}}>
			<table className="specialTable">
				<thead><tr>
					<th ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
						Matrícula
					</th>
					<th>
						&nbsp;
						Nombre
					</th>
					<th ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #000000", "important")}}>
						Saldo
					</th>
					<th />
				</tr></thead>
				<tbody>{
					scheduleRef[key]
						.students
						.filter((student : any) => (+student.enrollments[0].kitsEnrollmentBalance) > 0 || student.enrollments[0].kits.length < 1)
						.sort((a : any, b : any)=> b.enrollments[0].kitsEnrollmentBalance - a.enrollments[0].kitsEnrollmentBalance)
						.map
						(
							(student : any)=>
							{
								const color : any =
								{
									color : (+student.enrollments[0].kitsEnrollmentBalance > 0 ? "#BA0000" : "#000000"),
									outline : "5px solid " + (student.enrollments[0].status_id == ENROLLMENT_STATUS.ACTIVE ? "unset" : "#C7C7C7"),
									background : student.enrollments[0].status_id == ENROLLMENT_STATUS.ACTIVE ? "unset" : "#C7C7C7",
								}
								return <tr key={student.enrollments[0].id}>
									<td style={color} ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
										{student.enrollments[0].id}
									</td>
									<td style={{...color, paddingRight : "5px"}}>
										{student.first_name}
										&nbsp;
										{student.last_name}
									</td>
									<td style={{...color, textAlign : "right"}} ref={el => {if(el)el.style.setProperty("border-bottom", "1px solid #C7C7C7", "important")}}>
										<div style={{display : "flex", alignItems : "stretch", whiteSpace : "nowrap"}}>
											<span style={{paddingRight : "5px"}}>
												{campusRef[currentUserCampus].company.currency.symbol}
											</span>
											<span style={{flexGrow : 1}}>{
												sprintf
												(
													"%.02f " + campusRef[currentUserCampus].company.currency.code,
													+student.enrollments[0].kitsEnrollmentBalance
												)
											}</span>
										</div>
									</td>
									<td>{
										student.enrollments[0].kits.length > 0 ? "" : <span className="badge bg-danger" style={{height : "20px"}}>
											Sin kit
										</span>
									}</td>
								</tr>
							}
						)
				}</tbody>
			</table>
		</div>
}
																			</fieldset>
																		  </div>}
																		>
																			<div style={{flexGrow : 1, textAlign : "center"}}>
																				<OverlayTrigger overlay=
																				  {<Tooltip id="tooltip-activos">
																					Saldos Kits
																				  </Tooltip>}
																				>
																					<button
																					  type="button"
																					  disabled={idleLocked}
																					  className="btn btn-default"
																					  style={{width : "100%", padding : "unset", textAlign : "center", color : (scheduleRef[key].unlinkedKitCount > 0 ? "#FF0000" : "#000000")}}
																					  onClick={() => infoShownSetting(key + "/" + date + ".kitDebt")}
																					>
																						<FontAwesomeIcon icon={solid("toolbox")} />
																					</button>
																				</OverlayTrigger>
																			</div>
																		</Popover>
																		<OverlayTrigger overlay={<Tooltip id="tooltip-activos">Ver detalles</Tooltip>}>
																			<div style={{flexGrow : 1, textAlign : "center"}}>
																				<button
																				  type="button"
																				  disabled={idleLocked}
																				  className="btn btn-default"
																				  style={{width : "100%", padding : "unset", textAlign : "center"}}
																				  onClick=
																				  {
																					()=>
																					{
																						let hours : any = {}
																						scheduleRef[key].sessions.map
																						(
																							(session : any)=>
																							{
																								let currentHours : number = session.start_hours
																								while(currentHours < session.end_hours)
																								{
																									hours
																									[
																										sprintf
																										(
																											"DH%d%03d",
																											session.week_day,
																											currentHours
																										)
																									] = true
																									currentHours += 5
																								}
																							}
																						)
																						promptEnrolling
																						(
																							scheduleRef[key].level.course,
																							{
																								...scheduleRef[key].level, kits : [],
																								kitRef : {}
																							},
																							{...scheduleRef[key], hours : hours}
																						)
																					}
																				  }
																				>
																					<FontAwesomeIcon icon={solid("eye")} flip="horizontal" />
																				</button>
																			</div>
																		</OverlayTrigger>
																	</div>
																</div>
															</div>
														}
													</React.Fragment>
												)
											}</div>
										</div>
									}</div>
								)
							}</div>
						)
					}
				</div>}
			</div></div>
		</div>
		{
			selectedEnrollment == null || selectedUserProfile ? "" : <EnrollmentPrompt closeFn={() => {setSelectedEnrollment(null)}}
			  afterCloseFn={search} selectedEnrollment={selectedEnrollment} profileViewingFn={setSelectedUserProfile}
			/>
		}
		{
			selectedUserProfile == null ? "" : <UserPrompt promptCloseFn={()=> setSelectedUserProfile(null)}
			  employeeView={selectedUserProfile.employeeView}
			/>
		}
	</>
}

export default WeeklyView
