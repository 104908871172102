import Constants, {
    ENROLLMENT_STATUS,
    APPLIED_CREDIT_TYPES
} from "../constants";
import {
    AppliedCreditTypes,
    enrollmentStatusType
} from "../types";

const renderStatusEnrollment = (statusId: enrollmentStatusType) => {
    switch (statusId) {
        case ENROLLMENT_STATUS.ACTIVE:
            return "primary";
        case ENROLLMENT_STATUS.COMPLETED:
            return "success";
        case ENROLLMENT_STATUS.ERROR:
            return "danger";
        case ENROLLMENT_STATUS.NOT_STARTED:
        case ENROLLMENT_STATUS.CHANGE:
        case ENROLLMENT_STATUS.POSTPONED:
        case ENROLLMENT_STATUS.SPECIALIZATION:
            return "warning";
        case ENROLLMENT_STATUS.RETIRED:
            return "dark";
        default:
            return "secondary"
    }
}

const renderImportCreditTitle = (statusId: AppliedCreditTypes, currency: string | null) => {
    let title = "";
    switch (statusId) {
        case APPLIED_CREDIT_TYPES.CREATED:
            title = `${Constants.Common.import} ${Constants.Common.created}`;
            break;
        case APPLIED_CREDIT_TYPES.APPLIED:
            title = `${Constants.Common.import} ${Constants.Common.applied}`;
            break;
        case APPLIED_CREDIT_TYPES.TRANSFERED:
            title = `${Constants.Common.import} ${Constants.Common.transfered}`;
            break;
        case APPLIED_CREDIT_TYPES.RECEIVED:
            title = `${Constants.Common.import} ${Constants.Common.received}`;
            break;
        default:
            title = "";
            break;
    }
    return `${title} (${currency})`;
}

const renderCreditDetailTitle = (statusId: AppliedCreditTypes, invoice: number | null) => {
    let title = "";
    switch (statusId) {
        case APPLIED_CREDIT_TYPES.CREATED:
            title = `${Constants.Common.import} ${Constants.Common.created} por ${Constants.Common.invoice} ${invoice}`;
            break;
        case APPLIED_CREDIT_TYPES.APPLIED:
            title = `${Constants.Common.import} ${Constants.Common.applied} a ${Constants.Common.invoice} ${invoice}`;
            break;
        case APPLIED_CREDIT_TYPES.TRANSFERED:
            title = `${Constants.Common.import} ${Constants.Common.transfered}`;
            break;
        case APPLIED_CREDIT_TYPES.RECEIVED:
            title = `${Constants.Common.import} ${Constants.Common.received}`;
            break;
        default:
            title = "";
            break;
    }
    return title;
}

const renderCreditDateTitle = (statusId: AppliedCreditTypes) => {
    let title = "";
    switch (statusId) {
        case APPLIED_CREDIT_TYPES.CREATED:
            title = `${Constants.Common.date} de ${Constants.Common.creation} de ${Constants.Common.credit}`;
            break;
        case APPLIED_CREDIT_TYPES.APPLIED:
            title = `${Constants.Common.date} de ${Constants.Common.aplication} de ${Constants.Common.credit}`;
            break;
        case APPLIED_CREDIT_TYPES.TRANSFERED:
            title = `${Constants.Common.date} de ${Constants.Common.transfer} de ${Constants.Common.credit}`;
            break;
        case APPLIED_CREDIT_TYPES.RECEIVED:
            title = `${Constants.Common.date} de ${Constants.Common.received} el ${Constants.Common.credit}`;
            break;
        default:
            title = "";
            break;
    }
    return title;
}

const renderInvoiceTitle = (statusId: AppliedCreditTypes) => {
    let title = "";
    switch (statusId) {
        case APPLIED_CREDIT_TYPES.CREATED:
            title = Constants.Common.originInvoice;
            break;
        case APPLIED_CREDIT_TYPES.APPLIED:
            title = Constants.Common.appliedInvoice;
            break;
        default:
            title = Constants.Common.noAppliedInvoice;
            break;
    }
    return title;
}

const renderIsTransferedCredit = (statusId: AppliedCreditTypes) => {
    const title = statusId === APPLIED_CREDIT_TYPES.TRANSFERED
        || statusId === APPLIED_CREDIT_TYPES.RECEIVED
        ? "Sí" : "No"
    return title;
}

export const copyToClipBoard = async (text: string): Promise<boolean> => {
    try {
        await navigator.clipboard.writeText(text);
        console.log('Text copied to clipboard');
        return true; // Success
    } catch (err) {
        console.error('Failed to copy text: ', err);
        return false; // Failure
    }
};

const Utils = {
    renderStatusEnrollment,
    renderImportCreditTitle,
    renderCreditDetailTitle,
    renderCreditDateTitle,
    renderInvoiceTitle,
    renderIsTransferedCredit,
    copyToClipBoard
}

export default Utils;